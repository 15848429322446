<template>
  <AppDataTable
    :selected-row-data="selectedStudySite"
    title="Associated Sites"
    add-button="Associated Site"
    enable-add-button
    searchable
    :search-properties="['site_name', 'idCode']"
    :table-data="studySitesApiResource.getData()"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedStudySiteId"
    @onSearch="onSearch"
  >
    <Column header="Site">
      <template #body="{ data }">
        {{ getStudySiteName(data) }}
      </template>
    </Column>
    <Column header="Status">
      <template #body="{ data }">
        {{
          data.site_status
            ? data.site_status.charAt(0).toUpperCase() +
              data.site_status.slice(1)
            : ""
        }}
      </template>
    </Column>
    <Column header="Secondary ID">
      <template #body="{ data }">
        {{ data.secondary_site_id }}
      </template>
    </Column>
    <!-- <Column header="Site Contact">
      <template #body="{ data }">
        {{ data.site_contact_name }}
      </template>
    </Column>
    <Column header="Site Contact Number">
      <template #body="{ data }">
        {{ data.site_contact_number }}
      </template>
    </Column>
    <Column header="Site Contact Email">
      <template #body="{ data }">
        {{ data.site_contact_email }}
      </template>
    </Column>
    <Column header="Site Contact Fax">
      <template #body="{ data }">
        {{ data.site_contact_fax }}
      </template>
    </Column> -->
    <Column header="Investigator">
      <template #body="{ data }">
        {{ data.investigator }}
      </template>
    </Column>
    <Column header="Users">
      <template #body="{ data }">
        <div style="max-width: 300px">
          {{ getStudySiteUsers(data) }}
        </div>
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddEditStudySite
        :selected-study="selectedStudy"
        :all-sites="allSitesApiResource.getData()"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditStudySiteModal"
    :modal="true"
    :header="`Edit Study Site - ${
      openEditStudySiteModal && getStudySiteName(selectedStudySite)
    }`"
  >
    <AddEditStudySite
      :selected-study-site="selectedStudySite"
      :is-editing="true"
      :all-sites="allSitesApiResource.getData()"
      :selected-study="selectedStudy"
      @close-form="openEditStudySiteModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openEditStudySiteUserModal"
    :modal="true"
    :header="`Edit Study Site Users - ${
      openEditStudySiteUserModal && getStudySiteName(selectedStudySite)
    }`"
  >
    <AddEditStudySiteUsers
      :selected-study="selectedStudy"
      :selected-study-site="selectedStudySite"
      :current-users="studySiteUsersApiResource.getData()"
      :all-users="nonGSAUsersApiResource.getData()"
      :is-editing="removingUsers"
      @close-form="resetUsersForm"
    />
  </Dialog>
  <Dialog
    v-model:visible="openStudySiteEmailReceiversModal"
    :modal="true"
    :header="`Study Site Notification List - ${
      openStudySiteEmailReceiversModal && getStudySiteName(selectedStudySite)
    }`"
    :style="{ width: '40vw' }"
  >
    <div class="p-py-6" :style="{ 'min-height': '15vh' }">
      <StudySiteEmailReceivers
        :selected-study="selectedStudy"
        :selected-study-site="selectedStudySite"
      />
    </div>
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditStudySite from "@/components/Studies/AddEditStudySite.vue";
import AddEditStudySiteUsers from "@/components/Studies/AddEditStudySiteUsers.vue";
import StudySiteEmailReceivers from "@/components/Studies/StudySiteEmailReceivers.vue";

import Dialog from "primevue/dialog";
import Column from "primevue/column";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "Sites",
  components: {
    AppDataTable,
    AddEditStudySite,
    Dialog,
    Column,
    AddEditStudySiteUsers,
    StudySiteEmailReceivers,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
    allSitesApiResource: {
      type: Object,
      default: () => ({}),
    },
    studySitesApiResource: {
      type: Object,
      default: () => ({}),
    },
    studySiteNames: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["on-search"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditStudySiteModal.value = true;
        },
      },
      {
        label: "Add Users",
        command: () => {
          openEditStudySiteUserModal.value = true;
        },
      },
      {
        label: "Remove Users",
        command: () => {
          openEditStudySiteUserModal.value = true;
          removingUsers.value = true;
        },
      },
      {
        label: "Manage Site Notification List",
        command: () => {
          openStudySiteEmailReceiversModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteStudySiteHandler(event);
        },
      },
    ];

    const studySiteUsers = () => store.state.studySites.studySiteUsers;
    const fetchStudySiteUsers = (studySiteId) =>
      store.dispatch("studySites/fetchStudySiteUsers", {
        studyId: props.selectedStudy.minervaId,
        studySiteId,
      });

    const studySiteUsersApiResource = new ApiResource(
      studySiteUsers,
      fetchStudySiteUsers,
      undefined,
      "There was an error fetching the study site users."
    );

    const nonGSAUsers = () => store.state.users.nonGSAUsers;
    const fetchNonGSAUsers = () => store.dispatch("users/fetchNonGSAUsers");

    const nonGSAUsersApiResource = new ApiResource(
      nonGSAUsers,
      fetchNonGSAUsers,
      undefined,
      "There was an error fetching users."
    );

    const getStudySiteName = (studySite) => {
      if (
        Object.prototype.hasOwnProperty.call(
          props.studySiteNames,
          studySite.minervaId
        )
      ) {
        return props.studySiteNames[studySite.minervaId];
      }
      return "";
    };

    const mappedStudySiteUsers = computed(() => {
      const users = {};
      if (
        Object.keys(studySiteUsersApiResource.getData()).length > 0 &&
        props.studySitesApiResource.getData() &&
        props.studySitesApiResource.getData().length > 0 &&
        nonGSAUsersApiResource.getData().length > 0
      ) {
        const allUsers = studySiteUsersApiResource.getData();

        props.studySitesApiResource.getData().forEach((studySite) => {
          if (
            Object.prototype.hasOwnProperty.call(allUsers, studySite.minervaId)
          ) {
            users[studySite.minervaId] = allUsers[studySite.minervaId]
              .map((user) => {
                const userData = nonGSAUsersApiResource
                  .getData()
                  .find(
                    (nonGSAUser) => nonGSAUser.minervaId === user.minervauser
                  );
                return userData.display_name;
              })
              .join(", ");
          }
        });
      }
      return users;
    });

    const getStudySiteUsers = (studySite) => {
      if (
        Object.prototype.hasOwnProperty.call(
          mappedStudySiteUsers.value,
          studySite.minervaId
        )
      ) {
        return mappedStudySiteUsers.value[studySite.minervaId];
      }
      return "";
    };

    onMounted(async () => {
      const unWatch = watch(
        () => props.studySitesApiResource.getLoading(),
        async () => {
          if (
            !props.studySitesApiResource.getLoading() &&
            props.studySitesApiResource.getData()
          ) {
            await nonGSAUsersApiResource.fetchResource();
            props.studySitesApiResource.getData().forEach((site) => {
              studySiteUsersApiResource.fetchResource(site.minervaId);
            });
            unWatch();
          }
        }
      );
    });

    const deleteStudySite = () =>
      store.dispatch("studySites/deleteStudySite", {
        studyId: props.selectedStudy.minervaId,
        payload: selectedStudySite.value,
      });
    const deleteStudySiteApiResource = new ApiResource(
      undefined,
      deleteStudySite,
      "Successfully deleted study site.",
      "There was an error deleting the study site."
    );

    const selectedStudySiteId = ref("");
    const selectedStudySite = computed(() => {
      if (selectedStudySiteId.value.length > 0) {
        return store.state.studySites.studySites[
          props.selectedStudy.minervaId
        ].find((site) => site.minervaId === selectedStudySiteId.value);
      }
      return {};
    });
    const setSelectedStudySiteId = (site) => {
      selectedStudySiteId.value = site.minervaId;
    };

    const openEditStudySiteModal = ref(false);
    const openEditStudySiteUserModal = ref(false);
    const removingUsers = ref(false);

    const resetUsersForm = () => {
      openEditStudySiteUserModal.value = false;
      removingUsers.value = false;
    };

    const tableLoading = computed(
      () =>
        props.parentLoading ||
        deleteStudySiteApiResource.getLoading() ||
        nonGSAUsersApiResource.getLoading() ||
        studySiteUsersApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteStudySiteHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete study site ${selectedStudySite.value.display_name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteStudySiteApiResource.fetchResource();
        },
        reject: () => {
          selectedStudySiteId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const openStudySiteEmailReceiversModal = ref(false);

    const onSearch = (value) => emit("on-search", value);

    return {
      contextMenuOptions,
      setSelectedStudySiteId,
      selectedStudySite,
      openEditStudySiteModal,
      openEditStudySiteUserModal,
      tableLoading,
      getStudySiteName,
      getStudySiteUsers,
      nonGSAUsersApiResource,
      studySiteUsersApiResource,
      resetUsersForm,
      removingUsers,
      openStudySiteEmailReceiversModal,
      onSearch,
    };
  },
};
</script>
