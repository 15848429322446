<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="name" class="title">Name</label>
      <InputText
        id="name"
        v-model="newApiModel.name.$model"
        :class="{ 'p-error': newApiModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newApiModel.name" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="api_url" class="title">API URL</label>
      <InputText
        id="api_url"
        v-model="newApiModel.api_url.$model"
        :class="{ 'p-error': newApiModel.api_url.$invalid }"
      />
      <ErrorMessages :model-property="newApiModel.api_url" />
    </FormCol>
    <FormCol>
      <label for="esp_url" class="title">ESP URL</label>
      <InputText
        id="esp_url"
        v-model="newApiModel.esp_url.$model"
        :class="{ 'p-error': newApiModel.esp_url.$invalid }"
      />
      <ErrorMessages :model-property="newApiModel.esp_url" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="base_path_suffix" class="title">Base Path Suffix</label>
      <InputText id="base_path_suffix" v-model="newApi.base_path_suffix" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="base_path" class="title">Base Path</label>
      <InputText id="base_path" v-model="newApi.base_path" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="version" class="title">Version</label>
      <InputText id="version" v-model="newApi.version" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="keep_alive_base" class="title">Keep Alive Base</label>
      <InputText id="keep_alive_base" v-model="newApi.keep_alive_base" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <div class="p-d-flex p-ai-start p-pt-1">
        <InputSwitch v-model="newApi.active" />
        <label for="active" class="title p-ml-3 p-pt-1">Active</label>
      </div>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newApiModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import InputSwitch from "primevue/inputswitch";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditApi",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    InputSwitch,
  },
  props: {
    selectedApi: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newApi = reactive({
      name: "",
      api_url: "",
      esp_url: "",
      base_path_suffix: "",
      base_path: "api",
      version: "v1",
      keep_alive_base: "keep_alive",
      active: true,
    });

    const rules = {
      name: {
        required: helpers.withMessage("A name is required", required),
      },
      api_url: {
        required: helpers.withMessage("An API URL is required", required),
      },
      esp_url: {
        required: helpers.withMessage("An ESP URL is required", required),
      },
    };

    const newApiModel = useVuelidate(rules, newApi, { $lazy: true });

    const createApi = () => store.dispatch("apiManagement/createApi", newApi);

    const createApiApiResource = new ApiResource(
      undefined,
      createApi,
      "Successfully created new API",
      "There was an error creating the new API."
    );

    const updateApi = () => store.dispatch("apiManagement/updateApi", newApi);

    const updateApiApiResource = new ApiResource(
      undefined,
      updateApi,
      "Successfully updated the API.",
      "There was an error updating the API."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newApiModel.value.$validate()) {
        props.isEditing
          ? await updateApiApiResource.fetchResource()
          : await createApiApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createApiApiResource.getLoading() || updateApiApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedApi)) {
          newApi[key] = value;
        }
      }
    });

    return {
      closeForm,
      newApi,
      newApiModel,
      formLoading,
      handleSave,
    };
  },
};
</script>