const StudySiteUser = [
  "Subject Cancellation",
  "Unscheduled Scanner Maintenance",
  "Other",
];
const StudyAdmin = [
  "Subject Cancellation",
  "Unscheduled Scanner Maintenance",
  "Production Failure - Chemistry",
  "Production Failure - Equipment Failure",
  "Production Failure - OOS",
  "Production Failure - Unplanned Maintenance",
  "Production Failure - Planned Maintenance",
  "Capacity Reached",
  "Courier Issue",
  "Other",
];
const DoseOrderAdmin = [...StudyAdmin];

const PharmacyTechnician = [
  "Capacity Reached",
  "Planned Maintenance",
  "Unplanned Maintenance",
  "Courier Issue",
  "Production Failure",
  "Other",
];
const PharmacySystemAdmin = [
  "Capacity Reached",
  "Planned Maintenance",
  "Unplanned Maintenance",
  "Courier Issue",
  "Production Failure",
  "Production Failure - Chemistry",
  "Production Failure - Equipment Failure",
  "Production Failure - OOS",
  "Other",
];
const PharmacyOrderAdmin = [...PharmacySystemAdmin];

export default {
  StudySiteUser: StudySiteUser,
  StudyAdmin: StudyAdmin,
  DoseOrderAdmin: DoseOrderAdmin,
  PharmacyTechnician: PharmacyTechnician,
  PharmacySystemAdmin: PharmacySystemAdmin,
  PharmacyOrderAdmin: PharmacyOrderAdmin,
};
