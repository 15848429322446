import { ref, computed, watch } from "vue";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import { calibrationDate } from "@/helpers/timeHelpers";

export const buildNetworkHandlers = (store, props, ApiResource, newOrder) => {
  const availableNetworks = () =>
    store.state.orderManagement.studySiteNetworks[newOrder.studysiteproduct];

  const fetchAvailableNetworks = () =>
    store.dispatch("orderManagement/fetchStudySiteNetworks", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      productId: newOrder.studysiteproduct,
    });

  const availableNetworksApiResource = new ApiResource(
    availableNetworks,
    fetchAvailableNetworks,
    undefined,
    "There was an error fetching available networks."
  );

  const networkPlaceholder = computed(() =>
    newOrder.studysiteproduct === ""
      ? "Select a product"
      : availableNetworksApiResource.getLoading()
      ? "Loading networks"
      : "Select a network"
  );

  const selectedNetwork = computed(
    () =>
      availableNetworksApiResource.getData() &&
      availableNetworksApiResource
        .getData()
        .find(
          (network) => network.minervaId === newOrder.studysiteproductnetwork
        )
  );

  const networkChanged = computed(
    () =>
      newOrder.studysiteproductnetwork !==
      props.selectedOrder.studysiteproductnetwork
  );

  const networkChangedReason = ref("");

  const resetNetwork = () => {
    newOrder.studysiteproductnetwork =
      props.selectedOrder.studysiteproductnetwork;
    networkChangedReason.value = "";
  };

  const updateNetworkRequest = () =>
    store.dispatch("orderManagement/updateStudySiteOrderNetwork", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      studySiteOrderId: props.selectedOrder.minervaId,
      updatedOrder: {
        study: newOrder.study,
        studysite: newOrder.studysite,
        studysiteorder: props.selectedOrder.minervaId,
        studysiteproductnetwork: newOrder.studysiteproductnetwork,
        calibration: calibrationDate(
          newOrder.date,
          newOrder.time,
          props.studySite.timezone
        ),
        cancellation_reason: networkChangedReason.value,
        reservation_id: newOrder.reservation_id,
        skip_network_api_call: newOrder.skip_network_api_call,
      },
    });

  const saveUpdatedNetworkApiResource = new ApiResource(
    undefined,
    updateNetworkRequest,
    "Network successfully updated.",
    "There was an error updating the network."
  );

  // must start as undefined to allow live availability checking to not overwrite values when setting up editing.
  const liveAvailability = ref(undefined);
  const liveAvailabilityType = ref("");

  const productDoseNetwork = () =>
    store.state.orderManagement.productNetworkDetails[
      newOrder.studysiteproduct
    ];

  const fetchProductDoseNetwork = (doseNetworkIds) =>
    store.dispatch("orderManagement/fetchProductDoseNetwork", {
      doseNetworkIds,
      productId: newOrder.studysiteproduct,
    });

  const productDoseNetworkApiResource = new ApiResource(
    productDoseNetwork,
    fetchProductDoseNetwork,
    undefined,
    "There was an error fetching the products dose network."
  );

  const getProductDoseNetworks = async () => {
    if (!availableNetworksApiResource.getData()) {
      await availableNetworksApiResource.fetchResource();
    }

    if (!productDoseNetworkApiResource.getData()) {
      await productDoseNetworkApiResource.fetchResource(
        availableNetworksApiResource
          .getData()
          .map((network) => network.dosenetwork)
      );
    }
  };

  const liveAvailabilityCallFailed = ref(false);

  const getLiveAvailabilityRequest = async () => {
    const networkUriObject = productDoseNetworkApiResource
      .getData()
      .find(
        (network) => network.minervaId === selectedNetwork.value.dosenetwork
      );

    try {
      const isAvailable = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: "live_availability",
        }),
        {
          network_implementation_uri:
            networkUriObject.internal_implementation_uri,
        }
      );

      return isAvailable;
    } catch (error) {
      liveAvailabilityCallFailed.value = true;
      Promise.reject(error);
    }
  };
  const liveAvailabilityApiResource = new ApiResource(
    undefined,
    getLiveAvailabilityRequest,
    undefined,
    "There was an error fetching the live availability."
  );

  const getLiveAvailability = () => {
    return new Promise((resolve) => {
      const setLiveAvailability = async () => {
        const availability = await liveAvailabilityApiResource.fetchResource();
        liveAvailability.value = availability.availability;
        liveAvailabilityType.value = availability.type;
        resolve();
      };

      if (
        !productDoseNetworkApiResource.getData() ||
        productDoseNetworkApiResource.getLoading()
      ) {
        const unWatch = watch(productDoseNetwork, async () => {
          await setLiveAvailability();
          unWatch();
        });
      } else {
        setLiveAvailability();
      }
    });
  };

  const liveAvailabilityDisplay = computed(() => {
    const baseString = "Live Availability: ";
    const display = {
      text: "",
      colorClass: "",
      hintText: "",
    };

    if (!selectedNetwork.value) {
      display.text = `${baseString} Select a Network`;
      display.colorClass = "chip-success";
    } else {
      display.text = `${baseString} ${
        liveAvailability.value
          ? `Yes - ${
              liveAvailabilityType.value === "pre-def" ? "Pre-Def" : "Live"
            }`
          : "No"
      }`;
      display.colorClass = liveAvailability.value ? "chip-info" : "chip-error";

      display.hintText =
        liveAvailabilityType.value === "pre-def"
          ? "These are pre-defined dates. The pharmacy can still accept or reject an order based on current capacity"
          : "";
    }

    return display;
  });

  const networkConfigurationChecked = ref(false);
  const networkConfigured = ref(false);

  const networkConfiguredRequest = async ({
    network_implementation_uri,
    network_site_id,
  }) => {
    networkConfigurationChecked.value = false;
    try {
      const response = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: "configured_to_order",
        }),
        {
          network_implementation_uri,
          network_site_id,
          product: newOrder.studysiteproduct,
          network: newOrder.studysiteproductnetwork,
          additional_data: {
            studysite: props.studySite.minervaId,
          },
        }
      );
      networkConfigured.value = response;
      return networkConfigured;
    } catch (error) {
      networkConfigured.value = false;
      return Promise.reject(error);
    } finally {
      networkConfigurationChecked.value = true;
    }
  };

  const networkConfiguredRequestApiResource = new ApiResource(
    undefined,
    networkConfiguredRequest,
    undefined,
    "There was an error checking the network configuration"
  );

  return {
    availableNetworksApiResource,
    networkPlaceholder,
    selectedNetwork,
    networkChanged,
    networkChangedReason,
    resetNetwork,
    saveUpdatedNetworkApiResource,
    liveAvailability,
    productDoseNetworkApiResource,
    getProductDoseNetworks,
    liveAvailabilityApiResource,
    getLiveAvailability,
    liveAvailabilityDisplay,
    liveAvailabilityCallFailed,
    networkConfigured,
    networkConfiguredRequestApiResource,
    networkConfigurationChecked,
  };
};
