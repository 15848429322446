<template>
  <AppDataTable
    :selected-row-data="selectedOrganization"
    title="Organizations"
    add-button="Organization"
    enable-add-button
    :table-data="organizationsApiResource.getData()"
    :columns="columns"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedOrganizationId"
  >
    <template #addModalContent="{ closeAddModal }">
      <AddEditOrganization @close-form="closeAddModal" />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Organization - ${
      selectedOrganization && selectedOrganization.name
    }`"
  >
    <AddEditOrganization
      :selected-organization="selectedOrganization"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditOrganization from "@/components/Organizations/AddEditOrganization.vue";

import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Organizations",
  components: {
    AppDataTable,
    AddEditOrganization,
    Dialog,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([
      { field: "name", header: "Name" },
      { field: "type", header: "Type" },
    ]);

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteOrganizationHandler(event);
        },
      },
    ];

    const organizations = () => store.state.organizations.organizations;
    const fetchOrganizations = () =>
      store.dispatch("organizations/fetchOrganizations");
    const organizationsApiResource = new ApiResource(
      organizations,
      fetchOrganizations,
      undefined,
      "There was an error fetching your organizations."
    );

    onMounted(() => {
      if (
        !organizationsApiResource.getData() ||
        organizationsApiResource.getData().length === 0
      ) {
        organizationsApiResource.fetchResource();
      }
    });

    const deleteOrganization = () =>
      store.dispatch(
        "organizations/deleteOrganization",
        selectedOrganization.value
      );
    const deleteOrganizationApiResource = new ApiResource(
      undefined,
      deleteOrganization,
      "Successfully deleted organization.",
      "There was an error deleting the organization."
    );

    const selectedOrganizationId = ref("");
    const selectedOrganization = computed(() => {
      if (selectedOrganizationId.value.length > 0) {
        return store.state.organizations.organizations.find(
          (organization) =>
            organization.minervaId === selectedOrganizationId.value
        );
      }
      return {};
    });
    const setSelectedOrganizationId = (organization) => {
      selectedOrganizationId.value = organization.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        organizationsApiResource.getLoading() ||
        deleteOrganizationApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteOrganizationHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete organization ${selectedOrganization.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteOrganizationApiResource.fetchResource();
        },
        reject: () => {
          selectedOrganizationId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      columns,
      contextMenuOptions,
      organizationsApiResource,
      setSelectedOrganizationId,
      selectedOrganization,
      openEditModal,
      tableLoading,
    };
  },
};
</script>
