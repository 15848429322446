<template>
  <AppDataTable
    title="Documents"
    :table-data="mappedTableData"
    :loading="tableLoading"
    :columns="columns"
    add-button="Document"
    enable-add-button
    searchable
    :search-properties="['date_string', 'order_ids', 'batch', 'product']"
  >
    <Column header="Download">
      <template #body="{ data }">
        <Button
          v-if="data && Object.keys(data).length > 0"
          label="Download"
          class="p-button-xs"
          @click="downloadDocument(data)"
        />
      </template>
    </Column>
    <template #headerContent>
      <Dropdown
        v-model="documentType"
        :options="documentTypes"
        placeholder="Select a document type"
        append-to="body"
      />
    </template>
    <template #addModalContent="{ closeAddModal }">
      <AddDocument
        :pharmacy="pharmacy"
        :products="productsApiResource.getData()"
        :document-types="documentTypes"
        @close-form="updateTableData(closeAddModal)"
      />
    </template>
  </AppDataTable>
  <a
    ref="fileDownload"
    :url="downloadUrl"
    style="visibility: hidden"
    target="_blank"
  />
</template>

<script>
import AddDocument from "@/components/Pharmacies/AddDocument.vue";
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import { computed, ref, watch, inject, onMounted } from "vue";
import { useStore } from "vuex";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import axiosInstance from "@/api/apiConfig.js";
import { ToastService } from "@/toast/toast";

export default {
  name: "PharmacyDocuments",
  components: {
    AppDataTable,
    Dropdown,
    Column,
    AddDocument,
  },
  props: {
    pharmacy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const documentTypes = ["COA"];
    const documentType = ref("");

    const pharmacyDocuments = () =>
      store.state.pharmacies.pharmacyDocuments[
        `${props.pharmacy.minervaId}-${documentType.value}`
      ];
    const fetchPharmacyDocuments = () =>
      store.dispatch("pharmacies/fetchPharmacyDocuments", {
        pharmacyId: props.pharmacy.minervaId,
        docType: documentType.value,
      });

    const pharmacyDocumentsApiResource = new ApiResource(
      pharmacyDocuments,
      fetchPharmacyDocuments,
      undefined,
      "There was an error fetching your pharmacy documents."
    );

    const fetchTableData = () => {
      if (documentType.value) {
        pharmacyDocumentsApiResource.fetchResource();
      }
    };

    const getProduct = (document) => {
      const thisProduct = productsApiResource
        .getData()
        .find((product) => product.minervaId === document.product);
      return thisProduct ? thisProduct.name : "";
    };

    const updateTableData = async (closeDialog) => {
      closeDialog();
      await new Promise((res) => setTimeout(res, 5000));
      fetchTableData();
    };

    watch(
      () => documentType.value,
      () => fetchTableData()
    );

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const tableLoading = computed(
      () =>
        pharmacyDocumentsApiResource.getLoading() ||
        productsApiResource.getLoading()
    );

    onMounted(() => {
      documentType.value = "COA";
      if (productsApiResource.getData().length === 0)
        productsApiResource.fetchResource();
    });

    const fileDownload = ref("fileDownload");
    const downloadUrl = ref("");

    const downloadDocument = async (document) => {
      try {
        const downloadURL = await axiosInstance.get(
          buildBaseUrl({
            apiModule: "pharmacyManagerApi",
            url: `pharmacy/${props.pharmacy.minervaId}/pharmacy_document/${document.document_id}`,
          })
        );
        if (downloadURL) {
          fileDownload.value.download = document.name;
          fileDownload.value.href = downloadURL.url;
          fileDownload.value.click();
          fileDownload.value.download = null;
          fileDownload.value.href = null;
        }
      } catch (error) {
        console.log(error);
        ToastService.addWithNotification({
          type: "error",
          message: "There was an error downloading your document",
        });
      }
    };

    const columns = ref([
      { field: "doc_type", header: "Document Type" },
      { field: "date_string", header: "Date" },
      { field: "product", header: "Product" },
      { field: "batch", header: "Batch" },
      { field: "order_ids", header: "Order IDs" },
    ]);
    const mappedTableData = computed(() => {
      if (
        pharmacyDocumentsApiResource.getData() &&
        pharmacyDocumentsApiResource.getData().length > 0
      ) {
        return pharmacyDocumentsApiResource.getData().map((order) => {
          return {
            document_id: order.document_id,
            doc_type: order.doc_type,
            date_string: order.date_string,
            product: getProduct(order),
            batch: order.batch,
            order_ids: order.order_ids.join(", "),
          };
        });
      }

      return [];
    });

    return {
      documentTypes,
      documentType,
      tableLoading,
      pharmacyDocumentsApiResource,
      getProduct,
      productsApiResource,
      updateTableData,
      downloadDocument,
      fileDownload,
      downloadUrl,
      columns,
      mappedTableData,
    };
  },
};
</script>
