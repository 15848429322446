<template>
  <FormWrapper :loading="loading">
    <FormCol col-width="p-md-6">
      <label for="new_pharmacy" class="title">New Pharmacy</label>
      <Dropdown
        v-model="model.new_pharmacy_id.$model"
        :options="availablePharmcies"
        option-label="display_name"
        option-value="minervaId"
        placeholder="Select a new pharmacy"
        :class="{
          'p-invalid': model.new_pharmacy_id.$invalid,
        }"
        append-to="body"
        :filter="true"
      />
      <ErrorMessages :model-property="model.new_pharmacy_id" />
    </FormCol>
    <Divider />
    <FormCol v-if="hasActivePharmacy" col-width="p-md-6">
      <label for="order_reroute_reason" class="title">Reroute Reason</label>
      <Dropdown
        id="order_reroute_reason"
        v-model="model.reroute_reason.$model"
        :options="orderCancellationReasons['PharmacySystemAdmin']"
        placeholder="Select a reason"
        append-to="body"
        :class="{
          'p-invalid': model.reroute_reason.$invalid,
        }"
      />
      <ErrorMessages :model-property="model.reroute_reason" />
    </FormCol>
    <Divider v-if="hasActivePharmacy" />
    <FormCol v-if="reroutePayload.reroute_reason === 'Other'">
      <label for="other_reason" class="title">Other Reason</label>
      <Textarea
        v-model="model.other.$model"
        label="other_reason"
        :auto-resize="true"
        rows="3"
        cols="60"
        :class="{
          'p-invalid': model.other.$invalid,
        }"
      />
      <ErrorMessages :model-property="model.other" />
    </FormCol>
    <Divider v-if="reroutePayload.reroute_reason === 'Other'" />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="loading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="rerouteOrder"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Divider from "primevue/divider";
import { reactive, inject, computed, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  helpers,
} from "@vuelidate/validators";
import { useStore } from "vuex";
import orderCancellationReasons from "@/helpers/orderCancellationReasons";

export default {
  name: "ReroutePharmacyOrder",
  components: {
    FormWrapper,
    FormButtonCol,
    FormCol,
    CancelConfirmButtons,
    ErrorMessages,
    Divider,
    Dropdown,
    Textarea,
  },
  props: {
    pharmacyOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const reroutePayload = reactive({
      new_pharmacy_id: "",
      reroute_reason: "",
      other: "",
    });

    const rules = {
      new_pharmacy_id: {
        required: helpers.withMessage(
          "A new pharmacy id is required",
          required
        ),
      },
      reroute_reason: {
        requiredIf: helpers.withMessage(
          "A reroute reason is required",
          requiredIf(() => hasActivePharmacy.value)
        ),
      },
      other: {
        requiredIf: helpers.withMessage(
          "A reason is required",
          requiredIf(() => reroutePayload.reroute_reason === "Other")
        ),
        minLength: minLength(1),
      },
    };

    const model = useVuelidate(rules, reroutePayload, { $lazy: true });

    const closeForm = () => emit("close-form");

    const hasActivePharmacy = computed(
      () => props.pharmacyOrder?.active_pharmacy?.minervaId.length > 0 ?? false
    );

    const genPayload = computed(() => {
      if (hasActivePharmacy.value) {
        return {
          new_pharmacy_id: reroutePayload.new_pharmacy_id,
          reroute_reason:
            reroutePayload.reroute_reason === "Other"
              ? reroutePayload.other
              : reroutePayload.reroute_reason,
        };
      } else {
        return {
          new_pharmacy_id: reroutePayload.new_pharmacy_id,
        };
      }
    });

    const reroutePharmacyOrder = () =>
      store.dispatch("pharmacyOrders/reroutePharmacyOrder", {
        pharmacyOrder: props.pharmacyOrder,
        payload: genPayload.value,
      });
    const reroutePharmacyOrderApiResource = new ApiResource(
      undefined,
      reroutePharmacyOrder,
      "Successfully rerouted the pharmacy order",
      "There was an error rerouting the pharmacy order."
    );

    const pharmacies = () => store.state.pharmacies.pharmacies;
    const fetchPharmacies = () => store.dispatch("pharmacies/fetchPharmacies");
    const pharmaciesApiResource = new ApiResource(
      pharmacies,
      fetchPharmacies,
      undefined,
      "There was an error fetching your pharmacies."
    );

    const rerouteOrder = async () => {
      if (await model.value.$validate()) {
        await reroutePharmacyOrderApiResource.fetchResource();
        closeForm();
      }
    };

    const availablePharmcies = computed(() => {
      if (hasActivePharmacy.value) {
        return pharmaciesApiResource
          .getData()
          .filter(
            (pharmacy) =>
              pharmacy.minervaId !==
              props.pharmacyOrder.active_pharmacy.minervaId
          );
      }
      return pharmaciesApiResource.getData();
    });

    onMounted(() => {
      pharmaciesApiResource.fetchResource();
    });

    const loading = computed(
      () =>
        pharmaciesApiResource.getLoading() ||
        reroutePharmacyOrderApiResource.getLoading()
    );

    return {
      model,
      reroutePharmacyOrderApiResource,
      rerouteOrder,
      closeForm,
      orderCancellationReasons,
      loading,
      availablePharmcies,
      hasActivePharmacy,
      reroutePayload,
    };
  },
};
</script>
