<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="admin_name" class="title">New Study Administrator</label>
      <Dropdown
        id="admin_name"
        v-model="studyAdminModel.newStudyAdmin.$model"
        :options="availableUsers"
        option-label="display_name"
        option-value="minervaId"
        placeholder="Select a new administrator"
        :class="{ 'p-error': studyAdminModel.newStudyAdmin.$invalid }"
        append-to="body"
      />
      <ErrorMessages :model-property="studyAdminModel.newStudyAdmin" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || studyAdminModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";

import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddStudyAdmin",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
  },
  props: {
    availableUsers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newStudyAdmin = ref("");

    const rules = {
      newStudyAdmin: {
        required: helpers.withMessage("A study admin is required", required),
      },
    };

    const studyAdminModel = useVuelidate(
      rules,
      { newStudyAdmin },
      { $lazy: true }
    );

    const addStudyAdmin = () =>
      store.dispatch("studyManagement/addStudyAdmin", {
        minervauser: newStudyAdmin.value,
      });

    const addStudyAdminApiResource = new ApiResource(
      undefined,
      addStudyAdmin,
      "Successfully added new study administrator",
      "There was an error adding the study administrator."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await studyAdminModel.value.$validate()) {
        await addStudyAdminApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(() => addStudyAdminApiResource.getLoading());

    return {
      closeForm,
      studyAdminModel,
      formLoading,
      handleSave,
    };
  },
};
</script>
