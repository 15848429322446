<template>
  <AppDataTable
    :selected-row-data="selectedStudySiteProductNetwork"
    title="Associated Product Networks"
    add-button="Associated Product Network"
    enable-add-button
    :table-data="allStudySiteProductNetworksFiltered"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedStudySiteProductId"
  >
    <Column header="Site">
      <template #body="{ data }">
        {{ getStudySiteName(data) }}
      </template>
    </Column>
    <Column header="Secondary ID">
      <template #body="{ data }">
        {{ data.secondary_site_id }}
      </template>
    </Column>
    <Column header="Product">
      <template #body="{ data }">
        {{ getProductName(data) }}
      </template>
    </Column>
    <Column header="Network">
      <template #body="{ data }">
        {{ data.dosenetwork_name }}
      </template>
    </Column>
    <Column header="Priority">
      <template #body="{ data }">
        {{ data.priority }}
      </template>
    </Column>
    <Column header="Ordering Enabled">
      <template #body="{ data }">
        <span v-if="Object.keys(data).length > 0">
          <span
            v-if="data.ordering_enabled"
            class="p-tag p-tag-success p-px-4"
            style="font-size: 1em"
            >Yes</span
          >
          <span v-else class="p-tag p-tag-warning p-px-4" style="font-size: 1em"
            >No</span
          >
        </span>
      </template>
    </Column>
    <Column header="Currency">
      <template #body="{ data }">
        {{ data.dose_default_currency }}
      </template>
    </Column>
    <Column header="Price / Freight">
      <template #body="{ data }">
        <span v-if="Object.keys(data).length > 0">
          {{ `${getNetworkPrice(data)} / ${getNetworkFreight(data)}` }}
        </span>
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddEditStudySiteProductNetwork
        :selected-study="selectedStudy"
        :study-sites="studySitesApiResource.getData()"
        :all-study-site-products="allStudySiteProducts"
        :all-study-site-product-networks="allStudySiteProductNetworks"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Product - ${
      selectedStudySiteProductNetwork &&
      selectedStudySiteProductNetwork.display_name
    }`"
  >
    <AddEditStudySiteProductNetwork
      :selected-study="selectedStudy"
      :study-sites="studySitesApiResource.getData()"
      :all-study-site-products="allStudySiteProducts"
      :all-study-site-product-networks="allStudySiteProductNetworks"
      :selected-study-site-product-network="selectedStudySiteProductNetwork"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openEditDosePriceModal"
    :modal="true"
    header="Set Dose Prices"
  >
    <AddEditDosePricing
      :selected-product-network="selectedStudySiteProductNetwork"
      :existing-dose-price="getNetworkPrice(selectedStudySiteProductNetwork)"
      :existing-freight-price="
        getNetworkFreight(selectedStudySiteProductNetwork)
      "
      @close-form="openEditDosePriceModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditStudySiteProductNetwork from "@/components/Studies/AddEditStudySiteProductNetwork.vue";
import AddEditDosePricing from "@/components/Studies/AddEditDosePricing";

import Column from "primevue/column";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "StudySiteProductNetworks",
  components: {
    AppDataTable,
    AddEditStudySiteProductNetwork,
    AddEditDosePricing,
    Dialog,
    Column,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
    allStudySiteProductNetworkPrices: {
      type: Array,
      default: () => [],
    },
    allStudySiteProductNetworkFreight: {
      type: Array,
      default: () => [],
    },
    productsApiResource: {
      type: Object,
      default: () => ({}),
    },
    studySiteNames: {
      type: Object,
      default: () => ({}),
    },
    allStudySiteProductNetworks: {
      type: Array,
      default: () => [],
    },
    studySitesApiResource: {
      type: Object,
      default: () => ({}),
    },
    allStudySiteProducts: {
      type: Array,
      default: () => [],
    },
    searchValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Set Dose Prices",
        command: () => {
          openEditDosePriceModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteStudySiteProductNetworkHandler(event);
        },
      },
    ];

    const deleteStudySiteProductNetwork = () =>
      store.dispatch("studySiteProducts/deleteStudySiteProductNetwork", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: selectedStudySiteProductNetwork.value.studysite,
        productId: selectedStudySiteProductNetwork.value.studysiteproduct,
        networkId: selectedStudySiteProductNetwork.value.minervaId,
        payload: selectedStudySiteProductNetwork.value,
      });
    const deleteStudySiteProductNetworkApiResource = new ApiResource(
      undefined,
      deleteStudySiteProductNetwork,
      "Successfully deleted study site product network.",
      "There was an error deleting the study site product network."
    );

    const selectedStudySiteProductNetworkId = ref("");
    const selectedStudySiteProductNetwork = computed(() => {
      if (selectedStudySiteProductNetworkId.value.length > 0) {
        return props.allStudySiteProductNetworks.find(
          (network) =>
            network.minervaId === selectedStudySiteProductNetworkId.value
        );
      }
      return {};
    });
    const setSelectedStudySiteProductId = (product) => {
      selectedStudySiteProductNetworkId.value = product.minervaId;
    };

    const openEditModal = ref(false);
    const openEditDosePriceModal = ref(false);

    const tableLoading = computed(
      () =>
        props.parentLoading ||
        deleteStudySiteProductNetworkApiResource.getLoading()
    );

    const confirm = useConfirm();

    const getStudySiteName = (studySite) => {
      if (
        Object.prototype.hasOwnProperty.call(
          props.studySiteNames,
          studySite.studysite
        )
      ) {
        return props.studySiteNames[studySite.studysite];
      }
      return "";
    };

    const getProductName = (productNetwork) => {
      const thisProduct = props.productsApiResource
        .getData()
        .find((product) => product.minervaId === productNetwork.product);
      return thisProduct ? thisProduct.name : "";
    };

    const getNetworkPrice = (productNetwork) => {
      if (!productNetwork.minervaId) return "";
      const thisPrice = props.allStudySiteProductNetworkPrices.find(
        (network) =>
          network.studysiteproductnetwork === productNetwork.minervaId
      );
      return thisPrice ? thisPrice.dose_default_price : "U";
    };
    const getNetworkFreight = (productNetwork) => {
      if (!productNetwork.minervaId) return "";
      const thisFreight = props.allStudySiteProductNetworkFreight.find(
        (network) =>
          network.studysiteproductnetwork === productNetwork.minervaId
      );
      return thisFreight ? thisFreight.dose_default_freight : "U";
    };

    const allStudySiteProductNetworksFiltered = computed(() => {
      if (props.searchValue.length > 0) {
        return props.allStudySiteProductNetworks.filter((network) => {
          const name = getStudySiteName(network);
          if (name.length > 0) {
            return name.toLowerCase().includes(props.searchValue.toLowerCase());
          }
          return true;
        });
      } else {
        return props.allStudySiteProductNetworks;
      }
    });

    const confirmDeleteStudySiteProductNetworkHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete the study site product network for: ${getStudySiteName(
          selectedStudySiteProductNetwork.value
        )} - ${getProductName(selectedStudySiteProductNetwork.value)} - ${
          selectedStudySiteProductNetwork.value.display_name
        }?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteStudySiteProductNetworkApiResource.fetchResource();
        },
        reject: () => {
          selectedStudySiteProductNetworkId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      contextMenuOptions,
      setSelectedStudySiteProductId,
      selectedStudySiteProductNetwork,
      openEditModal,
      openEditDosePriceModal,
      tableLoading,
      getStudySiteName,
      getProductName,
      getNetworkPrice,
      getNetworkFreight,
      allStudySiteProductNetworksFiltered,
    };
  },
};
</script>
