<template>
  <FormWrapper :loading="acceptPharmacyOrderApiResource.getLoading()">
    <div class="p-ml-2 p-mb-2">
      <p>
        Are you sure you want to accept order
        {{ pharmacyOrder?.order_data?.order_id }}?
      </p>
    </div>
    <FormCol col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="need_courier"
          v-model="acceptOrder.courier_needed"
          :binary="true"
          class="p-mb-1"
        />
        <label for="need_courier" class="checkbox-text p-ml-2 p-mb-1"
          >Courier Needed?</label
        >
      </div>
    </FormCol>
    <FormCol v-if="acceptOrder.courier_needed" col-width="p-md-6">
      <label for="pickup_time" class="title">Pickup Time</label>
      <Calendar
        id="pickup_time"
        :model-value="pickupTime"
        :time-only="true"
        placeholder="Select a time"
        append-to="body"
        :class="{ 'p-error': model.pickup_time.$invalid }"
        @update:model-value="setPickupTime(model, $event)"
      />
      <ErrorMessages :model-property="model.pickup_time" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="
          acceptPharmacyOrderApiResource.getLoading() || model.$invalid
        "
        cancel-text="NO"
        confirm-text="YES"
        @cancelled="closeForm"
        @confirmed="sendData"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import Calendar from "primevue/calendar";
import Divider from "primevue/divider";
import Checkbox from "primevue/checkbox";
import { reactive, computed, inject } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import { useStore } from "vuex";
import {
  calibrationDate,
  setTime,
  displayTime,
  getDate,
  getTime,
} from "@/helpers/timeHelpers";

export default {
  name: "AcceptPharmacyOrder",
  components: {
    FormWrapper,
    FormButtonCol,
    FormCol,
    Calendar,
    CancelConfirmButtons,
    ErrorMessages,
    Divider,
    Checkbox,
  },
  props: {
    pharmacyOrder: {
      type: Object,
      default: () => ({}),
    },
    pharmacy: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const acceptOrder = reactive({
      courier_needed: false,
      pickup_time: "",
    });

    const pickupTimeBeforeOrderTime = (input) => {
      const selectedTime = Number(input.replace("-", ""));
      const orderTime = Number(
        getTime(props.pharmacyOrder.order_data.local_calibration).replace(
          ":",
          ""
        )
      );
      return selectedTime <= orderTime;
    };

    const rules = {
      pickup_time: {
        requiredIf: helpers.withMessage(
          "A pickup time is required",
          requiredIf(() => acceptOrder.courier_needed)
        ),
        pickupTimeBeforeOrderTime: helpers.withMessage(
          "The pickup time must be before the order time",
          pickupTimeBeforeOrderTime
        ),
      },
    };

    const model = useVuelidate(rules, acceptOrder, { $lazy: true });

    const acceptPharmacyOrder = () =>
      store.dispatch("pharmacyOrders/acceptPharmacyOrder", {
        pharmacyOrder: props.pharmacyOrder,
        payload: {
          courier_needed: acceptOrder.courier_needed,
          pickup_time: acceptOrder.courier_needed
            ? calibrationDate(
                getDate(props.pharmacyOrder.order_data.local_calibration),
                acceptOrder.pickup_time,
                props.pharmacyOrder.active_pharmacy.timezone
              )
            : null,
        },
      });

    const acceptPharmacyOrderApiResource = new ApiResource(
      undefined,
      acceptPharmacyOrder,
      undefined,
      "There was an error accepting your pharmacy order."
    );

    const pickupTime = computed(() => {
      if (acceptOrder.pickup_time.length > 0) {
        return displayTime(acceptOrder.pickup_time);
      }
    });

    const closeForm = () => emit("close-form");

    const sendData = async () => {
      if (await model.value.$validate()) {
        await acceptPharmacyOrderApiResource.fetchResource();
        closeForm();
      }
    };

    const setPickupTime = (model, time) => {
      model.pickup_time.$model = setTime(time);
    };

    return {
      model,
      acceptPharmacyOrderApiResource,
      sendData,
      closeForm,
      pickupTime,
      acceptOrder,
      setPickupTime,
    };
  },
};
</script>
