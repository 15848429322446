<template>
  <Panel>
    <template #header>
      <div
        class="p-d-flex p-flex-row p-jc-between p-ai-center"
        style="width: 100%"
      >
        <h5 class="app-data-list-title">{{ title }}</h5>
        <div class="p-d-flex p-flex-row p-py-1">
          <span class="p-mr-4">
            <ProgressLoader
              style="width: 23px; height: 23px"
              :loading="loading"
            />
          </span>
          <Button @click="openAddModal = true">Add {{ addButton }}</Button>
        </div>
      </div>
    </template>
    <div class="calendar-container">
      <FullCalendar
        ref="calendarRef"
        :events="data"
        :options="calendarOptions"
      />
    </div>
  </Panel>
  <Dialog
    v-model:visible="openAddModal"
    :modal="true"
    :header="`Create new ${addButton.toLowerCase()}`"
  >
    <slot name="addModalContent" :closeAddModal="closeAddModel"></slot>
  </Dialog>
</template>

<script>
import ProgressLoader from "@/components/ProgressLoader/ProgressLoader";
import Panel from "primevue/panel";
import Dialog from "primevue/dialog";
import FullCalendar from "primevue/fullcalendar";
import { useConfirm } from "primevue/useConfirm";
import { getDate } from "@/helpers/timeHelpers";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import { ref } from "vue";

export default {
  name: "AppCalendar",
  components: {
    Panel,
    Dialog,
    ProgressLoader,
    FullCalendar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    addButton: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    timeZoneForCal: {
      type: String,
      default: "local",
    },
  },
  emits: ["delete-date", "month-selected"],
  setup(props, { emit }) {
    const openAddModal = ref(false);
    const calendarRef = ref(null);
    const closeAddModel = () => (openAddModal.value = false);

    const calendarOptions = {
      timeZone: props.timeZoneForCal,
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      weekends: true,
      initialDate: new Date(),
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,listWeek",
      },
      editable: false,
      slotMinTime: "06:00",
      slotMaxTime: "22:00",
      eventClick: (eventClickInfo) => {
        confirmDeleteAvailableDate(eventClickInfo);
      },
      datesSet: () => {
        emit(
          "month-selected",
          getDate(new Date(calendarRef.value.calendar.getDate()).toISOString())
        );
      },
    };

    const confirm = useConfirm();

    const eventToDelete = ref("");

    const confirmDeleteAvailableDate = (eventClickInfo) => {
      eventClickInfo.jsEvent.preventDefault();
      eventToDelete.value = eventClickInfo.event.url;
      confirm.require({
        target: eventClickInfo.jsEvent.currentTarget,
        message:
          "Are you sure you wish to delete this availability? This cannot be undone.",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          emit("delete-date", eventToDelete.value);
          eventToDelete.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      openAddModal,
      closeAddModel,
      calendarOptions,
      calendarRef,
    };
  },
};
</script>

<style>
.p-panel-header {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.app-data-list-title {
  margin: 0px !important;
}
.fc-event-title {
  font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  cursor: pointer;
  white-space: pre-wrap;
}
.fc-event-title-container {
  font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  cursor: pointer;
}
.fc-event-time {
  display: none;
}
.calendar-container {
  margin: 0 auto;
}
.fc-listWeek-button {
  color: rgb(73, 80, 87) !important;
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(206, 212, 218) !important;
}
.fc-button-active {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 150, 136) !important;
  border-color: rgb(0, 150, 136) !important;
}
</style>
