<template>
  <AppDataTable
    :selected-row-data="selectedProduct"
    title="Products"
    add-button="Product"
    enable-add-button
    :table-data="productsApiResource.getData()"
    :columns="columns"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedProductId"
  >
    <template #addModalContent="{ closeAddModal }">
      <AddEditProduct @close-form="closeAddModal" />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Product - ${selectedProduct && selectedProduct.name}`"
  >
    <AddEditProduct
      :selected-product="selectedProduct"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditProduct from "@/components/Studies/AddEditProduct.vue";

import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Products",
  components: {
    AppDataTable,
    AddEditProduct,
    Dialog,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([
      { field: "name", header: "Name" },
      { field: "type", header: "Type" },
      { field: "usage", header: "Usage" },
    ]);

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteProductHandler(event);
        },
      },
    ];

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    onMounted(() => {
      productsApiResource.fetchResource();
    });

    const deleteProduct = () =>
      store.dispatch("products/deleteProduct", selectedProduct.value);
    const deleteProductApiResource = new ApiResource(
      undefined,
      deleteProduct,
      "Successfully deleted product.",
      "There was an error deleting the product."
    );

    const selectedProductId = ref("");
    const selectedProduct = computed(() => {
      if (selectedProductId.value.length > 0) {
        return store.state.products.products.find(
          (product) => product.minervaId === selectedProductId.value
        );
      }
      return {};
    });
    const setSelectedProductId = (product) => {
      selectedProductId.value = product.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        productsApiResource.getLoading() ||
        deleteProductApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteProductHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete product ${selectedProduct.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteProductApiResource.fetchResource();
        },
        reject: () => {
          selectedProductId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      columns,
      contextMenuOptions,
      productsApiResource,
      setSelectedProductId,
      selectedProduct,
      openEditModal,
      tableLoading,
    };
  },
};
</script>
