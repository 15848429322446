<template>
  <div v-if="!hideCancel" :class="{ 'p-mr-4': !hideConfirm }">
    <Button
      :label="cancelText"
      class="p-button-danger p-button-text"
      @click="cancelClicked"
    />
  </div>
  <div v-if="!hideConfirm">
    <Button :label="confirmText" :disabled="disabled" @click="confirmClicked" />
  </div>
</template>

<script>
export default {
  name: "CancelConfirmButtons",
  props: {
    cancelText: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    hideConfirm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancelled", "confirmed"],
  setup(props, { emit }) {
    const cancelClicked = () => emit("cancelled");
    const confirmClicked = (event) => emit("confirmed", event);

    return {
      cancelClicked,
      confirmClicked,
    };
  },
};
</script>
