<template>
  <AppDataTable
    :selected-row-data="selectedStudyAdmin"
    title="Users"
    add-button="User"
    enable-add-button
    :table-data="studyAdminsApiResource.getData()"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedStudyAdminId"
  >
    <Column field="display_name" header="Name"></Column>
    <Column header="Email">
      <template #body="slotProps">
        {{ getUserEmail(slotProps.data) }}
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddStudyAdmin
        :available-users="availableUsers"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <div class="p-mt-4">
    <AppDataTable
      :selected-row-data="selectedOrderEmailReceiver"
      title="Order Email Receiver"
      add-button="Order Email Receiver"
      enable-add-button
      :table-data="orderEmailReceiversApiResource.getData()"
      :loading="orderEmailRecieverTableLoading"
      :context-menu-options="orderEmailReceiverContextMenuOptions"
      @update:selected-row-data="setSelectedOrderEmailReceiverId"
    >
      <Column field="display_name" header="Name"></Column>
      <Column field="email" header="Email"></Column>
      <template #addModalContent="{ closeAddModal }">
        <AddOrderEmailReceiver @close-form="closeAddModal" />
      </template>
    </AppDataTable>
  </div>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddStudyAdmin from "@/components/Studies/AddStudyAdmin.vue";
import AddOrderEmailReceiver from "@/components/Studies/AddOrderEmailReceiver.vue";

import { useConfirm } from "primevue/useConfirm";
import Column from "primevue/column";
import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "StudyAdministrators",
  components: {
    AppDataTable,
    AddStudyAdmin,
    AddOrderEmailReceiver,
    Column,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteStudyAdminHandler(event);
        },
      },
    ];

    const orderEmailReceiverContextMenuOptions = [
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteOrderEmailReceiverHandler(event);
        },
      },
    ];

    const studyAdmins = () => store.state.studyManagement.studyAdministrators;
    const fetchStudyAdmins = () =>
      store.dispatch("studyManagement/fetchStudyAdministrators");

    const studyAdminsApiResource = new ApiResource(
      studyAdmins,
      fetchStudyAdmins,
      undefined,
      "There was an error fetching the users."
    );

    const orderEmailReceivers = () =>
      store.state.studyManagement.orderEmailReceivers;
    const fetchOrderEmailReceivers = () =>
      store.dispatch("studyManagement/fetchOrderEmailReceivers");

    const orderEmailReceiversApiResource = new ApiResource(
      orderEmailReceivers,
      fetchOrderEmailReceivers,
      undefined,
      "There was an error fetching the order email receivers."
    );

    const users = () => store.state.users.users;
    const fetchUsers = () => store.dispatch("users/fetchUsers");

    const usersApiResource = new ApiResource(
      users,
      fetchUsers,
      undefined,
      "There was an error fetching the users."
    );

    const getUserEmail = (user) => {
      const users = usersApiResource.getData();
      if (users) {
        const userObject = usersApiResource
          .getData()
          .find((userObj) => userObj.minervaId === user.minervauser);
        return userObject ? userObject.email : "";
      }
      return "";
    };

    const getOrderEmailReceiverEmail = (user) => {
      const users = usersApiResource.getData();
      if (users) {
        const userObject = usersApiResource
          .getData()
          .find((userObj) => userObj.minervaId === user.minervauser);
        return userObject ? userObject.email : "";
      }
      return "";
    };

    onMounted(() => {
      if (!usersApiResource.getData().value) usersApiResource.fetchResource();
      studyAdminsApiResource.fetchResource();
      orderEmailReceiversApiResource.fetchResource();
    });

    const deleteStudyAdmin = () =>
      store.dispatch(
        "studyManagement/deleteStudyAdmin",
        selectedStudyAdmin.value
      );

    const deleteStudyAdminApiResource = new ApiResource(
      undefined,
      deleteStudyAdmin,
      "Successfully deleted user.",
      "There was an error deleting the user."
    );

    const deleteOrderEmailReceiver = () =>
      store.dispatch(
        "studyManagement/deleteOrderEmailReceiver",
        selectedOrderEmailReceiver.value
      );

    const deleteOrderEmailReceiversApiResource = new ApiResource(
      undefined,
      deleteOrderEmailReceiver,
      "Successfully deleted order email receiver.",
      "There was an error deleting the user order email receiver."
    );

    const selectedStudyAdminId = ref("");
    const selectedStudyAdmin = computed(() => {
      if (selectedStudyAdminId.value.length > 0) {
        return store.state.studyManagement.studyAdministrators.find(
          (studyAdmin) => studyAdmin.minervaId === selectedStudyAdminId.value
        );
      }
      return {};
    });

    const setSelectedStudyAdminId = (study) => {
      selectedStudyAdminId.value = study.minervaId;
    };

    const selectedOrderEmailReceiverId = ref("");
    const selectedOrderEmailReceiver = computed(() => {
      if (selectedOrderEmailReceiverId.value.length > 0) {
        return store.state.studyManagement.orderEmailReceivers.find(
          (emailReceiver) =>
            emailReceiver.minervaId === selectedOrderEmailReceiverId.value
        );
      }
      return {};
    });

    const setSelectedOrderEmailReceiverId = (user) => {
      selectedOrderEmailReceiverId.value = user.minervaId;
    };

    const availableUsers = computed(() => {
      const currentAdmins = studyAdminsApiResource
        .getData()
        .map((user) => user.minervauser);

      return usersApiResource
        .getData()
        .filter((user) => !currentAdmins.includes(user.minervaId));
    });

    const tableLoading = computed(
      () =>
        studyAdminsApiResource.getLoading() ||
        deleteStudyAdminApiResource.getLoading()
    );

    const orderEmailRecieverTableLoading = computed(
      () =>
        orderEmailReceiversApiResource.getLoading() ||
        deleteOrderEmailReceiversApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteStudyAdminHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete user ${selectedStudyAdmin.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteStudyAdminApiResource.fetchResource();
        },
        reject: () => {
          selectedStudyAdminId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const confirmDeleteOrderEmailReceiverHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete order email receiver ${selectedOrderEmailReceiver.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteOrderEmailReceiversApiResource.fetchResource();
        },
        reject: () => {
          selectedOrderEmailReceiverId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      contextMenuOptions,
      orderEmailReceiverContextMenuOptions,
      studyAdminsApiResource,
      orderEmailReceiversApiResource,
      setSelectedStudyAdminId,
      selectedStudyAdmin,
      setSelectedOrderEmailReceiverId,
      selectedOrderEmailReceiver,
      tableLoading,
      orderEmailRecieverTableLoading,
      getUserEmail,
      getOrderEmailReceiverEmail,
      availableUsers,
    };
  },
};
</script>