<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="study_name" class="title">Study Name</label>
      <InputText
        id="study_name"
        v-model="newStudyModel.name.$model"
        :class="{ 'p-error': newStudyModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newStudyModel.name" />
    </FormCol>
    <FormCol v-if="isEditing" col-width="p-md-6">
      <label for="current_status" class="title">Status</label>
      <InputText id="current_status" v-model="newStudy.current_status" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="trial_code" class="title">Trial Code</label>
      <InputText
        id="trial_code"
        v-model="newStudyModel.trial_code.$model"
        :class="{ 'p-error': newStudyModel.trial_code.$invalid }"
      />
      <ErrorMessages :model-property="newStudyModel.trial_code" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <div
        v-if="getInternalId.length > 0"
        class="chip-wrapper chip-info"
        style="margin-top: 28px"
      >
        <i class="pi pi-info-circle"></i>
        <p class="p-mx-2">Internal Id: {{ getInternalId }}</p>
      </div>
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="clinical_trial_id" class="title">Clinical Trial ID</label>
      <InputText
        id="clinical_trial_id"
        v-model="newStudyModel.clinical_trial_id.$model"
        :class="{ 'p-error': newStudyModel.clinical_trial_id.$invalid }"
      />
      <ErrorMessages :model-property="newStudyModel.clinical_trial_id" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="collaborator" class="title">Collaborator</label>
      <InputText id="collaborator" v-model="newStudy.collaborator" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="notes" class="title">Description</label>
      <Textarea
        v-model="newStudyModel.description.$model"
        label="notes"
        :auto-resize="true"
        rows="3"
        cols="60"
        :class="{ 'p-error': newStudyModel.description.$invalid }"
      />
      <ErrorMessages :model-property="newStudyModel.description" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newStudyModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Textarea from "primevue/textarea";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import {
  required,
  between,
  minLength,
  maxLength,
  helpers,
} from "@vuelidate/validators";

export default {
  name: "AddEditStudy",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Textarea,
  },
  props: {
    selectedStudy: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newStudy = reactive({
      name: "",
      description: "",
      trial_code: "",
      clinical_trial_id: "",
      collaborator: "",
      current_status: "",
    });

    const clinicalTrialIdVerification = (input) => {
      if (!input || input.length === 0) return true;
      return /^[\w.-]+$/g.test(input);
    };

    const rules = {
      name: { required: helpers.withMessage("A name is required", required) },
      description: {
        required: helpers.withMessage("A description is required", required),
      },
      trial_code: {
        required: helpers.withMessage("A trial code is required", required),
        between: helpers.withMessage(
          "The trial code must be between 1 and 99999",
          between(0, 99999)
        ),
        minLength: minLength(1),
        maxLength: maxLength(5),
      },
      clinical_trial_id: {
        maxLength: helpers.withMessage(
          "The clinical trial id cannot be longer than 32 characters",
          maxLength(32)
        ),
        clinicalTrialIdVerification: helpers.withMessage(
          "The clinical trial id can only contain alphanumeric characters, '-', or '_'",
          clinicalTrialIdVerification
        ),
      },
    };

    const newStudyModel = useVuelidate(rules, newStudy, { $lazy: true });

    const createStudy = () =>
      store.dispatch("studyManagement/createStudy", {
        name: newStudy.name,
        description: newStudy.description,
        trial_code: newStudy.trial_code,
        clinical_trial_id: newStudy.clinical_trial_id,
        collaborator: newStudy.collaborator,
      });

    const createStudyApiResource = new ApiResource(
      undefined,
      createStudy,
      "Successfully created new study",
      "There was an error creating the new study."
    );

    const updateStudy = () =>
      store.dispatch("studyManagement/updateStudy", {
        minervaId: props.selectedStudy.minervaId,
        name: newStudy.name,
        description: newStudy.description,
        trial_code: newStudy.trial_code,
        clinical_trial_id: newStudy.clinical_trial_id,
        collaborator: newStudy.collaborator,
        current_status: newStudy.current_status,
      });

    const updateStudyApiResource = new ApiResource(
      undefined,
      updateStudy,
      "Successfully updated the study.",
      "There was an error updating the study."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newStudyModel.value.$validate()) {
        props.isEditing
          ? await updateStudyApiResource.fetchResource()
          : await createStudyApiResource.fetchResource();
        closeForm();
      }
    };

    const getInternalId = computed(() => {
      if (newStudy.trial_code.length === 0) {
        return "";
      }
      return `${newStudy.trial_code}`;
    });

    const formLoading = computed(
      () =>
        createStudyApiResource.getLoading() ||
        updateStudyApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        newStudy.name = props.selectedStudy.name;
        newStudy.description = props.selectedStudy.description;
        newStudy.trial_code = props.selectedStudy.trial_code;
        newStudy.clinical_trial_id = props.selectedStudy.clinical_trial_id;
        newStudy.collaborator = props.selectedStudy.collaborator;
        newStudy.current_status = props.selectedStudy.current_status;
      }
    });

    return {
      closeForm,
      newStudy,
      newStudyModel,
      formLoading,
      handleSave,
      getInternalId,
    };
  },
};
</script>
