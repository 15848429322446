<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="waybill_number" class="title">Waybill Number</label>
      <InputText
        id="waybill_number"
        v-model="model.waybill_number.$model"
        :class="{ 'p-error': model.waybill_number.$invalid }"
      />
      <ErrorMessages :model-property="model.waybill_number" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="notify_imaging_site"
          v-model="newWaybill.notify_imaging_site"
          :binary="true"
          class="p-mb-1"
        />
        <label for="notify_imaging_site" class="checkbox-text p-ml-2 p-mb-1">
          Notify Imaging Site?
        </label>
      </div>
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="courier" class="title">Courier</label>
      <Dropdown
        id="courier"
        v-model="newWaybill.courier"
        :options="['MNX', 'FEDEX', 'DHL', 'UPS', 'Other']"
        append-to="body"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="courier_type" class="title">Courier Type</label>
      <Dropdown
        id="courier_type"
        v-model="newWaybill.courier_type"
        :options="['GROUND', 'FLIGHT']"
        append-to="body"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditWaybill",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    Checkbox,
  },
  props: {
    selectedPharmacyOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newWaybill = reactive({
      waybill_number: "",
      notify_imaging_site: false,
      courier: "MNX",
      courier_type: "GROUND",
    });

    const rules = {
      waybill_number: {
        required: helpers.withMessage("A waybill number is required", required),
      },
    };

    const model = useVuelidate(rules, newWaybill, { $lazy: true });

    const setWaybill = () =>
      store.dispatch("pharmacyOrders/setWaybill", {
        pharmacyOrder: props.selectedPharmacyOrder,
        newWaybill,
      });

    const setWaybillApiResource = new ApiResource(
      undefined,
      setWaybill,
      "Successfully set waybill",
      "There was an error setting the waybill."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await model.value.$validate()) {
        await setWaybillApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(() => setWaybillApiResource.getLoading());

    onMounted(() => {
      const activePharmacy = props.selectedPharmacyOrder.active_pharmacy;
      if (activePharmacy.waybill.length > 0)
        newWaybill.waybill_number = activePharmacy.waybill;
      if (activePharmacy.notify_imaging_site)
        newWaybill.notify_imaging_site = activePharmacy.notify_imaging_site;
      if (activePharmacy.courier.length > 0)
        newWaybill.courier = activePharmacy.courier;
      if (activePharmacy.courier_type.length > 0)
        newWaybill.courier_type = activePharmacy.courier_type;
    });

    return {
      newWaybill,
      closeForm,
      model,
      formLoading,
      handleSave,
    };
  },
};
</script>
