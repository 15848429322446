<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="permission_name" class="title">Permission Name</label>
      <InputText
        id="permission_name"
        v-model="newPermissionModel.name.$model"
        :class="{ 'p-error': newPermissionModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newPermissionModel.name" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newPermissionModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";

import { reactive, computed, inject } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

export default {
  name: "AddPermission",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
  },
  props: {
    selectedRoleName: {
      type: String,
      default: "",
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPermission = reactive({
      name: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage(
          "A permission name is required",
          required
        ),
      },
    };

    const newPermissionModel = useVuelidate(rules, newPermission, {
      $lazy: true,
    });

    const createPermission = async () => {
      try {
        const response = await axiosInstance.post(
          buildBaseUrl({
            apiModule: "userManagerApi",
            url: "permission",
          }),
          newPermission
        );
        if (response) return response;
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const createPermissionApiResource = new ApiResource(
      undefined,
      createPermission,
      "Successfully created new permission",
      "There was an error creating the new permission."
    );

    const addPermissionToRole = (newPermission) =>
      store.dispatch("rolesPermissions/addPermissionsByRole", {
        roleName: props.selectedRoleName,
        payload: {
          role_name: props.selectedRoleName,
          permission_name: newPermission.name,
        },
      });

    const addPermissionToRoleApiRequest = new ApiResource(
      undefined,
      addPermissionToRole,
      "Successfully added the new permission to the selected role",
      "There was an error adding the new permission to the selected role."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newPermissionModel.value.$validate()) {
        const newBasePermission = await createPermissionApiResource.fetchResource();
        await addPermissionToRoleApiRequest.fetchResource(newBasePermission);
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createPermissionApiResource.getLoading() ||
        addPermissionToRoleApiRequest.getLoading()
    );

    return {
      closeForm,
      newPermission,
      newPermissionModel,
      formLoading,
      handleSave,
    };
  },
};
</script>
