<template>
  <div class="p-fluid p-formgrid p-grid dashboard" :style="`width: ${width}`">
    <slot></slot>
    <div style="height: 0.5rem" class="form-footer">
      <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.5rem" />
    </div>
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
export default {
  name: "FormWrapper",
  components: {
    ProgressBar,
  },
  props: {
    loading: {
      type: Boolean,
      defaul: false,
    },
    width: {
      type: String,
      default: "600px",
    },
  },
};
</script>

<style>
.form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e6e7eb;
}
</style>
