<template>
  <div class="p-d-flex">
    <div class="p-col-7">
      <PharmacyCalendar :selected-pharmacy="selectedPharmacy" />
    </div>
    <div v-if="viewTechnicians" class="p-col-5">
      <PharmacyTechnicians :selected-pharmacy="selectedPharmacy" />
      <!-- <div class="p-pt-6">
        <PharmacyPo :selected-pharmacy="selectedPharmacy" />
      </div> -->
    </div>
  </div>
</template>

<script>
import PharmacyTechnicians from "@/components/Pharmacies/PharmacyTechnicians";
import PharmacyCalendar from "@/components/Pharmacies/PharmacyCalendar";
// import PharmacyPo from "@/components/Pharmacies/PharmacyPo";

export default {
  name: "PharmcyDetailsWrapper",
  components: { PharmacyTechnicians, PharmacyCalendar },
  props: {
    selectedPharmacy: {
      type: Object,
      required: true,
    },
    viewTechnicians: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
