<template>
  <AppDataTable
    :selected-row-data="selectedPharmacyAdmin"
    title="Pharmacy Administrators"
    add-button="Pharmacy Administrator"
    enable-add-button
    :table-data="pharmacyAdminsApiResource.getData()"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedPharmacyAdminId"
  >
    <Column field="display_name" header="Name"></Column>
    <Column header="Email">
      <template #body="slotProps">
        {{ getUserEmail(slotProps.data) }}
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddPharmacyAdmin
        :available-users="availableUsers"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddPharmacyAdmin from "@/components/Pharmacies/AddPharmacyAdmin.vue";

import { useConfirm } from "primevue/useConfirm";
import Column from "primevue/column";
import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "PharmacyAdministrators",
  components: {
    AppDataTable,
    AddPharmacyAdmin,
    Column,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Delete",
        command: (event) => {
          confirmDeletePharmacyAdminHandler(event);
        },
      },
    ];

    const pharmacyAdmins = () => store.state.pharmacies.pharmacySystemAdmins;
    const fetchPharmacyAdmins = () =>
      store.dispatch("pharmacies/fetchPharmacySystemAdmins");

    const pharmacyAdminsApiResource = new ApiResource(
      pharmacyAdmins,
      fetchPharmacyAdmins,
      undefined,
      "There was an error fetching the pharmacy system administrators."
    );

    const nonGSAUsers = () => store.state.users.nonGSAUsers;
    const fetchNonGSAUsers = () => store.dispatch("users/fetchNonGSAUsers");

    const nonGSAUsersApiResource = new ApiResource(
      nonGSAUsers,
      fetchNonGSAUsers,
      undefined,
      "There was an error fetching the study users."
    );

    const getUserEmail = (user) => {
      const users = nonGSAUsersApiResource.getData();
      if (users) {
        const userObject = nonGSAUsersApiResource
          .getData()
          .find((userObj) => userObj.minervaId === user.minervauser);
        return userObject ? userObject.email : "";
      }
      return "";
    };

    onMounted(() => {
      if (!nonGSAUsersApiResource.getData().value)
        nonGSAUsersApiResource.fetchResource();
      pharmacyAdminsApiResource.fetchResource();
    });

    const deletePharmacyAdmin = () =>
      store.dispatch(
        "pharmacies/deletePharmacySystemAdmin",
        selectedPharmacyAdmin.value
      );

    const deletePharmacyAdminApiResource = new ApiResource(
      undefined,
      deletePharmacyAdmin,
      "Successfully deleted pharmacy system administrator.",
      "There was an error deleting the pharmacy system administrator."
    );

    const selectedPharmcyAdminId = ref("");
    const selectedPharmacyAdmin = computed(() => {
      if (selectedPharmcyAdminId.value.length > 0) {
        return store.state.pharmacies.pharmacySystemAdmins.find(
          (pharmacyAdmin) =>
            pharmacyAdmin.minervaId === selectedPharmcyAdminId.value
        );
      }
      return {};
    });
    const setSelectedPharmacyAdminId = (study) => {
      selectedPharmcyAdminId.value = study.minervaId;
    };

    const availableUsers = computed(() => {
      const currentAdmins = pharmacyAdminsApiResource
        .getData()
        .map((user) => user.minervauser);

      return nonGSAUsersApiResource
        .getData()
        .filter((user) => !currentAdmins.includes(user.minervaId));
    });

    const tableLoading = computed(
      () =>
        nonGSAUsersApiResource.getLoading() ||
        pharmacyAdminsApiResource.getLoading() ||
        deletePharmacyAdminApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeletePharmacyAdminHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete study administrator ${selectedPharmacyAdmin.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deletePharmacyAdminApiResource.fetchResource();
        },
        reject: () => {
          selectedPharmcyAdminId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      contextMenuOptions,
      pharmacyAdminsApiResource,
      setSelectedPharmacyAdminId,
      selectedPharmacyAdmin,
      tableLoading,
      getUserEmail,
      availableUsers,
    };
  },
};
</script>
