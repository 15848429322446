<template>
  <div class="p-field p-col-12 card summary" :class="colWidth">
    <slot></slot>
  </div>
  <div v-if="singleRow" class="p-col-12 p-md-6"></div>
</template>

<script>
export default {
  name: "FormCol",
  props: {
    colWidth: {
      type: String,
      default: "",
    },
    singleRow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
