<template>
  <Card style="width: 25rem; margin-bottom: 2em">
    <template #title>
      <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
        <h4>API Management</h4>
        <div class="p-d-flex p-flex-row">
          <span
            class="p-d-flex p-ai-center p-mr-4 p-ml-2"
            style="width: 25px; height: 25px"
          >
            <ProgressLoader
              style="width: 25px; height: 25px"
              :loading="apiTableLoading"
            />
          </span>
          <Button @click="openAddApiModal = true">Add API</Button>
        </div>
      </div>
    </template>
  </Card>
  <div class="p-d-flex p-flex-wrap">
    <Panel
      v-for="(api, index) in apiManagerApiResource.getData()"
      :key="index"
      :header="api.name"
      :toggleable="true"
      :collapsed="true"
      style="width: 40em; margin: 1em"
    >
      <CardLine title="API URL:" :data="api.api_url" />
      <CardLine title="ESP URL:" :data="api.esp_url" />
      <CardLine title="Base Path Suffix:" :data="api.base_path_suffix" />
      <CardLine title="Base Path:" :data="api.base_path" />
      <CardLine title="Version:" :data="api.version" />
      <CardLine title="Keep Alive Base:" :data="api.keep_alive_base" />
      <CardLine title="Active:" :data="api.active" />
      <div class="p-col-12 p-d-flex p-ai-center p-jc-end">
        <div class="p-d-flex">
          <Button
            icon="pi pi-pencil"
            label="Edit"
            @click="handleEditApi(api)"
          />
        </div>
        <div class="p-d-flex">
          <Button
            icon="pi pi-times"
            label="Delete"
            class="p-button-secondary"
            style="margin-left: 0.5em"
            @click="handleDeleteApi($event, api)"
          />
        </div>
      </div>
    </Panel>
  </div>
  <!-- <div class="p-d-flex p-flex-wrap">
      <Card
        v-for="(api, index) in apiManagerApiResource.getData()"
        :key="index"
        style="width: 40em; margin: 1em"
      >
        <template #title>{{ api.name }}</template>
        <template #content>
          <CardLine title="API URL:" :data="api.api_url" />
          <CardLine title="ESP URL:" :data="api.esp_url" />
          <CardLine title="Base Path Suffix:" :data="api.base_path_suffix" />
          <CardLine title="Base Path:" :data="api.base_path" />
          <CardLine title="Version:" :data="api.version" />
          <CardLine title="Keep Alive Base:" :data="api.keep_alive_base" />
          <CardLine title="Active:" :data="api.active" />
        </template>
        <template #footer>
          <div class="p-col-12 p-d-flex p-ai-center p-jc-end">
            <div class="p-d-flex">
              <Button
                icon="pi pi-pencil"
                label="Edit"
                @click="handleEditApi(api)"
              />
            </div>
            <div class="p-d-flex">
              <Button
                icon="pi pi-times"
                label="Delete"
                class="p-button-secondary"
                style="margin-left: 0.5em"
                @click="handleDeleteApi($event, api)"
              />
            </div>
          </div>
        </template>
      </Card>
    </div> -->
  <!-- <AppDataTable
      :selected-row-data="selectedApi"
      title="Api Management"
      add-button="Api"
      enable-add-button
      :table-data="apiManagerApiResource.getData()"
      :loading="apiTableLoading"
      :context-menu-options="apiContextMenuOptions"
      @update:selected-row-data="setSelectedApiId"
    >
      <Column header="Name">
        <template #body="{ data }">
          {{ data.name }}
        </template>
      </Column>
      <template #addModalContent="{ closeAddModal }">
        <AddEditApi @close-form="closeAddModal" />
      </template>
    </AppDataTable> -->
  <Dialog
    v-model:visible="openAddApiModal"
    :modal="true"
    header="Create new API"
  >
    <AddEditApi @close-form="openAddApiModal = false" />
  </Dialog>
  <Dialog
    v-model:visible="openEditApiModal"
    :modal="true"
    :header="`Edit Api - ${selectedApi && selectedApi.name}`"
  >
    <AddEditApi
      :selected-api="selectedApi"
      :is-editing="true"
      @close-form="openEditApiModal = false"
    />
  </Dialog>
</template>

<script>
import Card from "primevue/card";
import Panel from "primevue/panel";
import AddEditApi from "@/components/Users/AddEditApi.vue";
import CardLine from "@/components/Users/CardLine.vue";
import ProgressLoader from "@/components/ProgressLoader/ProgressLoader";
import Dialog from "primevue/dialog";

import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "ApiManagement",
  components: {
    Dialog,
    AddEditApi,
    Card,
    Panel,
    ProgressLoader,
    CardLine,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const confirm = useConfirm();

    const apiContextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditApiModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteApiHandler(event);
        },
      },
    ];

    const apis = () => store.state.apiManagement.apis;
    const fetchApis = () => store.dispatch("apiManagement/fetchApis");
    const apiManagerApiResource = new ApiResource(
      apis,
      fetchApis,
      undefined,
      "There was an error fetching your apis."
    );

    const deleteApi = () =>
      store.dispatch("apiManagement/deleteApi", selectedApi.value);
    const deleteApiApiResource = new ApiResource(
      undefined,
      deleteApi,
      "Successfully deleted api.",
      "There was an error deleting the api."
    );

    const selectedApiId = ref("");
    const selectedApi = computed(() => {
      if (selectedApiId.value.length > 0) {
        return store.state.apiManagement.apis.find(
          (api) => api.minervaId === selectedApiId.value
        );
      }
      return {};
    });
    const setSelectedApiId = (api) => {
      selectedApiId.value = api.minervaId;
    };

    const openAddApiModal = ref(false);

    const openEditApiModal = ref(false);

    const apiTableLoading = computed(
      () =>
        apiManagerApiResource.getLoading() || deleteApiApiResource.getLoading()
    );

    const confirmDeleteApiHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete api ${selectedApi.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteApiApiResource.fetchResource();
        },
        reject: () => {
          deleteApiApiResource.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    onMounted(async () => {
      apiManagerApiResource.fetchResource();
    });

    const handleEditApi = (api) => {
      setSelectedApiId(api);
      openEditApiModal.value = true;
    };

    const handleDeleteApi = (event, api) => {
      setSelectedApiId(api);
      confirmDeleteApiHandler(event);
    };
    return {
      apiContextMenuOptions,
      openEditApiModal,
      apiManagerApiResource,
      selectedApi,
      selectedApiId,
      setSelectedApiId,
      apiTableLoading,
      handleEditApi,
      handleDeleteApi,
      openAddApiModal,
    };
  },
};
</script>
