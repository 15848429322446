<template>
  <AppDataTable
    :selected-row-data="selectedGroup"
    title="Groups"
    add-button="Group"
    enable-add-button
    :table-data="groupsApiResource.getData()"
    :columns="columns"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedGroupId"
  >
    <template #addModalContent="{ closeAddModal }">
      <AddEditGroup @close-form="closeAddModal" />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Group - ${selectedGroup && selectedGroup.name}`"
  >
    <AddEditGroup
      :selected-group="selectedGroup"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditGroup from "@/components/Groups/AddEditGroup.vue";

import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Groups",
  components: {
    AppDataTable,
    AddEditGroup,
    Dialog,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([{ field: "name", header: "Name" }]);

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteGroupHandler(event);
        },
      },
    ];

    const groups = () => store.state.groups.groups;
    const fetchGroups = () => store.dispatch("groups/fetchGroups");
    const groupsApiResource = new ApiResource(
      groups,
      fetchGroups,
      undefined,
      "There was an error fetching your groups."
    );

    onMounted(() => {
      if (
        !groupsApiResource.getData() ||
        groupsApiResource.getData().length === 0
      ) {
        groupsApiResource.fetchResource();
      }
    });

    const deleteGroup = () =>
      store.dispatch("groups/deleteGroup", selectedGroup.value);
    const deleteGroupApiResource = new ApiResource(
      undefined,
      deleteGroup,
      "Successfully deleted group.",
      "There was an error deleting the group."
    );

    const selectedGroupId = ref("");
    const selectedGroup = computed(() => {
      if (selectedGroupId.value.length > 0) {
        return store.state.groups.groups.find(
          (group) => group.minervaId === selectedGroupId.value
        );
      }
      return {};
    });
    const setSelectedGroupId = (group) => {
      selectedGroupId.value = group.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        groupsApiResource.getLoading() || deleteGroupApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteGroupHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete group ${selectedGroup.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteGroupApiResource.fetchResource();
        },
        reject: () => {
          selectedGroupId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      columns,
      contextMenuOptions,
      groupsApiResource,
      setSelectedGroupId,
      selectedGroup,
      openEditModal,
      tableLoading,
    };
  },
};
</script>
