<template>
  <div class="summary-data-table">
    <DataTable
      ref="summarydatatable"
      :value="mappedData"
      :scrollable="true"
      scroll-height="600px"
      scroll-direction="both"
      row-hover
    >
      <template #header>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <h4>Order Summary</h4>
          <div class="p-d-flex p-flex-row">
            <span class="p-mr-2">
              <MultiSelect
                :model-value="selectedColumns"
                :options="columns"
                option-label="header"
                placeholder="Select Columns"
                style="width: 20em"
                :option-disabled="(v) => v.field === 'OrderID'"
                @update:modelValue="onToggle"
              />
            </span>
            <span class="p-d-flex p-ai-center p-mr-4 p-ml-2">
              <ProgressLoader
                style="width: 25px; height: 25px"
                :loading="orderSummaryApiResource.getLoading()"
              />
            </span>
            <span class="p-d-flex p-ai-center p-ml-2">
              <Button
                icon="pi pi-external-link"
                label="Export"
                @click="exportCSV($event)"
              />
            </span>
          </div>
        </div>
      </template>
      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
      ></Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import ProgressLoader from "@/components/ProgressLoader/ProgressLoader";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import { ref, inject, watch, computed } from "vue";
import { getAmountString } from "@/helpers/units";
import { useStore } from "vuex";

export default {
  name: "OrderSummary",
  components: {
    DataTable,
    ProgressLoader,
    MultiSelect,
    Column,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([
      { field: "OrderID", header: "Order ID", default: true },
      { field: "SubjectID", header: "Subject ID", default: true },
      { field: "AdditionalCourierNotes", header: "Additional Courier Notes" },
      { field: "Amount", header: "Amount", default: true },
      { field: "CalDate", header: "Order Date", default: true },
      { field: "CalTime", header: "Order Time", default: true },
      { field: "CancellationReason", header: "Cancellation Reason" },
      { field: "Cancelled", header: "Cancelled" },
      { field: "CustomerCode", header: "Customer Code" },
      { field: "DefaultDeliveryNotes", header: "Default Delivery Notes" },
      { field: "DefaultOrderAmount", header: "Default Order Amount" },
      { field: "Investigator", header: "Investigator" },
      { field: "Network", header: "Network", default: true },
      { field: "NetworkSiteID", header: "Network Site ID" },
      { field: "OrderAmountFixed", header: "Order Amount Fixed" },
      { field: "OrderNotes", header: "Order Notes" },
      { field: "Pharmacy", header: "Pharmacy", default: true },
      { field: "Product", header: "Product", default: true },
      { field: "RXID", header: "RXID" },
      { field: "ReservationID", header: "Reservation ID", default: true },
      { field: "Site", header: "Site" },
      { field: "SiteAddress", header: "Site Address" },
      { field: "SiteCity", header: "Site City" },
      { field: "SiteCode", header: "Site Code" },
      { field: "SiteContact", header: "Site Contact" },
      { field: "SiteContactEmail", header: "Site Contact Email" },
      { field: "SiteContactFax", header: "Site Contact Fax" },
      { field: "SiteContactNumber", header: "Site Contact Number" },
      { field: "SiteCountry", header: "Site Country" },
      { field: "SitePostalCode", header: "Site Postal Code" },
      { field: "SiteRamExpiry", header: "Site Ram Expiry" },
      { field: "SiteRamLicense", header: "Site Ram License" },
      { field: "SiteRegion", header: "Site Region" },
      { field: "SiteState", header: "Site State" },
      { field: "Status", header: "Status", default: true },
      { field: "Study", header: "Study" },
      { field: "StudyDescription", header: "StudyDescription" },
    ]);
    const selectedColumns = ref(columns.value.filter((col) => col.default));

    const onToggle = (val) => {
      selectedColumns.value = columns.value.filter((col) => val.includes(col));
    };

    const orderSummary = () => store.state.orderManagement.orderSummary;
    const fetchOrderSummary = () =>
      store.dispatch("orderManagement/fetchOrderSummary");
    const orderSummaryApiResource = new ApiResource(
      orderSummary,
      fetchOrderSummary,
      undefined,
      "There was an error fetching your order summary."
    );

    watch(
      () => store.state.orderManagement.orderReportToken,
      (token) => {
        if (token.length > 0) orderSummaryApiResource.fetchResource();
      },
      { immediate: true }
    );

    const summarydatatable = ref();

    const exportCSV = () => {
      summarydatatable.value.exportCSV();
    };

    const mappedData = computed(() => {
      if (orderSummaryApiResource.getData().length == 0) {
        return [];
      }
      return orderSummaryApiResource.getData().map((data) => {
        return { ...data, Amount: getAmountString(data.Amount) };
      });
    });
    return {
      orderSummaryApiResource,
      onToggle,
      selectedColumns,
      columns,
      summarydatatable,
      exportCSV,
      mappedData,
    };
  },
};
</script>

<style>
.summary-data-table th {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
.summary-data-table td {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
</style>
