const regionList = [
  {
    value: "NORTH_AMERICA",
    display_name: "NORTH AMERICA",
  },
  {
    value: "SOUTH_AMERICA",
    display_name: "SOUTH AMERICA",
  },
  {
    value: "EUROPE",
    display_name: "EUROPE",
  },
  {
    value: "MIDDLE_EAST",
    display_name: "MIDDLE EAST",
  },
  {
    value: "AFRICA",
    display_name: "AFRICA",
  },
  {
    value: "ASIA",
    display_name: "ASIA",
  },
  {
    value: "AUSTRALIA",
    display_name: "AUSTRALIA",
  },
];

const validRegion = (region) => regionList.map((r) => r.value.includes(region));

export { regionList, validRegion };
