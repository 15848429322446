<template>
  <FormWrapper>
    <FormCol col-width="p-md-4">
      <FormDataView title="Subject" :data="order.studysitesubjectid" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Product" :data="order.studysiteproduct_name" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Amount" :data="getAmountString(order.amount)" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Network"
        :data="order.studysiteproductnetwork_name"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Date"
        :data="getOrderDate(order.local_calibration)"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Time"
        :data="getOrderTime(order.local_calibration)"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <FormDataView title="Order ID" :data="order.order_id" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView title="Order Status" :data="order.status" />
    </FormCol>
    <FormCol v-if="order.status == 'Cancelled'" col-width="p-md-6">
      <FormDataView
        title="Cancellation Reason"
        :data="order.cancellation_reason"
      />
    </FormCol>
    <FormCol col-width="p-md-8">
      <FormDataView title="Reservation ID" :data="order.network_order_id" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <FormDataView title="Pharmacy" :data="order.pharmacy" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <FormDataView title="RX ID" :data="order.rx_id" />
    </FormCol>
    <Divider />
    <FormCol>
      <FormDataView title="Order Notes" :data="order.notes" />
    </FormCol>
    <Divider />
    <FormCol>
      <FormDataView title="Delivery Notes" :data="studySite.delivery_info" />
    </FormCol>
    <Divider />
    <FormCol>
      <FormDataView title="Additional Notes" :data="order.courier_notes" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <FormDataView
        title="Order cancelled"
        :data="order.cancelled ? 'Yes' : 'No'"
      />
    </FormCol>
    <FormCol v-if="order.cancelled" col-width="p-md-6">
      <FormDataView
        title="Cancellation Reason"
        :data="order.cancellation_reason"
      />
    </FormCol>
  </FormWrapper>
</template>

<script>
import Divider from "primevue/divider";
import FormCol from "@/components/FormComponents/FormCol";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormDataView from "@/components/FormComponents/FormDataView";
import { getAmountString } from "@/helpers/units";
import { getTime, getDate } from "@/helpers/timeHelpers";

export default {
  name: "ViewOrder",
  components: {
    Divider,
    FormCol,
    FormWrapper,
    FormDataView,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    studySite: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const getOrderDate = (date) => {
      return getDate(date);
    };

    const getOrderTime = (date) => {
      return getTime(date);
    };

    return {
      getOrderDate,
      getOrderTime,
      getAmountString,
    };
  },
};
</script>
