<template>
  <AppDataList
    title="Study Order Notification List"
    add-title="study notification user"
    display-prop="name_with_email"
    :data="mappedEmailReceivers"
    :loading="loading"
    @delete-entity="confirmDeleteEmailReceiver($event, admin)"
  >
    <template #addModalContent="{ closeAddModal }">
      <FormWrapper :loading="loading">
        <FormCol col-width="p-md-6">
          <label for="email_receiver_name" class="title">Name</label>
          <InputText
            id="email_receiver_name"
            v-model="model.name.$model"
            :class="{ 'p-error': model.name.$invalid }"
          />
          <ErrorMessages :model-property="model.name" />
        </FormCol>
        <FormCol col-width="p-md-6">
          <label for="email_receiver_email" class="title">Email</label>
          <InputText
            id="email_receiver_email"
            v-model="model.email.$model"
            :class="{ 'p-error': model.email.$invalid }"
          />
          <ErrorMessages :model-property="model.email" />
        </FormCol>
        <Divider />
        <FormButtonCol>
          <CancelConfirmButtons
            :disabled="loading || model.$invalid"
            cancel-text="CLOSE"
            confirm-text="SUBMIT"
            @cancelled="closeAddModal()"
            @confirmed="handleSave(closeAddModal)"
          />
        </FormButtonCol>
      </FormWrapper>
    </template>
  </AppDataList>
</template>

<script>
import AppDataList from "@/components/AppDataList/AppDataList";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import { inject, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useConfirm";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

export default {
  name: "StudyEmailReceivers",
  components: {
    AppDataList,
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const emailReceivers = () =>
      store.state.emailReceivers.emailReceivers[props.selectedStudy.minervaId];
    const fetchEmailReceivers = () =>
      store.dispatch("emailReceivers/fetchEmailReceivers", {
        studyId: props.selectedStudy.minervaId,
      });

    const emailReceiversApiResource = new ApiResource(
      emailReceivers,
      fetchEmailReceivers,
      undefined,
      "There was an error fetching the study email receivers."
    );

    const mappedEmailReceivers = computed(() => {
      if (
        emailReceiversApiResource.getData() &&
        emailReceiversApiResource.getData().length > 0
      ) {
        return emailReceiversApiResource.getData().map((receiver) => {
          return {
            name_with_email: `${receiver.display_name} @ ${receiver.email}`,
            ...receiver,
          };
        });
      }

      return [];
    });

    const newEmailReceiver = reactive({
      study: props.selectedStudy.minervaId,
      name: "",
      email: "",
    });

    const rules = {
      name: { required: helpers.withMessage("A name is required", required) },
      email: {
        required: helpers.withMessage("An email is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };

    const model = useVuelidate(rules, newEmailReceiver, { $lazy: true });

    const addEmailReceiver = () =>
      store.dispatch("emailReceivers/addEmailReceiver", {
        studyId: props.selectedStudy.minervaId,
        payload: newEmailReceiver,
      });

    const addEmailReceiverApiResource = new ApiResource(
      undefined,
      addEmailReceiver,
      "Successfully added email receiver.",
      "There was an error adding the email receiver."
    );

    const handleSave = async (close) => {
      if (await model.value.$validate()) {
        await addEmailReceiverApiResource.fetchResource();
        newEmailReceiver.name = "";
        newEmailReceiver.email = "";
        model.value.$reset();
        close();
      }
    };

    const deleteEmailReceiver = (payload) =>
      store.dispatch("emailReceivers/deleteEmailReceiver", {
        studyId: props.selectedStudy.minervaId,
        payload,
      });

    const deleteEmailReceiverApiResource = new ApiResource(
      undefined,
      deleteEmailReceiver,
      "Successfully removed email receiver.",
      "There was an error removing the email receiver."
    );

    const confirm = useConfirm();

    const confirmDeleteEmailReceiver = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you wish to remove this email receiver?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await deleteEmailReceiverApiResource.fetchResource(event);
        },
        reject: () => {},
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const loading = computed(
      () =>
        emailReceiversApiResource.getLoading() ||
        deleteEmailReceiverApiResource.getLoading() ||
        addEmailReceiverApiResource.getLoading()
    );

    onMounted(() => {
      emailReceiversApiResource.fetchResource();
    });

    return {
      loading,
      confirmDeleteEmailReceiver,
      handleSave,
      model,
      mappedEmailReceivers,
    };
  },
};
</script>
