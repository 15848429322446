import { computed } from "vue";
import {
  calibrationDate,
  getDate,
  setTimeFromLocalCalibration,
} from "@/helpers/timeHelpers";

export const buildDateTimeChangeHandlers = (
  store,
  props,
  ApiResource,
  newOrder
) => {
  const dateTimeChanged = computed(
    () =>
      newOrder.date !== getDate(props.selectedOrder.local_calibration) ||
      newOrder.time !==
        setTimeFromLocalCalibration(props.selectedOrder.local_calibration)
  );

  const resetDateTime = () => {
    newOrder.date = getDate(props.selectedOrder.local_calibration);
    newOrder.time = setTimeFromLocalCalibration(
      props.selectedOrder.local_calibration
    );
  };

  const updateDateTimeRequest = () =>
    store.dispatch("orderManagement/updateStudySiteOrderDateTime", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      studySiteOrderId: props.selectedOrder.minervaId,
      updatedOrder: {
        study: newOrder.study,
        studysite: newOrder.studysite,
        studysiteorder: props.selectedOrder.minervaId,
        calibration: calibrationDate(
          newOrder.date,
          newOrder.time,
          props.studySite.timezone
        ),
        reservation_id: newOrder.reservation_id,
        skip_network_api_call: newOrder.skip_network_api_call,
      },
    });

  const saveUpdatedDateTimeApiResource = new ApiResource(
    undefined,
    updateDateTimeRequest,
    "Successfully updated the date and time.",
    "There was an error updating the date and time."
  );

  return {
    dateTimeChanged,
    resetDateTime,
    saveUpdatedDateTimeApiResource,
  };
};
