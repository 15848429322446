<template>
  <AppDataTable
    :selected-row-data="selectedUser"
    title="Users"
    add-button="User"
    enable-add-button
    :table-data="usersToDisplay"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedUserId"
  >
    <Column header="Name">
      <template #body="{ data }">
        {{ data.name }}
      </template>
    </Column>
    <Column header="Approved">
      <template #body="{ data }">
        {{ data.approved_text }}
      </template>
    </Column>
    <Column header="Disabled">
      <template #body="{ data }">
        {{ data.disabled_text }}
      </template>
    </Column>
    <Column header="GSA">
      <template #body="{ data }">
        {{ data.gsa_account_text }}
      </template>
    </Column>
    <Column header="User Groups">
      <template #body="{ data }">
        <div style="max-width: 300px">
          {{ getUserGroups(data) }}
        </div>
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddEditUser @close-form="closeAddModal" />
    </template>
    <template #headerContent>
      <div class="p-d-flex p-ai-center">
        <InputSwitch v-model="gsaOnly" />
        <label class="checkbox-text p-ml-3 p-mt-1">GSA</label>
      </div>
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit User - ${selectedUser && selectedUser.name}`"
  >
    <AddEditUser
      :selected-user="selectedUser"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openEditGroupModal"
    :modal="true"
    :header="`Edit User - ${selectedUser && selectedUser.name}`"
  >
    <AddEditUserGroup
      :selected-user="selectedUser"
      :is-removing="removingGroup"
      :current-groups="userGroupsApiResource.getData(selectedUser)"
      :all-groups="groupsApiResource.getData()"
      @close-form="resetGroupForm"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditUser from "@/components/Users/AddEditUser.vue";
import AddEditUserGroup from "@/components/Users/AddEditUserGroup.vue";
import InputSwitch from "primevue/inputswitch";

import Dialog from "primevue/dialog";
import Column from "primevue/column";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Users",
  components: {
    AppDataTable,
    AddEditUser,
    Dialog,
    InputSwitch,
    Column,
    AddEditUserGroup,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Add Group Permission",
        command: () => {
          openEditGroupModal.value = true;
        },
      },
      {
        label: "Remove Group Permission",
        command: () => {
          openEditGroupModal.value = true;
          removingGroup.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteUserHandler(event);
        },
      },
    ];

    const gsaOnly = ref(false);

    const users = () => store.state.users.users;
    const fetchUsers = () => store.dispatch("users/fetchUsers");
    const usersApiResource = new ApiResource(
      users,
      fetchUsers,
      undefined,
      "There was an error fetching your users."
    );

    const usersToDisplay = computed(() => {
      if (usersApiResource.getData() && usersApiResource.getData().length > 0) {
        return gsaOnly.value
          ? usersApiResource.getData().filter((user) => user.gsa_account)
          : usersApiResource.getData().filter((user) => !user.gsa_account);
      }
      return [];
    });

    const deleteUser = () =>
      store.dispatch("users/deleteUser", selectedUser.value);
    const deleteUserApiResource = new ApiResource(
      undefined,
      deleteUser,
      "Successfully deleted user.",
      "There was an error deleting the user."
    );

    const selectedUserId = ref("");
    const selectedUser = computed(() => {
      if (selectedUserId.value.length > 0) {
        return store.state.users.users.find(
          (user) => user.minervaId === selectedUserId.value
        );
      }
      return {};
    });
    const setSelectedUserId = (user) => {
      selectedUserId.value = user.minervaId;
    };

    const openEditModal = ref(false);
    const openEditGroupModal = ref(false);
    const removingGroup = ref(false);

    const resetGroupForm = () => {
      openEditGroupModal.value = false;
      removingGroup.value = false;
    };

    const userGroups = (user) => store.state.users.userGroups[user.minervaId];
    const fetchUserGroups = (user) =>
      store.dispatch("users/fetchUserGroups", user);
    const userGroupsApiResource = new ApiResource(
      userGroups,
      fetchUserGroups,
      undefined,
      "There was an error fetching your groups."
    );

    const groups = () => store.state.groups.groups;
    const fetchGroups = () => store.dispatch("groups/fetchGroups");
    const groupsApiResource = new ApiResource(
      groups,
      fetchGroups,
      undefined,
      "There was an error fetching your groups."
    );

    const getUserGroups = (user) => {
      if (
        userGroupsApiResource.getData(user) !== undefined &&
        userGroupsApiResource.getData(user).length > 0
      ) {
        return userGroupsApiResource
          .getData(user)
          .map((group) => group.group_name)
          .join(", ");
      }
      return "";
    };

    const tableLoading = computed(
      () =>
        usersApiResource.getLoading() ||
        deleteUserApiResource.getLoading() ||
        userGroupsApiResource.getLoading() ||
        groupsApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteUserHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete user ${selectedUser.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteUserApiResource.fetchResource();
        },
        reject: () => {
          selectedUserId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    onMounted(async () => {
      if (
        !groupsApiResource.getData() ||
        groupsApiResource.getData().length === 0
      ) {
        groupsApiResource.fetchResource();
      }
      if (
        !usersApiResource.getData() ||
        usersApiResource.getData().length === 0
      ) {
        await usersApiResource.fetchResource();
      }
      const promises = usersApiResource.getData().map(async (user) => {
        await userGroupsApiResource.fetchResource(user);
      });
      Promise.all(promises);
    });

    return {
      contextMenuOptions,
      usersApiResource,
      setSelectedUserId,
      selectedUser,
      openEditModal,
      tableLoading,
      gsaOnly,
      usersToDisplay,
      openEditGroupModal,
      resetGroupForm,
      groupsApiResource,
      getUserGroups,
      removingGroup,
      userGroupsApiResource,
    };
  },
};
</script>
