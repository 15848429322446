<template>
  <FormWrapper :loading="formLoading" width="1200px">
    <FormCol col-width="p-md-6">
      <FormCol col-width="p-md-6">
        <label for="product" class="title">Product</label>
        <Dropdown
          id="product"
          v-model="model.product.$model"
          :options="productsApiResource.getData()"
          option-label="display_name"
          option-value="minervaId"
          :placeholder="productPlaceholder"
          :class="{ 'p-error': model.product.$invalid }"
        />
        <ErrorMessages :model-property="model.product" />
      </FormCol>
      <FormCol col-width="p-md-6">
        <label for="date" class="title">Dates</label>
        <Calendar
          id="date"
          v-model="model.date.$model"
          date-format="yy-mm-dd"
          :disabled-dates="disabledDates"
          :disabled-days="[0]"
          :manual-input="false"
          selection-mode="multiple"
          :min-date="new Date()"
          append-to="body"
          placeholder="Select one or more dates"
          :class="{ 'p-error': model.date.$invalid }"
          @month-change="setSearchDateForProductionDates"
        >
          <template #footer>
            <div style="height: 0.3rem">
              <ProgressBar
                v-if="pharmacyAvailableDatesApiResource.getLoading()"
                mode="indeterminate"
                style="height: 0.3rem"
              />
            </div>
          </template>
        </Calendar>
        <ErrorMessages :model-property="model.date" />
      </FormCol>
      <FormCol col-width="p-md-6">
        <label for="time" class="title">Earliest Time</label>
        <Calendar
          id="time-earliest"
          :model-value="displayEarliestTime"
          :time-only="true"
          placeholder="Select an earliest time"
          append-to="body"
          :step-minute="15"
          :class="{ 'p-error': model.earliest.$invalid }"
          @update:model-value="setEarliestTime(model, $event)"
        />
        <ErrorMessages :model-property="model.earliest" />
      </FormCol>
      <FormCol col-width="p-md-6">
        <label for="time" class="title">Latest Time</label>
        <Calendar
          id="time-latest"
          :model-value="displayLatestTime"
          :time-only="true"
          placeholder="Select a latest time"
          append-to="body"
          :step-minute="15"
          :class="{ 'p-error': model.latest.$invalid }"
          @update:model-value="setLatestTime(model, $event)"
        />
        <ErrorMessages :model-property="model.latest" />
      </FormCol>
      <FormCol col-width="p-md-6">
        <label for="slots" class="title">Slots</label>
        <InputNumber
          id="slots"
          v-model="model.slots.$model"
          mode="decimal"
          :show-buttons="true"
          :min="1"
          :class="{ 'p-error': model.slots.$invalid }"
        />
        <ErrorMessages :model-property="model.slots" />
      </FormCol>
      <Divider />
      <FormButtonCol>
        <CancelConfirmButtons
          :disabled="formLoading || model.$invalid"
          :cancel-text="
            newPharmacyDate.date.length <= 1 ? 'CLEAR DATE' : 'CLEAR DATES'
          "
          :confirm-text="
            newPharmacyDate.date.length <= 1 ? 'ADD DATE' : 'ADD DATES'
          "
          @cancelled="resetPharmacyDate"
          @confirmed="pushToSubmitArray"
        />
      </FormButtonCol>
    </FormCol>
    <FormCol col-width="p-md-6">
      <div class="dates-container">
        <div class="dates-inner-container">
          <h5 class="p-pa-2">Dates To Submit</h5>
          <table v-if="datesToSubmit.length > 0" class="date-table p-mt-2">
            <thead>
              <tr>
                <th class="date-table-header first-column-cell-borders">
                  Product
                </th>
                <th class="date-table-header first-column-cell-borders">
                  Date
                </th>
                <th class="date-table-header first-column-cell-borders">
                  Earliest Time
                </th>
                <th class="date-table-header first-column-cell-borders">
                  Latest Time
                </th>
                <th class="date-table-header first-column-cell-borders">
                  Slots
                </th>
                <th class="date-table-header last-column-cell-borders">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(date, index) in sortedDatesToSubmit" :key="index">
                <td class="date-table-cell first-column-cell-borders">
                  {{ getProductName(date.product) }}
                </td>
                <td class="date-table-cell first-column-cell-borders">
                  {{ formatDate(date.date) }}
                </td>
                <td class="date-table-cell first-column-cell-borders">
                  {{ date.earliest }}
                </td>
                <td class="date-table-cell first-column-cell-borders">
                  {{ date.latest }}
                </td>
                <td class="date-table-cell first-column-cell-borders">
                  {{ date.slots }}
                </td>
                <td class="date-table-cell last-column-cell-borders">
                  <i
                    class="pi pi-trash p-ml-2"
                    @click="removeDateToSubmit(index)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <Divider />
          <FormButtonCol>
            <CancelConfirmButtons
              :disabled="datesToSubmit.length === 0"
              cancel-text="CANCEL"
              confirm-text="SUBMIT DATES"
              @cancelled="closeForm"
              @confirmed="handleSave"
            />
          </FormButtonCol>
        </div>
      </div>
    </FormCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import ProgressBar from "primevue/progressbar";

import { ref, reactive, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { DateTime } from "luxon";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { setTZ, getDate } from "@/helpers/timeHelpers";

export default {
  name: "AddPharmacyDate",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    InputNumber,
    Dropdown,
    Calendar,
    ProgressBar,
  },
  props: {
    selectedPharmacy: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPharmacyDate = reactive({
      product: "",
      date: [],
      earliest: "11-00",
      latest: "15-00",
      slots: 5,
    });

    const isAfterEarliest = (value) => {
      if (newPharmacyDate.earliest) {
        return (
          Number(newPharmacyDate.earliest.replace("-", "")) <
          Number(value.replace("-", ""))
        );
      }
      return true;
    };

    const rules = {
      product: {
        required: helpers.withMessage(
          "A pharmacy product is required",
          required
        ),
      },
      date: { required: helpers.withMessage("A date is required", required) },
      earliest: {
        required: helpers.withMessage("An earliest time is required", required),
      },
      latest: {
        required: helpers.withMessage("A latest time is required", required),
        isAfterEarliest: helpers.withMessage(
          "The latest time must be after the earliest time",
          isAfterEarliest
        ),
      },
      slots: {
        required: helpers.withMessage(
          "At least one slot is required",
          required
        ),
      },
    };

    const model = useVuelidate(rules, newPharmacyDate, { $lazy: true });

    const handleDateInput = (model, date) => {
      model.date.$model = DateTime.fromISO(
        new Date(date).toISOString()
      ).toFormat("yyyy-LL-dd");
    };

    const formatDate = (date) => {
      return DateTime.fromISO(new Date(date).toISOString())
        .plus({ hours: 12 })
        .toFormat("yyyy-LL-dd");
    };

    const displayEarliestTime = computed(() => {
      if (newPharmacyDate.earliest.length > 0) {
        const hour = newPharmacyDate.earliest.split("-")[0];
        const minute = newPharmacyDate.earliest.split("-")[1];
        return new Date(DateTime.fromObject({ hour, minute }).toISO());
      }
    });

    const displayLatestTime = computed(() => {
      if (newPharmacyDate.latest.length > 0) {
        const hour = newPharmacyDate.latest.split("-")[0];
        const minute = newPharmacyDate.latest.split("-")[1];
        return new Date(DateTime.fromObject({ hour, minute }).toISO());
      }
    });

    const setEarliestTime = (model, time) => {
      model.earliest.$model = DateTime.fromISO(
        new Date(time).toISOString()
      ).toFormat("HH-mm");
    };

    const setLatestTime = (model, time) => {
      model.latest.$model = DateTime.fromISO(
        new Date(time).toISOString()
      ).toFormat("HH-mm");
    };

    const createPharmacyAvailableDate = (newDate) =>
      store.dispatch("pharmacies/createPharmacyAvailableDate", {
        pharmacyId: props.selectedPharmacy.minervaId,
        payload: newDate,
      });

    const createPharmacyAvailableDateApiResource = new ApiResource(
      undefined,
      createPharmacyAvailableDate,
      "Successfully created new pharmacy available date",
      "There was an error creating the new pharmacy available date."
    );

    const datesToSubmit = ref([]);

    const sortedDatesToSubmit = computed(() => {
      return datesToSubmit.value.sort((a, b) => {
        return DateTime.fromISO(new Date(a.date).toISOString()) >
          DateTime.fromISO(new Date(b.date).toISOString())
          ? 1
          : -1;
      });
    });

    const pushToSubmitArray = async () => {
      if (await model.value.$validate()) {
        newPharmacyDate.date.forEach((date) => {
          const thisDate = {
            product: newPharmacyDate.product,
            date: DateTime.fromISO(new Date(date).toISOString()).toFormat(
              "yyyy-LL-dd"
            ),
            earliest: newPharmacyDate.earliest,
            latest: newPharmacyDate.latest,
            slots: newPharmacyDate.slots,
          };
          datesToSubmit.value.push(thisDate);
        });
        resetPharmacyDate();
      }
    };

    const resetPharmacyDate = () => {
      model.value.$reset();
      newPharmacyDate.product = "";
      newPharmacyDate.date = [];
      newPharmacyDate.earliest = "11-00";
      newPharmacyDate.latest = "15-00";
    };

    const removeDateToSubmit = (index) => datesToSubmit.value.splice(index, 1);

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      const promises = datesToSubmit.value.map(async (date) => {
        await createPharmacyAvailableDateApiResource.fetchResource(date);
      });
      await Promise.allSettled(promises);
      closeForm();
    };

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const productPlaceholder = productsApiResource.getLoading()
      ? "Loading products"
      : "Select a product";

    const getProductName = (productId) => {
      const thisProduct = productsApiResource
        .getData()
        .find((product) => product.minervaId === productId);
      return thisProduct ? thisProduct.name : "";
    };

    const searchDateForProductionDates = ref("");
    const dateFormat = "yyyy-LL-dd";

    const setSearchDateForProductionDates = (inputMonth) => {
      const currentDate = DateTime.local();
      const currentMonth = Number(currentDate.toFormat("M"));
      const currentYear = Number(currentDate.toFormat("y"));
      const adjustedInputMonth = inputMonth.month + 1;

      if (
        (currentMonth === adjustedInputMonth &&
          currentYear === inputMonth.year) ||
        inputMonth.year < currentYear
      ) {
        searchDateForProductionDates.value = DateTime.local().toFormat(
          dateFormat
        );
      } else {
        searchDateForProductionDates.value = DateTime.fromObject({
          month: adjustedInputMonth,
          year: inputMonth.year,
        }).toFormat(dateFormat);
      }
    };

    const pharmacyAvailableDates = () =>
      store.state.pharmacies.pharmacyAvailableDates[
        props.selectedPharmacy.minervaId
      ];

    const fetchPharmacyAvailableDates = () =>
      store.dispatch("pharmacies/fetchPharmacyAvailableDates", {
        pharmacyId: props.selectedPharmacy.minervaId,
        searchDate: searchDateForProductionDates.value,
      });

    const pharmacyAvailableDatesApiResource = new ApiResource(
      pharmacyAvailableDates,
      fetchPharmacyAvailableDates,
      undefined,
      "There was an error fetching the pharmacy available dates."
    );

    watch(
      () => searchDateForProductionDates.value,
      () => pharmacyAvailableDatesApiResource.fetchResource()
    );

    const timeZoneForCal = ref("");

    watch(
      () => pharmacyAvailableDatesApiResource.getData(),
      () => {
        if (pharmacyAvailableDatesApiResource.getData().length > 0) {
          const firstDate = pharmacyAvailableDatesApiResource.getData()[0]
            .earliest;
          const timeZone = DateTime.fromISO(firstDate, { setZone: true })
            .zoneName;
          timeZoneForCal.value = timeZone;
        }
      }
    );

    const disabledDates = computed(() => {
      let disabledDates = [];
      if (timeZoneForCal.value.length === 0) return disabledDates;
      let firstOfMonthCopy = DateTime.fromISO(
        searchDateForProductionDates.value
      );
      const firstOfMonthDate = DateTime.fromISO(
        searchDateForProductionDates.value
      );

      if (pharmacyAvailableDatesApiResource.getLoading()) {
        while (firstOfMonthCopy.hasSame(firstOfMonthDate, "month")) {
          disabledDates.push(new Date(firstOfMonthCopy.toString()));
          firstOfMonthCopy = firstOfMonthCopy.plus({ day: 1 });
        }
      } else if (
        !pharmacyAvailableDatesApiResource.getLoading() &&
        pharmacyAvailableDatesApiResource.getData()
      ) {
        const dates = pharmacyAvailableDatesApiResource
          .getData()
          .map((date) => getDate(setTZ(date.earliest, timeZoneForCal.value)));

        while (firstOfMonthCopy.hasSame(firstOfMonthDate, "month")) {
          if (dates.includes(firstOfMonthCopy.toFormat(dateFormat))) {
            disabledDates.push(new Date(firstOfMonthCopy.toString()));
          }
          firstOfMonthCopy = firstOfMonthCopy.plus({ day: 1 });
        }
      }
      return disabledDates;
    });

    const formLoading = computed(
      () =>
        createPharmacyAvailableDateApiResource.getLoading() ||
        productsApiResource.getLoading()
    );

    onMounted(() => {
      if (productsApiResource.getData().length === 0)
        productsApiResource.fetchResource();
      searchDateForProductionDates.value = DateTime.local().toFormat(
        dateFormat
      );
    });

    return {
      newPharmacyDate,
      closeForm,
      model,
      formLoading,
      handleSave,
      resetPharmacyDate,
      datesToSubmit,
      pushToSubmitArray,
      removeDateToSubmit,
      productPlaceholder,
      productsApiResource,
      handleDateInput,
      displayEarliestTime,
      displayLatestTime,
      setEarliestTime,
      setLatestTime,
      getProductName,
      formatDate,
      sortedDatesToSubmit,
      disabledDates,
      setSearchDateForProductionDates,
      pharmacyAvailableDatesApiResource,
    };
  },
};
</script>

<style scoped>
.date-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-style: hidden;
}
.date-table-header {
  padding: 10px 8px;
  text-align: left;
}

.date-table-cell {
  padding: 10px 8px;
  text-align: left;
}

.first-column-cell-borders {
  border-bottom: 1px dashed grey;
  border-right: 1px dashed grey;
}

.last-column-cell-borders {
  border-bottom: 1px dashed grey;
}
.dates-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dates-inner-container {
  flex-grow: 1;
}
</style>
