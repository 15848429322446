<template>
  <FormWrapper :loading="formLoading" width="600px">
    <FormCol>
      <label for="product_site" class="title">Site</label>
      <Dropdown
        id="product_site"
        v-model="newStudySiteProductModel.studysite.$model"
        :options="studySites"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        placeholder="Select a site"
        :disabled="isEditing"
        :class="{ 'p-error': newStudySiteProductModel.studysite.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteProductModel.studysite" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="product" class="title">Product</label>
      <Dropdown
        id="product"
        v-model="newStudySiteProductModel.product.$model"
        :options="isEditing ? productsApiResource.getData() : availableProducts"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        :placeholder="productPlaceholder"
        :disabled="isEditing"
        :class="{ 'p-error': newStudySiteProductModel.product.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteProductModel.product" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="order_quantity" class="title">Order Amount</label>
      <InputNumber
        id="order_quantity"
        v-model="newStudySiteProductModel.orderQty.$model"
        mode="decimal"
        :min="1"
        :max="15"
        :min-fraction-digits="2"
        :class="{ 'p-error': newStudySiteProductModel.orderQty.$invalid }"
      />
      <InlineMessage
        v-if="newStudySiteProduct.orderQty"
        severity="info"
        class="p-mt-2"
      >
        {{ `${getMBQ(newStudySiteProduct.orderQty)} MBq` }}
      </InlineMessage>
      <ErrorMessages :model-property="newStudySiteProductModel.orderQty" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="fixed_order_quantity"
          v-model="newStudySiteProduct.orderQtyFixed"
          :binary="true"
          class="p-mb-1"
        />
        <label for="fixed_order_quantity" class="checkbox-text p-ml-2 p-mb-1"
          >Fixed Order Amount</label
        >
      </div>
    </FormCol>
    <Divider />
    <FormCol>
      <label for="product_organization" class="title">Organization</label>
      <Dropdown
        id="product_organization"
        v-model="newStudySiteProductModel.organization.$model"
        :options="allOrgsApiResource.getData()"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        placeholder="Select an organization"
        :class="{ 'p-error': newStudySiteProductModel.organization.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteProductModel.organization" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newStudySiteProductModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import InlineMessage from "primevue/inlinemessage";

import { reactive, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { getMBQ } from "@/helpers/units";

import { useVuelidate } from "@vuelidate/core";
import { required, minValue, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditStudySiteProduct",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    Checkbox,
    InputNumber,
    InlineMessage,
  },
  props: {
    selectedStudy: {
      type: Object,
      default: () => ({}),
    },
    selectedStudySiteProduct: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    studySites: {
      type: Array,
      default: () => [],
    },
    allStudySiteProducts: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newStudySiteProduct = reactive({
      study: props.selectedStudy.minervaId,
      site: "",
      studysite: "",
      product: "",
      organization: "",
      orderQty: 5,
      orderQtyFixed: false,
    });

    const rules = {
      studysite: {
        required: helpers.withMessage("A site is required", required),
      },
      product: {
        required: helpers.withMessage("A product is required", required),
      },
      organization: {
        required: helpers.withMessage("An organization is required", required),
      },
      orderQty: {
        required: helpers.withMessage(
          "An order quantity is required",
          required
        ),
        minValue: helpers.withMessage(
          "Please enter a quantity of at least 1",
          minValue(1)
        ),
      },
    };

    const newStudySiteProductModel = useVuelidate(rules, newStudySiteProduct, {
      $lazy: true,
    });

    const selectedStudySite = computed(() => {
      if (newStudySiteProduct.studysite) {
        return props.studySites.find(
          (studySite) => studySite.minervaId === newStudySiteProduct.studysite
        );
      }
      return {};
    });

    watch(
      () => selectedStudySite.value,
      () => (newStudySiteProduct.site = selectedStudySite.value.site)
    );

    const createStudySiteProduct = () =>
      store.dispatch("studySiteProducts/addStudySiteProduct", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: selectedStudySite.value.minervaId,
        payload: newStudySiteProduct,
      });

    const createStudySiteProductApiResource = new ApiResource(
      undefined,
      createStudySiteProduct,
      "Successfully created new study site product",
      "There was an error creating the new study site product."
    );

    const updateStudySiteProduct = () =>
      store.dispatch("studySiteProducts/updateStudySiteProduct", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: selectedStudySite.value.minervaId,
        payload: newStudySiteProduct,
      });

    const updateStudySiteProductApiResource = new ApiResource(
      undefined,
      updateStudySiteProduct,
      "Successfully updated the study site product.",
      "There was an error updating the study site product."
    );

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const availableProducts = computed(() => {
      if (newStudySiteProduct.studysite) {
        const existingProducts = props.allStudySiteProducts
          .filter(
            (product) => product.studysite === newStudySiteProduct.studysite
          )
          .map((product) => product.product);
        return productsApiResource
          .getData()
          .filter((product) => !existingProducts.includes(product.minervaId));
      }
      return [];
    });

    const productPlaceholder = computed(() =>
      newStudySiteProduct.studysite === ""
        ? "Select a site"
        : productsApiResource.getLoading()
        ? "Loading products"
        : "Select a product"
    );

    const allOrgs = () => store.state.studyOrganizations.allOrgs;
    const fetchAllOrgs = () =>
      store.dispatch("studyOrganizations/fetchAllOrganizations");

    const allOrgsApiResource = new ApiResource(
      allOrgs,
      fetchAllOrgs,
      undefined,
      "There was an error fetching the study organizations."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newStudySiteProductModel.value.$validate()) {
        props.isEditing
          ? await updateStudySiteProductApiResource.fetchResource()
          : await createStudySiteProductApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        productsApiResource.getLoading() ||
        allOrgsApiResource.getLoading() ||
        createStudySiteProductApiResource.getLoading() ||
        updateStudySiteProductApiResource.getLoading()
    );

    onMounted(() => {
      productsApiResource.fetchResource();
      allOrgsApiResource.fetchResource();
      if (props.isEditing) {
        for (const [key, value] of Object.entries(
          props.selectedStudySiteProduct
        )) {
          if ((value && value.length > 0) || value > 0)
            newStudySiteProduct[key] = value;
        }
      }
    });

    return {
      closeForm,
      newStudySiteProduct,
      newStudySiteProductModel,
      availableProducts,
      productPlaceholder,
      allOrgsApiResource,
      formLoading,
      handleSave,
      productsApiResource,
      getMBQ,
    };
  },
};
</script>
