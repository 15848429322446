<template>
  <FormWrapper>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Order ID"
        :data="pharmacyOrder.order_data.order_id"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Product" :data="pharmacyOrder.product_info.name" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Amount"
        :data="getAmountString(pharmacyOrder.order_data.amount)"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Subject"
        :data="pharmacyOrder.order_data.subject_id"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Date"
        :data="getDate(pharmacyOrder.order_data.local_calibration)"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Time"
        :data="getTime(pharmacyOrder.order_data.local_calibration)"
      />
    </FormCol>
    <Divider v-if="pharmacyOrder?.order_data?.study_name ?? false" />
    <FormCol v-if="pharmacyOrder?.order_data?.study_name ?? false">
      <FormDataView
        title="Additional Info"
        :data="pharmacyOrder.order_data.study_name"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView title="Imaging Site" :data="pharmacyOrder.site_info.name" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <FormDataView title="Site Address" :data="displayAddress" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Courier Needed"
        :data="pharmacyOrder.active_pharmacy.courier_needed ? 'Yes' : 'No'"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <FormDataView
        title="Pickup Time"
        :data="
          pharmacyOrder.active_pharmacy.courier_needed
            ? getTime(pharmacyOrder.active_pharmacy.pickup_time)
            : ''
        "
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Site Notes"
        :data="pharmacyOrder.order_data.site_default_notes"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Courier Notes"
        :data="pharmacyOrder.order_data.courier_notes"
      />
    </FormCol>
    <Divider />
  </FormWrapper>
</template>

<script>
import Divider from "primevue/divider";
import FormCol from "@/components/FormComponents/FormCol";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormDataView from "@/components/FormComponents/FormDataView";

import { computed } from "vue";
import { getDate, getTime } from "@/helpers/timeHelpers";
import { getAmountString } from "@/helpers/units";

export default {
  name: "ViewPharmacyOrder",
  components: {
    Divider,
    FormCol,
    FormWrapper,
    FormDataView,
  },
  props: {
    pharmacyOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const displayAddress = computed(() => {
      const site = props.pharmacyOrder.site_info;
      return `${site.address}, ${site.city}, ${site.state} ${site.postal_code}, ${site.country}`;
    });

    return {
      displayAddress,
      getDate,
      getTime,
      getAmountString,
    };
  },
};
</script>
