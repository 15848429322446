<template>
  <FormWrapper :loading="formLoading">
    <FormCol>
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 39px">
        <InputSwitch v-model="deleteParentRole" />
        <label for="delete_parent_role" class="checkbox-text p-ml-3 p-mb-1"
          >Delete parent role: {{ parentRole.display_name }}?</label
        >
      </div>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import InputSwitch from "primevue/inputswitch";

import { computed, inject, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "DeletePermission",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    InputSwitch,
    CancelConfirmButtons,
    Divider,
  },
  props: {
    selectedPermission: {
      type: Object,
      default: () => ({}),
    },
    parentRole: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form", "clear-viewed-role"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const deleteParentRole = ref(false);

    const deleteRolePermission = () =>
      store.dispatch("rolesPermissions/deletePermissionsByRole", {
        roleName: props.parentRole.name,
        payload: props.selectedPermission,
      });

    const deletePermissionForRoleApiResource = new ApiResource(
      undefined,
      deleteRolePermission,
      "Successfully deleted permission.",
      "There was an error deleting the permission."
    );

    const deleteRole = () =>
      store.dispatch("rolesPermissions/deleteRole", props.parentRole);

    const deleteRoleApiResource = new ApiResource(
      undefined,
      deleteRole,
      "Successfully deleted role.",
      "There was an error deleting the role."
    );

    const handleSave = async () => {
      if (deleteParentRole.value) {
        await deleteRoleApiResource.fetchResource();
        emit("clear-viewed-role");
      } else {
        await deletePermissionForRoleApiResource.fetchResource();
      }

      closeForm();
    };

    const formLoading = computed(
      () =>
        deleteRoleApiResource.getLoading() ||
        deletePermissionForRoleApiResource.getLoading()
    );

    const closeForm = () => emit("close-form");

    return {
      closeForm,
      formLoading,
      handleSave,
      deleteParentRole,
    };
  },
};
</script>
