import { useConfirm } from "primevue/useConfirm";
import orderCancellationReasons from "@/helpers/orderCancellationReasons";
import { ref, computed, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf, minLength, helpers } from "@vuelidate/validators";

export const buildOrderCancellationHelpers = (
  store,
  props,
  ApiResource,
  newOrder,
  user,
  closeForm
) => {
  const reasonPermissionsStudyAdmin = ref(false);
  const reasonPermissionsDoseOrderAdmin = ref(false);
  const reasonPermissionsStudySiteUser = ref(false);

  const getReasonPermissionsRequestStudyAdmin = () =>
    store.dispatch("users/hasRole", {
      role: "StudyAdmin",
      email: user.email,
      resource: `/study/${props.studySite.study}/studysite/${props.studySite.minervaId}`,
    });

  const reasonPermissionsApiResourceStudyAdmin = new ApiResource(
    undefined,
    getReasonPermissionsRequestStudyAdmin,
    undefined,
    undefined
  );

  const getReasonPermissionsRequestDoseOrderAdmin = () =>
    store.dispatch("users/hasRole", {
      role: "DoseOrderAdmin",
      email: user.email,
      resource: `/study/${props.studySite.study}/studysite/${props.studySite.minervaId}`,
    });

  const reasonPermissionsApiResourceDoseOrderAdmin = new ApiResource(
    undefined,
    getReasonPermissionsRequestDoseOrderAdmin,
    undefined,
    undefined
  );

  const getReasonPermissionsRequestStudySiteUser = () =>
    store.dispatch("users/hasRole", {
      role: "StudySiteUser",
      email: user.email,
      resource: `/study/${props.studySite.study}/studysite/${props.studySite.minervaId}`,
    });

  const reasonPermissionsApiResourceStudySiteUser = new ApiResource(
    undefined,
    getReasonPermissionsRequestStudySiteUser,
    undefined,
    undefined
  );

  const getReasonPermissions = async () => {
    const responses = await Promise.all([
      reasonPermissionsApiResourceStudyAdmin.fetchResource(),
      reasonPermissionsApiResourceDoseOrderAdmin.fetchResource(),
      reasonPermissionsApiResourceStudySiteUser.fetchResource(),
    ]);
    reasonPermissionsStudyAdmin.value = responses[0];
    reasonPermissionsDoseOrderAdmin.value = responses[1];
    reasonPermissionsStudySiteUser.value = responses[2];
  };

  const cancellationReasons = computed(() => {
    if (
      reasonPermissionsStudyAdmin.value ||
      reasonPermissionsDoseOrderAdmin.value
    ) {
      return orderCancellationReasons["StudyAdmin"];
    }
    if (reasonPermissionsStudySiteUser.value) {
      return orderCancellationReasons["StudySiteUser"];
    }
    return [];
  });

  const cancellationReason = reactive({
    cancellation_reason: "",
    other: "",
  });

  const rules = {
    other: {
      requiredIf: helpers.withMessage(
        "A reason is required",
        requiredIf(() => cancellationReason.cancellation_reason === "Other")
      ),
      minLength: minLength(1),
    },
  };

  const cancellationReasonModel = useVuelidate(rules, cancellationReason, {
    $lazy: true,
  });

  const cancelOrder = () =>
    store.dispatch("orderManagement/cancelStudySiteOrder", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      studySiteOrderId: props.selectedOrder.minervaId,
      payload: {
        study: newOrder.study,
        studysite: newOrder.studysite,
        studysiteorder: props.selectedOrder.minervaId,
        cancellation_reason:
          cancellationReason.cancellation_reason === "Other"
            ? cancellationReason.other
            : cancellationReason.cancellation_reason,
        reservation_id: newOrder.reservation_id,
        // skip_network_api_call: newOrder.skip_network_api_call,
      },
    });

  const cancelOrderApiResource = new ApiResource(
    undefined,
    cancelOrder,
    "Successfully cancelled the order.",
    "There was an error cancelling your order."
  );

  const confirm = useConfirm();

  const confirmCancelOrderHandler = async (event) => {
    if (await cancellationReasonModel.value.$validate()) {
      confirm.require({
        target: event.currentTarget,
        message:
          "Are you sure you wish to cancel this order? This cannot be undone.",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await cancelOrderApiResource.fetchResource();
          closeForm();
        },
        reject: () => {
          cancellationReason.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    }
  };

  return {
    getReasonPermissions,
    confirmCancelOrderHandler,
    cancellationReason,
    cancellationReasons,
    cancelOrderApiResource,
    cancellationReasonModel,
  };
};
