<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="name" class="title">Name</label>
      <InputText
        id="name"
        v-model="model.name.$model"
        :class="{ 'p-error': model.name.$invalid }"
      />
      <ErrorMessages :model-property="model.name" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="address" class="title">Address</label>
      <InputText
        id="address"
        v-model="model.address.$model"
        :class="{ 'p-error': model.address.$invalid }"
      />
      <ErrorMessages :model-property="model.address" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="city" class="title">City</label>
      <InputText
        id="city"
        v-model="model.city.$model"
        :class="{ 'p-error': model.city.$invalid }"
      />
      <ErrorMessages :model-property="model.city" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="state" class="title">State</label>
      <InputText
        id="state"
        v-model="model.state.$model"
        :class="{ 'p-error': model.state.$invalid }"
      />
      <ErrorMessages :model-property="model.state" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="country" class="title">Country</label>
      <InputText
        id="country"
        v-model="model.country.$model"
        :class="{ 'p-error': model.country.$invalid }"
      />
      <ErrorMessages :model-property="model.country" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="postal_code" class="title">Postal Code</label>
      <InputText
        id="postal_code"
        v-model="model.postal_code.$model"
        :class="{ 'p-error': model.postal_code.$invalid }"
      />
      <ErrorMessages :model-property="model.postal_code" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="region" class="title">Region</label>
      <Dropdown
        id="region"
        v-model="model.region.$model"
        :options="regionList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        :class="{ 'p-error': model.region.$invalid }"
      />
      <ErrorMessages :model-property="model.region" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="timezone" class="title">Time Zone</label>
      <AutoComplete
        id="timezone"
        v-model="model.timezone.$model"
        :suggestions="filteredTimeZones"
        :dropdown="true"
        dropdown-mode="current"
        force-selection
        append-to="body"
        :class="{ 'p-error': model.timezone.$invalid }"
        @complete="setTimeZoneSearch($event)"
      />
      <ErrorMessages :model-property="model.timezone" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="ram_license" class="title">Ram License</label>
      <InputText
        id="ram_license"
        v-model="model.ram_license.$model"
        :class="{ 'p-error': model.ram_license.$invalid }"
      />
      <ErrorMessages :model-property="model.ram_license" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="ram_license_expiry" class="title"
        >Expiry Date (YYYY-MM-DD)</label
      >
      <InputText
        id="ram_license_expiry"
        v-model="model.ram_license_expiry.$model"
        :class="{ 'p-error': model.ram_license_expiry.$invalid }"
      />
      <ErrorMessages :model-property="model.ram_license_expiry" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="site_contact_name" class="title">Site Contact Name</label>
      <InputText id="site_contact_name" v-model="newSite.site_contact_name" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="site_contact_number" class="title">Site Contact Number</label>
      <InputText
        id="site_contact_number"
        v-model="newSite.site_contact_number"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="site_contact_email" class="title">Site Contact Email</label>
      <InputText
        id="site_contact_email"
        v-model="model.site_contact_email.$model"
        :class="{ 'p-error': model.site_contact_email.$invalid }"
      />
      <ErrorMessages :model-property="model.site_contact_email" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";

import { ref, reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { rawTimeZones } from "@vvo/tzdb";
import { DateTime } from "luxon";
import { regionList, validRegion } from "@/helpers/regionList";

export default {
  name: "AddEditSite",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    AutoComplete,
  },
  props: {
    selectedSite: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newSite = reactive({
      name: "",
      address: "",
      city: "",
      postal_code: "",
      state: "",
      country: "",
      region: "",
      timezone: "",
      ram_license: "",
      ram_license_expiry: "",
      site_contact_name: "",
      site_contact_number: "",
      site_contact_email: "",
    });

    const validDate = (input) => /^\d{4}-\d{2}-\d{2}$/.test(input);

    const todayDate = DateTime.local();
    const isAfterToday = (input) =>
      DateTime.fromISO(input).isValid && DateTime.fromISO(input) > todayDate;

    const rules = {
      name: {
        required: helpers.withMessage("A site name is required", required),
      },
      address: {
        required: helpers.withMessage("A site address is required", required),
      },
      city: {
        required: helpers.withMessage("A site city is required", required),
      },
      postal_code: {
        required: helpers.withMessage(
          "A site postal code is required",
          required
        ),
      },
      state: {
        required: helpers.withMessage("A site state is required", required),
      },
      country: {
        required: helpers.withMessage("A site country is required", required),
      },
      region: {
        required: helpers.withMessage("A site region is required", required),
        validRegion: helpers.withMessage("Select a valid region", validRegion),
      },
      timezone: {
        required: helpers.withMessage("A site timezone is required", required),
      },
      ram_license: {
        required: helpers.withMessage(
          "A site Ram license is required",
          required
        ),
      },
      ram_license_expiry: {
        required: helpers.withMessage(
          "A site Ram license expiry is required",
          required
        ),
        validDate: helpers.withMessage(
          "The date format is incorrect",
          validDate
        ),
        isAfterToday: helpers.withMessage(
          "The date must be after today",
          isAfterToday
        ),
      },
      site_contact_email: {
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };

    const model = useVuelidate(rules, newSite, { $lazy: true });

    const timeZonesByName = rawTimeZones.map((zone) => zone.name).sort();
    const filteredTimeZones = ref([]);

    const setTimeZoneSearch = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTimeZones.value = [...timeZonesByName];
        } else {
          filteredTimeZones.value = timeZonesByName.filter((timezone) =>
            timezone.toLowerCase().includes(event.query.toLowerCase())
          );
        }
      }, 250);
    };

    const createSite = () =>
      store.dispatch("studyManagement/createSite", newSite);

    const createSiteApiResource = new ApiResource(
      undefined,
      createSite,
      "Successfully created new site.",
      "There was an error creating the new site."
    );

    const updateSite = () =>
      store.dispatch("studyManagement/updateSite", newSite);

    const updateSiteApiResource = new ApiResource(
      undefined,
      updateSite,
      "Successfully updated the site.",
      "There was an error updating the site."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await model.value.$validate()) {
        props.isEditing
          ? await updateSiteApiResource.fetchResource()
          : await createSiteApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createSiteApiResource.getLoading() || updateSiteApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedSite)) {
          if (value && value.length > 0) newSite[key] = value;
        }
      }
    });

    return {
      newSite,
      closeForm,
      model,
      formLoading,
      handleSave,
      regionList,
      filteredTimeZones,
      setTimeZoneSearch,
    };
  },
};
</script>
