<template>
  <div class="p-d-flex">
    <div class="p-col-9">
      <StudySites
        :selected-study="selectedStudy"
        :all-sites-api-resource="allSitesApiResource"
        :study-sites-api-resource="studySitesApiResource"
        :parent-loading="loading"
        :study-site-names="studySiteNames"
        @onSearch="onSearch"
      />
      <div class="p-mt-6">
        <StudySiteProducts
          :selected-study="selectedStudy"
          :study-sites-api-resource="studySitesApiResource"
          :parent-loading="loading"
          :study-site-names="studySiteNames"
          :all-study-site-products="allStudySiteProducts"
          :search-value="searchValue"
        />
      </div>
      <div class="p-mt-6">
        <StudySiteProductNetworks
          :selected-study="selectedStudy"
          :parent-loading="loading"
          :study-sites-api-resource="studySitesApiResource"
          :all-study-site-product-network-prices="
            allStudySiteProductNetworkPrices
          "
          :all-study-site-product-network-freight="
            allStudySiteProductNetworkFreight
          "
          :products-api-resource="productsApiResource"
          :study-site-names="studySiteNames"
          :all-study-site-product-networks="allStudySiteProductNetworks"
          :all-study-site-products="allStudySiteProducts"
          :search-value="searchValue"
        />
      </div>
    </div>
    <div class="p-col-3">
      <StudyOrganizations :selected-study="selectedStudy" />
      <div class="p-mt-6">
        <StudyAdmins :selected-study="selectedStudy" />
      </div>
      <div class="p-mt-6">
        <StudyOrderingAdmins :selected-study="selectedStudy" />
      </div>
      <div class="p-mt-6">
        <StudyEmailReceivers :selected-study="selectedStudy" />
      </div>
    </div>
  </div>
</template>

<script>
import StudySites from "@/components/Studies/StudySites";
import StudyOrderingAdmins from "@/components/Studies/StudyOrderingAdmins";
import StudyAdmins from "@/components/Studies/StudyAdmins";
import StudyEmailReceivers from "@/components/Studies/StudyEmailReceivers";
import StudyOrganizations from "@/components/Studies/StudyOrganizations";
import StudySiteProducts from "@/components/Studies/StudySiteProducts";
import StudySiteProductNetworks from "@/components/Studies/StudySiteProductNetworks";

import { computed, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "StudyDetailsWrapper",
  components: {
    StudySites,
    StudyOrderingAdmins,
    StudyAdmins,
    StudyEmailReceivers,
    StudyOrganizations,
    StudySiteProducts,
    StudySiteProductNetworks,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const studySites = () =>
      store.state.studySites.studySites[props.selectedStudy.minervaId];
    const fetchStudySites = () =>
      store.dispatch("studySites/fetchStudySites", {
        studyId: props.selectedStudy.minervaId,
      });
    const studySitesApiResource = new ApiResource(
      studySites,
      fetchStudySites,
      undefined,
      "There was an error fetching your study sites."
    );

    const allSites = () => store.state.studyManagement.allSites;
    const fetchAllSites = () => store.dispatch("studyManagement/fetchAllSites");
    const allSitesApiResource = new ApiResource(
      allSites,
      fetchAllSites,
      undefined,
      "There was an error fetching your sites."
    );

    const studySiteNames = computed(() => {
      if (
        allSitesApiResource.getData() &&
        allSitesApiResource.getData().length > 0 &&
        studySitesApiResource.getData() &&
        studySitesApiResource.getData().length > 0
      ) {
        return studySitesApiResource.getData().reduce((res, studySite) => {
          const thisSite = allSitesApiResource
            .getData()
            .find((site) => site.minervaId === studySite.site);
          res[studySite.minervaId] =
            studySite.idCode.length > 0
              ? `${thisSite.name} - ${studySite.idCode}`
              : thisSite.name;

          return res;
        }, {});
      }
    });

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const studySiteProducts = ({ studyId, studySiteId }) =>
      store.state.studySiteProducts.studySiteProducts[
        `${studyId}${studySiteId}`
      ];
    const fetchStudySiteProducts = (studySiteId) =>
      store.dispatch("studySiteProducts/fetchStudySiteProducts", {
        studyId: props.selectedStudy.minervaId,
        studySiteId,
      });
    const studySiteProductsApiResource = new ApiResource(
      studySiteProducts,
      fetchStudySiteProducts,
      undefined,
      "There was an error fetching your products."
    );

    const allStudySiteProducts = computed(() => {
      const products = [];
      if (
        studySitesApiResource.getData() &&
        studySitesApiResource.getData().length > 0
      ) {
        const studySitesIds = studySitesApiResource
          .getData()
          .map((studySite) => studySite.minervaId);

        studySitesIds.forEach((id) => {
          for (const studySiteProducts in studySiteProductsApiResource.getData({
            studyId: props.selectedStudy.minervaId,
            studySiteId: id,
          })) {
            const thisProduct = studySiteProductsApiResource.getData({
              studyId: props.selectedStudy.minervaId,
              studySiteId: id,
            })[studySiteProducts];
            products.push(thisProduct);
          }
        });
      }
      return products;
    });

    const studySiteProductNetworks = ({ studyId, studySiteId, productId }) =>
      store.state.studySiteProducts.studySiteProductNetworks[
        `${studyId}${studySiteId}${productId}`
      ];
    const fetchProductNetworks = ({ studySiteId, productId }) =>
      store.dispatch("studySiteProducts/fetchStudySiteProductNetworks", {
        studyId: props.selectedStudy.minervaId,
        studySiteId,
        productId,
      });

    const studySiteProductNetworksApiResource = new ApiResource(
      studySiteProductNetworks,
      fetchProductNetworks,
      undefined,
      "There was an error fetching your product networks."
    );

    const allStudySiteProductNetworks = computed(() => {
      const networks = [];
      if (
        studySitesApiResource.getData() &&
        studySitesApiResource.getData().length > 0
      ) {
        const studySitesIds = studySitesApiResource
          .getData()
          .map((studySite) => studySite.minervaId);

        studySitesIds.forEach((id) => {
          for (const studySiteProducts in studySiteProductsApiResource.getData({
            studyId: props.selectedStudy.minervaId,
            studySiteId: id,
          })) {
            const thisProduct = studySiteProductsApiResource.getData({
              studyId: props.selectedStudy.minervaId,
              studySiteId: id,
            })[studySiteProducts];
            for (const studySiteProductNetworks in studySiteProductNetworksApiResource.getData(
              {
                studyId: props.selectedStudy.minervaId,
                studySiteId: id,
                productId: thisProduct.minervaId,
              }
            )) {
              const thisProductNetwork = studySiteProductNetworksApiResource.getData(
                {
                  studyId: props.selectedStudy.minervaId,
                  studySiteId: id,
                  productId: thisProduct.minervaId,
                }
              )[studySiteProductNetworks];
              networks.push(thisProductNetwork);
            }
          }
        });
      }
      return networks;
    });

    const studySiteProductNetworkPrices = () =>
      store.state.studySiteProducts.studySiteProductNetworkPrices;
    const fetchProductNetworkPrices = ({ studySiteId, productId, networkId }) =>
      store.dispatch("studySiteProducts/fetchStudySiteProductNetworkPrice", {
        studyId: props.selectedStudy.minervaId,
        studySiteId,
        productId,
        networkId,
      });

    const allStudySiteProductNetworkPrices = computed(() => {
      const prices = [];
      for (const studySiteProductNetworkPrices in studySiteProductNetworkPricesApiResource.getData()) {
        prices.push(
          ...studySiteProductNetworkPricesApiResource.getData()[
            studySiteProductNetworkPrices
          ]
        );
      }
      return prices;
    });

    const studySiteProductNetworkPricesApiResource = new ApiResource(
      studySiteProductNetworkPrices,
      fetchProductNetworkPrices,
      undefined,
      "There was an error fetching your product network prices."
    );

    const studySiteProductNetworkFreight = () =>
      store.state.studySiteProducts.studySiteProductNetworkFreight;
    const fetchProductNetworkFreight = ({
      studySiteId,
      productId,
      networkId,
    }) =>
      store.dispatch("studySiteProducts/fetchStudySiteProductNetworkFreight", {
        studyId: props.selectedStudy.minervaId,
        studySiteId,
        productId,
        networkId,
      });

    const studySiteProductNetworkFreightApiResource = new ApiResource(
      studySiteProductNetworkFreight,
      fetchProductNetworkFreight,
      undefined,
      "There was an error fetching your product network freight."
    );

    const allStudySiteProductNetworkFreight = computed(() => {
      const freight = [];
      for (const studySiteProductNetworkFreight in studySiteProductNetworkFreightApiResource.getData()) {
        freight.push(
          ...studySiteProductNetworkFreightApiResource.getData()[
            studySiteProductNetworkFreight
          ]
        );
      }
      return freight;
    });

    const searchValue = ref("");
    const onSearch = (value) => (searchValue.value = value);

    onMounted(async () => {
      const promises = [await studySitesApiResource.fetchResource()];

      if (!productsApiResource.getData().length > 0)
        promises.push(await productsApiResource.fetchResource());

      if (!allSitesApiResource.getData().length > 0)
        promises.push(await allSitesApiResource.fetchResource());

      await Promise.all(promises);

      let productPromises = [];
      if (studySitesApiResource.getData()) {
        productPromises = studySitesApiResource
          .getData()
          .map(async (studySite) => {
            await studySiteProductsApiResource.fetchResource(
              studySite.minervaId
            );
          });
      }

      await Promise.all(productPromises);

      const productNetworkPromises = allStudySiteProducts.value.map(
        async (product) => {
          await studySiteProductNetworksApiResource.fetchResource({
            studySiteId: product.studysite,
            productId: product.minervaId,
          });
        }
      );

      await Promise.all(productNetworkPromises);

      const productNetworkPricePromises = allStudySiteProductNetworks.value.map(
        async (network) => {
          await studySiteProductNetworkPricesApiResource.fetchResource({
            studySiteId: network.studysite,
            productId: network.studysiteproduct,
            networkId: network.minervaId,
          });
        }
      );

      await Promise.all(productNetworkPricePromises);

      const productNetworkFreightPromises = allStudySiteProductNetworks.value.map(
        async (network) => {
          await studySiteProductNetworkFreightApiResource.fetchResource({
            studySiteId: network.studysite,
            productId: network.studysiteproduct,
            networkId: network.minervaId,
          });
        }
      );

      await Promise.all(productNetworkFreightPromises);
    });

    const loading = computed(
      () =>
        productsApiResource.getLoading() ||
        studySitesApiResource.getLoading() ||
        allSitesApiResource.getLoading() ||
        studySiteProductsApiResource.getLoading() ||
        studySiteProductNetworksApiResource.getLoading() ||
        studySiteProductNetworkPricesApiResource.getLoading() ||
        studySiteProductNetworkFreightApiResource.getLoading()
    );

    return {
      loading,
      allSitesApiResource,
      studySitesApiResource,
      studySiteNames,
      allStudySiteProducts,
      productsApiResource,
      allStudySiteProductNetworks,
      allStudySiteProductNetworkPrices,
      allStudySiteProductNetworkFreight,
      onSearch,
      searchValue,
    };
  },
};
</script>
