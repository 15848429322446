<template>
  <FormWrapper>
    <FormCol col-width="p-md-4">
      <FormDataView title="Subject" :data="orderRequest.studysitesubjectid" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Product"
        :data="orderRequest.studysiteproduct_name"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Amount"
        :data="getAmountString(orderRequest.amount)"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Network"
        :data="orderRequest.studysiteproductnetwork_name"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Date" :data="orderRequest.order_date" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Time"
        :data="displayTimeString(orderRequest.order_time)"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView title="Order Status" :data="orderRequest.status" />
    </FormCol>
    <Divider />
    <FormCol>
      <FormDataView title="Order Notes" :data="orderRequest.notes" />
    </FormCol>
  </FormWrapper>
</template>

<script>
import Divider from "primevue/divider";
import FormCol from "@/components/FormComponents/FormCol";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormDataView from "@/components/FormComponents/FormDataView";
import { getAmountString } from "@/helpers/units";
import { displayTimeString } from "@/helpers/timeHelpers";

export default {
  name: "ViewOrder",
  components: {
    Divider,
    FormCol,
    FormWrapper,
    FormDataView,
  },
  props: {
    orderRequest: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      getAmountString,
      displayTimeString,
    };
  },
};
</script>
