<template>
  <div class="p-d-flex p-flex-row p-mb-4" style="width: 100%">
    <div class="p-component study-details-container" style="width: 100%">
      <FormWrapper width="100%">
        <FormCol col-width="p-md-6">
          <label class="title">Selected Site</label>
          <Dropdown
            v-model="selectedSite"
            :options="permittedSitesApiResource.getData()"
            option-label="display_name"
            :placeholder="permittedSitesPlaceholder"
            :class="{
              'p-invalid':
                permittedSitesApiResource.getData().length > 0 &&
                Object.keys(selectedSite).length === 0,
            }"
            append-to="body"
            :filter="true"
          />
        </FormCol>
        <FormCol v-if="adminUser" col-width="p-md-6">
          <div
            class="p-d-flex p-ai-end p-pb-1"
            style="height: 34px; margin-top: 26px"
          >
            <InputSwitch v-model="showInactivePermitted" />
            <label class="checkbox-text p-ml-3 p-mb-1">Show Inactive</label>
          </div>
        </FormCol>
        <FormCol col-width="p-md-3">
          <FormDataView
            title="Physician"
            :data="
              studySiteApiResource.getData() &&
              studySiteApiResource.getData().investigator
            "
          />
        </FormCol>
        <FormCol col-width="p-md-3">
          <FormDataView
            title="Phone"
            :data="
              studySiteApiResource.getData() &&
              studySiteApiResource.getData().site_contact_number
            "
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-3">
          <FormDataView
            title="Name"
            :data="
              studySiteApiResource.getData() &&
              studySiteApiResource.getData().site_name
            "
          />
        </FormCol>
        <FormCol col-width="p-md-3">
          <FormDataView
            title="Address"
            :data="
              studySiteApiResource.getData() &&
              studySiteApiResource.getData().site_full_address
            "
          />
        </FormCol>
        <FormCol col-width="p-md-3">
          <FormDataView
            title="Delivery"
            :data="
              studySiteApiResource.getData() &&
              studySiteApiResource.getData().delivery_info
            "
          />
        </FormCol>
      </FormWrapper>
    </div>
  </div>
  <div
    v-if="
      Object.keys(selectedSite).length > 0 &&
      selectedSite.status_message.message
    "
    class="p-my-4"
  >
    <div
      aria-live="polite"
      :class="`p-inline-message p-component p-inline-message-${selectedSite.status_message.type}`"
    >
      <span class="p-inline-message-text" style="font-size: 18px">{{
        selectedSite.status_message.message
      }}</span>
    </div>
  </div>
  <div
    v-if="
      Object.keys(selectedSite).length > 0 &&
      selectedSite.status_message.secondary_message
    "
    class="p-my-4"
  >
    <div
      aria-live="polite"
      :class="`p-inline-message p-component p-inline-message-${selectedSite.status_message.type}`"
    >
      <span class="p-inline-message-text" style="font-size: 18px">{{
        selectedSite.status_message.secondary_message
      }}</span>
    </div>
  </div>
  <AppDataTable
    :selected-row-data="selectedOrder"
    title="Order Management"
    add-button="Order"
    :enable-add-button="Object.keys(selectedSite).length > 0"
    :table-data="filteredOrderData"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    :order-table="true"
    :search-properties="[
      'studysitesubjectid',
      'studysiteproduct_name',
      'studysiteproductnetwork_name',
      'pharmacy',
      'status',
      'network_order_id',
      'date',
      'time',
    ]"
    @update:selected-row-data="setSelectedOrderId"
    @last-page="fetchNextSetOfOrders"
  >
    <Column header="Order ID">
      <template #body="{ data }">
        {{ data.order_id }}
        <i
          v-if="data.active_sparrow_link && data.active_sparrow_link.length > 0"
          v-tooltip.top="
            'Possible Modification Suggested - Click the link here to see'
          "
          class="pi pi-exclamation-triangle icon-button"
          @click="viewSparrowDoc(data)"
        />
      </template>
    </Column>
    <Column header="Subject">
      <template #body="{ data }">
        {{ data.studysitesubjectid }}
      </template>
    </Column>
    <Column header="Product">
      <template #body="{ data }">
        {{ data.studysiteproduct_name }}
      </template>
    </Column>
    <Column header="Date">
      <template #body="{ data }">
        {{ data.minervaId ? getDate(data.local_calibration) : "" }}
      </template>
    </Column>
    <Column header="Amount">
      <template #body="{ data }">
        {{ data.minervaId ? getAmountString(data.amount) : "" }}
      </template>
    </Column>
    <Column header="Time">
      <template #body="{ data }">
        {{ data.minervaId ? getTime(data.local_calibration) : "" }}
      </template>
    </Column>
    <Column header="Network">
      <template #body="{ data }">
        {{ data.studysiteproductnetwork_name }}
      </template>
    </Column>
    <Column header="Pharmacy">
      <template #body="{ data }">
        {{ data.pharmacy }}
      </template>
    </Column>
    <Column header="Status">
      <template #body="{ data }">
        <span
          v-if="data.status"
          class="p-tag"
          :class="getStatusColor(data)"
          style="font-size: 1em"
          >{{ data.status }}</span
        >
      </template>
    </Column>
    <Column header="Reservation #">
      <template #body="{ data }">
        {{ data.network_order_id }}
      </template>
    </Column>
    <Column v-if="adminUser" header="Order Sync">
      <template #body="{ data }">
        <Button
          v-if="data.minervaId"
          label="Sync"
          class="p-button-xs"
          :disabled="data.status && data.status === 'Cancelled'"
          @click="syncRemote(data)"
        />
      </template>
    </Column>
    <Column v-if="adminUser" header="Billing/Dose Price">
      <template #body="{ data }">
        <Button
          v-if="data.minervaId"
          :label="billingReportLabel(data)"
          class="p-button-xs"
          :disabled="!getBillingReport(data)"
          @click="openBillingReport(data)"
        />
      </template>
    </Column>
    <!-- <template #headerContent>
      <Dropdown
        v-model="selectedSite"
        :options="permittedSitesApiResource.getData()"
        option-label="display_name"
        :placeholder="permittedSitesPlaceholder"
        :class="{
          'p-invalid':
            permittedSitesApiResource.getData().length > 0 &&
            Object.keys(selectedSite).length === 0,
        }"
        append-to="body"
        :filter="true"
      />
    </template> -->
    <template #headerContent>
      <div class="p-d-flex p-flex-row p-ai-center">
        <span class="p-mr-2">Time Frame:</span>
        <Dropdown
          v-model="selectedViewOption"
          :options="viewOptions"
          append-to="body"
        />
      </div>
    </template>
    <template #addModalContent="{ closeAddModal }">
      <AddEditOrder :study-site="selectedSite" @close-form="closeAddModal" />
    </template>
    <template #confirmation_id="{ data }">
      <span
        v-if="data.length > 0"
        class="p-tag p-tag-success"
        style="font-size: 1em"
        >Confirmed</span
      >
      <span v-else class="p-tag p-tag-warning" style="font-size: 1em"
        >Not Confirmed</span
      >
    </template>
    <template #cancelled="{ data }">
      <span v-if="data" class="p-tag p-tag-danger" style="font-size: 1em"
        >Cancelled</span
      >
      <span v-else class="p-tag p-tag-info" style="font-size: 1em">Active</span>
    </template>
  </AppDataTable>
  <div class="p-mt-4">
    <AppDataTable
      :selected-row-data="selectedOrderRequest"
      title="Order Requests"
      :enable-add-button="false"
      :table-data="orderRequestsApiResource.getData()"
      :loading="tableLoading"
      :context-menu-options="contextMenuOptionsOrderRequests"
      :show-add-button="false"
      @update:selected-row-data="setSelectedOrderRequestId"
    >
      <Column header="Subject">
        <template #body="{ data }">
          {{ data.studysitesubjectid }}
        </template>
      </Column>
      <Column header="Product">
        <template #body="{ data }">
          {{ data.studysiteproduct_name }}
        </template>
      </Column>
      <Column header="Amount">
        <template #body="{ data }">
          {{ data.amount ? getAmountString(data.amount) : "" }}
        </template>
      </Column>
      <Column header="Date">
        <template #body="{ data }">
          {{ data.order_date }}
        </template>
      </Column>
      <Column header="Time">
        <template #body="{ data }">
          {{ data.order_time ? displayTimeString(data.order_time) : "" }}
        </template>
      </Column>
      <Column header="Network">
        <template #body="{ data }">
          {{ data.studysiteproductnetwork_name }}
        </template>
      </Column>
      <Column header="Status">
        <template #body="{ data }">
          <span
            v-if="data.status"
            class="p-tag"
            :class="getOrderRequestStatusColor(data)"
            style="font-size: 1em"
            >{{ data.status }}</span
          >
        </template>
      </Column>
    </AppDataTable>
  </div>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Order - ${selectedOrder && selectedOrder.order_id}`"
  >
    <AddEditOrder
      :study-site="selectedSite"
      :selected-order="selectedOrder"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openDetailModal"
    :modal="true"
    header="Order Details"
    :dismissable-mask="true"
  >
    <ViewOrder
      :order="selectedOrder"
      :study-site="studySiteApiResource.getData()"
    />
  </Dialog>
  <Dialog
    v-model:visible="openEditOrderRequestModal"
    :modal="true"
    :header="`Edit Order Request`"
  >
    <EditOrderRequest
      :study-site="selectedSite"
      :selected-order-request="selectedOrderRequest"
      :is-editing="true"
      @close-form="openEditOrderRequestModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openConvertOrderRequestModal"
    :modal="true"
    :header="`Edit Order Request`"
  >
    <ConvertOrderRequest
      :study-site="selectedSite"
      :selected-order-request="selectedOrderRequest"
      @close-form="openConvertOrderRequestModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openOrderRequestDetailModal"
    :modal="true"
    header="Order Request Details"
    :dismissable-mask="true"
  >
    <ViewOrderRequest :order-request="selectedOrderRequest" />
  </Dialog>
  <Dialog
    v-model:visible="openEditPostOrderDateSubjectModal"
    :modal="true"
    :header="`Edit Order - ${selectedOrder && selectedOrder.order_id}`"
    :dismissable-mask="true"
  >
    <EditPostOrderDate
      :selected-order="selectedOrder"
      :available-study-sites="permittedSitesApiResource.getData()"
      :is-subject="true"
      @close-form="openEditPostOrderDateSubjectModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openEditPostOrderDateCancellationModal"
    :modal="true"
    :header="`Edit Order - ${selectedOrder && selectedOrder.order_id}`"
    :dismissable-mask="true"
  >
    <EditPostOrderDate
      :selected-order="selectedOrder"
      :available-study-sites="permittedSitesApiResource.getData()"
      :is-cancellation="true"
      @close-form="openEditPostOrderDateCancellationModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openViewEditBillingReport"
    :modal="true"
    :header="`Billing Report - ${selectedOrder && selectedOrder.order_id}`"
    :dismissable-mask="true"
  >
    <ViewEditBillingReport
      :selected-order="selectedOrder"
      :available-study-sites="permittedSitesApiResource.getData()"
      :is-cancellation="true"
      @close-form="openViewEditBillingReport = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import ViewOrder from "@/components/Orders/ViewOrder.vue";
import AddEditOrder from "@/components/Orders/AddEditOrder.vue";
import EditPostOrderDate from "@/components/Orders/EditPostOrderDate.vue";
import ViewOrderRequest from "@/components/Orders/ViewOrderRequest.vue";
import EditOrderRequest from "@/components/Orders/EditOrderRequest.vue";
import ConvertOrderRequest from "@/components/Orders/ConvertOrderRequest.vue";
import ViewEditBillingReport from "@/components/Orders/ViewEditBillingReport.vue";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import FormCol from "@/components/FormComponents/FormCol";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormDataView from "@/components/FormComponents/FormDataView";
import { computed, ref, watch, inject, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { DateTime } from "luxon";
import { getUserRole } from "@/helpers/getUserRole";
import { getDate, getTime, displayTimeString } from "@/helpers/timeHelpers";
import { getAmountString } from "@/helpers/units";

export default {
  name: "OrderManagement",
  components: {
    AppDataTable,
    Dropdown,
    Dialog,
    Column,
    ViewOrder,
    AddEditOrder,
    ViewOrderRequest,
    EditOrderRequest,
    ConvertOrderRequest,
    EditPostOrderDate,
    ViewEditBillingReport,
    Divider,
    FormCol,
    FormWrapper,
    FormDataView,
    InputSwitch,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const auth = inject("Auth");
    const user = auth?.user?.value ?? undefined;
    const router = useRouter();

    const adminUser = computed(() => {
      if (user) {
        const orderRoles = getUserRole(user, "dose_order");
        if (orderRoles) {
          return orderRoles.some(
            (role) =>
              role.role === "SuperAdmin" || role.role === "StudyDoseOrderAdmin"
          );
        }
        return false;
      }
      return false;
    });

    // table setup
    const contextMenuOptions = [
      {
        label: "View Details",
        command: () => {
          openDetailModal.value = true;
        },
      },
      {
        label: "Modify/Cancel",
        visible: () => {
          const isBeforeToday =
            DateTime.fromISO(selectedOrder.value.local_calibration, {
              setZone: true,
            }) < DateTime.local();
          return !selectedOrder.value.cancelled && !isBeforeToday;
        },
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Update Subject",
        visible: () => {
          const isBeforeToday =
            DateTime.fromISO(selectedOrder.value.local_calibration, {
              setZone: true,
            }) < DateTime.local();
          return isAdmin.value && isBeforeToday;
        },
        command: () => {
          openEditPostOrderDateSubjectModal.value = true;
        },
      },
      {
        label: "Update Cancellation",
        visible: () => {
          const isBeforeToday =
            DateTime.fromISO(selectedOrder.value.local_calibration, {
              setZone: true,
            }) < DateTime.local();
          return isAdmin.value && isBeforeToday;
        },
        command: () => {
          openEditPostOrderDateCancellationModal.value = true;
        },
      },
    ];

    const showInactivePermitted = ref(false);

    const permittedSites = () => store.state.orderManagement.permittedSites;
    const fetchPermittedSites = () =>
      store.dispatch("orderManagement/fetchPermittedSites", {
        showInactive: showInactivePermitted.value,
      });
    const permittedSitesApiResource = new ApiResource(
      permittedSites,
      fetchPermittedSites,
      undefined,
      "There was an error fetching your sites."
    );
    const permittedSitesPlaceholder = computed(() =>
      permittedSitesApiResource.getLoading() ? "Loading sites" : "Select a site"
    );

    watch(
      () => showInactivePermitted.value,
      () => permittedSitesApiResource.fetchResource()
    );

    const selectedSite = ref({});
    const siteOrdersOffset = ref(0);

    const siteOrders = () =>
      store.state.orderManagement.studySiteOrders[
        selectedSite.value.minervaId
      ] || [];
    const fetchStudySiteOrders = () =>
      store.dispatch("orderManagement/fetchStudySiteOrders", {
        studyId: selectedSite.value.study,
        studySiteId: selectedSite.value.minervaId,
        offset: siteOrdersOffset.value,
        shouldFetchBilling: adminUser.value,
      });
    const siteOrdersApiResource = new ApiResource(
      siteOrders,
      fetchStudySiteOrders,
      undefined,
      "There was an error fetching your site orders."
    );
    const fetchNextSetOfOrders = async () => {
      siteOrdersOffset.value += 20;
      await siteOrdersApiResource.fetchResource();
    };

    const syncRemoteOrder = (order) =>
      store.dispatch("orderManagement/syncStudySiteOrder", {
        studyId: selectedSite.value.study,
        studySiteId: selectedSite.value.minervaId,
        order,
      });

    const syncRemoteOrderApiResource = new ApiResource(
      undefined,
      syncRemoteOrder,
      "Successfully synced order",
      "There was an error syncing the order."
    );

    const syncRemote = (order) => {
      syncRemoteOrderApiResource.fetchResource(order);
    };

    const studySite = () =>
      store.state.orderManagement.studySites[selectedSite.value.minervaId];
    const fetchStudySite = () =>
      store.dispatch("orderManagement/fetchStudySite", {
        studyId: selectedSite.value.study,
        studySiteId: selectedSite.value.minervaId,
      });
    const studySiteApiResource = new ApiResource(
      studySite,
      fetchStudySite,
      undefined,
      "There was an error fetching your study sites."
    );

    const fetchTableData = () => {
      Promise.all([
        studySiteApiResource.fetchResource(),
        siteOrdersApiResource.fetchResource(),
        orderRequestsApiResource.fetchResource(),
      ]);
    };

    const reFetchTableData = () => {
      Promise.all([
        store.dispatch("orderManagement/fetchAllStudySiteOrders", {
          studyId: selectedSite.value.study,
          studySiteId: selectedSite.value.minervaId,
          length: siteOrdersApiResource.getData().length,
          shouldFetchBilling: adminUser.value,
        }),
        studySiteApiResource.fetchResource(),
        orderRequestsApiResource.fetchResource(),
      ]);
    };

    watch(selectedSite, () => {
      if (
        !studySiteApiResource.getData() ||
        !siteOrdersApiResource.getData().length > 0 ||
        !orderRequestsApiResource.getData().length > 0
      ) {
        if (
          siteOrdersApiResource.getData() &&
          siteOrdersApiResource.getData().length > 0
        ) {
          reFetchTableData();
        } else {
          fetchTableData();
        }
      }
    });

    const selectedOrderId = ref("");
    const selectedOrder = computed(() => {
      if (selectedSite.value.minervaId && selectedOrderId.value.length > 0) {
        return store.state.orderManagement.studySiteOrders[
          selectedSite.value.minervaId
        ].find((order) => order.minervaId === selectedOrderId.value);
      }
      return {};
    });

    watch(selectedSite, () => (selectedOrderId.value = ""));

    const setSelectedOrderId = (order) => {
      selectedOrderId.value = order.minervaId;
    };

    const getStatusColor = (data) => {
      const colors = {
        Reserved: "p-tag-success",
        Cancelled: "p-tag-danger",
      };

      return colors[data.status] || "p-tag-warning";
    };

    const openEditModal = ref(false);
    const openDetailModal = ref(false);
    const openEditPostOrderDateSubjectModal = ref(false);
    const openEditPostOrderDateCancellationModal = ref(false);
    const openViewEditBillingReport = ref(false);

    const orderRequests = () =>
      store.state.orderRequests.orderRequests[selectedSite.value.minervaId];
    const fetchOrderRequests = () =>
      store.dispatch("orderRequests/fetchOrderRequests", {
        studyId: selectedSite.value.study,
        studySiteId: selectedSite.value.minervaId,
      });
    const orderRequestsApiResource = new ApiResource(
      orderRequests,
      fetchOrderRequests,
      undefined,
      "There was an error fetching your order requests."
    );

    const selectedOrderRequestId = ref("");
    const selectedOrderRequest = computed(() => {
      if (
        selectedSite.value.minervaId &&
        selectedOrderRequestId.value.length > 0
      ) {
        return store.state.orderRequests.orderRequests[
          selectedSite.value.minervaId
        ].find((order) => order.minervaId === selectedOrderRequestId.value);
      }
      return {};
    });

    const setSelectedOrderRequestId = (orderRequest) => {
      selectedOrderRequestId.value = orderRequest.minervaId;
    };

    const openOrderRequestDetailModal = ref(false);
    const openEditOrderRequestModal = ref(false);
    const openConvertOrderRequestModal = ref(false);

    const contextMenuOptionsOrderRequests = [
      {
        label: "Create Order",
        command: () => {
          openConvertOrderRequestModal.value = true;
        },
        visible: () => {
          return selectedOrderRequest?.value?.status == "Pending";
        },
      },
      {
        label: "View Details",
        command: () => {
          openOrderRequestDetailModal.value = true;
        },
      },
      {
        label: "Modify",
        command: () => {
          openEditOrderRequestModal.value = true;
        },
      },
    ];

    const getOrderRequestStatusColor = (data) => {
      const colors = {
        Completed: "p-tag-success",
        Cancelled: "p-tag-danger",
      };

      return colors[data.status] || "p-tag-warning";
    };

    const viewSparrowDoc = (order) => {
      const linkList = order.active_sparrow_link.split("/");
      const link = `/sparrow_dt_confirm/${linkList[linkList.length - 1]}`;
      router.push(link);
    };

    const reasonPermissionsStudyAdmin = ref(false);
    const reasonPermissionsDoseOrderAdmin = ref(false);

    const getReasonPermissionsRequestStudyAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "StudyAdmin",
        email: user.email,
        resource: `/study/${selectedSite.value.study}/studysite/${selectedSite.value.minervaId}`,
      });

    const reasonPermissionsApiResourceStudyAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestStudyAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestDoseOrderAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "DoseOrderAdmin",
        email: user.email,
        resource: `/study/${selectedSite.value.study}/studysite/${selectedSite.value.minervaId}`,
      });

    const reasonPermissionsApiResourceDoseOrderAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestDoseOrderAdmin,
      undefined,
      undefined
    );

    const getSiteReasonPermissions = async () => {
      const responses = await Promise.all([
        reasonPermissionsApiResourceStudyAdmin.fetchResource(),
        reasonPermissionsApiResourceDoseOrderAdmin.fetchResource(),
      ]);
      reasonPermissionsStudyAdmin.value = responses[0];
      reasonPermissionsDoseOrderAdmin.value = responses[1];
    };

    const isAdmin = computed(
      () =>
        reasonPermissionsStudyAdmin.value ||
        reasonPermissionsDoseOrderAdmin.value
    );

    const getBillingReport = (order) =>
      store.state.orderManagement.billingReports[order.minervaId];

    const billingReportLabel = (order) => {
      const billingReport = getBillingReport(order);
      return billingReport
        ? `${billingReport.price} / ${billingReport.freight}`
        : "NA/NA";
    };

    const openBillingReport = (order) => {
      setSelectedOrderId(order);
      openViewEditBillingReport.value = true;
    };

    const viewOptions = ["All", "Future", "Past"];

    const selectedViewOption = ref("All");

    const filteredOrderData = computed(() => {
      if (siteOrdersApiResource.getData().length == 0) return [];
      if (selectedViewOption.value === "All")
        return siteOrdersApiResource.getData();
      if (selectedViewOption.value === "Future") {
        return siteOrdersApiResource.getData().filter((order) => {
          return (
            DateTime.fromISO(order.local_calibration, {
              setZone: true,
            }).startOf("day") > DateTime.local().startOf("day")
          );
        });
      }
      if (selectedViewOption.value === "Past") {
        return siteOrdersApiResource.getData().filter((order) => {
          return (
            DateTime.fromISO(order.local_calibration, {
              setZone: true,
            }).startOf("day") < DateTime.local().startOf("day")
          );
        });
      }
      return [];
    });

    const tableLoading = computed(
      () =>
        permittedSitesApiResource.getLoading() ||
        studySiteApiResource.getLoading() ||
        siteOrdersApiResource.getLoading() ||
        syncRemoteOrderApiResource.getLoading() ||
        orderRequestsApiResource.getLoading()
    );

    let siteDataRefreshTimer = undefined;
    let shouldFetchBilling = ref(0);

    const initiateSiteRefresh = () => {
      siteDataRefreshTimer = setInterval(() => {
        if (siteOrdersApiResource.getData().length > 0) {
          store.dispatch("orderManagement/fetchAllStudySiteOrders", {
            studyId: selectedSite.value.study,
            studySiteId: selectedSite.value.minervaId,
            length: siteOrdersApiResource.getData().length,
            shouldFetchBilling: adminUser.value
              ? shouldFetchBilling.value % 5 == 0
              : false,
          });
          orderRequestsApiResource.fetchResource();
          shouldFetchBilling.value++;
        }
      }, 10000);
    };

    watch(selectedSite, () => {
      if (selectedSite.value.minervaId) {
        clearInterval(siteDataRefreshTimer);
        shouldFetchBilling.value = 0;
        initiateSiteRefresh();
        getSiteReasonPermissions();
      }
    });

    onUnmounted(() => {
      clearInterval(siteDataRefreshTimer);
      shouldFetchBilling.value = 0;
    });

    onMounted(() => {
      permittedSitesApiResource.fetchResource();
    });

    return {
      contextMenuOptions,
      tableLoading,
      selectedSite,
      selectedOrder,
      setSelectedOrderId,
      openEditModal,
      openDetailModal,
      openEditPostOrderDateSubjectModal,
      openEditPostOrderDateCancellationModal,
      permittedSitesApiResource,
      studySiteApiResource,
      siteOrdersApiResource,
      permittedSitesPlaceholder,
      fetchNextSetOfOrders,
      getStatusColor,
      syncRemote,
      adminUser,
      selectedOrderRequest,
      setSelectedOrderRequestId,
      openOrderRequestDetailModal,
      openViewEditBillingReport,
      openEditOrderRequestModal,
      contextMenuOptionsOrderRequests,
      orderRequestsApiResource,
      getOrderRequestStatusColor,
      viewSparrowDoc,
      getDate,
      getTime,
      getBillingReport,
      billingReportLabel,
      openBillingReport,
      viewOptions,
      selectedViewOption,
      filteredOrderData,
      openConvertOrderRequestModal,
      getAmountString,
      displayTimeString,
      showInactivePermitted,
    };
  },
};
</script>

<style scoped>
.icon-button {
  cursor: pointer;
  color: #fbc02d;
  font-size: 18px;
  transform: translate(2px, 2px);
}

.study-details-container {
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  background-color: white;
}
</style>
