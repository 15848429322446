<template>
  <AppDataTable
    :selected-row-data="selectedSite"
    title="Sites"
    add-button="Site"
    enable-add-button
    searchable
    :table-data="sitesApiResource.getData()"
    :columns="columns"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    :search-properties="[
      'display_name',
      'name',
      'address',
      'city',
      'country',
      'state',
      'postal_code',
    ]"
    @update:selected-row-data="setSelectedSiteId"
    @last-page="fetchNextSetOfSites"
  >
    <template #addModalContent="{ closeAddModal }">
      <AddEditSite @close-form="closeAddModal" />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Site - ${selectedSite && selectedSite.display_name}`"
  >
    <AddEditSite
      :selected-site="selectedSite"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditSite from "@/components/Studies/AddEditSite.vue";

import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Sites",
  components: {
    AppDataTable,
    AddEditSite,
    Dialog,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([
      { field: "name", header: "Name" },
      { field: "city", header: "City" },
      { field: "state", header: "State" },
      { field: "country", header: "Country" },
      { field: "postal_code", header: "Zip Code" },
    ]);

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteSiteHandler(event);
        },
      },
    ];

    const sitesOffset = ref(0);

    const sites = () => store.state.studyManagement.sites;
    const fetchSites = () =>
      store.dispatch("studyManagement/fetchSites", sitesOffset.value);
    const sitesApiResource = new ApiResource(
      sites,
      fetchSites,
      undefined,
      "There was an error fetching your sites."
    );

    const refreshSites = (length) =>
      store.dispatch("studyManagement/refreshSites", length);
    const sitesRefreshApiResource = new ApiResource(
      sites,
      refreshSites,
      undefined,
      "There was an error fetching your sites."
    );

    const fetchNextSetOfSites = async () => {
      sitesOffset.value += 20;
      await sitesApiResource.fetchResource();
    };

    onMounted(() => {
      if (
        !sitesApiResource.getData() ||
        sitesApiResource.getData().length === 0
      ) {
        sitesApiResource.fetchResource();
      } else {
        sitesRefreshApiResource.fetchResource(sites().length);
      }
    });

    const deleteSite = () =>
      store.dispatch("studyManagement/deleteSite", selectedSite.value);
    const deleteSiteApiResource = new ApiResource(
      undefined,
      deleteSite,
      "Successfully deleted site.",
      "There was an error deleting the site."
    );

    const selectedSiteId = ref("");
    const selectedSite = computed(() => {
      if (selectedSiteId.value.length > 0) {
        return store.state.studyManagement.sites.find(
          (site) => site.minervaId === selectedSiteId.value
        );
      }
      return {};
    });
    const setSelectedSiteId = (site) => {
      selectedSiteId.value = site.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        sitesApiResource.getLoading() ||
        deleteSiteApiResource.getLoading() ||
        sitesRefreshApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteSiteHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete site ${selectedSite.value.display_name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteSiteApiResource.fetchResource();
        },
        reject: () => {
          selectedSiteId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      columns,
      contextMenuOptions,
      sitesApiResource,
      setSelectedSiteId,
      selectedSite,
      openEditModal,
      tableLoading,
      fetchNextSetOfSites,
    };
  },
};
</script>
