<template>
  <AppDataTable
    :selected-row-data="selectedPharmacyTechnician"
    title="Pharmacy Technicians"
    add-button="Pharmacy Technician"
    enable-add-button
    :table-data="pharmacyTechniciansApiResource.getData()"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedPharmacyTechnicianId"
  >
    <Column field="display_name" header="Name"></Column>
    <Column field="site_contact_number" header="Phone"></Column>
    <Column header="Email">
      <template #body="slotProps">
        {{ getUserEmail(slotProps.data) }}
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddEditPharmacyTechnician
        :selected-pharmacy="selectedPharmacy"
        :pharmacy-technicians="pharmacyTechniciansApiResource.getData()"
        :non-g-s-a-users="nonGSAUsersApiResource.getData()"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Technician - ${
      selectedPharmacyTechnician && selectedPharmacyTechnician.display_name
    }`"
  >
    <AddEditPharmacyTechnician
      :selected-pharmacy="selectedPharmacy"
      :selected-pharmacy-technician="selectedPharmacyTechnician"
      :pharmacy-technicians="pharmacyTechniciansApiResource.getData()"
      :non-g-s-a-users="nonGSAUsersApiResource.getData()"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditPharmacyTechnician from "@/components/Pharmacies/AddEditPharmacyTechnician.vue";

import Dialog from "primevue/dialog";
import Column from "primevue/column";

import { useConfirm } from "primevue/useConfirm";
import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "PharmacyTechnicians",
  components: {
    AppDataTable,
    AddEditPharmacyTechnician,
    Column,
    Dialog,
  },
  props: {
    selectedPharmacy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeletePharmacyTechnicianHandler(event);
        },
      },
    ];

    const pharmacyTechnicians = () =>
      store.state.pharmacies.pharmacyTechnicians[
        props.selectedPharmacy.minervaId
      ];
    const fetchPharmacyTechnicians = () =>
      store.dispatch("pharmacies/fetchPharmacyTechnicians", {
        pharmacyId: props.selectedPharmacy.minervaId,
      });

    const pharmacyTechniciansApiResource = new ApiResource(
      pharmacyTechnicians,
      fetchPharmacyTechnicians,
      undefined,
      "There was an error fetching the pharmacy technicians."
    );

    const nonGSAUsers = () => store.state.users.nonGSAUsers;
    const fetchNonGSAUsers = () => store.dispatch("users/fetchNonGSAUsers");

    const nonGSAUsersApiResource = new ApiResource(
      nonGSAUsers,
      fetchNonGSAUsers,
      undefined,
      "There was an error fetching the study users."
    );

    const getUserEmail = (user) => {
      const users = nonGSAUsersApiResource.getData();
      if (users) {
        const userObject = nonGSAUsersApiResource
          .getData()
          .find((userObj) => userObj.minervaId === user.minervauser);
        return userObject ? userObject.email : "";
      }
      return "";
    };

    onMounted(() => {
      if (!nonGSAUsersApiResource.getData().value)
        nonGSAUsersApiResource.fetchResource();
      pharmacyTechniciansApiResource.fetchResource();
    });

    const deletePharmacyTechnician = () =>
      store.dispatch("pharmacies/deletePharmacyTechnician", {
        pharmacyId: props.selectedPharmacy.minervaId,
        payload: selectedPharmacyTechnician.value,
      });

    const deletePharmacyTechnicianApiResource = new ApiResource(
      undefined,
      deletePharmacyTechnician,
      "Successfully deleted pharmacy technician.",
      "There was an error deleting the pharmacy technician."
    );

    const selectedPharmacyTechnicianId = ref("");
    const selectedPharmacyTechnician = computed(() => {
      if (selectedPharmacyTechnicianId.value.length > 0) {
        return store.state.pharmacies.pharmacyTechnicians[
          props.selectedPharmacy.minervaId
        ].find(
          (pharmacyTechnician) =>
            pharmacyTechnician.minervaId === selectedPharmacyTechnicianId.value
        );
      }
      return {};
    });
    const setSelectedPharmacyTechnicianId = (pharmacyTechnician) => {
      selectedPharmacyTechnicianId.value = pharmacyTechnician.minervaId;
    };

    const tableLoading = computed(
      () =>
        pharmacyTechniciansApiResource.getLoading() ||
        deletePharmacyTechnicianApiResource.getLoading()
    );

    const openEditModal = ref(false);
    const confirm = useConfirm();

    const confirmDeletePharmacyTechnicianHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete pharmacy technician ${selectedPharmacyTechnician.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deletePharmacyTechnicianApiResource.fetchResource();
        },
        reject: () => {
          selectedPharmacyTechnicianId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      contextMenuOptions,
      nonGSAUsersApiResource,
      pharmacyTechniciansApiResource,
      setSelectedPharmacyTechnicianId,
      selectedPharmacyTechnician,
      tableLoading,
      getUserEmail,
      openEditModal,
    };
  },
};
</script>
