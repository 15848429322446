import { ref, computed } from "vue";

export const buildSubjectHandlers = (store, props, ApiResource, newOrder) => {
  const showNewSubjectInput = ref(false);
  const newSubjectId = ref("");

  const newSubjectValidator = {
    $validator: (value) => {
      if (typeof value === "undefined" || value === null || value === "") {
        return true;
      }
      return /^[A-Z0-9.\-_]+$/g.test(value);
    },
    $message:
      "Subject IDs may only contain capital letters, numbers, and dashes.",
  };

  const toggleShowNewSubjectInput = (subjectModel) => {
    showNewSubjectInput.value = !showNewSubjectInput.value;
    if (!showNewSubjectInput.value) subjectModel.$reset();
  };

  const studySiteSubjects = () =>
    store.state.orderManagement.studySiteSubjects[props.studySite.minervaId];

  const fetchStudySiteSubjects = () =>
    store.dispatch("orderManagement/fetchStudySiteSubjects", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
    });

  const studySiteSubjectApiResource = new ApiResource(
    studySiteSubjects,
    fetchStudySiteSubjects,
    undefined,
    "There was an error fetching the study site subjects."
  );

  const subjectPlaceholder = computed(() =>
    studySiteSubjectApiResource.getLoading()
      ? "Loading subjects"
      : "Select a subject"
  );

  const subjectChanged = computed(
    () => newOrder.studysitesubject !== props.selectedOrder.studysitesubject
  );

  const resetSubject = () =>
    (newOrder.studysitesubject = props.selectedOrder.studysitesubject);

  const updateSubjectRequest = () =>
    store.dispatch("orderManagement/updateStudySiteOrderSubject", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      studySiteOrderId: props.selectedOrder.minervaId,
      updatedOrder: {
        studysitesubject: newOrder.studysitesubject,
        study: props.studySite.study,
        studysite: props.studySite.minervaId,
        studysiteorder: props.selectedOrder.minervaId,
        // modification_time: DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss"),
      },
    });

  const saveUpdatedSubjectApiResource = new ApiResource(
    undefined,
    updateSubjectRequest,
    "Order subject successfully updated.",
    "There was an error updating the order subject."
  );

  const addNewSubjectRequest = () =>
    store.dispatch("orderManagement/createStudySiteSubject", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      newSubject: {
        subjectID: newSubjectId.value,
        study: props.studySite.study,
        site: props.studySite.site,
        studysite: props.studySite.minervaId,
      },
    });

  const addNewSubjectApiResource = new ApiResource(
    undefined,
    addNewSubjectRequest,
    "Successfully added new subject.",
    "There was an error adding the new subject."
  );

  const createSubject = async (subjectModel) => {
    if (await subjectModel.$validate()) {
      await addNewSubjectApiResource.fetchResource();
      await studySiteSubjectApiResource.fetchResource();
      showNewSubjectInput.value = false;
      newSubjectId.value = "";
    }
  };

  const subjectOtherOrders = () =>
    store.state.orderManagement.subjectOtherOrders[newOrder.studysitesubject];

  const fetchSubjectOtherOrders = () =>
    store.dispatch("orderManagement/fetchSubjectOtherOrders", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      subjectId: newOrder.studysitesubject,
    });

  const subjectOtherOrderApiResource = new ApiResource(
    subjectOtherOrders,
    fetchSubjectOtherOrders,
    undefined,
    "There was an error fetching the subjects other orders."
  );

  return {
    showNewSubjectInput,
    toggleShowNewSubjectInput,
    newSubjectId,
    newSubjectValidator,
    subjectPlaceholder,
    subjectChanged,
    resetSubject,
    createSubject,
    addNewSubjectApiResource,
    studySiteSubjectApiResource,
    saveUpdatedSubjectApiResource,
    subjectOtherOrderApiResource,
  };
};
