<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="first_name" class="title">First Name</label>
      <InputText
        id="first_name"
        v-model="newUserModel.firstName.$model"
        :class="{ 'p-error': newUserModel.firstName.$invalid }"
      />
      <ErrorMessages :model-property="newUserModel.firstName" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="last_name" class="title">Last Name</label>
      <InputText
        id="last_name"
        v-model="newUserModel.lastName.$model"
        :class="{ 'p-error': newUserModel.lastName.$invalid }"
      />
      <ErrorMessages :model-property="newUserModel.lastName" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="user_email" class="title">Email</label>
      <InputText
        id="user_email"
        v-model="newUserModel.email.$model"
        :class="{ 'p-error': newUserModel.email.$invalid }"
      />
      <ErrorMessages :model-property="newUserModel.email" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="auth_id" class="title">Auth ID</label>
      <InputText id="auth_id" v-model="newUser.authID" />
    </FormCol>
    <Divider />
    <FormCol>
      <div class="p-d-flex p-ai-start p-pt-1">
        <InputSwitch v-model="newUser.approved" />
        <label for="user_approved" class="title p-ml-3 p-pt-1">Approved</label>
      </div>
    </FormCol>
    <Divider />
    <FormCol>
      <div class="p-d-flex p-ai-start p-pt-1">
        <InputSwitch v-model="newUser.disabled" />
        <label for="user_disabled" class="title p-ml-3 p-pt-1">Disabled</label>
      </div>
    </FormCol>
    <Divider />
    <FormCol>
      <div class="p-d-flex p-ai-start p-pt-1">
        <InputSwitch v-model="newUser.gsa_account" />
        <label for="gsa_account" class="title p-ml-3 p-pt-1">GSA Account</label>
      </div>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newUserModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import InputSwitch from "primevue/inputswitch";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditUser",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    InputSwitch,
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newUser = reactive({
      firstName: "",
      lastName: "",
      email: "",
      authID: "",
      approved: false,
      disabled: false,
      gsa_account: false,
    });

    const rules = {
      firstName: {
        required: helpers.withMessage("A first name is required", required),
      },
      lastName: {
        required: helpers.withMessage("A last name is required", required),
      },
      email: {
        required: helpers.withMessage("An email address is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };

    const newUserModel = useVuelidate(rules, newUser, { $lazy: true });

    const createUser = () => store.dispatch("users/createUser", newUser);

    const createUserApiResource = new ApiResource(
      undefined,
      createUser,
      "Successfully created new user",
      "There was an error creating the new user."
    );

    const updateUser = () => store.dispatch("users/updateUser", newUser);

    const updateUserApiResource = new ApiResource(
      undefined,
      updateUser,
      "Successfully updated the user.",
      "There was an error updating the user."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newUserModel.value.$validate()) {
        props.isEditing
          ? await updateUserApiResource.fetchResource()
          : await createUserApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createUserApiResource.getLoading() || updateUserApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedUser)) {
          newUser[key] = value;
        }
      }
    });

    return {
      closeForm,
      newUser,
      newUserModel,
      formLoading,
      handleSave,
    };
  },
};
</script>
