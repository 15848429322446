<template>
  <div
    class="p-col-12 p-d-flex p-ai-center"
    :class="`${colWidth} ${useLeftButton ? 'p-jc-between' : 'p-jc-end'}`"
  >
    <div class="p-d-flex p-pt-3">
      <slot name="leftButton"></slot>
    </div>
    <div class="p-d-flex p-pt-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormButtonCol",
  props: {
    colWidth: {
      type: String,
      default: "",
    },
    useLeftButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
