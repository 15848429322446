import { computed } from "vue";

export const buildNoteHandlers = (store, props, ApiResource, newOrder) => {
  const notesChanged = computed(
    () => newOrder.notes !== props.selectedOrder.notes
  );

  const resetNotes = () => {
    newOrder.notes = props.selectedOrder.notes;
  };

  const updateNotesRequest = () =>
    store.dispatch("orderManagement/updateStudySiteOrderNotes", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      studySiteOrderId: props.selectedOrder.minervaId,
      updatedOrder: {
        study: props.studySite.study,
        studysite: props.studySite.minervaId,
        studysiteorder: props.selectedOrder.minervaId,
        notes: newOrder.notes,
      },
    });

  const saveUpdatedNotesApiResource = new ApiResource(
    undefined,
    updateNotesRequest,
    "Notes successfully updated.",
    "There was an error updating the notes."
  );

  const courierNotesChanged = computed(
    () => newOrder.courier_notes !== props.selectedOrder.courier_notes
  );

  const resetCourierNotes = () => {
    newOrder.courier_notes = props.selectedOrder.courier_notes;
  };

  const updateCourierNotesRequest = () =>
    store.dispatch("orderManagement/updateStudySiteOrderCourierNotes", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      studySiteOrderId: props.selectedOrder.minervaId,
      updatedOrder: {
        study: props.studySite.study,
        studysite: props.studySite.minervaId,
        studysiteorder: props.selectedOrder.minervaId,
        courier_notes: newOrder.courier_notes,
      },
    });

  const saveUpdatedCourierNotesApiResource = new ApiResource(
    undefined,
    updateCourierNotesRequest,
    "Courier notes successfully updated.",
    "There was an error updating the courier notes."
  );

  return {
    notesChanged,
    resetNotes,
    saveUpdatedNotesApiResource,
    courierNotesChanged,
    resetCourierNotes,
    saveUpdatedCourierNotesApiResource,
  };
};
