<template>
  <div class="p-d-flex p-flex-row">
    <span class="lineHeight titleStyling title p-mr-2">{{ title }}</span>
    <span class="detail p-pt-1">{{ data }}</span>
  </div>
</template>

<script>
export default {
  name: "CardLine",
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: [String, Boolean],
      required: true,
    },
  },
};
</script>
<style lang="scss">
.titleStyling {
  width: 160px;
  text-align: end;
  flex-shrink: 0;
}
.lineHeight {
  height: 28px;
}
</style>
