<template>
  <FormWrapper :loading="formLoading" width="650px">
    <FormCol col-width="p-md-6">
      <label for="site" class="title">Site</label>
      <Dropdown
        id="site"
        v-model="newStudySiteModel.site.$model"
        :options="allSites"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        :filter="true"
        :class="{ 'p-error': newStudySiteModel.site.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteModel.site" />
    </FormCol>
    <FormCol col-width="p-md-3">
      <label for="id_code" class="title">ID Code</label>
      <InputText
        id="id_code"
        v-model="newStudySiteModel.idCode.$model"
        :class="{ 'p-error': newStudySiteModel.idCode.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteModel.idCode" />
    </FormCol>
    <FormCol col-width="p-md-3">
      <label for="secondary_site_id" class="title">Secondary ID</label>
      <InputText
        id="secondary_site_id"
        v-model="newStudySite.secondary_site_id"
      />
    </FormCol>
    <Divider />
    <FormCol v-if="isEditing && studyAdmin" col-width="p-md-6">
      <label for="site_status" class="title">Site Status</label>
      <Dropdown
        id="site_status"
        v-model="newStudySite.site_status"
        append-to="body"
        :options="['active', 'inactive']"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-items-center">
            <div>
              {{
                slotProps.value.charAt(0).toUpperCase() +
                slotProps.value.slice(1)
              }}
            </div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <div>
              {{
                slotProps.option.charAt(0).toUpperCase() +
                slotProps.option.slice(1)
              }}
            </div>
          </div>
        </template>
      </Dropdown>
    </FormCol>
    <Divider v-if="isEditing && studyAdmin" />
    <FormCol col-width="p-md-6">
      <label for="site_contact_name" class="title"
        >Study Specific Contact Name</label
      >
      <InputText
        id="site_contact_name"
        v-model="newStudySiteModel.site_contact_name.$model"
        :class="{ 'p-error': newStudySiteModel.site_contact_name.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteModel.site_contact_name" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="site_contact_number" class="title"
        >Study Specific Contact Number</label
      >
      <InputText
        id="site_contact_number"
        v-model="newStudySiteModel.site_contact_number.$model"
        :class="{ 'p-error': newStudySiteModel.site_contact_number.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteModel.site_contact_number" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="site_contact_fax" class="title"
        >Study Specific Contact Fax</label
      >
      <InputText
        id="site_contact_fax"
        v-model="newStudySite.site_contact_fax"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="site_contact_email" class="title"
        >Study Specific Contact Email</label
      >
      <InputText
        id="site_contact_email"
        v-model="newStudySiteModel.site_contact_email.$model"
        :class="{ 'p-error': newStudySiteModel.site_contact_email.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteModel.site_contact_email" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="investigator" class="title">Investigator</label>
      <InputText
        id="investigator"
        v-model="newStudySiteModel.investigator.$model"
        :class="{ 'p-error': newStudySiteModel.investigator.$invalid }"
      />
      <ErrorMessages :model-property="newStudySiteModel.investigator" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="default_delivery_details" class="title"
        >Default Delivery Details</label
      >
      <Textarea
        id="default_delivery_details"
        v-model="newStudySite.delivery_info"
        label="default_delivery_details"
        :auto-resize="true"
        rows="3"
        cols="60"
      />
    </FormCol>
    <FormCol>
      <InlineMessage severity="info" class="p-mt-2">
        General delivery notes only. Order specific delivery notes can be added
        later.
      </InlineMessage>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newStudySiteModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import InlineMessage from "primevue/inlinemessage";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";

import { reactive, computed, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export default {
  name: "AddEditStudySite",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    InlineMessage,
    Dropdown,
    Textarea,
  },
  props: {
    selectedStudy: {
      type: Object,
      default: () => ({}),
    },
    selectedStudySite: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    allSites: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const auth = inject("Auth");
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const user = auth?.user?.value ?? undefined;

    const newStudySite = reactive({
      study: props.selectedStudy.minervaId,
      site: "",
      idCode: "",
      secondary_site_id: "",
      site_contact_name: "",
      site_contact_number: "",
      site_contact_fax: "",
      site_contact_email: "",
      site_status: "active",
      delivery_info: "",
      investigator: "",
    });

    const validPhone = (value) => {
      try {
        phoneUtil.parse(value, "ZZ");
        return true;
      } catch (error) {
        return false;
      }
      // const number = phoneUtil.parse(value, "ZZ");
      // return phoneUtil.isValidNumber(number);
    };

    const rules = {
      site: {
        required: helpers.withMessage("A site name is required", required),
      },
      idCode: {
        required: helpers.withMessage("A site ID code is required", required),
      },
      site_contact_name: {
        required: helpers.withMessage(
          "A site contact name is required",
          required
        ),
      },
      site_contact_number: {
        required: helpers.withMessage(
          "A site contact number is required",
          required
        ),
        validPhone: helpers.withMessage(
          "A valid phone number is required ex: +1 555 555 555",
          validPhone
        ),
      },
      site_contact_email: {
        required: helpers.withMessage(
          "A site contact email is required",
          required
        ),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      investigator: {
        required: helpers.withMessage(
          "A site investigator is required",
          required
        ),
      },
    };

    const newStudySiteModel = useVuelidate(rules, newStudySite, {
      $lazy: true,
    });

    const createStudySite = () =>
      store.dispatch("studySites/addStudySite", {
        studyId: props.selectedStudy.minervaId,
        payload: newStudySite,
      });

    const createStudySiteApiResource = new ApiResource(
      undefined,
      createStudySite,
      "Successfully created new study site",
      "There was an error creating the new study site."
    );

    const updateStudySite = () =>
      store.dispatch("studySites/updateStudySite", {
        studyId: props.selectedStudy.minervaId,
        payload: newStudySite,
      });

    const updateStudySiteApiResource = new ApiResource(
      undefined,
      updateStudySite,
      "Successfully updated the study site.",
      "There was an error updating the study site."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newStudySiteModel.value.$validate()) {
        props.isEditing
          ? await updateStudySiteApiResource.fetchResource()
          : await createStudySiteApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createStudySiteApiResource.getLoading() ||
        updateStudySiteApiResource.getLoading()
    );

    const studyAdmin = ref(false);

    const getPermissionsRequestStudyAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "StudyAdmin",
        email: user.email,
        resource: `/study/${props.selectedStudy.minervaId}/studysite/${props.selectedStudySite.minervaId}`,
      });

    const permissionsApiResourceStudyAdmin = new ApiResource(
      undefined,
      getPermissionsRequestStudyAdmin,
      undefined,
      undefined
    );

    onMounted(async () => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedStudySite)) {
          if (value && value.length > 0) newStudySite[key] = value;
        }
      }
      if (props.isEditing) {
        studyAdmin.value = await permissionsApiResourceStudyAdmin.fetchResource();
      }
    });

    return {
      closeForm,
      newStudySite,
      newStudySiteModel,
      formLoading,
      handleSave,
      studyAdmin,
    };
  },
};
</script>
