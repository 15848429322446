<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="order_status" class="title">Order Status</label>
      <Dropdown
        id="order_status"
        v-model="orderRequest.status"
        append-to="body"
        :options="['Pending', 'Completed', 'Cancelled']"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";

import { reactive, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "AddEditStudy",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
    Dropdown,
  },
  props: {
    studySite: {
      type: Object,
      default: () => ({}),
    },
    selectedOrderRequest: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const orderRequest = reactive({
      status: props.selectedOrderRequest.status,
      minervaId: props.selectedOrderRequest.minervaId,
    });

    const updateOrderRequest = () =>
      store.dispatch("orderRequests/updateOrderRequestStatus", {
        studyId: props.selectedOrderRequest.study,
        studySiteId: props.studySite.minervaId,
        payload: orderRequest,
      });

    const updateOrderRequestApiResource = new ApiResource(
      undefined,
      updateOrderRequest,
      "Successfully updated the order request.",
      "There was an error updating the order request."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      await updateOrderRequestApiResource.fetchResource();
      closeForm();
    };

    const formLoading = computed(() =>
      updateOrderRequestApiResource.getLoading()
    );

    return {
      closeForm,
      orderRequest,
      formLoading,
      handleSave,
    };
  },
};
</script>
