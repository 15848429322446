<template>
  <AppDataTable
    :selected-row-data="selectedNetwork"
    title="Networks"
    add-button="Network"
    enable-add-button
    :table-data="networksApiResource.getData()"
    :columns="columns"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedNetworkId"
  >
    <template #addModalContent="{ closeAddModal }">
      <AddEditNetwork
        :selected-network="selectedNetwork"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Network - ${
      selectedNetwork && selectedNetwork.display_name
    }`"
  >
    <AddEditNetwork
      :selected-network="selectedNetwork"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditNetwork from "@/components/Studies/AddEditNetwork.vue";
import Dialog from "primevue/dialog";

import { useConfirm } from "primevue/useConfirm";
import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Networks",
  components: {
    AppDataTable,
    AddEditNetwork,
    Dialog,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([{ field: "name", header: "Name" }]);

    const openEditModal = ref(false);

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteNetworkHandler(event);
        },
      },
    ];

    const networks = () => store.state.studyManagement.networks;
    const fetchNetworks = () => store.dispatch("studyManagement/fetchNetworks");

    const networksApiResource = new ApiResource(
      networks,
      fetchNetworks,
      undefined,
      "There was an error fetching the networks."
    );

    onMounted(() => {
      networksApiResource.fetchResource();
    });

    const deleteNetwork = () =>
      store.dispatch("studyManagement/deleteNetwork", selectedNetwork.value);

    const deleteNetworkApiResource = new ApiResource(
      undefined,
      deleteNetwork,
      "Successfully deleted network.",
      "There was an error deleting the network."
    );

    const selectedNetworkId = ref("");
    const selectedNetwork = computed(() => {
      if (selectedNetworkId.value.length > 0) {
        return store.state.studyManagement.networks.find(
          (network) => network.minervaId === selectedNetworkId.value
        );
      }
      return {};
    });
    const setSelectedNetworkId = (study) => {
      selectedNetworkId.value = study.minervaId;
    };

    const tableLoading = computed(
      () =>
        networksApiResource.getLoading() ||
        deleteNetworkApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteNetworkHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete network ${selectedNetwork.value.name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteNetworkApiResource.fetchResource();
        },
        reject: () => {
          selectedNetworkId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      columns,
      contextMenuOptions,
      networksApiResource,
      setSelectedNetworkId,
      selectedNetwork,
      openEditModal,
      tableLoading,
    };
  },
};
</script>
