import { computed } from "vue";

export const buildProductsHandlers = (store, props, ApiResource, newOrder) => {
  const availableProducts = () =>
    store.state.orderManagement.studySiteProducts[props.studySite.minervaId];

  const fetchAvailableProducts = () =>
    store.dispatch("orderManagement/fetchStudySiteProducts", {
      studyId: props.studySite.study,
      studySiteId: props.studySite.minervaId,
      productId: newOrder.studysiteproduct,
    });

  const availableProductsApiResource = new ApiResource(
    availableProducts,
    fetchAvailableProducts,
    undefined,
    "There was an error fetching the available products."
  );

  const productPlaceholder = computed(() =>
    availableProductsApiResource.getLoading()
      ? "Loading products"
      : "Select a product"
  );

  const selectedProduct = computed(
    () =>
      availableProductsApiResource.getData() &&
      availableProductsApiResource
        .getData()
        .find((product) => product.minervaId === newOrder.studysiteproduct)
  );

  return {
    availableProductsApiResource,
    productPlaceholder,
    selectedProduct,
  };
};
