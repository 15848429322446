<template>
  <Dialog
    v-model:visible="openModal"
    :modal="true"
    :header="
      initLoading
        ? ''
        : `Order Details - ${sparrowApiResource.getData().order_id || ''}`
    "
  >
    <FormWrapper :loading="formLoading || initLoading">
      <template v-if="isPharmacyOrder && !initLoading">
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Order ID"
            :data="
              pharmacyOrderApiResource.getData().order_data?.order_id ?? ''
            "
          />
        </FormCol>
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Product"
            :data="pharmacyOrderApiResource.getData().product_info?.name ?? ''"
          />
        </FormCol>
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Amount"
            :data="
              pharmacyOrderApiResource.getData().order_data?.amount
                ? getAmountString(
                    pharmacyOrderApiResource.getData().order_data?.amount
                  )
                : ''
            "
          />
        </FormCol>
        <Divider
          v-if="
            pharmacyOrderApiResource.getData().order_data?.study_name ?? false
          "
        />
        <FormCol
          v-if="
            pharmacyOrderApiResource.getData().order_data?.study_name ?? false
          "
          col-width="p-md-6"
        >
          <FormDataView
            title="Additional Info"
            :data="
              pharmacyOrderApiResource.getData().order_data?.study_name ?? ''
            "
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Subject"
            :data="
              pharmacyOrderApiResource.getData().order_data?.subject_id ?? ''
            "
          />
        </FormCol>
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Date"
            :data="
              pharmacyOrderApiResource.getData().order_data?.local_calibration
                ? getDate(
                    pharmacyOrderApiResource.getData().order_data
                      .local_calibration
                  )
                : ''
            "
          />
        </FormCol>
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Time"
            :data="
              pharmacyOrderApiResource.getData().order_data?.local_calibration
                ? getTime(
                    pharmacyOrderApiResource.getData().order_data
                      .local_calibration
                  )
                : ''
            "
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Imaging Site"
            :data="`${
              pharmacyOrderApiResource.getData().site_info?.name ?? ''
            } - ${pharmacyOrderApiResource.getData().site_info?.city ?? ''}`"
          />
        </FormCol>
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Site Address"
            :data="
              pharmacyOrderApiResource.getData().site_info
                ? displayAddress(pharmacyOrderApiResource.getData().site_info)
                : ''
            "
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-6">
          <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
            <Checkbox
              id="need_courier"
              v-model="acceptOrderPayload.courier_needed"
              :binary="true"
              class="p-mb-1"
              :disabled="!initLoading && !sparrowApiResource.getData().active"
            />
            <label for="need_courier" class="checkbox-text p-ml-2 p-mb-1"
              >Courier Needed?</label
            >
          </div>
        </FormCol>
        <FormCol v-if="acceptOrderPayload.courier_needed" col-width="p-md-6">
          <label for="pickup_time" class="title">Pickup Time</label>
          <Calendar
            id="pickup_time"
            :model-value="pickupTime"
            :time-only="true"
            placeholder="Select a time"
            append-to="body"
            :class="{ 'p-error': pharmacyModel.pickup_time.$invalid }"
            @update:model-value="setPickupTime(pharmacyModel, $event)"
          />
          <ErrorMessages :model-property="pharmacyModel.pickup_time" />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-4">
          <FormDataView
            title="Site Notes"
            :data="
              pharmacyOrderApiResource.getData().order_data
                ?.site_default_notes ?? ''
            "
          />
        </FormCol>
        <Divider />
        <FormCol>
          <FormDataView
            title="Courier Notes"
            :data="
              pharmacyOrderApiResource.getData().order_data?.courier_notes ?? ''
            "
          />
        </FormCol>
        <Divider />
      </template>
      <template v-if="isSiteOrder && !initLoading">
        <FormCol>
          <FormDataView
            title="Order ID"
            :data="studySiteOrderApiResource.getData().order_id"
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Study"
            :data="studySiteOrderApiResource.getData().study_name"
          />
        </FormCol>
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Subject ID"
            :data="studySiteOrderApiResource.getData().studysitesubjectid"
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Product"
            :data="studySiteOrderApiResource.getData().studysiteproduct_name"
          />
        </FormCol>
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Amount"
            :data="studySiteOrderApiResource.getData().amount"
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Date"
            :data="
              studySiteOrderApiResource.getData()?.local_calibration
                ? getDate(studySiteOrderApiResource.getData().local_calibration)
                : ''
            "
          />
        </FormCol>
        <FormCol col-width="p-md-6">
          <FormDataView
            title="Time"
            :data="
              studySiteOrderApiResource.getData()?.local_calibration
                ? getTime(studySiteOrderApiResource.getData().local_calibration)
                : ''
            "
          />
        </FormCol>
        <Divider />
        <FormCol col-width="p-md-6">
          <FormDataView
            title="New Date"
            classes="p-error"
            :data="siteOrderNewDate"
          />
        </FormCol>
        <FormCol col-width="p-md-6">
          <FormDataView
            title="New Time"
            classes="p-error"
            :data="siteOrderNewTime"
          />
        </FormCol>
        <Divider />
      </template>
      <FormButtonCol
        v-if="!initLoading && sparrowApiResource.getData().active"
        :use-left-button="true"
      >
        <template #leftButton>
          <Button
            label="Suggest Alt. Date/Time"
            @click="openSuggestDateTimeModal = true"
          />
        </template>
        <CancelConfirmButtons
          :disabled="formLoading || initLoading || pharmacyModel.$invalid"
          cancel-text="REJECT/CANCEL"
          confirm-text="ACCEPT"
          @cancelled="openRejectModal = true"
          @confirmed="acceptOrder"
        />
      </FormButtonCol>
      <FormCol v-if="!initLoading && !sparrowApiResource.getData().active">
        <InlineMessage severity="info" class="p-mt-2">
          This manage link is inactive, modifications are not allowed.
        </InlineMessage>
      </FormCol>
    </FormWrapper>
  </Dialog>
  <Dialog
    v-model:visible="openRejectModal"
    :modal="true"
    :header="`Reject/Cancel Order - ${sparrowApiResource.getData().order_id}`"
  >
    <FormWrapper :loading="formLoading">
      <FormCol col-width="p-md-6">
        <label for="order_cancellation_reason" class="title"
          >Cancellation Reason</label
        >
        <Dropdown
          id="order_cancellation_reason"
          v-model="model.cancellation_reason.$model"
          :options="cancellationReasons"
          placeholder="Select a reason"
          append-to="body"
          :class="{
            'p-invalid': model.cancellation_reason.$invalid,
          }"
        />
        <ErrorMessages :model-property="model.cancellation_reason" />
      </FormCol>
      <Divider />
      <FormCol v-if="cancellationReason.cancellation_reason === 'Other'">
        <label for="other_reason" class="title">Other Reason</label>
        <Textarea
          v-model="model.other.$model"
          label="other_reason"
          :auto-resize="true"
          rows="3"
          cols="60"
          :class="{
            'p-invalid': model.other.$invalid,
          }"
        />
        <ErrorMessages :model-property="model.other" />
      </FormCol>
      <Divider v-if="cancellationReason.cancellation_reason === 'Other'" />
      <FormButtonCol>
        <CancelConfirmButtons
          :disabled="formLoading || model.$invalid"
          cancel-text="CLOSE"
          confirm-text="SUBMIT"
          @cancelled="openRejectModal = false"
          @confirmed="rejectOrder"
        />
      </FormButtonCol>
    </FormWrapper>
  </Dialog>
  <Dialog
    v-model:visible="openSuggestDateTimeModal"
    :modal="true"
    :header="`Suggest New Date/Time - ${sparrowApiResource.getData().order_id}`"
  >
    <SuggestNewDateTime
      :sparrow-id="sparrowId"
      :sparrow-order="sparrowApiResource.getData()"
      :original-order="
        isSiteOrder
          ? studySiteOrderApiResource.getData()
          : pharmacyOrderApiResource.getData()
      "
      :is-site-order="isSiteOrder"
      :is-pharmacy-order="isPharmacyOrder"
      :study-site="studySiteApiResource.getData()"
      :pharmacy="pharmacyApiResource.getData()"
      @close-form="openSuggestDateTimeModal = false"
      @suggestionSuccess="routeHome"
    />
  </Dialog>
</template>

<script>
import Divider from "primevue/divider";
import FormCol from "@/components/FormComponents/FormCol";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormDataView from "@/components/FormComponents/FormDataView";
import Dialog from "primevue/dialog";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import InlineMessage from "primevue/inlinemessage";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import SuggestNewDateTime from "@/components/OrderConfirmation/SuggestNewDateTime";
import { ref, inject, onMounted, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  helpers,
} from "@vuelidate/validators";
import orderCancellationReasons from "@/helpers/orderCancellationReasons";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import { DateTime } from "luxon";
import {
  getTime,
  getDate,
  setTZ,
  displayTime,
  setTime,
  calibrationDate,
} from "@/helpers/timeHelpers";
import { getAmountString } from "@/helpers/units";

export default {
  name: "OrderConfirm",
  components: {
    Divider,
    FormCol,
    FormWrapper,
    FormDataView,
    Dialog,
    FormButtonCol,
    CancelConfirmButtons,
    Dropdown,
    ErrorMessages,
    InlineMessage,
    SuggestNewDateTime,
    Textarea,
    Calendar,
    Checkbox,
  },
  props: {
    sparrowId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const auth = inject("Auth");
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const router = useRouter();
    const modificationTime = DateTime.local().toFormat("yyyy-LL-dd HH:mm:ss");
    const user = auth?.user?.value ?? undefined;

    const sparrowOrder = () => store.state.orderConfirmation.sparrowObject;
    const fetchSparrowOrder = () =>
      store.dispatch("orderConfirmation/fetchSparrowDetails", props.sparrowId);
    const sparrowApiResource = new ApiResource(
      sparrowOrder,
      fetchSparrowOrder,
      undefined,
      "There was an error fetching the order details."
    );

    const studySiteOrder = () => store.state.orderConfirmation.studySiteOrder;
    const fetchStudySiteOrder = () =>
      store.dispatch("orderConfirmation/fetchStudySiteOrder", {
        studyId: sparrowApiResource.getData()?.additional_data?.study_id ?? "",
        studySiteId: sparrowApiResource.getData()?.entity_id ?? "",
        studySiteOrderId:
          sparrowApiResource.getData()?.additional_data?.studysiteorder_id ??
          "",
      });
    const studySiteOrderApiResource = new ApiResource(
      studySiteOrder,
      fetchStudySiteOrder,
      undefined,
      "There was an error fetching the study site order."
    );

    const pharmacyOrder = () => store.state.orderConfirmation.pharmacyOrder;
    const fetchPharmacyOrder = () =>
      store.dispatch("orderConfirmation/fetchPharmacyOrder", {
        pharmacyId: sparrowApiResource.getData()?.entity_id ?? "",
        pharmacyOrderId: sparrowApiResource.getData()?.order_id ?? "",
      });
    const pharmacyOrderApiResource = new ApiResource(
      pharmacyOrder,
      fetchPharmacyOrder,
      undefined,
      "There was an error fetching the pharmacy order."
    );

    const pharmacy = () => store.state.pharmacies.pharmacy;
    const fetchPharmacy = () =>
      store.dispatch(
        "pharmacies/fetchPharmacy",
        sparrowApiResource.getData()?.entity_id ?? ""
      );
    const pharmacyApiResource = new ApiResource(
      pharmacy,
      fetchPharmacy,
      undefined,
      "There was an error fetching your pharmacy."
    );

    const studySite = () => store.state.studySites.studySite;
    const fetchStudySite = () =>
      store.dispatch("studySites/fetchStudySite", {
        studyId: sparrowApiResource.getData()?.additional_data.study_id ?? "",
        siteId: sparrowApiResource.getData()?.entity_id ?? "",
      });
    const studySiteApiResource = new ApiResource(
      studySite,
      fetchStudySite,
      undefined,
      "There was an error fetching your study site."
    );

    const siteOrderNewDate = computed(() => {
      return sparrowApiResource.getData()?.suggested_calibration &&
        studySiteApiResource.getData()
        ? getDate(
            setTZ(
              sparrowApiResource.getData().suggested_calibration,
              studySiteApiResource.getData().time_zone
            )
          )
        : "";
    });

    const siteOrderNewTime = computed(() => {
      return sparrowApiResource.getData()?.suggested_calibration &&
        studySiteApiResource.getData()
        ? getTime(
            setTZ(
              sparrowApiResource.getData().suggested_calibration,
              studySiteApiResource.getData().time_zone
            )
          )
        : "";
    });

    const pickupTimeBeforeOrderTime = (input) => {
      const selectedTime = Number(input.replace("-", ""));
      const orderTime = Number(
        getTime(
          pharmacyOrderApiResource.getData().order_data.local_calibration
        ).replace(":", "")
      );
      return selectedTime <= orderTime;
    };

    const acceptOrderPayload = reactive({
      courier_needed: false,
      pickup_time: "",
    });

    const pharmacyRules = {
      pickup_time: {
        requiredIf: helpers.withMessage(
          "A pickup time is required",
          requiredIf(() => acceptOrderPayload.courier_needed)
        ),
        pickupTimeBeforeOrderTime: helpers.withMessage(
          "The pickup time must be before the order time",
          pickupTimeBeforeOrderTime
        ),
      },
    };

    const pharmacyModel = useVuelidate(pharmacyRules, acceptOrderPayload, {
      $lazy: true,
    });

    const pickupTime = computed(() => {
      if (acceptOrderPayload.pickup_time.length > 0) {
        return displayTime(acceptOrderPayload.pickup_time);
      }
    });

    const setPickupTime = (model, time) => {
      model.pickup_time.$model = setTime(time);
    };

    const postAcceptOrder = () => {
      const payload = {
        modification_time: modificationTime.value,
      };

      if (isPharmacyOrder.value) {
        payload["courier_needed"] = acceptOrderPayload.courier_needed;
        payload["pickup_time"] = acceptOrderPayload.courier_needed
          ? calibrationDate(
              getDate(
                pharmacyOrderApiResource.getData().order_data.local_calibration
              ),
              acceptOrderPayload.pickup_time,
              pharmacyOrderApiResource.getData().active_pharmacy.timezone
            )
          : null;
      }
      return axiosInstance.post(
        buildBaseUrl({
          apiModule: "sparrowManagerApi",
          url: `sparrow/${props.sparrowId}/accept`,
        }),
        payload
      );
    };
    const acceptOrderApiResource = new ApiResource(
      undefined,
      postAcceptOrder,
      "Successfully accepted the order",
      "There was an error accepting the order."
    );

    const acceptOrder = async () => {
      if (isPharmacyOrder.value) {
        if (await pharmacyModel.value.$validate()) {
          await acceptOrderApiResource.fetchResource();
          routeHome();
        }
      } else {
        await acceptOrderApiResource.fetchResource();
        routeHome();
      }
    };

    const openRejectModal = ref(false);
    const postRejectOrder = () =>
      axiosInstance.post(
        buildBaseUrl({
          apiModule: "sparrowManagerApi",
          url: `sparrow/${props.sparrowId}/reject`,
        }),
        {
          modification_time: modificationTime.value,
          cancellation_reason:
            cancellationReason.cancellation_reason === "Other"
              ? cancellationReason.other
              : cancellationReason.cancellation_reason,
        }
      );
    const rejectOrderApiResource = new ApiResource(
      undefined,
      postRejectOrder,
      "Successfully rejected/canceled the order",
      "There was an error rejecting/canceling the order."
    );

    const reasonPermissionsStudyAdmin = ref(false);
    const reasonPermissionsDoseOrderAdmin = ref(false);
    const reasonPermissionsStudySiteUser = ref(false);

    const getReasonPermissionsRequestStudyAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "StudyAdmin",
        email: user.email,
        resource: `/study/${
          sparrowApiResource.getData()?.additional_data?.study_id ?? ""
        }/studysite/${sparrowApiResource.getData()?.entity_id ?? ""}`,
      });

    const reasonPermissionsApiResourceStudyAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestStudyAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestDoseOrderAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "DoseOrderAdmin",
        email: user.email,
        resource: `/study/${
          sparrowApiResource.getData()?.additional_data?.study_id ?? ""
        }/studysite/${sparrowApiResource.getData()?.entity_id ?? ""}`,
      });

    const reasonPermissionsApiResourceDoseOrderAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestDoseOrderAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestStudySiteUser = () =>
      store.dispatch("users/hasRole", {
        role: "StudySiteUser",
        email: user.email,
        resource: `/study/${
          sparrowApiResource.getData()?.additional_data?.study_id ?? ""
        }/studysite/${sparrowApiResource.getData()?.entity_id ?? ""}`,
      });

    const reasonPermissionsApiResourceStudySiteUser = new ApiResource(
      undefined,
      getReasonPermissionsRequestStudySiteUser,
      undefined,
      undefined
    );

    const getSiteReasonPermissions = async () => {
      const responses = await Promise.all([
        reasonPermissionsApiResourceStudyAdmin.fetchResource(),
        reasonPermissionsApiResourceDoseOrderAdmin.fetchResource(),
        reasonPermissionsApiResourceStudySiteUser.fetchResource(),
      ]);
      reasonPermissionsStudyAdmin.value = responses[0];
      reasonPermissionsDoseOrderAdmin.value = responses[1];
      reasonPermissionsStudySiteUser.value = responses[2];
    };

    const reasonPermissionsPharmacySystemAdmin = ref(false);
    const reasonPermissionsPharmacyOrderAdmin = ref(false);
    const reasonPermissionsPharmacyTechnician = ref(false);

    const getReasonPermissionsRequestPharmacySystemAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "PharmacySystemAdmin",
        email: user.email,
        resource: `/pharmacy/${sparrowApiResource.getData()?.entity_id ?? ""}`,
      });

    const reasonPermissionsApiResourcePharmacySystemAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestPharmacySystemAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestPharmacyOrderAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "PharmacyOrderAdmin",
        email: user.email,
        resource: `/pharmacy/${sparrowApiResource.getData()?.entity_id ?? ""}`,
      });

    const reasonPermissionsApiResourcePharmacyOrderAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestPharmacyOrderAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestPharmacyTechnician = () =>
      store.dispatch("users/hasRole", {
        role: "PharmacyTechnician",
        email: user.email,
        resource: `/pharmacy/${sparrowApiResource.getData()?.entity_id ?? ""}`,
      });

    const reasonPermissionsApiResourcePharmacyTechnician = new ApiResource(
      undefined,
      getReasonPermissionsRequestPharmacyTechnician,
      undefined,
      undefined
    );
    const getPharmacyReasonPermissions = async () => {
      const responses = await Promise.all([
        reasonPermissionsApiResourcePharmacySystemAdmin.fetchResource(),
        reasonPermissionsApiResourcePharmacyOrderAdmin.fetchResource(),
        reasonPermissionsApiResourcePharmacyTechnician.fetchResource(),
      ]);
      reasonPermissionsPharmacySystemAdmin.value = responses[0];
      reasonPermissionsPharmacyOrderAdmin.value = responses[1];
      reasonPermissionsPharmacyTechnician.value = responses[2];
    };

    const cancellationReasons = computed(() => {
      if (isSiteOrder.value) {
        if (
          reasonPermissionsStudyAdmin.value ||
          reasonPermissionsDoseOrderAdmin.value
        ) {
          return orderCancellationReasons["StudyAdmin"];
        }
        if (reasonPermissionsStudySiteUser.value) {
          return orderCancellationReasons["StudySiteUser"];
        }
      }
      if (isPharmacyOrder.value) {
        if (
          reasonPermissionsPharmacySystemAdmin.value ||
          reasonPermissionsPharmacyOrderAdmin.value
        ) {
          return orderCancellationReasons["PharmacySystemAdmin"];
        }
        if (reasonPermissionsPharmacyTechnician.value) {
          return orderCancellationReasons["PharmacyTechnician"];
        }
      }
      return [];
    });

    const cancellationReason = reactive({
      cancellation_reason: "",
      other: "",
    });

    const rules = {
      cancellation_reason: {
        required: helpers.withMessage(
          "A cancellation reason is required",
          required
        ),
      },
      other: {
        requiredIf: helpers.withMessage(
          "A reason is required",
          requiredIf(() => cancellationReason.cancellation_reason === "Other")
        ),
        minLength: minLength(1),
      },
    };

    const model = useVuelidate(rules, cancellationReason, { $lazy: true });

    const rejectOrder = async () => {
      if (await model.value.$validate()) {
        await rejectOrderApiResource.fetchResource();
        routeHome();
      }
    };

    const openSuggestDateTimeModal = ref(false);

    const displayAddress = (site) => {
      return `${site.address}, ${site.city}, ${site.state} ${site.postal_code}, ${site.country}`;
    };

    const homeRoute = computed(() => store.state.activeRoute.homeRoute);

    const routeHome = () => router.push(homeRoute.value);

    const isSiteOrder = computed(
      () =>
        sparrowApiResource.getData() &&
        sparrowApiResource.getData().access === "Site"
    );
    const isPharmacyOrder = computed(
      () =>
        sparrowApiResource.getData() &&
        sparrowApiResource.getData().access === "Pharmacy"
    );

    const initLoading = computed(
      () =>
        sparrowApiResource.getLoading() ||
        studySiteOrderApiResource.getLoading() ||
        pharmacyOrderApiResource.getLoading()
    );

    const formLoading = computed(
      () =>
        acceptOrderApiResource.getLoading() ||
        rejectOrderApiResource.getLoading() ||
        studySiteApiResource.getLoading() ||
        pharmacyApiResource.getLoading() ||
        reasonPermissionsApiResourceStudyAdmin.getLoading() ||
        reasonPermissionsApiResourceDoseOrderAdmin.getLoading() ||
        reasonPermissionsApiResourceStudySiteUser.getLoading()
    );

    const openModal = ref(true);

    onMounted(async () => {
      await sparrowApiResource.fetchResource();
      if (isPharmacyOrder.value) {
        await pharmacyOrderApiResource.fetchResource();
        pharmacyApiResource.fetchResource();
        getPharmacyReasonPermissions();
      }
      if (isSiteOrder.value) {
        studySiteOrderApiResource.fetchResource();
        studySiteApiResource.fetchResource();
        getSiteReasonPermissions();
      }
    });

    return {
      formLoading,
      initLoading,
      studySiteOrderApiResource,
      pharmacyOrderApiResource,
      sparrowApiResource,
      isSiteOrder,
      studySiteOrder,
      isPharmacyOrder,
      openModal,
      rejectOrder,
      acceptOrder,
      cancellationReason,
      cancellationReasons,
      openRejectModal,
      model,
      openSuggestDateTimeModal,
      routeHome,
      getDate,
      getTime,
      siteOrderNewDate,
      siteOrderNewTime,
      pharmacyApiResource,
      studySiteApiResource,
      acceptOrderPayload,
      pharmacyModel,
      pickupTime,
      setPickupTime,
      getAmountString,
      displayAddress,
    };
  },
};
</script>
