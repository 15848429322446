<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="new_study_site_users" class="title"
        >{{ isEditing ? "Remove" : "Add" }} Study Site Users</label
      >
      <MultiSelect
        v-if="!isEditing"
        id="new_study_site_users"
        v-model="newUsers"
        :filter="true"
        :options="availableNewUsers"
        option-label="display_name"
        append-to="body"
      />
      <MultiSelect
        v-else
        id="new_study_site_users"
        v-model="removeUsers"
        :filter="true"
        :options="usersToRemove"
        option-label="display_name"
        append-to="body"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import MultiSelect from "primevue/multiselect";

import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "AddEditStudySiteUsers",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
    MultiSelect,
  },
  props: {
    selectedStudy: {
      type: Object,
      default: () => ({}),
    },
    selectedStudySite: {
      type: Object,
      default: () => ({}),
    },
    currentUsers: {
      type: Object,
      default: () => ({}),
    },
    allUsers: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newUsers = ref([]);
    const removeUsers = ref([]);

    const addStudySiteUser = (newUser) =>
      store.dispatch("studySites/addStudySiteUser", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: props.selectedStudySite.minervaId,
        payload: {
          study: props.selectedStudy.minervaId,
          site: props.selectedStudySite.site,
          studysite: props.selectedStudySite.minervaId,
          minervauser: newUser.minervaId,
        },
      });

    const addStudySiteUserApiResource = new ApiResource(
      undefined,
      addStudySiteUser,
      "Successfully added a user.",
      "There was an error adding a user."
    );

    const deleteStudySiteUser = (deleteUser) =>
      store.dispatch("studySites/deleteStudySiteUser", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: props.selectedStudySite.minervaId,
        payload: deleteUser,
      });

    const deleteStudySiteUserApiResource = new ApiResource(
      undefined,
      deleteStudySiteUser,
      "Successfully deleted a user.",
      "There was an error deleting a user."
    );

    const availableNewUsers = computed(() => {
      if (
        Object.prototype.hasOwnProperty.call(
          props.currentUsers,
          props.selectedStudySite.minervaId
        )
      ) {
        const currentUserIds = props.currentUsers[
          props.selectedStudySite.minervaId
        ].map((user) => user.minervauser);
        return props.allUsers.filter(
          (user) => !currentUserIds.includes(user.minervaId)
        );
      }
      return props.allUsers;
    });

    const usersToRemove = computed(() => {
      if (
        Object.prototype.hasOwnProperty.call(
          props.currentUsers,
          props.selectedStudySite.minervaId
        )
      ) {
        return props.currentUsers[props.selectedStudySite.minervaId].map(
          (user) => {
            const thisUser = props.allUsers.find(
              (allUser) => allUser.minervaId === user.minervauser
            );
            return {
              ...user,
              display_name: thisUser.display_name,
            };
          }
        );
      }
      return [];
    });

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      let promises;
      if (!props.isEditing) {
        promises = newUsers.value.map(async (newUser) => {
          await addStudySiteUserApiResource.fetchResource(newUser);
        });
      } else {
        promises = removeUsers.value.map(async (removeUser) => {
          await deleteStudySiteUserApiResource.fetchResource(removeUser);
        });
      }
      await Promise.all(promises);
      closeForm();
    };

    const formLoading = computed(
      () =>
        addStudySiteUserApiResource.getLoading() ||
        deleteStudySiteUserApiResource.getLoading()
    );

    return {
      closeForm,
      formLoading,
      handleSave,
      availableNewUsers,
      newUsers,
      removeUsers,
      usersToRemove,
    };
  },
};
</script>
