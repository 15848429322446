<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="group_name" class="title">Group Name</label>
      <InputText
        id="group_name"
        v-model="newGroupModel.name.$model"
        :class="{ 'p-error': newGroupModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newGroupModel.name" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newGroupModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditGroup",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
  },
  props: {
    selectedGroup: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newGroup = reactive({
      name: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage("A group name is required", required),
      },
    };

    const newGroupModel = useVuelidate(rules, newGroup, { $lazy: true });

    const createGroup = () => store.dispatch("groups/createGroup", newGroup);

    const createGroupApiResource = new ApiResource(
      undefined,
      createGroup,
      "Successfully created new group",
      "There was an error creating the new group."
    );

    const updateGroup = () => store.dispatch("groups/updateGroup", newGroup);

    const updateGroupApiResource = new ApiResource(
      undefined,
      updateGroup,
      "Successfully updated the group.",
      "There was an error updating the group."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newGroupModel.value.$validate()) {
        props.isEditing
          ? await updateGroupApiResource.fetchResource()
          : await createGroupApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createGroupApiResource.getLoading() ||
        updateGroupApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedGroup)) {
          newGroup[key] = value;
        }
      }
    });

    return {
      closeForm,
      newGroup,
      newGroupModel,
      formLoading,
      handleSave,
    };
  },
};
</script>
