<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="reservation_id" class="title">Reservation ID</label>
      <InputText
        id="reservation_id"
        v-model="model.reservation_id.$model"
        :class="{ 'p-error': model.reservation_id.$invalid }"
      />
      <ErrorMessages :model-property="model.reservation_id" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="date" class="title">Date</label>
      <Calendar
        id="date"
        :model-value="calendarDate"
        date-format="yy-mm-dd"
        :disabled-days="[0]"
        :min-date="new Date()"
        :manual-input="false"
        append-to="body"
        :class="{ 'p-error': model.date.$invalid }"
        @update:modelValue="handleDateInput(model, $event)"
      >
      </Calendar>
      <ErrorMessages :model-property="model.date" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="time" class="title">Time</label>
      <Calendar
        id="time"
        :model-value="displayTime"
        :time-only="true"
        append-to="body"
        :class="{ 'p-error': model.time.$invalid }"
        @update:model-value="setOrderTime(model, $event)"
      />
      <ErrorMessages :model-property="model.time" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import Calendar from "primevue/calendar";
import Divider from "primevue/divider";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { reactive, computed, inject } from "vue";
import { useStore } from "vuex";
import { buildDateTimeHandlers } from "@/components/Orders/orderUtils/dateTimeHandlers";
import { calibrationDate } from "@/helpers/timeHelpers";
import { DateTime } from "luxon";

export default {
  name: "AddEditStudy",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
    Calendar,
    ErrorMessages,
  },
  props: {
    studySite: {
      type: Object,
      default: () => ({}),
    },
    selectedOrderRequest: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newOrder = reactive({
      study: props.studySite.study,
      site: props.studySite.site,
      studysite: props.studySite.minervaId,
      studysiteproduct: props.selectedOrderRequest.studysiteproduct,
      studysitesubject: props.selectedOrderRequest.studysitesubject,
      studysiteproductnetwork:
        props.selectedOrderRequest.studysiteproductnetwork,
      calibration: "",
      date: props.selectedOrderRequest.order_date,
      time: props.selectedOrderRequest.order_time,
      amount: props.selectedOrderRequest.amount,
      reservation_id: "",
    });

    const rules = {
      reservation_id: {
        required: helpers.withMessage("A reservation ID is required", required),
      },
      date: {
        required: helpers.withMessage("A date is required", required),
      },
      time: {
        required: helpers.withMessage("A time is required", required),
      },
    };

    const model = useVuelidate(rules, newOrder, { $lazy: true });

    const updateOrderRequest = () =>
      store.dispatch("orderRequests/updateOrderRequestStatus", {
        studyId: props.selectedOrderRequest.study,
        studySiteId: props.studySite.minervaId,
        payload: {
          status: "Completed",
          minervaId: props.selectedOrderRequest.minervaId,
        },
      });

    const updateOrderRequestApiResource = new ApiResource(
      undefined,
      updateOrderRequest,
      "Successfully updated the order request.",
      "There was an error updating the order request."
    );

    const createOrderRequest = () =>
      store.dispatch("orderManagement/createStudySiteOrder", {
        studyId: props.studySite.study,
        studySiteId: props.studySite.minervaId,
        order: {
          ...newOrder,
          calibration: calibrationDate(
            newOrder.date,
            newOrder.time,
            props.studySite.timezone
          ),
        },
      });

    const createOrderApiResource = new ApiResource(
      undefined,
      createOrderRequest,
      "Successfully created new order.",
      "There was an error creating your new order."
    );

    const {
      calendarDate,
      handleDateInput,
      setOrderTime,
      displayTime,
    } = buildDateTimeHandlers(store, props, ApiResource, newOrder);

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await model.value.$validate()) {
        let hasError = false;
        try {
          await createOrderApiResource.fetchResource();
        } catch (error) {
          hasError = true;
        }
        if (!hasError) await updateOrderRequestApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        updateOrderRequestApiResource.getLoading() ||
        createOrderApiResource.getLoading()
    );

    return {
      closeForm,
      formLoading,
      handleSave,
      model,
      calendarDate,
      handleDateInput,
      displayTime,
      setOrderTime,
      DateTime,
    };
  },
};
</script>
