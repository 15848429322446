<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-4">
      <FormDataView title="Subject" :data="selectedSubjectName" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Product" :data="selectedProductName" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Amount" :data="getAmountString(order.amount)" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView title="Network" :data="selectedNetworkName" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="order_request_date" class="title">Date</label>
      <Calendar
        id="order_request_date"
        :model-value="calendarDate"
        date-format="yy-mm-dd"
        :disabled-days="[0]"
        :min-date="new Date()"
        :manual-input="false"
        append-to="body"
        placeholder="Select a date"
        :class="{ 'p-error': model.order_date.$invalid }"
        @update:modelValue="handleDateInput(model, $event)"
      >
      </Calendar>
      <ErrorMessages :model-property="model.order_date" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="order_request_time" class="title">Time</label>
      <Calendar
        id="order_request_time"
        :model-value="displayTime"
        :time-only="true"
        placeholder="Select a time"
        append-to="body"
        :class="{ 'p-error': model.order_time.$invalid }"
        @update:model-value="setOrderTime(model, $event)"
      />
      <ErrorMessages :model-property="model.order_time" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="order_request_notes" class="title">Notes</label>
      <Textarea
        v-model="newOrderRequest.notes"
        label="notes"
        :auto-resize="true"
        rows="3"
        cols="60"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="createOrder"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormDataView from "@/components/FormComponents/FormDataView";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import Calendar from "primevue/calendar";
import Divider from "primevue/divider";
import Textarea from "primevue/textarea";
import { defaultTimeSlots } from "@/helpers/defaultTimeSlots";
import { DateTime } from "luxon";
import { reactive, computed, inject, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useStore } from "vuex";
import { getAmountString } from "@/helpers/units";
import { setTZ, getTime, getDate } from "@/helpers/timeHelpers";

export default {
  name: "AddOrderRequest",
  components: {
    FormWrapper,
    FormButtonCol,
    FormCol,
    FormDataView,
    Calendar,
    CancelConfirmButtons,
    ErrorMessages,
    Divider,
    Textarea,
  },
  props: {
    studySite: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    selectedSubjectName: {
      type: String,
      default: "",
    },
    selectedProductName: {
      type: String,
      default: "",
    },
    selectedNetworkName: {
      type: String,
      default: "",
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();
    let earliestOrderTime;

    const newOrderRequest = reactive({
      study: props.studySite.study,
      site: props.studySite.site,
      studysite: props.studySite.minervaId,
      studysiteproduct: props.order.studysiteproduct,
      studysitesubject: props.order.studysitesubject,
      studysiteproductnetwork: props.order.studysiteproductnetwork,
      amount: props.order.amount,
      order_date: "",
      order_time: "",
      notes: "",
    });

    const timeWithinAvailableRange = (input) => {
      const selectedTime = Number(input.replace("-", ""));
      const timesToValidateAgainst = defaultTimeSlots;
      const minTime = Number(timesToValidateAgainst.minTime.replace(":", ""));
      const maxTime = Number(timesToValidateAgainst.maxTime.replace(":", ""));
      return selectedTime >= minTime && selectedTime <= maxTime;
    };

    const timeAfterLatestDate = (input) => {
      if (input) {
        if (newOrderRequest.order_date.length > 0) {
          const localDate = getDate(
            setTZ(DateTime.local().toISO(), props.studySite.timezone)
          );
          const isSameDay = newOrderRequest.order_date === localDate;

          if (isSameDay) {
            const selectedTime = Number(input.replace("-", ""));
            const minTime = Number(getTime(earliestOrderTime).replace(":", ""));
            return selectedTime >= minTime;
          }
        }
      }
      return true;
    };

    const rules = {
      order_date: {
        required: helpers.withMessage("An order date is required", required),
      },
      order_time: {
        required: helpers.withMessage("An order time is required", required),
        timeWithinAvailableRange: helpers.withMessage(
          "The selected time must be within the available range",
          timeWithinAvailableRange
        ),
        timeAfterLatestDate: helpers.withMessage(
          "The time must be after 30 minutes from now",
          timeAfterLatestDate
        ),
      },
    };

    const model = useVuelidate(rules, newOrderRequest, { $lazy: true });

    const calendarDate = computed(() => {
      return newOrderRequest.order_date === ""
        ? ""
        : new Date(
            DateTime.fromISO(newOrderRequest.order_date)
              .plus({ hours: 12 })
              .toISO()
          );
    });

    const displayTime = computed(() => {
      if (newOrderRequest.order_time.length > 0) {
        const hour = newOrderRequest.order_time.split("-")[0];
        const minute = newOrderRequest.order_time.split("-")[1];
        return new Date(DateTime.fromObject({ hour, minute }).toISO());
      }
    });

    const createOrderRequest = () =>
      store.dispatch("orderRequests/createOrderRequest", {
        studyId: props.studySite.study,
        studySiteId: props.studySite.minervaId,
        payload: newOrderRequest,
      });
    const createOrderRequestApiResource = new ApiResource(
      undefined,
      createOrderRequest,
      "Successfully created your order request.",
      "There was an error creating your order request."
    );
    const closeForm = () => emit("close-form");

    const createOrder = async () => {
      if (await model.value.$validate()) {
        await createOrderRequestApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(() =>
      createOrderRequestApiResource.getLoading()
    );

    const handleDateInput = (model, date) => {
      model.order_date.$model = DateTime.fromISO(
        new Date(date).toISOString()
      ).toFormat("yyyy-LL-dd");
    };

    const setOrderTime = (model, time) => {
      model.order_time.$model = DateTime.fromISO(
        new Date(time).toISOString()
      ).toFormat("HH-mm");
    };

    onMounted(() => {
      earliestOrderTime = setTZ(
        DateTime.local().toISO(),
        props.studySite.timezone
      ).plus({
        minutes: 30,
      });
    });

    return {
      newOrderRequest,
      model,
      calendarDate,
      displayTime,
      closeForm,
      createOrder,
      formLoading,
      handleDateInput,
      setOrderTime,
      getAmountString,
    };
  },
};
</script>
