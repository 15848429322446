<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="name" class="title">Name</label>
      <InputText
        id="name"
        v-model="model.name.$model"
        :class="{ 'p-error': model.name.$invalid }"
      />
      <ErrorMessages :model-property="model.name" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="show_study_info"
          v-model="showStudyInfo"
          :binary="true"
          class="p-mb-1"
        />
        <label for="show_study_info" class="checkbox-text p-ml-2 p-mb-1"
          >Show Study Info? {{ newPharmacy.show_study_info }}</label
        >
      </div>
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="address" class="title">Address</label>
      <InputText
        id="address"
        v-model="model.address.$model"
        :class="{ 'p-error': model.address.$invalid }"
      />
      <ErrorMessages :model-property="model.address" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="city" class="title">City</label>
      <InputText
        id="city"
        v-model="model.city.$model"
        :class="{ 'p-error': model.city.$invalid }"
      />
      <ErrorMessages :model-property="model.city" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="state" class="title">State</label>
      <InputText
        id="state"
        v-model="model.state.$model"
        :class="{ 'p-error': model.state.$invalid }"
      />
      <ErrorMessages :model-property="model.state" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="country" class="title">Country</label>
      <InputText
        id="country"
        v-model="model.country.$model"
        :class="{ 'p-error': model.country.$invalid }"
      />
      <ErrorMessages :model-property="model.country" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="postal_code" class="title">Postal Code</label>
      <InputText
        id="postal_code"
        v-model="model.postal_code.$model"
        :class="{ 'p-error': model.postal_code.$invalid }"
      />
      <ErrorMessages :model-property="model.postal_code" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="region" class="title">Region</label>
      <Dropdown
        id="region"
        v-model="model.region.$model"
        :options="regionList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        :class="{ 'p-error': model.region.$invalid }"
      />
      <ErrorMessages :model-property="model.region" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="timezone" class="title">Time Zone</label>
      <AutoComplete
        id="timezone"
        v-model="model.timezone.$model"
        :suggestions="filteredTimeZones"
        :dropdown="true"
        dropdown-mode="current"
        force-selection
        append-to="body"
        :class="{ 'p-error': model.timezone.$invalid }"
        @complete="setTimeZoneSearch($event)"
      />
      <ErrorMessages :model-property="model.timezone" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";

import { ref, reactive, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { rawTimeZones } from "@vvo/tzdb";
import { regionList, validRegion } from "@/helpers/regionList";

export default {
  name: "AddEditPharmacy",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    AutoComplete,
    Checkbox,
  },
  props: {
    selectedPharmacy: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPharmacy = reactive({
      name: "",
      address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      region: "",
      timezone: "",
      show_study_info: "NO",
    });

    const rules = {
      name: { required: helpers.withMessage("A name is required", required) },
      address: {
        required: helpers.withMessage("An address is required", required),
      },
      city: { required: helpers.withMessage("A city is required", required) },
      state: { required: helpers.withMessage("A state is required", required) },
      postal_code: {
        required: helpers.withMessage("A postal code is required", required),
      },
      country: {
        required: helpers.withMessage("A country is required", required),
      },
      region: {
        required: helpers.withMessage("A region is required", required),
        validRegion: helpers.withMessage(
          "Please select a valid region",
          validRegion
        ),
      },
      timezone: {
        required: helpers.withMessage("A timezone is required", required),
      },
    };

    const model = useVuelidate(rules, newPharmacy, { $lazy: true });

    const timeZonesByName = rawTimeZones.map((zone) => zone.name).sort();
    const filteredTimeZones = ref([]);

    const setTimeZoneSearch = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTimeZones.value = [...timeZonesByName];
        } else {
          filteredTimeZones.value = timeZonesByName.filter((timezone) =>
            timezone.toLowerCase().includes(event.query.toLowerCase())
          );
        }
      }, 250);
    };

    const showStudyInfo = ref(false);

    watch(
      () => showStudyInfo.value,
      () => (newPharmacy.show_study_info = showStudyInfo.value ? "YES" : "NO")
    );

    const createPharmacy = () =>
      store.dispatch("pharmacies/createPharmacy", newPharmacy);

    const createPharmacyApiResource = new ApiResource(
      undefined,
      createPharmacy,
      "Successfully created new pharmacy",
      "There was an error creating the new pharmacy."
    );

    const updatePharmacy = () =>
      store.dispatch("pharmacies/updatePharmacy", newPharmacy);

    const updatePharmacyApiResource = new ApiResource(
      undefined,
      updatePharmacy,
      "Successfully updated the pharmacy.",
      "There was an error updating the pharmacy."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await model.value.$validate()) {
        props.isEditing
          ? await updatePharmacyApiResource.fetchResource()
          : await createPharmacyApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createPharmacyApiResource.getLoading() ||
        updatePharmacyApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedPharmacy)) {
          if (value && value.length > 0) newPharmacy[key] = value;
        }
        if (props.selectedPharmacy.show_study_info === "YES") {
          showStudyInfo.value = true;
        }
      }
    });

    return {
      newPharmacy,
      closeForm,
      model,
      formLoading,
      handleSave,
      regionList,
      filteredTimeZones,
      setTimeZoneSearch,
      showStudyInfo,
    };
  },
};
</script>
