import { DateTime } from "luxon";

export const calibrationDate = (date, time, tz) => {
  const year = date.split("-")[0];
  const month = date.split("-")[1];
  const day = date.split("-")[2];
  const hour = time.split("-")[0];
  const minute = time.split("-")[1];
  return DateTime.fromObject({
    year,
    month,
    day,
    hour,
    minute,
    zone: tz,
  });
};

export const getDate = (date) => {
  return DateTime.fromISO(date, { setZone: true }).toFormat("yyyy-LL-dd");
};

export const getTime = (date) => {
  return DateTime.fromISO(date, { setZone: true }).toFormat("HH:mm");
};

export const setTZ = (date, tz) => {
  return DateTime.fromISO(date).setZone(tz);
};

export const setTime = (time) => {
  return DateTime.fromISO(new Date(time).toISOString()).toFormat("HH-mm");
};

export const setTimeFromLocalCalibration = (date) => {
  return DateTime.fromISO(date, {
    setZone: true,
  }).toFormat("HH-mm");
};

export const displayTime = (time) => {
  const hour = time.split("-")[0];
  const minute = time.split("-")[1];
  return new Date(DateTime.fromObject({ hour, minute }).toISO());
};

export const displayTimeString = (timeString) => {
  return timeString.replace("-", ":");
};
