<template>
  <AppDataList
    title="Operating Organizations"
    add-title="perating organization"
    display-prop="display_name"
    :data="operatingOrganizations"
    :loading="loadingOperating"
    @delete-entity="confirmDeleteOperatingOrg($event, admin)"
  >
    <template #addModalContent="{ closeAddModal }">
      <FormWrapper :loading="loadingOperating">
        <FormCol col-width="p-md-6">
          <label for="operating_org" class="title"
            >New Operating Organization</label
          >
          <Dropdown
            id="operating_org"
            v-model="operatingOrgModel.newOperatingOrg.$model"
            :options="availableOperatingOrgs"
            option-label="display_name"
            option-value="minervaId"
            placeholder="Select a new operating organization"
            :class="{ 'p-error': operatingOrgModel.newOperatingOrg.$invalid }"
            append-to="body"
          />
          <ErrorMessages :model-property="operatingOrgModel.newOperatingOrg" />
        </FormCol>
        <Divider />
        <FormButtonCol>
          <CancelConfirmButtons
            :disabled="loadingOperating || operatingOrgModel.$invalid"
            cancel-text="CLOSE"
            confirm-text="SUBMIT"
            @cancelled="closeAddModal()"
            @confirmed="handleSaveOperatingOrg(closeAddModal)"
          />
        </FormButtonCol>
      </FormWrapper>
    </template>
  </AppDataList>
  <AppDataList
    title="Sponsor Organizations"
    add-title="sponsor organization"
    display-prop="display_name"
    :data="sponsorOrganizations"
    :loading="loadingSponsor"
    @delete-entity="confirmDeleteSponsorOrg($event, admin)"
  >
    <template #addModalContent="{ closeAddModal }">
      <FormWrapper :loading="loadingSponsor">
        <FormCol col-width="p-md-6">
          <label for="sponsor_org" class="title"
            >New Sponsor Organization</label
          >
          <Dropdown
            id="sponsor_org"
            v-model="sponsorOrgModel.newSponsorOrg.$model"
            :options="availableSponsorOrgs"
            option-label="display_name"
            option-value="minervaId"
            placeholder="Select a new sponsor organization"
            :class="{ 'p-error': sponsorOrgModel.newSponsorOrg.$invalid }"
            append-to="body"
          />
          <ErrorMessages :model-property="sponsorOrgModel.newSponsorOrg" />
        </FormCol>
        <Divider />
        <FormButtonCol>
          <CancelConfirmButtons
            :disabled="loadingSponsor || sponsorOrgModel.$invalid"
            cancel-text="CLOSE"
            confirm-text="SUBMIT"
            @cancelled="closeAddModal()"
            @confirmed="handleSaveSponsorOrg(closeAddModal)"
          />
        </FormButtonCol>
      </FormWrapper>
    </template>
  </AppDataList>
  <AppDataList
    title="Observer Organizations"
    add-title="observer organization"
    display-prop="display_name"
    :data="observerOrganizations"
    :loading="loadingObserver"
    @delete-entity="confirmDeleteObserverOrg($event, admin)"
  >
    <template #addModalContent="{ closeAddModal }">
      <FormWrapper :loading="loadingObserver">
        <FormCol col-width="p-md-6">
          <label for="observer_org" class="title"
            >New Observer Organization</label
          >
          <Dropdown
            id="observer_org"
            v-model="observerOrgModel.newObserverOrg.$model"
            :options="availableObserverOrgs"
            option-label="display_name"
            option-value="minervaId"
            placeholder="Select a new observer organization"
            :class="{ 'p-error': observerOrgModel.newObserverOrg.$invalid }"
            append-to="body"
          />
          <ErrorMessages :model-property="observerOrgModel.newObserverOrg" />
        </FormCol>
        <Divider />
        <FormButtonCol>
          <CancelConfirmButtons
            :disabled="loadingObserver || observerOrgModel.$invalid"
            cancel-text="CLOSE"
            confirm-text="SUBMIT"
            @cancelled="closeAddModal()"
            @confirmed="handleSaveObserverOrg(closeAddModal)"
          />
        </FormButtonCol>
      </FormWrapper>
    </template>
  </AppDataList>
</template>

<script>
import AppDataList from "@/components/AppDataList/AppDataList";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import { inject, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useConfirm";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "StudyOrderingAdmins",
  components: {
    AppDataList,
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Dropdown,
    Divider,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const confirm = useConfirm();

    const allOrgs = () => store.state.studyOrganizations.allOrgs;
    const fetchAllOrgs = () =>
      store.dispatch("studyOrganizations/fetchAllOrganizations");

    const allOrgsApiResource = new ApiResource(
      allOrgs,
      fetchAllOrgs,
      undefined,
      "There was an error fetching the study organizations."
    );

    // operating organizations
    const operatingOrgs = () =>
      store.state.studyOrganizations.studyOperators[
        props.selectedStudy.minervaId
      ];
    const fetchOperatingOrgs = () =>
      store.dispatch("studyOrganizations/fetchStudyOperators", {
        studyId: props.selectedStudy.minervaId,
      });

    const operatingOrgsApiResource = new ApiResource(
      operatingOrgs,
      fetchOperatingOrgs,
      undefined,
      "There was an error fetching the study operating organizations."
    );

    const operatingOrganizations = computed(() => {
      if (
        operatingOrgsApiResource.getData() &&
        allOrgsApiResource.getData() &&
        operatingOrgsApiResource.getData().length > 0 &&
        allOrgsApiResource.getData().length > 0
      ) {
        return operatingOrgsApiResource.getData().map((studyOrg) => {
          const orgObject = allOrgsApiResource
            .getData()
            .find((org) => org.minervaId === studyOrg.organization);

          return {
            display_name: orgObject.display_name,
            minervaId: studyOrg.minervaId,
          };
        });
      }
      return [];
    });

    const availableOperatingOrgs = computed(() => {
      const currentOrgs = operatingOrgsApiResource
        .getData()
        .map((org) => org.organization);

      return allOrgsApiResource
        .getData()
        .filter((org) => !currentOrgs.includes(org.minervaId));
    });

    const newOperatingOrg = ref("");

    const operatorRules = {
      newOperatingOrg: {
        required: helpers.withMessage(
          "An operating organization is required",
          required
        ),
      },
    };

    const operatingOrgModel = useVuelidate(
      operatorRules,
      { newOperatingOrg },
      { $lazy: true }
    );

    const appOperatingOrg = (orgId) =>
      store.dispatch("studyOrganizations/addStudyOperator", {
        studyId: props.selectedStudy.minervaId,
        payload: {
          study: props.selectedStudy.minervaId,
          organization: orgId,
        },
      });

    const addOperatingOrgApiResource = new ApiResource(
      undefined,
      appOperatingOrg,
      "Successfully added operating organization.",
      "There was an error adding the operating organization."
    );

    const handleSaveOperatingOrg = async (close) => {
      if (await operatingOrgModel.value.$validate()) {
        await addOperatingOrgApiResource.fetchResource(newOperatingOrg.value);
        close();
      }
    };

    const deleteOperatingOrg = (payload) =>
      store.dispatch("studyOrganizations/deleteStudyOperator", {
        studyId: props.selectedStudy.minervaId,
        payload,
      });

    const deleteOperatingOrgApiResource = new ApiResource(
      undefined,
      deleteOperatingOrg,
      "Successfully removed operating organization.",
      "There was an error removing the operating organization."
    );

    const confirmDeleteOperatingOrg = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you wish to remove this operating organization?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await deleteOperatingOrgApiResource.fetchResource(event);
        },
        reject: () => {},
        rejectClass: "p-button-danger p-button-text",
      });
    };

    // sponsor organizations
    const sponsorOrgs = () =>
      store.state.studyOrganizations.studySponsors[
        props.selectedStudy.minervaId
      ];
    const fetchSponsorOrgs = () =>
      store.dispatch("studyOrganizations/fetchStudySponsors", {
        studyId: props.selectedStudy.minervaId,
      });

    const sponsorOrgsApiResource = new ApiResource(
      sponsorOrgs,
      fetchSponsorOrgs,
      undefined,
      "There was an error fetching the study sponsor organizations."
    );

    const sponsorOrganizations = computed(() => {
      if (
        sponsorOrgsApiResource.getData() &&
        allOrgsApiResource.getData() &&
        sponsorOrgsApiResource.getData().length > 0 &&
        allOrgsApiResource.getData().length > 0
      ) {
        return sponsorOrgsApiResource.getData().map((studyOrg) => {
          const orgObject = allOrgsApiResource
            .getData()
            .find((org) => org.minervaId === studyOrg.organization);

          return {
            display_name: orgObject.display_name,
            minervaId: studyOrg.minervaId,
          };
        });
      }
      return [];
    });

    const availableSponsorOrgs = computed(() => {
      const currentOrgs = sponsorOrgsApiResource
        .getData()
        .map((org) => org.organization);

      return allOrgsApiResource
        .getData()
        .filter((org) => !currentOrgs.includes(org.minervaId));
    });

    const newSponsorOrg = ref("");

    const sponsorRules = {
      newSponsorOrg: {
        required: helpers.withMessage(
          "A sponsor organization is required",
          required
        ),
      },
    };

    const sponsorOrgModel = useVuelidate(
      sponsorRules,
      { newSponsorOrg },
      { $lazy: true }
    );

    const appSponsorOrg = (orgId) =>
      store.dispatch("studyOrganizations/addStudySponsor", {
        studyId: props.selectedStudy.minervaId,
        payload: {
          study: props.selectedStudy.minervaId,
          organization: orgId,
        },
      });

    const addSponsorOrgApiResource = new ApiResource(
      undefined,
      appSponsorOrg,
      "Successfully added sponsor organization.",
      "There was an error adding the sponsor organization."
    );

    const handleSaveSponsorOrg = async (close) => {
      if (await sponsorOrgModel.value.$validate()) {
        await addSponsorOrgApiResource.fetchResource(newSponsorOrg.value);
        close();
      }
    };

    const deleteSponsorOrg = (payload) =>
      store.dispatch("studyOrganizations/deleteStudySponsor", {
        studyId: props.selectedStudy.minervaId,
        payload,
      });

    const deleteSponsorOrgApiResource = new ApiResource(
      undefined,
      deleteSponsorOrg,
      "Successfully removed sponsor organization.",
      "There was an error removing the sponsor organization."
    );

    const confirmDeleteSponsorOrg = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you wish to remove this sponsor organization?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await deleteSponsorOrgApiResource.fetchResource(event);
        },
        reject: () => {},
        rejectClass: "p-button-danger p-button-text",
      });
    };

    // observer organizations
    const observerOrgs = () =>
      store.state.studyOrganizations.studyObservers[
        props.selectedStudy.minervaId
      ];
    const fetchObserverOrgs = () =>
      store.dispatch("studyOrganizations/fetchStudyObservers", {
        studyId: props.selectedStudy.minervaId,
      });

    const observerOrgsApiResource = new ApiResource(
      observerOrgs,
      fetchObserverOrgs,
      undefined,
      "There was an error fetching the study observer organizations."
    );

    const observerOrganizations = computed(() => {
      if (
        observerOrgsApiResource.getData() &&
        allOrgsApiResource.getData() &&
        observerOrgsApiResource.getData().length > 0 &&
        allOrgsApiResource.getData().length > 0
      ) {
        return observerOrgsApiResource.getData().map((studyOrg) => {
          const orgObject = allOrgsApiResource
            .getData()
            .find((org) => org.minervaId === studyOrg.organization);

          return {
            display_name: orgObject.display_name,
            minervaId: studyOrg.minervaId,
          };
        });
      }
      return [];
    });

    const availableObserverOrgs = computed(() => {
      const currentOrgs = observerOrgsApiResource
        .getData()
        .map((org) => org.organization);

      return allOrgsApiResource
        .getData()
        .filter((org) => !currentOrgs.includes(org.minervaId));
    });

    const newObserverOrg = ref("");

    const observerRules = {
      newObserverOrg: {
        required: helpers.withMessage(
          "An observer organization is required",
          required
        ),
      },
    };

    const observerOrgModel = useVuelidate(
      observerRules,
      { newObserverOrg },
      { $lazy: true }
    );

    const appObserverOrg = (orgId) =>
      store.dispatch("studyOrganizations/addStudyObserver", {
        studyId: props.selectedStudy.minervaId,
        payload: {
          study: props.selectedStudy.minervaId,
          organization: orgId,
        },
      });

    const addObserverOrgApiResource = new ApiResource(
      undefined,
      appObserverOrg,
      "Successfully added observer organization.",
      "There was an error adding the observer organization."
    );

    const handleSaveObserverOrg = async (close) => {
      if (await observerOrgModel.value.$validate()) {
        await addObserverOrgApiResource.fetchResource(newObserverOrg.value);
        close();
      }
    };

    const deleteObserverOrg = (payload) =>
      store.dispatch("studyOrganizations/deleteStudyObserver", {
        studyId: props.selectedStudy.minervaId,
        payload,
      });

    const deleteObserverOrgApiResource = new ApiResource(
      undefined,
      deleteObserverOrg,
      "Successfully removed observer organization.",
      "There was an error removing the observer organization."
    );

    const confirmDeleteObserverOrg = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you wish to remove this observer organization?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await deleteObserverOrgApiResource.fetchResource(event);
        },
        reject: () => {},
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const loadingOperating = computed(
      () =>
        allOrgsApiResource.getLoading() ||
        operatingOrgsApiResource.getLoading() ||
        addOperatingOrgApiResource.getLoading() ||
        deleteOperatingOrgApiResource.getLoading()
    );

    const loadingSponsor = computed(
      () =>
        allOrgsApiResource.getLoading() ||
        sponsorOrgsApiResource.getLoading() ||
        addSponsorOrgApiResource.getLoading() ||
        deleteSponsorOrgApiResource.getLoading()
    );

    const loadingObserver = computed(
      () =>
        allOrgsApiResource.getLoading() ||
        observerOrgsApiResource.getLoading() ||
        addObserverOrgApiResource.getLoading() ||
        deleteObserverOrgApiResource.getLoading()
    );

    onMounted(() => {
      allOrgsApiResource.fetchResource();
      operatingOrgsApiResource.fetchResource();
      sponsorOrgsApiResource.fetchResource();
      observerOrgsApiResource.fetchResource();
    });

    return {
      loadingOperating,
      operatingOrganizations,
      availableOperatingOrgs,
      confirmDeleteOperatingOrg,
      handleSaveOperatingOrg,
      operatingOrgModel,
      loadingSponsor,
      confirmDeleteSponsorOrg,
      handleSaveSponsorOrg,
      availableSponsorOrgs,
      sponsorOrganizations,
      sponsorOrgModel,
      loadingObserver,
      confirmDeleteObserverOrg,
      handleSaveObserverOrg,
      availableObserverOrgs,
      observerOrganizations,
      observerOrgModel,
    };
  },
};
</script>
