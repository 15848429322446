import { ref, computed } from "vue";
import { DateTime } from "luxon";
import { defaultTimeSlots } from "@/helpers/defaultTimeSlots";
import { getTime, setTZ, getDate } from "@/helpers/timeHelpers";

export const buildDateTimeHandlers = (store, props, ApiResource, newOrder) => {
  const searchDateForProductionDates = ref("");
  const dateFormat = "yyyy-LL-dd";

  const setSearchDateForProductionDates = (inputMonth) => {
    const currentDate = DateTime.local();
    const currentMonth = Number(currentDate.toFormat("M"));
    const currentYear = Number(currentDate.toFormat("y"));
    const adjustedInputMonth = inputMonth.month + 1;

    if (
      (currentMonth === adjustedInputMonth &&
        currentYear === inputMonth.year) ||
      inputMonth.year < currentYear
    ) {
      searchDateForProductionDates.value = DateTime.local().toFormat(
        dateFormat
      );
    } else {
      searchDateForProductionDates.value = DateTime.fromObject({
        month: adjustedInputMonth,
        year: inputMonth.year,
      }).toFormat(dateFormat);
    }
  };

  const availableDateStorageKey = (networkId) =>
    `${props.studySite.minervaId}-${networkId}-${searchDateForProductionDates.value}`;

  const availableDates = (networkId) =>
    store.state.orderManagement.availableDates[
      availableDateStorageKey(networkId)
    ];

  const fetchProductionDates = ({
    network_implementation_uri,
    network_site_id,
    order_id,
    product_uuid,
    networkId,
  }) =>
    store.dispatch("orderManagement/fetchProductionDates", {
      availableDateStorageKey: availableDateStorageKey(networkId),
      params: {
        network_implementation_uri,
        network_site_id,
        product: product_uuid,
        network: newOrder.studysiteproductnetwork,
        amount: newOrder.amount,
        date: searchDateForProductionDates.value,
        order_id,
        additional_data: { studysite: props.studySite.minervaId },
      },
    });

  const productionDatesApiResource = new ApiResource(
    availableDates,
    fetchProductionDates,
    undefined,
    "There was an error fetching available production dates, please try again later or contact an administrator if the problem persists."
  );

  const datePlaceholder = computed(() =>
    newOrder.studysiteproductnetwork === ""
      ? "Select a network"
      : productionDatesApiResource.getLoading()
      ? "Loading dates"
      : "Select a date"
  );

  const disabledDates = computed(() => {
    let disabledDates = [];
    if (newOrder.reservation_id.length > 0) return disabledDates;
    let firstOfMonthCopy = DateTime.fromISO(searchDateForProductionDates.value);
    const firstOfMonthDate = DateTime.fromISO(
      searchDateForProductionDates.value
    );

    if (productionDatesApiResource.getLoading()) {
      while (firstOfMonthCopy.hasSame(firstOfMonthDate, "month")) {
        disabledDates.push(new Date(firstOfMonthCopy.toString()));
        firstOfMonthCopy = firstOfMonthCopy.plus({ day: 1 });
      }
    } else if (
      !productionDatesApiResource.getLoading() &&
      productionDatesApiResource.getData(newOrder.studysiteproductnetwork)
    ) {
      const dates = productionDatesApiResource
        .getData(newOrder.studysiteproductnetwork)
        .map((date) => getDate(setTZ(date.Earliest, props.studySite.timezone)));

      while (firstOfMonthCopy.hasSame(firstOfMonthDate, "month")) {
        if (!dates.includes(firstOfMonthCopy.toFormat(dateFormat))) {
          disabledDates.push(new Date(firstOfMonthCopy.toString()));
        }
        firstOfMonthCopy = firstOfMonthCopy.plus({ day: 1 });
      }
    }
    return disabledDates;
  });

  const handleDateInput = (model, date) => {
    model.date.$model = DateTime.fromISO(new Date(date).toISOString()).toFormat(
      dateFormat
    );
  };

  const timesForSelectedDate = computed(() => {
    if (
      newOrder.reservation_id.length > 0 ||
      productionDatesApiResource.getLoading() ||
      !productionDatesApiResource.getData(newOrder.studysiteproductnetwork) ||
      newOrder.date.length === 0
    )
      return defaultTimeSlots;

    if (productionDatesApiResource.getData(newOrder.studysiteproductnetwork)) {
      const thisDate = productionDatesApiResource
        .getData(newOrder.studysiteproductnetwork)
        .find(
          (date) =>
            getDate(setTZ(date.Earliest, props.studySite.timezone)) ===
            newOrder.date
        );
      if (!thisDate) return defaultTimeSlots;
      return {
        minTime: getTime(setTZ(thisDate.Earliest, props.studySite.timezone)),
        maxTime: getTime(setTZ(thisDate.Latest, props.studySite.timezone)),
      };
    }
  });

  const setOrderTime = (model, time) => {
    model.time.$model = DateTime.fromISO(new Date(time).toISOString()).toFormat(
      "HH-mm"
    );
  };

  const displayTime = computed(() => {
    if (newOrder.time.length > 0) {
      const hour = newOrder.time.split("-")[0];
      const minute = newOrder.time.split("-")[1];
      return new Date(DateTime.fromObject({ hour, minute }).toISO());
    }
  });

  const timePlaceholder = computed(() =>
    newOrder.date.length === 0
      ? "Select a date"
      : productionDatesApiResource.getLoading()
      ? "Loading times"
      : "Select a time"
  );

  const calendarDate = computed(() => {
    return newOrder.date === ""
      ? ""
      : new Date(DateTime.fromISO(newOrder.date).plus({ hours: 12 }).toISO());
  });

  const formatAvailableTime = (times) => `${times.minTime} - ${times.maxTime}`;

  return {
    dateFormat,
    searchDateForProductionDates,
    setSearchDateForProductionDates,
    productionDatesApiResource,
    datePlaceholder,
    disabledDates,
    handleDateInput,
    timesForSelectedDate,
    setOrderTime,
    displayTime,
    timePlaceholder,
    calendarDate,
    formatAvailableTime,
  };
};
