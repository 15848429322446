<template>
  <Panel>
    <template #header>
      <div
        class="p-d-flex p-flex-row p-jc-between p-ai-center"
        style="width: 100%"
      >
        <h5 class="app-data-list-title">{{ title }}</h5>
        <div class="p-d-flex p-flex-row">
          <span class="p-mr-4">
            <ProgressLoader
              style="width: 25px; height: 25px"
              :loading="loading"
            />
          </span>
          <Button
            icon="pi pi-plus"
            class="p-button-rounded p-button-text"
            @click="openAddModal = true"
          ></Button>
        </div>
      </div>
    </template>
    <div v-for="(item, index) in data" :key="index">
      <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
        <span style="font-size: 12px">{{ item[displayProp] }}</span>
        <i class="pi pi-trash" @click="deleteEntity(item)"></i>
      </div>
      <Divider v-if="data.length > 0 && index !== data.length - 1" />
    </div>
  </Panel>
  <Dialog
    v-model:visible="openAddModal"
    :modal="true"
    :header="`Add new ${addTitle}`"
  >
    <slot name="addModalContent" :closeAddModal="closeAddModel"></slot>
  </Dialog>
</template>

<script>
import ProgressLoader from "@/components/ProgressLoader/ProgressLoader";
import Panel from "primevue/panel";
import Divider from "primevue/divider";
import Dialog from "primevue/dialog";

import { ref } from "vue";

export default {
  name: "AppDataList",
  components: {
    Panel,
    Dialog,
    ProgressLoader,
    Divider,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    addTitle: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    displayProp: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["delete-entity"],
  setup(props, { emit }) {
    const openAddModal = ref(false);
    const closeAddModel = () => (openAddModal.value = false);
    const deleteEntity = (entity) => emit("delete-entity", entity);

    return {
      openAddModal,
      closeAddModel,
      deleteEntity,
    };
  },
};
</script>

<style>
.p-panel-header {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.app-data-list-title {
  margin: 0px !important;
}
</style>
