<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="pharmcy_admin_name" class="title"
        >New Pharmacy System Administrator</label
      >
      <Dropdown
        id="pharmcy_admin_name"
        v-model="pharmacyAdminModel.newPharmacyAdmin.$model"
        :options="availableUsers"
        option-label="display_name"
        option-value="minervaId"
        placeholder="Select a new administrator"
        :class="{ 'p-error': pharmacyAdminModel.newPharmacyAdmin.$invalid }"
        append-to="body"
      />
      <ErrorMessages :model-property="pharmacyAdminModel.newPharmacyAdmin" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || pharmacyAdminModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";

import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddPharmacyAdmin",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
  },
  props: {
    availableUsers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPharmacyAdmin = ref("");

    const rules = {
      newPharmacyAdmin: {
        required: helpers.withMessage("A pharmacy admin is required", required),
      },
    };

    const pharmacyAdminModel = useVuelidate(
      rules,
      { newPharmacyAdmin },
      { $lazy: true }
    );

    const addPharmacyAdmin = () =>
      store.dispatch("pharmacies/createPharmacySystemAdmin", {
        minervauser: newPharmacyAdmin.value,
      });

    const addPharmacyAdminApiResource = new ApiResource(
      undefined,
      addPharmacyAdmin,
      "Successfully added new pharmacy system administrator",
      "There was an error adding the pharmacy system administrator."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await pharmacyAdminModel.value.$validate()) {
        await addPharmacyAdminApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(() =>
      addPharmacyAdminApiResource.getLoading()
    );

    return {
      closeForm,
      pharmacyAdminModel,
      formLoading,
      handleSave,
    };
  },
};
</script>
