<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="role_name" class="title">Role Name</label>
      <InputText
        id="role_name"
        v-model="newRoleModel.name.$model"
        :class="{ 'p-error': newRoleModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newRoleModel.name" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newRoleModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditRole",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
  },
  props: {
    selectedRole: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newRole = reactive({
      name: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage("A role name is required", required),
      },
    };

    const newRoleModel = useVuelidate(rules, newRole, { $lazy: true });

    const createRole = () =>
      store.dispatch("rolesPermissions/createRole", newRole);

    const createRoleApiResource = new ApiResource(
      undefined,
      createRole,
      "Successfully created new role",
      "There was an error creating the new role."
    );

    const updateRole = () =>
      store.dispatch("rolesPermissions/updateRole", {
        minervaId: props.selectedRole.minervaId,
        name: newRole.name,
      });

    const updateRoleApiResource = new ApiResource(
      undefined,
      updateRole,
      "Successfully updated the role.",
      "There was an error updating the role."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newRoleModel.value.$validate()) {
        props.isEditing
          ? await updateRoleApiResource.fetchResource()
          : await createRoleApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createRoleApiResource.getLoading() || updateRoleApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        newRole.name = props.selectedRole.name;
      }
    });

    return {
      closeForm,
      newRole,
      newRoleModel,
      formLoading,
      handleSave,
    };
  },
};
</script>
