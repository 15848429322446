<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="subject" class="title">Subject</label>
      <Dropdown
        id="subject"
        v-model="model.studysitesubject.$model"
        :options="studySiteSubjectApiResource.getData()"
        option-label="subjectID"
        option-value="minervaId"
        :placeholder="subjectPlaceholder"
        :class="{ 'p-error': model.studysitesubject.$invalid }"
        :filter="true"
      />
      <ErrorMessages :model-property="model.studysitesubject" />
    </FormCol>
    <FormButtonCol col-width="p-md-6">
      <Button
        :label="showNewSubjectInput ? 'CANCEL ADD' : 'ADD SUBJECT'"
        :class="{ 'p-button-warning': showNewSubjectInput }"
        @click="toggleShowNewSubjectInput(subjectModel)"
      />
    </FormButtonCol>
    <FormCol
      v-if="
        subjectOtherOrderApiResource.getData() &&
        subjectOtherOrderApiResource.getData().length > 0
      "
      col-width="p-md-6"
    >
      <label class="p-pt-2" style="font-size: 14px">
        Latest/Scheduled Orders</label
      >
      <div class="p-ml-4 p-d-flex p-flex-column" style="font-size: 12px">
        <p
          v-for="(order, index) in subjectOtherOrderApiResource.getData()"
          :key="index"
          class="p-d-flex p-ai-center"
          style="color: grey"
        >
          <span>
            {{ order.product }}: {{ order.date }}
            {{ order.in_future ? "(scheduled)" : "" }}
          </span>
          <i
            v-if="order.warn"
            class="pi pi-exclamation-triangle p-pl-2"
            style="color: #fbc02d; font-size: 1.1rem"
          ></i>
        </p>
      </div>
    </FormCol>
    <FormCol v-if="showNewSubjectInput" col-width="p-md-6">
      <label for="new_subject_id" class="title">New Subject ID</label>
      <InputText
        id="new_subject_id"
        v-model="subjectModel.newSubjectId.$model"
        :class="{ 'p-error': subjectModel.newSubjectId.$invalid }"
      />
      <ErrorMessages :model-property="subjectModel.newSubjectId" />
    </FormCol>
    <FormButtonCol v-if="showNewSubjectInput" col-width="p-md-6">
      <Button
        label="SAVE NEW SUBJECT"
        class="p-mt-1"
        :disabled="formLoading || subjectModel.$invalid"
        @click="confirmCreateSubject"
      />
    </FormButtonCol>
    <FormCol v-if="showNewSubjectInput" col-width="p-md-12">
      <InlineMessage severity="error" class="p-mt-2">
        Please make sure the new Subject belongs to
        {{ studySite.display_name }} and that the ID DOES NOT include any
        patient identifiable information such as parts of the name, Initials,
        Date of Birth etc…
      </InlineMessage>
    </FormCol>
    <FormButtonCol v-if="isEditing && subjectChanged">
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CANCEL SUBJECT CHANGE"
        confirm-text="UPDATE SUBJECT"
        @cancelled="resetSubject"
        @confirmed="updateSubject"
      />
    </FormButtonCol>
    <!-- change order to a subject from another study -->
    <!-- <FormButtonCol col-width="p-md-6">
      <Button
        v-if="isEditing"
        label="CHANGE STUDY SUBJECT"
        @click="toggleChangeStudySubjectModal"
      />
      <Dialog
        v-model:visible="showChangeStudySubjectModal"
        :modal="true"
        header="Move order to another Study Subject"
      >
        <ChangeSiteSubject
          :study-site="studySite"
          :current-order="selectedOrder"
          @close-form="toggleChangeStudySubjectModal"
          @subject-changed-other-site="orderStudySubjectChangeSuccess"
        />
      </Dialog>
    </FormButtonCol> -->
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="product" class="title">Product</label>
      <Dropdown
        id="product"
        v-model="model.studysiteproduct.$model"
        :options="availableProductsApiResource.getData()"
        option-label="display_name"
        option-value="minervaId"
        :placeholder="productPlaceholder"
        :disabled="isEditing"
        :class="{ 'p-error': model.studysiteproduct.$invalid }"
      />
      <ErrorMessages :model-property="model.studysiteproduct" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="quantity" class="title">Amount (mCi)</label>
      <InputNumber
        id="quantity"
        v-model="model.amount.$model"
        mode="decimal"
        :min="1"
        :max="15"
        :min-fraction-digits="2"
        :disabled="
          isEditing || (selectedProduct && selectedProduct.orderQtyFixed)
        "
        :class="{ 'p-error': model.amount.$invalid }"
      />
      <ErrorMessages :model-property="model.amount" />
      <InlineMessage v-if="newOrder.amount" severity="info" class="p-mt-2">
        {{ `${getMBQ(newOrder.amount)} MBq` }}
      </InlineMessage>
      <InlineMessage
        v-if="selectedProduct && selectedProduct.orderQtyFixed"
        severity="info"
        class="p-mt-2"
      >
        Amount for the selected product is fixed.
      </InlineMessage>
    </FormCol>
    <Divider />
    <FormCol v-if="isEditing">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 39px">
        <InputSwitch v-model="enableEditNetworkDateTime" />
        <label
          for="enable_edit_network_date_time"
          class="checkbox-text p-ml-3 p-mb-1"
          >Edit Network, Date, or Time</label
        >
      </div>
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="network" class="title">Network</label>
      <Dropdown
        id="network"
        v-model="model.studysiteproductnetwork.$model"
        :options="availableNetworksApiResource.getData()"
        option-label="display_name"
        option-value="minervaId"
        :placeholder="networkPlaceholder"
        :class="{ 'p-error': model.studysiteproductnetwork.$invalid }"
        :disabled="
          productDoseNetworkApiResource.getLoading() ||
          !adminUser ||
          disableNetworkDateTime
        "
      />
      <ErrorMessages :model-property="model.studysiteproductnetwork" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <div
        class="chip-wrapper"
        style="margin-top: 36px; transform: translateY(-7px)"
        :class="liveAvailabilityDisplay.colorClass"
      >
        <i class="pi pi-info-circle"></i>
        <p class="p-mx-2">{{ liveAvailabilityDisplay.text }}</p>
      </div>
    </FormCol>
    <FormCol col-width="p-md-6" :single-row="!showReservationId">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="use_reservation_id"
          v-model="showReservationId"
          :binary="true"
          class="p-mb-1"
          :disabled="disableNetworkDateTime"
        />
        <label for="use_reservation_id" class="checkbox-text p-ml-2 p-mb-1"
          >I have a Reservation ID</label
        >
      </div>
    </FormCol>
    <FormCol v-if="showReservationId" col-width="p-md-6">
      <label for="reservation_id" class="title">Reservation ID</label>
      <InputText
        id="reservation_id"
        v-model="newOrder.reservation_id"
        :disabled="!networkConfigured"
      />
    </FormCol>
    <FormCol v-if="isEditing && networkChanged" single-row col-width="p-md-6">
      <label for="network_change_reason" class="title"
        >Network Change Reason</label
      >
      <Dropdown
        id="network_change_reason"
        v-model="networkChangedReason"
        :options="cancellationReasons"
        placeholder="Select a reason"
        append-to="body"
        panel-class="test"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="date" class="title">Date</label>
      <Calendar
        id="date"
        :model-value="calendarDate"
        date-format="yy-mm-dd"
        :disabled-dates="liveAvailability ? disabledDates : []"
        :disabled-days="[0]"
        :disabled="orderingDisabled || disableNetworkDateTime"
        :min-date="new Date()"
        :manual-input="false"
        append-to="body"
        :placeholder="datePlaceholder"
        :class="{ 'p-error': model.date.$invalid }"
        @update:modelValue="handleDateInput(model, $event)"
        @month-change="setSearchDateForProductionDates"
      >
        <template #footer>
          <div style="height: 0.3rem">
            <ProgressBar
              v-if="productionDatesApiResource.getLoading()"
              mode="indeterminate"
              style="height: 0.3rem"
            />
          </div>
        </template>
      </Calendar>
      <ErrorMessages :model-property="model.date" />
      <InlineMessage
        v-if="productionSite && !orderingDisabled"
        severity="info"
        class="p-mt-2"
      >
        Pharmacy: {{ productionSite }}
      </InlineMessage>
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="time" class="title">Time</label>
      <Calendar
        id="time"
        :model-value="displayTime"
        :time-only="true"
        :placeholder="timePlaceholder"
        append-to="body"
        :disabled="orderingDisabled || disableNetworkDateTime"
        :class="{ 'p-error': model.time.$invalid }"
        @update:model-value="setOrderTime(model, $event)"
      />
      <ErrorMessages :model-property="model.time" />
      <InlineMessage
        v-if="newOrder.date && !orderingDisabled"
        severity="info"
        class="p-mt-2"
        >Available Times:
        {{
          formatAvailableTime(
            liveAvailability ? timesForSelectedDate : defaultTimeSlots
          )
        }}
      </InlineMessage>
    </FormCol>
    <FormButtonCol v-if="isEditing && networkChanged">
      <CancelConfirmButtons
        :disabled="formLoading || networkChangedReason.length === 0"
        cancel-text="CANCEL NETWORK CHANGE"
        confirm-text="UPDATE NETWORK"
        @cancelled="resetNetworkHandler"
        @confirmed="confirmNetworkChange"
      />
    </FormButtonCol>
    <FormButtonCol v-if="isEditing && dateTimeChanged && !networkChanged">
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CANCEL DATE TIME CHANGE"
        confirm-text="UPDATE DATE TIME"
        @cancelled="resetDateTimeHandler"
        @confirmed="confirmDateTimeChange"
      />
    </FormButtonCol>
    <Divider />
    <!-- skip network api request checkbox -->
    <!-- <FormCol col-width="p-md-6">
      <div class="p-pt-2 p-mb-1">
        <Checkbox
          id="skip_network_api_call"
          v-model="newOrder.skip_network_api_call"
          :binary="true"
          class="p-mb-1"
        />
        <label for="skip_network_api_call" class="title p-ml-3 p-mb-0"
          >Skip Network Request</label
        >
      </div>
    </FormCol>
    <Divider /> -->
    <FormCol v-if="isEditing">
      <label for="notes" class="title">Notes</label>
      <Textarea
        v-model="newOrder.notes"
        label="notes"
        :auto-resize="true"
        rows="3"
        cols="60"
      />
    </FormCol>
    <FormButtonCol v-if="isEditing && notesChanged">
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CANCEL NOTES CHANGE"
        confirm-text="UPDATE NOTES"
        @cancelled="resetNotes"
        @confirmed="verifyNotes"
      />
    </FormButtonCol>
    <Divider v-if="isEditing" />
    <FormCol v-if="isEditing">
      <label for="courier_notes" class="title">Courier Notes</label>
      <Textarea
        v-model="newOrder.courier_notes"
        label="courier_notes"
        :auto-resize="true"
        rows="3"
        cols="60"
      />
    </FormCol>
    <FormButtonCol v-if="isEditing && courierNotesChanged">
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CANCEL COURIER NOTES CHANGE"
        confirm-text="UPDATE COURIER NOTES"
        @cancelled="resetCourierNotes"
        @confirmed="verifyCourierNotes"
      />
    </FormButtonCol>
    <Divider v-if="isEditing" />
    <FormCol v-if="isEditing" col-width="p-md-6">
      <label for="cancellation_reason" class="title">Cancellation Reason</label>
      <Dropdown
        id="cancellation_reason"
        v-model="cancellationReason.cancellation_reason"
        :options="cancellationReasons"
        placeholder="Select a reason"
        append-to="body"
      />
    </FormCol>
    <FormButtonCol v-if="isEditing" col-width="p-md-6">
      <Button
        label="CANCEL ORDER"
        class="p-button-danger p-button-text p-mt-1"
        :disabled="!cancellationReason.cancellation_reason"
        @click="confirmCancelOrderHandler"
      />
    </FormButtonCol>
    <FormCol v-if="cancellationReason.cancellation_reason === 'Other'">
      <label for="other_reason" class="title">Other Reason</label>
      <Textarea
        v-model="cancellationReasonModel.other.$model"
        label="other_reason"
        :auto-resize="true"
        rows="3"
        cols="60"
        :class="{
          'p-invalid': cancellationReasonModel.other.$invalid,
        }"
      />
      <ErrorMessages :model-property="cancellationReasonModel.other" />
    </FormCol>
    <Divider v-if="isEditing" />
    <FormCol>
      <Message
        v-if="
          liveAvailability &&
          selectedNetwork &&
          !productionDatesApiResource.getLoading() &&
          !productionDatesApiResource.getData(selectedNetwork.minervaId)
        "
        severity="error"
        >{{ isEditing ? "Editing" : "Ordering" }} for this network is disabled
        as we are not able to fetch the production dates. You may still place an
        order with a Reservation ID.
      </Message>
      <Message v-if="liveAvailabilityDisplay.hintText" severity="info">{{
        liveAvailabilityDisplay.hintText
      }}</Message>
      <Message v-if="liveAvailabilityCallFailed" severity="error"
        >The live availability check has failed, ordering is disabled at this
        time. Please try again later or contact an administrator if the problem
        persists.</Message
      >
      <Message
        v-if="networkConfigurationChecked && !networkConfigured"
        severity="error"
        >This network has not been configured properly, please contact an
        administrator.</Message
      >
    </FormCol>
    <FormCol col-width="p-md-6" />
    <FormCol col-width="p-md-6">
      <InlineMessage
        class="p-mt-2"
        :severity="siteRamIsExpired ? 'error' : 'info'"
        >Study Site Ram Expiry: {{ studySite.site_ram_expiry }}
        {{
          siteRamIsExpired
            ? "Ordering for this site is disabled until the Ram license is updated"
            : ""
        }}
      </InlineMessage>
    </FormCol>
    <FormButtonCol col-width="p-md-12" :use-left-button="true">
      <template #leftButton>
        <Button
          label="Order Request"
          :disabled="disableLeftButton"
          @click="showCreateOrderRequest = true"
        />
      </template>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid || orderingDisabled"
        :hide-confirm="isEditing"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="createOrder"
      />
    </FormButtonCol>
  </FormWrapper>
  <Dialog
    v-model:visible="showConfirmOrder"
    :modal="true"
    :header="confirmOrderTitle"
    :dismissable-mask="true"
  >
    <VerifyOrder
      :order="newOrder"
      :is-editing="isEditing"
      :selected-subject-name="selectedSubjectName"
      :selected-product-name="selectedProductName"
      :selected-network-name="selectedNetworkName"
      :selected-pharmacy="productionSite"
      @canceled="showConfirmOrder = false"
      @confirmed="verifyConfirmed"
    >
      <template #message>
        <div v-if="isEditing && networkChanged" class="p-d-flex p-ai-center">
          <div class="p-mr-2">
            <i
              class="pi pi-exclamation-triangle"
              style="font-size: 1.75rem"
            ></i>
          </div>
          <div style="font-size: 1.1rem">
            <p>
              Changing the network will cancel the existing order and create a
              new one with the details above.
              <span style="color: #fbc02d">
                If creating the new order fails, the previous order will still
                be canceled.
              </span>
            </p>
          </div>
        </div>
      </template>
    </VerifyOrder>
  </Dialog>
  <Dialog
    v-model:visible="showCreateOrderRequest"
    :modal="true"
    header="Create Order Request"
    :dismissable-mask="true"
  >
    <AddOrderRequest
      :study-site="studySite"
      :order="newOrder"
      :selected-subject-name="selectedSubjectName"
      :selected-product-name="selectedProductName"
      :selected-network-name="selectedNetworkName"
      @closeForm="closeForm"
    />
  </Dialog>
</template>

<script>
import { ref, reactive, inject, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import ProgressBar from "primevue/progressbar";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import { defaultTimeSlots } from "@/helpers/defaultTimeSlots";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import VerifyOrder from "@/components/Orders/VerifyOrder";
import AddOrderRequest from "@/components/Orders/AddOrderRequest";
// import ChangeSiteSubject from "@/components/Orders/ChangeSiteSubject";
import { buildSubjectHandlers } from "@/components/Orders/orderUtils/subjectHandlers";
import { buildProductsHandlers } from "@/components/Orders/orderUtils/productsHandlers";
import { buildNetworkHandlers } from "@/components/Orders/orderUtils/networkHandlers";
import { buildDateTimeHandlers } from "@/components/Orders/orderUtils/dateTimeHandlers";
import { buildNoteHandlers } from "@/components/Orders/orderUtils/notesHandlers";
import { buildOrderCancellationHelpers } from "@/components/Orders/orderUtils/orderCancellationHelpers";
import { buildDateTimeChangeHandlers } from "@/components/Orders/orderUtils/dateTimeChangeHandlers";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue, helpers } from "@vuelidate/validators";
import { getUserRole } from "@/helpers/getUserRole";
import {
  calibrationDate,
  getDate,
  setTimeFromLocalCalibration,
} from "@/helpers/timeHelpers";
import { useConfirm } from "primevue/useConfirm";
import { getMBQ } from "@/helpers/units";

export default {
  name: "AddEditOrder",
  components: {
    Divider,
    Dropdown,
    InputNumber,
    Calendar,
    Checkbox,
    ProgressBar,
    Message,
    InlineMessage,
    Textarea,
    CancelConfirmButtons,
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    Dialog,
    VerifyOrder,
    InputSwitch,
    AddOrderRequest,
    // ChangeSiteSubject,
  },
  props: {
    studySite: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    selectedOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const auth = inject("Auth");
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const user = auth?.user?.value ?? undefined;

    const adminUser = computed(() => {
      if (user) {
        const orderRoles = getUserRole(user, "dose_order");
        if (orderRoles) {
          return orderRoles.some(
            (role) =>
              role.role === "SuperAdmin" || role.role === "StudyDoseOrderAdmin"
          );
        }
        return false;
      }
      return false;
    });

    const confirm = useConfirm();

    //
    // Variable Setup
    //

    const newOrder = reactive({
      study: props.studySite.study,
      site: props.studySite.site,
      studysite: props.studySite.minervaId,
      studysiteproduct: "",
      studysitesubject: "",
      studysiteproductnetwork: "",
      calibration: "",
      date: "",
      time: "",
      amount: 0,
      reservation_id: "",
      // skip_network_api_call: false,
    });

    const timeWithinAvailableRange = (input) => {
      if (props.isEditing && !enableEditNetworkDateTime.value) {
        return true;
      }
      const selectedTime = Number(input.replace("-", ""));
      const timesToValidateAgainst = liveAvailability.value
        ? timesForSelectedDate.value
        : defaultTimeSlots;
      const minTime = Number(timesToValidateAgainst.minTime.replace(":", ""));
      const maxTime = Number(timesToValidateAgainst.maxTime.replace(":", ""));
      return selectedTime >= minTime && selectedTime <= maxTime;
    };

    const dateWithinAvailableRange = (input) => {
      let validDate = true;
      if (input) {
        const userInput = DateTime.fromISO(new Date(input).toISOString(), {
          setZone: true,
        });

        if (liveAvailability.value) {
          let searchDate = DateTime.fromISO(
            new Date(searchDateForProductionDates.value).toISOString(),
            {
              setZone: true,
            }
          );
          if (userInput.month !== searchDate.month) {
            validDate = false;
          }
          disabledDates.value.forEach((date) => {
            const disabledDate = DateTime.fromISO(new Date(date).toISOString());
            if (disabledDate.day === userInput.day) {
              validDate = false;
            }
          });
        } else {
          const selectedDay = userInput.toFormat("ccc");
          if (selectedDay === "Sun") {
            validDate = false;
          }
        }
      }
      return validDate;
    };

    const rules = {
      studysitesubject: {
        required: helpers.withMessage("A subject is required", required),
      },
      studysiteproduct: {
        required: helpers.withMessage("A product is required", required),
      },
      studysiteproductnetwork: {
        required: helpers.withMessage("A network is required", required),
      },
      amount: {
        required: helpers.withMessage("An amount is required", required),
        minValue: minValue(1),
      },
      date: {
        required: helpers.withMessage("A date is required", required),
        dateWithinAvailableRange: helpers.withMessage(
          "The selected date must be within the available range",
          dateWithinAvailableRange
        ),
      },
      time: {
        required: helpers.withMessage("A time is required", required),
        timeWithinAvailableRange: helpers.withMessage(
          "The selected time must be within the available range",
          timeWithinAvailableRange
        ),
      },
    };

    const model = useVuelidate(rules, newOrder, { $lazy: true });

    const closeForm = () => emit("close-form");

    const showReservationId = ref(false);

    watch(showReservationId, (showId) => {
      if (!showId) {
        newOrder.reservation_id = "";
      }
    });

    const showCreateOrderRequest = ref(false);

    const disableLeftButton = computed(
      () =>
        !(
          newOrder.studysiteproduct.length > 0 &&
          newOrder.studysitesubject.length > 0 &&
          newOrder.studysiteproductnetwork.length > 0
        )
    );

    //
    // Create order handlers
    //

    const createOrderRequest = () =>
      store.dispatch("orderManagement/createStudySiteOrder", {
        studyId: props.studySite.study,
        studySiteId: props.studySite.minervaId,
        order: {
          ...newOrder,
          calibration: calibrationDate(
            newOrder.date,
            newOrder.time,
            props.studySite.timezone
          ),
        },
      });

    const createOrderApiResource = new ApiResource(
      undefined,
      createOrderRequest,
      "Successfully created new order.",
      "There was an error creating your new order."
    );

    const createOrder = async () => {
      if (await model.value.$validate()) {
        showVerificationModal(async () => {
          await createOrderApiResource.fetchResource();
          closeForm();
        });
      }
    };

    //
    // Cancel order handlers
    //

    const {
      getReasonPermissions,
      confirmCancelOrderHandler,
      cancellationReason,
      cancellationReasons,
      cancelOrderApiResource,
      cancellationReasonModel,
    } = buildOrderCancellationHelpers(
      store,
      props,
      ApiResource,
      newOrder,
      user,
      closeForm
    );

    //
    // Study Site Subjects Functions
    //

    const {
      showNewSubjectInput,
      toggleShowNewSubjectInput,
      newSubjectId,
      newSubjectValidator,
      subjectPlaceholder,
      subjectChanged,
      resetSubject,
      createSubject,
      addNewSubjectApiResource,
      studySiteSubjectApiResource,
      saveUpdatedSubjectApiResource,
      subjectOtherOrderApiResource,
    } = buildSubjectHandlers(store, props, ApiResource, newOrder);

    const subjectRules = {
      newSubjectId: {
        required: helpers.withMessage("A subject id is required", required),
        newSubjectValidator,
      },
    };
    const subjectModel = useVuelidate(
      subjectRules,
      { newSubjectId },
      { $lazy: true }
    );

    const updateSubject = async () => {
      if (await model.value.$validate()) {
        showVerificationModal(() =>
          saveUpdatedSubjectApiResource.fetchResource()
        );
      }
    };

    const confirmCreateSubject = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Please confirm:\nSubject ${newSubjectId.value} belongs to study ${props.studySite.display_name}.\nThe Subject ID does not contain any patient identifiable information such as parts of the name, Initials, Date of Birth etc…`,
        icon: "pi pi-exclamation-triangle",
        accept: () => createSubject(subjectModel.value),
        rejectClass: "p-button-danger p-button-text",
      });
    };

    watch(
      () => newOrder.studysitesubject,
      () => subjectOtherOrderApiResource.fetchResource()
    );

    //
    // Move order to a subject from a different study
    //

    const showChangeStudySubjectModal = ref(false);
    const toggleChangeStudySubjectModal = () => {
      showChangeStudySubjectModal.value = !showChangeStudySubjectModal.value;
    };
    const orderStudySubjectChangeSuccess = () => {
      toggleChangeStudySubjectModal();
      closeForm();
    };

    //
    // Available Products Functions
    //

    const {
      availableProductsApiResource,
      productPlaceholder,
      selectedProduct,
    } = buildProductsHandlers(store, props, ApiResource, newOrder);

    watch(selectedProduct, () => {
      if (
        selectedProduct.value.orderQty &&
        selectedProduct.value.orderQty > 0 &&
        !props.isEditing
      )
        newOrder.amount = selectedProduct.value.orderQty;
      if (!props.isEditing) {
        newOrder.studysiteproductnetwork = "";
        newOrder.date = "";
        newOrder.time = "";
      }
    });

    //
    // Available Networks Functions
    //

    const {
      availableNetworksApiResource,
      networkPlaceholder,
      selectedNetwork,
      networkChanged,
      networkChangedReason,
      resetNetwork,
      saveUpdatedNetworkApiResource,
      liveAvailabilityApiResource,
      productDoseNetworkApiResource,
      getProductDoseNetworks,
      getLiveAvailability,
      liveAvailabilityDisplay,
      liveAvailability,
      liveAvailabilityCallFailed,
      networkConfigured,
      networkConfiguredRequestApiResource,
      networkConfigurationChecked,
    } = buildNetworkHandlers(store, props, ApiResource, newOrder);

    const enableEditNetworkDateTime = ref(false);

    const disableNetworkDateTime = computed(
      () => props.isEditing && !enableEditNetworkDateTime.value
    );

    watch(
      () => disableNetworkDateTime.value,
      () => {
        resetNetwork();
        resetDateTime();
        model.value.$reset();
      }
    );

    const confirmNetworkChange = async () => {
      if (await model.value.$validate()) {
        showVerificationModal(async () => {
          await saveUpdatedNetworkApiResource.fetchResource();
          closeForm();
        });
      }
    };

    const resetNetworkHandler = () => {
      enableEditNetworkDateTime.value = false;
    };

    watch([selectedProduct, disableNetworkDateTime], async () => {
      if (disableNetworkDateTime.value) return;
      if (!selectedProduct.value) return;
      await getProductDoseNetworks();
      if (
        !props.isEditing &&
        !adminUser.value &&
        availableNetworksApiResource.getData().length > 0
      ) {
        newOrder.studysiteproductnetwork = availableNetworksApiResource.getData()[0].minervaId;
      }
    });

    //
    // Available Dates and Times Functions
    //

    const {
      dateFormat,
      searchDateForProductionDates,
      setSearchDateForProductionDates,
      productionDatesApiResource,
      datePlaceholder,
      calendarDate,
      disabledDates,
      handleDateInput,
      timesForSelectedDate,
      formatAvailableTime,
      setOrderTime,
      displayTime,
      timePlaceholder,
      // resetAvailableDates,
    } = buildDateTimeHandlers(store, props, ApiResource, newOrder);

    // handle refresh of the production dates on network re-selection or when the search date for production dates changes. If there is live availablility and there are dates and times selected, verify we can keep them, if not, return
    watch(
      [
        selectedNetwork,
        searchDateForProductionDates,
        () => newOrder.amount,
        disableNetworkDateTime,
      ],
      async (newValues) => {
        // if user disabledNetworkDateTime, return
        if (disableNetworkDateTime.value) return;
        // if selectedNetwork is undefined, return
        if (!newValues[0]) return;
        // without the product dose networks we cannot get the production dates
        if (productDoseNetworkApiResource.getData() === undefined) return;
        if (!productDoseNetworkApiResource.getData().length > 0) return;
        const networkUriObject = productDoseNetworkApiResource
          .getData()
          .find((network) => network.minervaId === newValues[0].dosenetwork);

        if (networkUriObject === undefined) return;
        // check if network is properly configured, if not, early exit and prevent ordering
        await networkConfiguredRequestApiResource.fetchResource({
          network_implementation_uri:
            networkUriObject.internal_implementation_uri,
          network_site_id: newValues[0].networkstudysiteid,
        });
        if (!networkConfigured.value) return;
        // get new live availablility, if there is no live availability any previously available selection date must be valid, return
        // make sure this happens before the other early exits as it must happen everytime there is a new network
        await getLiveAvailability();
        if (!liveAvailability.value) return;

        // if there is a reservation, don't bother with production dates, all available
        if (newOrder.reservation_id.length > 0 || showReservationId.value)
          return;

        // clear out old dates before fetching new ones.
        // resetAvailableDates()

        await productionDatesApiResource.fetchResource({
          network_implementation_uri:
            networkUriObject.internal_implementation_uri,
          network_site_id: newValues[0].networkstudysiteid,
          order_id:
            props.isEditing && !networkChanged.value
              ? props.selectedOrder.order_id
              : undefined,
          product_uuid: selectedProduct.value.product,
          networkId: newValues[0].minervaId,
        });
        if (liveAvailability.value && newOrder.date.length > 0) {
          // const today = DateTime.local();
          const orderDate = DateTime.fromISO(newOrder.date);

          // if (orderDate < today) {
          //   newOrder.date = "";
          //   newOrder.time = "";
          //   console.log("date < today");
          //   return;
          // }

          const orderDateIsInvalid = disabledDates.value.some((date) =>
            DateTime.fromISO(date.toISOString()).hasSame(orderDate, "day")
          );

          if (orderDateIsInvalid) {
            newOrder.date = "";
            newOrder.time = "";
            searchDateForProductionDates.value = DateTime.local().toFormat(
              dateFormat
            );
          }

          const selectedTimeIsValid = timeWithinAvailableRange(newOrder.time);

          if (!selectedTimeIsValid) {
            newOrder.time = "";
          }
        }
      }
    );

    const productionSite = computed(() => {
      if (selectedNetwork.value && selectedNetwork.value.minervaId) {
        const dates = productionDatesApiResource.getData(
          selectedNetwork.value.minervaId
        );
        if (newOrder.date.length > 0 && dates && dates.length > 0) {
          const selectedDate = dates.find(
            (date) => getDate(date.Earliest) === newOrder.date
            // && selectedNetwork.value.networkstudysiteid === date.SiteID
          );
          return selectedDate ? selectedDate.Pharmacy : undefined;
        }
      }

      return undefined;
    });

    //
    // Date time change functions
    //

    const {
      dateTimeChanged,
      resetDateTime,
      saveUpdatedDateTimeApiResource,
    } = buildDateTimeChangeHandlers(store, props, ApiResource, newOrder);

    const confirmDateTimeChange = async () => {
      if (await model.value.$validate()) {
        showVerificationModal(() => {
          saveUpdatedDateTimeApiResource.fetchResource();
        });
      }
    };

    const resetDateTimeHandler = () => {
      enableEditNetworkDateTime.value = false;
    };

    //
    // Notes update functions
    //

    const {
      notesChanged,
      resetNotes,
      saveUpdatedNotesApiResource,
      courierNotesChanged,
      resetCourierNotes,
      saveUpdatedCourierNotesApiResource,
    } = buildNoteHandlers(store, props, ApiResource, newOrder);

    const verifyNotes = () =>
      showVerificationModal(() => saveUpdatedNotesApiResource.fetchResource());

    const verifyCourierNotes = () =>
      showVerificationModal(() =>
        saveUpdatedCourierNotesApiResource.fetchResource()
      );

    //
    // Component kickoff and general functions
    //
    const showConfirmOrder = ref(false);
    let verficationFunc = undefined;

    const confirmOrderTitle = computed(() =>
      props.isEditing ? "Verify Order Modification" : "Verify New Order"
    );

    const showVerificationModal = (func) => {
      verficationFunc = func;
      showConfirmOrder.value = true;
    };
    const verifyConfirmed = () => {
      showConfirmOrder.value = false;
      verficationFunc();
    };

    const selectedNetworkName = computed(
      () => selectedNetwork.value && selectedNetwork.value.display_name
    );

    const selectedProductName = computed(
      () => selectedProduct.value && selectedProduct.value.display_name
    );

    const selectedSubject = computed(
      () =>
        studySiteSubjectApiResource.getData() &&
        studySiteSubjectApiResource
          .getData()
          .find((subject) => subject.minervaId === newOrder.studysitesubject)
    );

    const selectedSubjectName = computed(
      () => selectedSubject.value && selectedSubject.value.subjectID
    );

    const orderingDisabled = computed(
      () =>
        (liveAvailability.value &&
          selectedNetwork.value &&
          !productionDatesApiResource.getData(
            selectedNetwork.value.minervaId
          ) &&
          newOrder.reservation_id.length === 0) ||
        siteRamIsExpired.value ||
        liveAvailabilityCallFailed.value ||
        (networkConfigurationChecked.value && !networkConfigured.value)
    );

    const siteRamIsExpired = computed(() => {
      return (
        DateTime.fromSQL(props.studySite.site_ram_expiry, {
          setZone: true,
        }) < DateTime.local()
      );
    });

    const formLoading = computed(
      () =>
        studySiteSubjectApiResource.getLoading() ||
        availableProductsApiResource.getLoading() ||
        availableNetworksApiResource.getLoading() ||
        productDoseNetworkApiResource.getLoading() ||
        liveAvailabilityApiResource.getLoading() ||
        productionDatesApiResource.getLoading() ||
        cancelOrderApiResource.getLoading() ||
        createOrderApiResource.getLoading() ||
        addNewSubjectApiResource.getLoading() ||
        saveUpdatedSubjectApiResource.getLoading() ||
        saveUpdatedNotesApiResource.getLoading() ||
        saveUpdatedCourierNotesApiResource.getLoading() ||
        saveUpdatedNetworkApiResource.getLoading() ||
        saveUpdatedDateTimeApiResource.getLoading()
    );

    onMounted(async () => {
      searchDateForProductionDates.value = DateTime.local().toFormat(
        dateFormat
      );

      Promise.all([
        studySiteSubjectApiResource.fetchResource(),
        availableProductsApiResource.fetchResource(),
      ]);

      if (props.isEditing) {
        searchDateForProductionDates.value = DateTime.fromISO(
          getDate(props.selectedOrder.local_calibration)
        )
          .set({ day: 1 })
          .toFormat(dateFormat);
        newOrder.studysiteproduct = props.selectedOrder.studysiteproduct;
        newOrder.amount = props.selectedOrder.amount;
        newOrder.studysitesubject = props.selectedOrder.studysitesubject;
        newOrder.date = getDate(props.selectedOrder.local_calibration);
        newOrder.time = setTimeFromLocalCalibration(
          props.selectedOrder.local_calibration
        );
        newOrder.notes = props.selectedOrder.notes;
        newOrder.courier_notes = props.selectedOrder.courier_notes;
        newOrder.studysiteproductnetwork =
          props.selectedOrder.studysiteproductnetwork;

        model.value.$validate();
        getReasonPermissions();
        getProductDoseNetworks();
      }
    });

    return {
      newOrder,
      model,
      selectedProduct,
      studySiteSubjectApiResource,
      availableNetworksApiResource,
      availableProductsApiResource,
      productionDatesApiResource,
      productDoseNetworkApiResource,
      liveAvailabilityDisplay,
      liveAvailability,
      subjectPlaceholder,
      productPlaceholder,
      networkPlaceholder,
      datePlaceholder,
      calendarDate,
      timePlaceholder,
      setOrderTime,
      displayTime,
      formatAvailableTime,
      handleDateInput,
      disabledDates,
      defaultTimeSlots,
      setSearchDateForProductionDates,
      formLoading,
      timesForSelectedDate,
      createOrder,
      cancellationReason,
      cancellationReasons,
      cancellationReasonModel,
      confirmCancelOrderHandler,
      closeForm,
      showReservationId,
      showNewSubjectInput,
      toggleShowNewSubjectInput,
      newSubjectId,
      subjectModel,
      createSubject,
      subjectChanged,
      resetSubject,
      saveUpdatedSubjectApiResource,
      notesChanged,
      resetNotes,
      courierNotesChanged,
      resetCourierNotes,
      saveUpdatedNotesApiResource,
      saveUpdatedCourierNotesApiResource,
      networkChanged,
      networkChangedReason,
      saveUpdatedNetworkApiResource,
      confirmNetworkChange,
      dateTimeChanged,
      saveUpdatedDateTimeApiResource,
      showChangeStudySubjectModal,
      toggleChangeStudySubjectModal,
      orderStudySubjectChangeSuccess,
      adminUser,
      productionSite,
      showConfirmOrder,
      confirmOrderTitle,
      verifyConfirmed,
      selectedNetwork,
      selectedNetworkName,
      selectedProductName,
      selectedSubjectName,
      updateSubject,
      verifyNotes,
      verifyCourierNotes,
      confirmDateTimeChange,
      orderingDisabled,
      enableEditNetworkDateTime,
      disableNetworkDateTime,
      resetDateTimeHandler,
      resetNetworkHandler,
      disableLeftButton,
      showCreateOrderRequest,
      confirmCreateSubject,
      siteRamIsExpired,
      liveAvailabilityCallFailed,
      networkConfigured,
      getMBQ,
      networkConfigurationChecked,
      subjectOtherOrderApiResource,
    };
  },
};
</script>

<style lang="scss">
.p-datepicker-calendar > tbody span:not(.p-disabled) {
  font-size: 1.1rem;
  font-weight: 500;
}
.p-confirm-dialog-message {
  white-space: pre !important;
}
</style>
