<template>
  <AppCalendar
    title="Pharmacy Available Dates"
    add-button="Pharmacy Available Dates"
    :time-zone-for-cal="timeZoneForCal"
    :data="mappedDates"
    :loading="pageLoading"
    @delete-date="deletePharmacyAvailableDate"
    @month-selected="setSearchDateForProductionDates"
  >
    <template #addModalContent="{ closeAddModal }">
      <AddPharmacyDate
        :selected-pharmacy="selectedPharmacy"
        @close-form="closeAddModal"
      />
    </template>
  </AppCalendar>
</template>

<script>
import AppCalendar from "@/components/AppCalendar/AppCalendar";
import AddPharmacyDate from "@/components/Pharmacies/AddPharmacyDate";

import { computed, inject, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "PharmacyCalendar",
  components: {
    AppCalendar,
    AddPharmacyDate,
  },
  props: {
    selectedPharmacy: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const searchDateForProductionDates = ref("");

    const setSearchDateForProductionDates = (searchString) => {
      searchDateForProductionDates.value = searchString;
    };

    const pharmacyAvailableDates = () =>
      store.state.pharmacies.pharmacyAvailableDates[
        props.selectedPharmacy.minervaId
      ];

    const fetchPharmacyAvailableDates = () =>
      store.dispatch("pharmacies/fetchPharmacyAvailableDates", {
        pharmacyId: props.selectedPharmacy.minervaId,
        searchDate: searchDateForProductionDates.value,
      });

    const pharmacyAvailableDatesApiResource = new ApiResource(
      pharmacyAvailableDates,
      fetchPharmacyAvailableDates,
      undefined,
      "There was an error fetching the pharmacy available dates."
    );

    const deleteAvailableDate = (payload) =>
      store.dispatch("pharmacies/deletePharmacyAvailableDate", {
        pharmacyId: props.selectedPharmacy.minervaId,
        payload,
      });

    const deleteAvailableDateApiResource = new ApiResource(
      undefined,
      deleteAvailableDate,
      "Successfully deleted the available date.",
      "There was an error deleting the available date."
    );

    const deletePharmacyAvailableDate = (dateToDelete) => {
      const thisDate = pharmacyAvailableDatesApiResource
        .getData()
        .find((date) => date.minervaId === dateToDelete);
      deleteAvailableDateApiResource.fetchResource(thisDate);
    };

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const mappedDates = computed(() => {
      if (
        productsApiResource.getData() &&
        pharmacyAvailableDatesApiResource.getData()
      ) {
        return pharmacyAvailableDatesApiResource.getData().map((date) => {
          const startString = DateTime.fromISO(date.earliest, {
            setZone: true,
          }).toFormat("HH:mm");
          const endString = DateTime.fromISO(date.latest, {
            setZone: true,
          }).toFormat("HH:mm");
          const thisProduct = productsApiResource
            .getData()
            .find((product) => product.minervaId === date.product);
          return {
            title: `${
              thisProduct ? thisProduct.name : ""
            }:\n${startString} - ${endString}\nSlots: ${date.slots}`,
            start: DateTime.fromISO(date.earliest, {
              setZone: true,
            }).toString(),
            end: date.latest,
            url: date.minervaId,
          };
        });
      }
      return [];
    });

    const timeZoneForCal = ref("");

    watch(
      () => pharmacyAvailableDatesApiResource.getData(),
      () => {
        if (pharmacyAvailableDatesApiResource.getData().length > 0) {
          const firstDate = pharmacyAvailableDatesApiResource.getData()[0]
            .earliest;
          const timeZone = DateTime.fromISO(firstDate, { setZone: true })
            .zoneName;
          timeZoneForCal.value = timeZone;
        }
      }
    );

    onMounted(() => {
      productsApiResource.fetchResource();
    });

    watch(
      () => searchDateForProductionDates.value,
      () => pharmacyAvailableDatesApiResource.fetchResource()
    );

    const pageLoading = computed(
      () =>
        pharmacyAvailableDatesApiResource.getLoading() ||
        deleteAvailableDateApiResource.getLoading()
    );

    return {
      mappedDates,
      pageLoading,
      timeZoneForCal,
      deletePharmacyAvailableDate,
      setSearchDateForProductionDates,
    };
  },
};
</script>
