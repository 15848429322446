<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="new_user_group" class="title"
        >{{ isRemoving ? "Remove" : "Add" }} Group Permission</label
      >
      <MultiSelect
        v-if="!isRemoving"
        id="new_user_group"
        v-model="newGroups"
        :options="availableNewGroups"
        option-label="display_name"
        append-to="body"
      />
      <MultiSelect
        v-else
        id="new_user_group"
        v-model="removeGroups"
        :options="groupsToRemove"
        option-label="display_name"
        append-to="body"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import MultiSelect from "primevue/multiselect";

import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "AddEditUserGroup",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
    MultiSelect,
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => ({}),
    },
    isRemoving: {
      type: Boolean,
      default: false,
    },
    currentGroups: {
      type: Array,
      default: () => [],
    },
    allGroups: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newGroups = ref([]);
    const removeGroups = ref([]);

    const addUserGroup = (newGroup) =>
      store.dispatch("users/addUserGroup", {
        userId: props.selectedUser.minervaId,
        group: newGroup,
      });

    const addUserGroupApiResource = new ApiResource(
      undefined,
      addUserGroup,
      "Successfully added a group.",
      "There was an error adding a group."
    );

    const deleteUserGroup = (deleteGroup) =>
      store.dispatch("users/deleteUserGroup", {
        userId: props.selectedUser.minervaId,
        deleteGroup,
      });

    const deleteUserGroupApiResource = new ApiResource(
      undefined,
      deleteUserGroup,
      "Successfully deleted a group.",
      "There was an error deleting a group."
    );

    const availableNewGroups = computed(() => {
      if (props.currentGroups && props.currentGroups.length > 0) {
        const currentGroupNames = props.currentGroups.map(
          (group) => group.group_name
        );
        return props.allGroups.filter(
          (group) => !currentGroupNames.includes(group.display_name)
        );
      }
      return props.allGroups;
    });

    const groupsToRemove = computed(() => {
      if (props.currentGroups && props.currentGroups.length > 0) {
        return props.currentGroups.map((group) => {
          const thisGroup = props.allGroups.find(
            (allGroup) => allGroup.display_name === group.group_name
          );
          return {
            ...group,
            display_name: thisGroup.display_name,
          };
        });
      }
      return [];
    });

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      let promises;
      if (!props.isRemoving) {
        promises = newGroups.value.map(async (newGroup) => {
          await addUserGroupApiResource.fetchResource(newGroup);
        });
      } else {
        promises = removeGroups.value.map(async (removeGroup) => {
          await deleteUserGroupApiResource.fetchResource(removeGroup);
        });
      }
      await Promise.all(promises);
      closeForm();
    };

    const formLoading = computed(
      () =>
        addUserGroupApiResource.getLoading() ||
        deleteUserGroupApiResource.getLoading()
    );

    return {
      closeForm,
      formLoading,
      handleSave,
      availableNewGroups,
      newGroups,
      removeGroups,
      groupsToRemove,
    };
  },
};
</script>
