<template>
  <AppDataTable
    :selected-row-data="selectedStudySiteProduct"
    title="Associated Products"
    add-button="Associated Product"
    enable-add-button
    :table-data="allStudySiteProductsFiltered"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedStudySiteProductId"
  >
    <Column header="Site">
      <template #body="{ data }">
        {{ getStudySiteName(data) }}
      </template>
    </Column>
    <Column header="Secondary ID">
      <template #body="{ data }">
        {{ data.secondary_site_id }}
      </template>
    </Column>
    <Column header="Product">
      <template #body="{ data }">
        {{ data.product_name }}
      </template>
    </Column>
    <Column header="Organization">
      <template #body="{ data }">
        {{ data.organization_name }}
      </template>
    </Column>
    <template #addModalContent="{ closeAddModal }">
      <AddEditStudySiteProduct
        :selected-study="selectedStudy"
        :study-sites="studySitesApiResource.getData()"
        :all-study-site-products="allStudySiteProducts"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Product - ${
      selectedStudySiteProduct && selectedStudySiteProduct.display_name
    }`"
  >
    <AddEditStudySiteProduct
      :selected-study="selectedStudy"
      :study-sites="studySitesApiResource.getData()"
      :all-study-site-products="allStudySiteProducts"
      :selected-study-site-product="selectedStudySiteProduct"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditStudySiteProduct from "@/components/Studies/AddEditStudySiteProduct.vue";

import Column from "primevue/column";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "StudySiteProducts",
  components: {
    AppDataTable,
    AddEditStudySiteProduct,
    Dialog,
    Column,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
    studySitesApiResource: {
      type: Object,
      default: () => ({}),
    },
    allStudySiteProducts: {
      type: Array,
      default: () => [],
    },
    studySiteNames: {
      type: Object,
      default: () => ({}),
    },
    searchValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteStudySiteProductHandler(event);
        },
      },
    ];

    const deleteStudySiteProduct = () =>
      store.dispatch("studySiteProducts/deleteStudySiteProduct", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: selectedStudySiteProduct.value.studysite,
        payload: selectedStudySiteProduct.value,
      });
    const deleteStudySiteProductApiResource = new ApiResource(
      undefined,
      deleteStudySiteProduct,
      "Successfully deleted study site product.",
      "There was an error deleting the study site product."
    );

    const selectedStudySiteProductId = ref("");
    const selectedStudySiteProduct = computed(() => {
      if (selectedStudySiteProductId.value.length > 0) {
        return props.allStudySiteProducts.find(
          (product) => product.minervaId === selectedStudySiteProductId.value
        );
      }
      return {};
    });
    const setSelectedStudySiteProductId = (product) => {
      selectedStudySiteProductId.value = product.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        props.parentLoading || deleteStudySiteProductApiResource.getLoading()
    );

    const confirm = useConfirm();

    const getStudySiteName = (studySite) => {
      if (
        Object.prototype.hasOwnProperty.call(
          props.studySiteNames,
          studySite.studysite
        )
      ) {
        return props.studySiteNames[studySite.studysite];
      }
      return "";
    };

    const allStudySiteProductsFiltered = computed(() => {
      if (props.searchValue.length > 0) {
        return props.allStudySiteProducts.filter((network) => {
          const name = getStudySiteName(network);
          if (name.length > 0) {
            return name.toLowerCase().includes(props.searchValue.toLowerCase());
          }
          return true;
        });
      } else {
        return props.allStudySiteProducts;
      }
    });

    const confirmDeleteStudySiteProductHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete study site product ${selectedStudySiteProduct.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteStudySiteProductApiResource.fetchResource();
        },
        reject: () => {
          selectedStudySiteProductId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      contextMenuOptions,
      setSelectedStudySiteProductId,
      selectedStudySiteProduct,
      openEditModal,
      tableLoading,
      getStudySiteName,
      allStudySiteProductsFiltered,
    };
  },
};
</script>
