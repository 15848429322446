<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="name" class="title">Name</label>
      <InputText
        id="name"
        v-model="model.name.$model"
        :class="{ 'p-error': model.name.$invalid }"
      />
      <ErrorMessages :model-property="model.name" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="internal_uri" class="title">Internal URI</label>
      <InputText
        id="internal_uri"
        v-model="model.internal_implementation_uri.$model"
        :class="{ 'p-error': model.internal_implementation_uri.$invalid }"
      />
      <ErrorMessages :model-property="model.internal_implementation_uri" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <div class="p-pt-2 p-mb-1">
        <Checkbox
          id="live_availability_support"
          v-model="newNetwork.live_availability_support"
          :binary="true"
          class="p-mb-1"
        />
        <label for="live_availability_support" class="title p-ml-3 p-mb-0"
          >Live Availability Support</label
        >
      </div>
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <div class="p-pt-2 p-mb-1">
        <Checkbox
          id="delivery_note_support"
          v-model="newNetwork.delivery_note_support"
          :binary="true"
          class="p-mb-1"
        />
        <label for="delivery_note_support" class="title p-ml-3 p-mb-0"
          >Delivery Note Support</label
        >
      </div>
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <div class="p-pt-2 p-mb-1">
        <Checkbox
          id="auto_gen_image_site_id"
          v-model="newNetwork.auto_gen_image_site_id"
          :binary="true"
          class="p-mb-1"
        />
        <label for="auto_gen_image_site_id" class="title p-ml-3 p-mb-0"
          >Autogenerate Site ID</label
        >
      </div>
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="service_email_1" class="title">Network Contact Email 1</label>
      <InputText
        id="service_email_1"
        v-model="model.service_email_1.$model"
        :class="{ 'p-error': model.service_email_1.$invalid }"
      />
      <ErrorMessages :model-property="model.service_email_1" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="service_email_2" class="title">Network Contact Email 2</label>
      <InputText
        id="service_email_2"
        v-model="model.service_email_2.$model"
        :class="{ 'p-error': model.service_email_2.$invalid }"
      />
      <ErrorMessages :model-property="model.service_email_2" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="service_email_3" class="title">Network Contact Email 3</label>
      <InputText
        id="service_email_3"
        v-model="model.service_email_3.$model"
        :class="{ 'p-error': model.service_email_3.$invalid }"
      />
      <ErrorMessages :model-property="model.service_email_3" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="service_email_4" class="title">Network Contact Email 4</label>
      <InputText
        id="service_email_4"
        v-model="model.service_email_4.$model"
        :class="{ 'p-error': model.service_email_4.$invalid }"
      />
      <ErrorMessages :model-property="model.service_email_4" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-12">
      <div class="p-pt-2 p-mb-1">
        <Checkbox
          id="order_detail_mod_after_delivery_support"
          v-model="newNetwork.order_detail_mod_after_delivery_support"
          :binary="true"
          class="p-mb-1"
        />
        <label
          for="order_detail_mod_after_delivery_support"
          class="title p-ml-3 p-mb-0"
          >Modify Order After Delivery Support
        </label>
      </div>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Checkbox from "primevue/checkbox";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";

export default {
  name: "AddStudyAdmin",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Checkbox,
  },
  props: {
    selectedNetwork: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newNetwork = reactive({
      name: "",
      internal_implementation_uri: "",
      live_availability_support: false,
      delivery_note_support: false,
      auto_gen_image_site_id: false,
      order_detail_mod_after_delivery_support: false,
      service_email_1: "",
      service_email_2: "",
      service_email_3: "",
      service_email_4: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage("A network name is required", required),
      },
      internal_implementation_uri: {
        required: helpers.withMessage("An internal URI is required", required),
      },
      service_email_1: {
        required: helpers.withMessage("A contact email is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      service_email_2: {
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      service_email_3: {
        email: helpers.withMessage("Please enter a valid email address", email),
      },
      service_email_4: {
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };

    const model = useVuelidate(rules, newNetwork, { $lazy: true });

    const newNetworkPayload = () => {
      const payload = {
        name: newNetwork.name,
        internal_implementation_uri: newNetwork.internal_implementation_uri,
        live_availability_support: newNetwork.live_availability_support,
        delivery_note_support: newNetwork.delivery_note_support,
        auto_gen_image_site_id: newNetwork.auto_gen_image_site_id,
        order_detail_mod_after_delivery_support:
          newNetwork.order_detail_mod_after_delivery_support,
        service_email_1: newNetwork.service_email_1,
      };
      if (newNetwork.service_email_2.length > 0) {
        payload.service_email_2 = newNetwork.service_email_2;
      }
      if (newNetwork.service_email_3.length > 0) {
        payload.service_email_3 = newNetwork.service_email_3;
      }
      if (newNetwork.service_email_4.length > 0) {
        payload.service_email_4 = newNetwork.service_email_4;
      }

      return payload;
    };

    const createNetwork = () =>
      store.dispatch("studyManagement/createNetwork", newNetworkPayload());

    const createNetworkApiResource = new ApiResource(
      undefined,
      createNetwork,
      "Successfully created new network",
      "There was an error adding the network."
    );

    const updateNetwork = () =>
      store.dispatch("studyManagement/updateNetwork", newNetwork);

    const updateNetworkApiResource = new ApiResource(
      undefined,
      updateNetwork,
      "Successfully updated the network.",
      "There was an error updating the network."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await model.value.$validate()) {
        props.isEditing
          ? await updateNetworkApiResource.fetchResource()
          : await createNetworkApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createNetworkApiResource.getLoading() ||
        updateNetworkApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        newNetwork.minervaId = props.selectedNetwork.minervaId;
        newNetwork.name = props.selectedNetwork.name;
        newNetwork.internal_implementation_uri =
          props.selectedNetwork.internal_implementation_uri;
        newNetwork.live_availability_support =
          props.selectedNetwork.live_availability_support;
        newNetwork.delivery_note_support =
          props.selectedNetwork.delivery_note_support;
        newNetwork.auto_gen_image_site_id =
          props.selectedNetwork.auto_gen_image_site_id;
        newNetwork.order_detail_mod_after_delivery_support =
          props.selectedNetwork.order_detail_mod_after_delivery_support;
        newNetwork.service_email_1 = props.selectedNetwork.service_email_1;
        newNetwork.service_email_2 = props.selectedNetwork.service_email_2;
        newNetwork.service_email_3 = props.selectedNetwork.service_email_3;
        newNetwork.service_email_4 = props.selectedNetwork.service_email_4;
      }
    });

    return {
      closeForm,
      model,
      formLoading,
      handleSave,
      newNetwork,
    };
  },
};
</script>
