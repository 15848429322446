<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="new_pharmacy_technician" class="title"
        >Pharmacy Technician</label
      >
      <Dropdown
        id="new_pharmacy_technician"
        v-model="newPharmacyTechnicianModel.minervauser.$model"
        option-label="display_name"
        option-value="minervaId"
        placeholder="Select a new pharmacy technician"
        append-to="body"
        :options="availableUsers"
        :class="{ 'p-error': newPharmacyTechnicianModel.minervauser.$invalid }"
        :disabled="isEditing"
      />
      <ErrorMessages :model-property="newPharmacyTechnicianModel.minervauser" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="technician_phone_number" class="title">Phone Number</label>
      <InputText
        id="technician_phone_number"
        v-model="newPharmacyTechnician.site_contact_number"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newPharmacyTechnicianModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditPharmacyTechnician",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
  },
  props: {
    selectedPharmacy: {
      type: Object,
      default: () => ({}),
    },
    pharmacyTechnicians: {
      type: Array,
      default: () => [],
    },
    nonGSAUsers: {
      type: Array,
      default: () => [],
    },
    selectedPharmacyTechnician: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPharmacyTechnician = reactive({
      pharmacy: props.selectedPharmacy.minervaId,
      minervauser: "",
      site_contact_number: "",
    });

    const rules = {
      minervauser: {
        required: helpers.withMessage("A user is required", required),
      },
    };

    const newPharmacyTechnicianModel = useVuelidate(
      rules,
      newPharmacyTechnician,
      { $lazy: true }
    );

    const availableUsers = computed(() => {
      if (props.isEditing) {
        return props.nonGSAUsers;
      }
      const currentPharmacyTechnicians = props.pharmacyTechnicians.map(
        (user) => user.minervauser
      );

      return props.nonGSAUsers.filter(
        (user) => !currentPharmacyTechnicians.includes(user.minervaId)
      );
    });

    const createPharmacyTechnician = () =>
      store.dispatch("pharmacies/createPharmacyTechnician", {
        pharmacyId: props.selectedPharmacy.minervaId,
        payload: newPharmacyTechnician,
      });

    const createPharmacyTechnicianApiResource = new ApiResource(
      undefined,
      createPharmacyTechnician,
      "Successfully created new pharmacy technician",
      "There was an error creating the new pharmacy technician."
    );

    const updatePharmacyTechnician = () =>
      store.dispatch("pharmacies/updatePharmacyTechnician", {
        pharmacyId: props.selectedPharmacy.minervaId,
        payload: newPharmacyTechnician,
      });

    const updatePharmacyTechnicianApiResource = new ApiResource(
      undefined,
      updatePharmacyTechnician,
      "Successfully updated the pharmacy technician.",
      "There was an error updating the pharmacy technician."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newPharmacyTechnicianModel.value.$validate()) {
        props.isEditing
          ? await updatePharmacyTechnicianApiResource.fetchResource()
          : await createPharmacyTechnicianApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createPharmacyTechnicianApiResource.getLoading() ||
        updatePharmacyTechnicianApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        newPharmacyTechnician.minervauser =
          props.selectedPharmacyTechnician.minervauser;
        newPharmacyTechnician.site_contact_number =
          props.selectedPharmacyTechnician.site_contact_number;
        newPharmacyTechnician.minervaId =
          props.selectedPharmacyTechnician.minervaId;
      }
    });

    return {
      newPharmacyTechnician,
      closeForm,
      newPharmacyTechnicianModel,
      formLoading,
      handleSave,
      availableUsers,
    };
  },
};
</script>
