<template>
  <span class="title" :class="classes">{{ title }}</span>
  <span class="detail" :class="classes">{{ data }}</span>
</template>

<script>
export default {
  name: "FormDataView",
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: [String, Number],
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
