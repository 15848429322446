<template>
  <AppDataList
    title="Ordering Administrators"
    display-prop="display_name"
    :data="userObjects"
    :loading="loading"
    @delete-entity="confirmDeleteOrderingAdmin($event, admin)"
  >
    <template #addModalContent="{ closeAddModal }">
      <FormWrapper :loading="loading">
        <FormCol col-width="p-md-6">
          <label for="ordering_admin_name" class="title"
            >New Ordering Administrator</label
          >
          <Dropdown
            id="ordering_admin_name"
            v-model="studyOrderAdminModel.newOrderAdmin.$model"
            :options="availableUsers"
            option-label="display_name"
            option-value="minervaId"
            placeholder="Select a new ordering administrator"
            :class="{ 'p-error': studyOrderAdminModel.newOrderAdmin.$invalid }"
            append-to="body"
          />
          <ErrorMessages :model-property="studyOrderAdminModel.newOrderAdmin" />
        </FormCol>
        <Divider />
        <FormButtonCol>
          <CancelConfirmButtons
            :disabled="loading || studyOrderAdminModel.$invalid"
            cancel-text="CLOSE"
            confirm-text="SUBMIT"
            @cancelled="closeAddModal()"
            @confirmed="handleSave(closeAddModal)"
          />
        </FormButtonCol>
      </FormWrapper>
    </template>
  </AppDataList>
</template>

<script>
import AppDataList from "@/components/AppDataList/AppDataList";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import { inject, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useConfirm";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "StudyOrderingAdmins",
  components: {
    AppDataList,
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Dropdown,
    Divider,
  },
  props: {
    selectedStudy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const orderingAdmins = () =>
      store.state.orderingAdmins.orderingAdmins[props.selectedStudy.minervaId];
    const fetchOrderingAdmins = () =>
      store.dispatch("orderingAdmins/fetchOrderingAdmins", {
        studyId: props.selectedStudy.minervaId,
      });

    const orderingAdminsApiResource = new ApiResource(
      orderingAdmins,
      fetchOrderingAdmins,
      undefined,
      "There was an error fetching the study ordering administrators."
    );

    const nonGSAUsers = () => store.state.users.nonGSAUsers;
    const fetchNonGSAUsers = () => store.dispatch("users/fetchNonGSAUsers");

    const nonGSAUsersApiResource = new ApiResource(
      nonGSAUsers,
      fetchNonGSAUsers,
      undefined,
      "There was an error fetching the study users."
    );

    const userObjects = computed(() => {
      if (
        orderingAdminsApiResource.getData() &&
        nonGSAUsersApiResource.getData() &&
        orderingAdminsApiResource.getData().length > 0 &&
        nonGSAUsersApiResource.getData().length > 0
      ) {
        return orderingAdminsApiResource.getData().map((user) => {
          const userObject = nonGSAUsersApiResource
            .getData()
            .find((studyUser) => studyUser.minervaId === user.minervauser);

          return {
            display_name: userObject.display_name,
            minervaId: user.minervaId,
          };
        });
      }
      return [];
    });

    const availableUsers = computed(() => {
      const currentAdmins = orderingAdminsApiResource
        .getData()
        .map((user) => user.minervauser);

      return nonGSAUsersApiResource
        .getData()
        .filter((user) => !currentAdmins.includes(user.minervaId));
    });

    const newOrderAdmin = ref("");

    const rules = {
      newOrderAdmin: {
        required: helpers.withMessage(
          "An ordering administrator is required",
          required
        ),
      },
    };

    const studyOrderAdminModel = useVuelidate(
      rules,
      { newOrderAdmin },
      { $lazy: true }
    );

    const addOrderingAdmin = (userId) =>
      store.dispatch("orderingAdmins/addOrderingAdmin", {
        studyId: props.selectedStudy.minervaId,
        payload: {
          study: props.selectedStudy.minervaId,
          minervauser: userId,
        },
      });

    const addOrderingAdminApiResource = new ApiResource(
      undefined,
      addOrderingAdmin,
      "Successfully added ordering administrator.",
      "There was an error adding the ordering administrator."
    );

    const handleSave = async (close) => {
      if (await studyOrderAdminModel.value.$validate()) {
        await addOrderingAdminApiResource.fetchResource(newOrderAdmin.value);
        close();
      }
    };

    const deleteOrderingAdmin = (payload) =>
      store.dispatch("orderingAdmins/deleteOrderingAdmin", {
        studyId: props.selectedStudy.minervaId,
        payload,
      });

    const deleteOrderingAdminApiResource = new ApiResource(
      undefined,
      deleteOrderingAdmin,
      "Successfully removed ordering administrator.",
      "There was an error removing the ordering administrator."
    );

    const confirm = useConfirm();

    const confirmDeleteOrderingAdmin = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you wish to remove this ordering administrator?",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await deleteOrderingAdminApiResource.fetchResource(event);
        },
        reject: () => {},
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const loading = computed(
      () =>
        orderingAdminsApiResource.getLoading() ||
        nonGSAUsersApiResource.getLoading() ||
        deleteOrderingAdminApiResource.getLoading() ||
        addOrderingAdminApiResource.getLoading()
    );

    onMounted(() => {
      orderingAdminsApiResource.fetchResource();
      if (!nonGSAUsersApiResource.getData().value)
        nonGSAUsersApiResource.fetchResource();
    });

    return {
      loading,
      availableUsers,
      userObjects,
      confirmDeleteOrderingAdmin,
      handleSave,
      studyOrderAdminModel,
    };
  },
};
</script>
