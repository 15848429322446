<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="role_list" class="title">Available Roles</label>
      <DropDown
        v-model="newUserRoleModel.role_name.$model"
        :options="allRolesApiResource.getData()"
        option-label="display_name"
        option-value="name"
        placeholder="Select Role"
        :class="{
          'p-invalid': newUserRoleModel.role_name.$invalid,
        }"
        append-to="body"
        :filter="true"
      />
      <ErrorMessages :model-property="newUserRoleModel.role_name" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="resource_uri" class="title">Resource URI</label>
      <InputText
        id="resource_uri"
        v-model="newUserRoleModel.resource_uri.$model"
        :class="{ 'p-error': newUserRoleModel.resource_uri.$invalid }"
      />
      <ErrorMessages :model-property="newUserRoleModel.resource_uri" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newUserRoleModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import DropDown from "primevue/dropdown";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddUserRole",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    DropDown,
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newUserRole = reactive({
      role_name: "",
      resource_uri: "",
      minervauser_email: props.selectedUser.email,
    });

    const rules = {
      role_name: {
        required: helpers.withMessage("A role name is required", required),
      },
      resource_uri: {
        required: helpers.withMessage("A resource URI is required", required),
      },
    };

    const newUserRoleModel = useVuelidate(rules, newUserRole, { $lazy: true });

    const addRoleToUser = () =>
      store.dispatch("rolesPermissions/addRoleByUser", {
        user: props.selectedUser,
        payload: newUserRole,
      });

    const addRoleToUserApiResource = new ApiResource(
      undefined,
      addRoleToUser,
      "Successfully added the role to the selected user",
      "There was an error adding the role to the selected user."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newUserRoleModel.value.$validate()) {
        await addRoleToUserApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        allRolesApiResource.getLoading() ||
        addRoleToUserApiResource.getLoading()
    );

    const allRoles = () => store.state.rolesPermissions.roles;
    const fetchAllRoles = () => store.dispatch("rolesPermissions/fetchRoles");

    const allRolesApiResource = new ApiResource(
      allRoles,
      fetchAllRoles,
      undefined,
      "There was an error fetching your roles."
    );

    onMounted(() => {
      allRolesApiResource.fetchResource();
    });

    return {
      closeForm,
      newUserRoleModel,
      allRolesApiResource,
      formLoading,
      handleSave,
    };
  },
};
</script>
