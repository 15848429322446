<template>
  <transition :name="transitionDirection" mode="out-in">
    <div v-if="!viewProductionDates && !viewDocuments" key="parent">
      <AppDataTable
        :selected-row-data="selectedPharmacyOrder"
        title="Pharmacy Order Management"
        :show-add-button="false"
        :table-data="tableData"
        :loading="tableLoading"
        :pharmacy-order-table="true"
        :context-menu-options="contextMenuOptions"
        @update:selected-row-data="setSelectedPharmacyOrderId"
        @last-page="fetchNextSetOfOrders"
      >
        <Column header="Order ID">
          <template #body="{ data }">
            {{ data?.order_data?.order_id ?? "" }}
          </template>
        </Column>
        <Column header="Site">
          <template #body="{ data }">
            {{ data?.site_info?.name ?? "" }}
          </template>
        </Column>
        <Column header="Pharmacy">
          <template #body="{ data }">
            <span
              v-if="getPharmacyName(data) === 'Unassigned'"
              class="p-tag p-tag-warning"
              style="font-size: 1em"
            >
              {{ getPharmacyName(data) }}
            </span>
            <span v-else>{{ getPharmacyName(data) }}</span>
          </template>
        </Column>
        <Column header="Subject">
          <template #body="{ data }">
            {{ data?.order_data?.subject_id ?? "" }}
          </template>
        </Column>
        <Column header="Product">
          <template #body="{ data }">
            {{ data?.product_info?.name ?? "" }}
          </template>
        </Column>
        <Column header="Amount">
          <template #body="{ data }">
            {{
              data?.order_data?.amount
                ? getAmountString(data.order_data.amount)
                : ""
            }}
          </template>
        </Column>
        <Column header="Date">
          <template #body="{ data }">
            {{
              data?.order_data?.local_calibration
                ? getDate(data.order_data.local_calibration)
                : ""
            }}
          </template>
        </Column>
        <Column header="Time">
          <template #body="{ data }">
            {{
              data?.order_data?.local_calibration
                ? getTime(data.order_data.local_calibration)
                : ""
            }}
          </template>
        </Column>
        <Column header="Status">
          <template #body="{ data }">
            <span
              v-if="getStatusText(data)"
              class="p-tag"
              :class="getStatusColor(data)"
              style="font-size: 1em"
            >
              {{ getStatusText(data) }}
            </span>
          </template>
        </Column>
        <Column header="Courier">
          <template #body="{ data }">
            <div
              v-if="data?.active_pharmacy?.courier_needed != null"
              class="p-flex p-pt-2"
            >
              <span
                class="p-tag"
                style="font-size: 1em"
                :class="getCourierColor(data)"
              >
                {{ data?.active_pharmacy?.courier_needed ? "Yes" : "No" }}
              </span>
              <p v-if="data?.active_pharmacy?.courier_needed" class="p-pt-2">
                {{ getWaybillText(data) }}
              </p>
            </div>
          </template>
        </Column>
        <Column header="Pickup Time">
          <template #body="{ data }">
            <span
              v-if="data?.active_pharmacy?.courier_needed ?? false"
              class="p-tag p-tag-warning"
              style="font-size: 1em"
            >
              {{
                data?.active_pharmacy?.courier_needed
                  ? getTime(data.active_pharmacy.pickup_time)
                  : ""
              }}
            </span>
          </template>
        </Column>
        <template #headerContent>
          <div class="p-d-flex p-ai-center">
            <div
              v-if="adminUser"
              class="p-d-flex p-ai-end p-pb-1 p-mr-4"
              style="height: 34px"
            >
              <InputSwitch v-model="allOrders" />
              <label class="checkbox-text p-ml-3 p-mb-1">All Orders</label>
            </div>
            <div
              v-if="adminUser"
              class="p-d-flex p-ai-end p-pb-1 p-mr-4"
              style="height: 34px"
            >
              <InputSwitch v-model="unassigned" :disabled="!allOrders" />
              <label class="checkbox-text p-ml-3 p-mb-1">Only Unassigned</label>
            </div>
            <!-- <div class="p-mr-4">
              <span class="p-mr-2">Time Frame:</span>
              <Dropdown
                v-model="selectedViewOption"
                :options="viewOptions"
                append-to="body"
              />
            </div> -->
            <Dropdown
              v-model="selectedPharmacy"
              :options="permittedPharmaciesApiResource.getData()"
              option-label="display_name"
              :disabled="allOrders"
              :placeholder="permittedPharmaciesPlaceholder"
              :class="{
                'p-invalid':
                  permittedPharmaciesApiResource.getData().length > 0 &&
                  Object.keys(selectedPharmacy).length === 0,
              }"
              append-to="body"
              :filter="true"
            />
            <Button
              class="p-ml-4"
              :disabled="Object.keys(selectedPharmacy).length === 0"
              @click="viewDocuments = true"
              >Documents</Button
            >
            <Button
              class="p-ml-4"
              :disabled="Object.keys(selectedPharmacy).length === 0"
              @click="viewProductionDates = true"
              >View Production Dates</Button
            >
          </div>
        </template>
      </AppDataTable>
      <div class="p-mt-4 p-d-flex">
        <div class="p-col-8 p-offset-2">
          <PharmacyOrderCalendar
            v-if="Object.keys(selectedPharmacy).length > 0 && !allOrders"
            :selected-pharmacy="selectedPharmacy"
            @order-options="onContextMenu"
          />
        </div>
      </div>
    </div>
    <div v-else key="child">
      <div class="p-d-flex p-ai-start p-ml-2">
        <Button
          v-if="viewProductionDates"
          label="Back"
          class="p-mb-4"
          @click="viewProductionDates = false"
        />
        <Button
          v-if="viewDocuments"
          label="Back"
          class="p-mb-4"
          @click="viewDocuments = false"
        />
        <h4
          v-if="selectedPharmacy && selectedPharmacy.display_name"
          class="p-ml-4"
          style="margin: 0px"
        >
          {{
            `Pharmacy - ${selectedPharmacy && selectedPharmacy.display_name}`
          }}
        </h4>
      </div>
      <div v-if="viewProductionDates">
        <PharmacyDetailsWrapper
          :selected-pharmacy="selectedPharmacy"
          :view-technicians="false"
        />
      </div>
      <PharmacyDocuments v-if="viewDocuments" :pharmacy="selectedPharmacy" />
    </div>
  </transition>
  <Dialog
    v-model:visible="openDetailModal"
    :modal="true"
    :header="`Pharmacy Order Details - ${selectedPharmacyOrderId}`"
    :dismissable-mask="true"
  >
    <ViewPharmacyOrder :pharmacy-order="selectedPharmacyOrder" />
  </Dialog>
  <Dialog
    v-model:visible="openWaybillModal"
    :modal="true"
    :header="`Pharmacy Order Waybill - ${selectedPharmacyOrderId}`"
    :dismissable-mask="true"
  >
    <AddEditWaybill
      :selected-pharmacy-order="selectedPharmacyOrder"
      @close-form="openWaybillModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openAcceptModal"
    :modal="true"
    :header="`Accept Order - ${selectedPharmacyOrderId}`"
  >
    <AcceptPharmacyOrder
      :pharmacy-order="selectedPharmacyOrder"
      @close-form="openAcceptModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openSuggestionModal"
    :modal="true"
    :header="`Suggest New Time - ${selectedPharmacyOrderId}`"
  >
    <SuggestPharmacyOrderDateTime
      :pharmacy-order="selectedPharmacyOrder"
      @close-form="openSuggestionModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openRejectModal"
    :modal="true"
    :header="`Cancel Order - ${selectedPharmacyOrderId}`"
  >
    <RejectPharmacyOrder
      :pharmacy-order="selectedPharmacyOrder"
      @close-form="openRejectModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="openRerouteModal"
    :modal="true"
    :header="`Reroute Order - ${selectedPharmacyOrderId}`"
  >
    <ReroutePharmacyOrder
      :pharmacy-order="selectedPharmacyOrder"
      @close-form="openRerouteModal = false"
    />
  </Dialog>
  <ContextMenu ref="contextMenuPharmacyOrder" :model="contextMenuOptions" />
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import ContextMenu from "primevue/contextmenu";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import PharmacyDetailsWrapper from "@/components/Pharmacies/PharmacyDetailsWrapper.vue";
import PharmacyDocuments from "@/components/Pharmacies/PharmacyDocuments.vue";
import ViewPharmacyOrder from "@/components/Pharmacies/ViewPharmacyOrder";
import SuggestPharmacyOrderDateTime from "@/components/Pharmacies/SuggestPharmacyOrderDateTime";
import RejectPharmacyOrder from "@/components/Pharmacies/RejectPharmacyOrder";
import ReroutePharmacyOrder from "@/components/Pharmacies/ReroutePharmacyOrder";
import AcceptPharmacyOrder from "@/components/Pharmacies/AcceptPharmacyOrder";
import AddEditWaybill from "@/components/Pharmacies/AddEditWaybill";
import PharmacyOrderCalendar from "@/components/Pharmacies/PharmacyOrderCalendar.vue";
import { getUserRole } from "@/helpers/getUserRole";
import { computed, ref, watch, inject, onMounted } from "vue";
import { useStore } from "vuex";
import { getDate, getTime } from "@/helpers/timeHelpers";
import { DateTime } from "luxon";
import { getAmountString } from "@/helpers/units";

export default {
  name: "PharmacyOrders",
  components: {
    AppDataTable,
    Dropdown,
    Dialog,
    Column,
    InputSwitch,
    ViewPharmacyOrder,
    SuggestPharmacyOrderDateTime,
    RejectPharmacyOrder,
    ReroutePharmacyOrder,
    PharmacyDetailsWrapper,
    AcceptPharmacyOrder,
    ContextMenu,
    AddEditWaybill,
    PharmacyOrderCalendar,
    PharmacyDocuments,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const auth = inject("Auth");
    const user = auth?.user?.value ?? undefined;

    const adminUser = computed(() => {
      if (user) {
        const pharmacyRoles = getUserRole(user, "pharmacy_order");
        if (pharmacyRoles) {
          return pharmacyRoles.some(
            (role) =>
              role.role === "SuperAdmin" || role.role === "PharmacyOrderAdmin"
          );
        }
        return false;
      }
      return false;
    });

    // table setup
    const contextMenuOptions = [
      {
        label: "View Details",
        command: () => {
          openDetailModal.value = true;
        },
      },
      {
        label: "Add/Modify Waybill",
        command: () => {
          openWaybillModal.value = true;
        },
      },
      {
        label: "Accept Order",
        command: () => {
          openAcceptModal.value = true;
        },
        visible: () => {
          return selectedPharmacyOrder.value?.confirmation_id.length === 0;
        },
      },
      {
        label: "Suggest New Time",
        command: () => {
          openSuggestionModal.value = true;
        },
        visible: () => {
          return (
            selectedPharmacyOrder.value?.active_pharmacy?.minervaId.length >
              0 ?? false
          );
        },
      },
      {
        label: "Reroute Order",
        command: () => {
          openRerouteModal.value = true;
        },
        visible: () => {
          return adminUser.value;
        },
      },
      {
        label: "Cancel Order",
        visible: () => {
          return (
            selectedPharmacyOrder.value?.active_pharmacy?.minervaId.length >
              0 ?? false
          );
        },
        command: () => {
          openRejectModal.value = true;
        },
      },
    ];

    const contextMenuPharmacyOrder = ref(null);
    const onContextMenu = (event, order) => {
      if (!order?.order_data?.order_id ?? false) return;
      setSelectedPharmacyOrderId(order);
      contextMenuPharmacyOrder.value.show(event);
    };
    const permittedPharmacies = () =>
      store.state.pharmacyOrders.permittedPharmacies;
    const fetchPermittedPharmacies = () =>
      store.dispatch("pharmacyOrders/fetchPermittedPharmacies");
    const permittedPharmaciesApiResource = new ApiResource(
      permittedPharmacies,
      fetchPermittedPharmacies,
      undefined,
      "There was an error fetching your permitted Pharmacies."
    );

    const pharmacyOrdersOffset = ref(0);

    const pharmacyOrders = () => store.state.pharmacyOrders.pharmacyOrders;
    const fetchPharmacyOrders = () =>
      store.dispatch("pharmacyOrders/fetchPharmacyOrders", {
        pharmacyId: selectedPharmacy.value.minervaId,
        offset: pharmacyOrdersOffset.value,
      });

    const pharmacyOrdersApiResource = new ApiResource(
      pharmacyOrders,
      fetchPharmacyOrders,
      undefined,
      "There was an error fetching your pharmacy orders."
    );

    const fetchNextSetOfOrders = async () => {
      pharmacyOrdersOffset.value += 20;
      if (allOrders.value) {
        await allPharmacyOrdersApiResource.fetchResource();
        return;
      }

      if (Object.keys(selectedPharmacy.value).length > 0) {
        await pharmacyOrdersApiResource.fetchResource();
      }
    };

    const fetchAllPharmacyOrders = () =>
      store.dispatch("pharmacyOrders/fetchAllPharmacyOrders", {
        offset: pharmacyOrdersOffset.value,
      });
    const allPharmacyOrdersApiResource = new ApiResource(
      pharmacyOrders,
      fetchAllPharmacyOrders,
      undefined,
      "There was an error fetching your pharmacy orders."
    );

    const viewOptions = ["All", "Future", "Past"];
    const selectedViewOption = ref("All");

    const openDetailModal = ref(false);
    const openWaybillModal = ref(false);
    const openSuggestionModal = ref(false);
    const openAcceptModal = ref(false);
    const openRejectModal = ref(false);
    const openRerouteModal = ref(false);
    const allOrders = ref(false);
    const unassigned = ref(false);
    const selectedPharmacy = ref({});
    const selectedPharmacyOrder = ref({});
    const selectedPharmacyOrderId = ref("");

    const setSelectedPharmacyOrderId = (order) => {
      selectedPharmacyOrderId.value = order.order_data.order_id;
      setSelectedPharmacyOrder(order);
    };
    const setSelectedPharmacyOrder = (order) => {
      selectedPharmacyOrder.value = order;
    };

    const getStatusText = (order) => {
      if (order.completed) return "Completed";
      if (order.cancelled) return "Cancelled";
      if (order.confirmation_id !== undefined)
        return order.confirmation_id === "" ? "Unconfirmed" : "Confirmed";

      return "";
    };

    const getStatusColor = (order) => {
      if (order.completed) return "p-tag-success";
      if (order.cancelled) return "p-tag-danger";
      if (order.confirmation_id !== undefined)
        return order.confirmation_id === "" ? "p-tag-warning" : "p-tag-success";

      return "";
    };

    const getCourierColor = (order) => {
      if (order?.active_pharmacy?.courier_needed ?? false)
        return "p-tag-warning";
      return "p-tag-grey";
    };

    const getPharmacyName = (order) => {
      if (!Object.keys(order).length) return "";
      if (order?.active_pharmacy?.name.length > 0)
        return order.active_pharmacy.name;
      return "Unassigned";
    };

    const fetchTableData = () => {
      pharmacyOrdersOffset.value = 0;
      if (allOrders.value) {
        allPharmacyOrdersApiResource.fetchResource();
        return;
      }

      if (Object.keys(selectedPharmacy.value).length > 0) {
        pharmacyOrdersApiResource.fetchResource();
      }
    };

    const tableData = computed(() => {
      const data = allOrders.value
        ? allPharmacyOrdersApiResource.getData()
        : pharmacyOrdersApiResource.getData();
      // const sortedData = data.sort(
      //   (a, b) =>
      //     DateTime.fromISO(b.order_data.local_calibration) -
      //     DateTime.fromISO(a.order_data.local_calibration)
      // );
      const firstFilter = unassigned.value
        ? data.filter((data) => data.unassigned)
        : data;
      if (selectedViewOption.value === "All") return firstFilter;
      if (selectedViewOption.value === "Future") {
        return firstFilter.filter((order) => {
          return (
            DateTime.fromISO(order.order_data.local_calibration, {
              setZone: true,
            }).startOf("day") > DateTime.local().startOf("day")
          );
        });
      }
      if (selectedViewOption.value === "Past") {
        return firstFilter.filter((order) => {
          return (
            DateTime.fromISO(order.order_data.local_calibration, {
              setZone: true,
            }).startOf("day") < DateTime.local().startOf("day")
          );
        });
      }
      return [];
    });

    const getWaybillText = (pharmacyOrder) => {
      const activePharmacy = pharmacyOrder.active_pharmacy;
      if (activePharmacy.waybill == "") return "";
      return `${activePharmacy.courier} | ${activePharmacy.courier_type} | ${activePharmacy.waybill}`;
    };

    const tableLoading = computed(
      () =>
        pharmacyOrdersApiResource.getLoading() ||
        permittedPharmaciesApiResource.getLoading() ||
        allPharmacyOrdersApiResource.getLoading()
    );

    const permittedPharmaciesPlaceholder = computed(() =>
      permittedPharmaciesApiResource.getLoading()
        ? "Loading pharmacies"
        : "Select a pharmacy"
    );

    const viewDocuments = ref(false);
    const viewProductionDates = ref(false);
    const transitionDirection = computed(() =>
      viewProductionDates.value || viewDocuments.value
        ? "page-right"
        : "page-left"
    );

    watch(
      () => selectedPharmacy.value,
      () => fetchTableData()
    );
    watch(
      () => allOrders.value,
      () => fetchTableData()
    );

    onMounted(() => {
      permittedPharmaciesApiResource.fetchResource();
    });

    return {
      contextMenuOptions,
      tableData,
      tableLoading,
      adminUser,
      allOrders,
      unassigned,
      selectedPharmacy,
      selectedPharmacyOrder,
      permittedPharmaciesApiResource,
      permittedPharmaciesPlaceholder,
      openDetailModal,
      openWaybillModal,
      openSuggestionModal,
      openRejectModal,
      getStatusText,
      setSelectedPharmacyOrderId,
      selectedPharmacyOrderId,
      getPharmacyName,
      openRerouteModal,
      getStatusColor,
      getDate,
      getTime,
      viewDocuments,
      viewProductionDates,
      transitionDirection,
      openAcceptModal,
      getCourierColor,
      viewOptions,
      selectedViewOption,
      getAmountString,
      onContextMenu,
      contextMenuPharmacyOrder,
      getWaybillText,
      fetchNextSetOfOrders,
      allPharmacyOrdersApiResource,
    };
  },
};
</script>

<style lang="scss">
.p-tag.p-tag-grey {
  background-color: grey;
  color: #ffffff;
}

.pharmacy-order-column {
  background-color: #fff;
  padding: 8px;
  margin: 8px;
  border: 1px solid #fff;
  border-radius: 8px;
}

.negative-margin {
  margin-top: -10px;
  margin-bottom: -10px;
}
</style>
