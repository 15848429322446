<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="suggested_new_date" class="title">Date</label>
      <Calendar
        id="suggested_new_date"
        :model-value="calendarDate"
        date-format="yy-mm-dd"
        :disabled-dates="isSiteOrder ? disabledDates : []"
        :disabled-days="[0]"
        :min-date="new Date()"
        :manual-input="false"
        append-to="body"
        :disabled="!enableDateInput"
        :placeholder="suggestedDatePlaceholder"
        :class="{ 'p-error': model.suggested_date.$invalid }"
        @update:modelValue="handleDateInput(model, $event)"
        @month-change="setSearchDateForProductionDates"
      >
      </Calendar>
      <div
        v-if="isSiteOrder"
        class="p-d-flex p-ai-end p-pb-2"
        style="height: 69px"
      >
        <Checkbox
          id="enable_date"
          v-model="enableDateInput"
          :binary="true"
          class="p-mb-1"
        />
        <label for="enable_date" class="checkbox-text p-ml-2 p-mb-1"
          >Enable Date Modification</label
        >
      </div>
      <ErrorMessages :model-property="model.suggested_date" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="suggested_new_time" class="title">Time</label>
      <Calendar
        id="suggested_new_time"
        :model-value="suggestedTime"
        :time-only="true"
        :placeholder="suggestedTimePlaceholder"
        append-to="body"
        :disabled="isSiteOrder && newDateTime.suggested_date.length === 0"
        :class="{ 'p-error': model.suggested_time.$invalid }"
        @update:model-value="handleTimeInput(model, $event)"
      />
      <ErrorMessages :model-property="model.suggested_time" />
      <InlineMessage severity="info" class="p-mt-2"
        >Available Times:
        {{ formatAvailableTime(timesForSelectedDate) }}
      </InlineMessage>
    </FormCol>
    <Divider />
    <FormCol v-if="isPharmacyOrder" col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="need_courier"
          v-model="acceptOrderPayload.courier_needed"
          :binary="true"
          class="p-mb-1"
        />
        <label for="need_courier" class="checkbox-text p-ml-2 p-mb-1"
          >Courier Needed?</label
        >
      </div>
    </FormCol>
    <FormCol v-if="acceptOrderPayload.courier_needed" col-width="p-md-6">
      <label for="pickup_time" class="title">Pickup Time</label>
      <Calendar
        id="pickup_time"
        :model-value="pickupTime"
        :time-only="true"
        placeholder="Select a time"
        append-to="body"
        :class="{ 'p-error': pharmacyModel.pickup_time.$invalid }"
        @update:model-value="setPickupTime(pharmacyModel, $event)"
      />
      <ErrorMessages :model-property="pharmacyModel.pickup_time" />
    </FormCol>
    <Divider v-if="isPharmacyOrder" />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || pharmacyModel.$invalid || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="sendData"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import InlineMessage from "primevue/inlinemessage";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import { defaultTimeSlots } from "@/helpers/defaultTimeSlots";
import { DateTime } from "luxon";
import { reactive, computed, inject, onMounted, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import {
  calibrationDate,
  getTime,
  getDate,
  setTZ,
  setTime,
  displayTime,
  setTimeFromLocalCalibration,
} from "@/helpers/timeHelpers";

export default {
  name: "SuggestNewDateTime",
  components: {
    FormWrapper,
    FormButtonCol,
    FormCol,
    Calendar,
    CancelConfirmButtons,
    ErrorMessages,
    Divider,
    InlineMessage,
    Checkbox,
  },
  props: {
    sparrowOrder: {
      type: Object,
      default: () => ({}),
    },
    sparrowId: {
      type: String,
      default: "",
    },
    isSiteOrder: {
      type: Boolean,
      default: false,
    },
    isPharmacyOrder: {
      type: Boolean,
      default: false,
    },
    originalOrder: {
      type: Object,
      default: () => ({}),
    },
    studySite: {
      type: Object,
      default: () => ({}),
    },
    pharmacy: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form", "suggestion-success"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");

    const newDateTime = reactive({
      suggested_date: "",
      suggested_time: "",
    });

    const acceptOrderPayload = reactive({
      courier_needed: false,
      pickup_time: "",
    });

    const pickupTimeBeforeOrderTime = (input) => {
      if (newDateTime.suggested_time.length === 0) return false;
      const selectedTime = Number(input.replace("-", ""));
      const orderTime = Number(newDateTime.suggested_time.replace("-", ""));
      return selectedTime <= orderTime;
    };

    const pharmacyRules = {
      pickup_time: {
        requiredIf: helpers.withMessage(
          "A pickup time is required",
          requiredIf(() => acceptOrderPayload.courier_needed)
        ),
        pickupTimeBeforeOrderTime: helpers.withMessage(
          "The pickup time must be before the order time",
          pickupTimeBeforeOrderTime
        ),
      },
    };

    const pharmacyModel = useVuelidate(pharmacyRules, acceptOrderPayload, {
      $lazy: true,
    });

    const pickupTime = computed(() => {
      if (acceptOrderPayload.pickup_time.length > 0) {
        return displayTime(acceptOrderPayload.pickup_time);
      }
    });

    const setPickupTime = (model, time) => {
      model.pickup_time.$model = setTime(time);
    };

    const dateWithinAvailableRange = (input) => {
      let validDate = true;
      if (input) {
        const userInput = DateTime.fromISO(new Date(input).toISOString(), {
          setZone: true,
        });

        if (props.isSiteOrder) {
          let searchDate = DateTime.fromISO(
            new Date(searchDateForProductionDates.value).toISOString(),
            {
              setZone: true,
            }
          );
          if (userInput.month !== searchDate.month) {
            validDate = false;
          }
          disabledDates.value.forEach((date) => {
            const disabledDate = DateTime.fromISO(
              new Date(date).toISOString(),
              {
                setZone: true,
              }
            );
            if (disabledDate.day === userInput.day) {
              validDate = false;
            }
          });
        } else {
          const selectedDay = userInput.toFormat("ccc");
          if (selectedDay === "Sun") {
            validDate = false;
          }
        }
      }
      return validDate;
    };

    const timeWithinAvailableRange = (input) => {
      const selectedTime = Number(input.replace("-", ""));
      const timesToValidateAgainst = timesForSelectedDate.value;
      const minTime = Number(timesToValidateAgainst.minTime.replace(":", ""));
      const maxTime = Number(timesToValidateAgainst.maxTime.replace(":", ""));
      return selectedTime >= minTime && selectedTime <= maxTime;
    };

    const rules = {
      suggested_date: {
        required: helpers.withMessage("A suggested date is required", required),
        dateWithinAvailableRange: helpers.withMessage(
          "The selected date must be within the available range",
          dateWithinAvailableRange
        ),
      },
      suggested_time: {
        required: helpers.withMessage("A suggested time is required", required),
        timeWithinAvailableRange: helpers.withMessage(
          "The selected time must be within the available range",
          timeWithinAvailableRange
        ),
      },
    };

    const model = useVuelidate(rules, newDateTime, { $lazy: true });

    const enableDateInput = ref(false);

    const calendarDate = computed(() => {
      return newDateTime.suggested_date === ""
        ? ""
        : new Date(
            DateTime.fromISO(newDateTime.suggested_date)
              .plus({ hours: 12 })
              .toISO()
          );
    });

    const suggestedTime = computed(() => {
      if (newDateTime.suggested_time.length > 0) {
        return displayTime(newDateTime.suggested_time);
      }
    });

    const closeForm = () => emit("close-form");
    const emitSuccess = () => emit("suggestion-success");

    const suggestNewDateTime = () => {
      const payload = {
        suggested_calibration: calibrationDate(
          newDateTime.suggested_date,
          newDateTime.suggested_time,
          props.isSiteOrder
            ? props.studySite.time_zone
            : props.pharmacy.timezone
        ),
      };
      if (props.isPharmacyOrder) {
        payload["courier_needed"] = acceptOrderPayload.courier_needed;
        payload["pickup_time"] = acceptOrderPayload.courier_needed
          ? calibrationDate(
              newDateTime.suggested_date,
              acceptOrderPayload.pickup_time,
              props.pharmacy.timezone
            )
          : null;
      }
      return axiosInstance.post(
        buildBaseUrl({
          apiModule: "sparrowManagerApi",
          url: `sparrow/${props.sparrowId}/suggestDT`,
        }),
        payload
      );
    };

    const suggestNewDateTimeApiResource = new ApiResource(
      undefined,
      suggestNewDateTime,
      "Successfully suggested a new date and time",
      "There was an error suggesting a new date and time."
    );

    const sendData = async () => {
      if (await model.value.$validate()) {
        if (props.isPharmacyOrder) {
          if (await pharmacyModel.value.$validate()) {
            await suggestNewDateTimeApiResource.fetchResource();
            emitSuccess();
          }
        } else {
          await suggestNewDateTimeApiResource.fetchResource();
          emitSuccess();
        }
      }
    };

    const handleDateInput = (model, date) => {
      model.suggested_date.$model = DateTime.fromISO(
        new Date(date).toISOString()
      ).toFormat("yyyy-LL-dd");
    };

    const handleTimeInput = (model, time) => {
      model.suggested_time.$model = setTime(time);
    };

    const formatAvailableTime = (times) =>
      `${times.minTime} - ${times.maxTime}`;

    const dateFormat = "yyyy-LL-dd";
    const searchDateForProductionDates = ref("");

    const setSearchDateForProductionDates = (inputMonth) => {
      const currentDate = DateTime.local();
      const currentMonth = Number(currentDate.toFormat("M"));
      const currentYear = Number(currentDate.toFormat("y"));
      const adjustedInputMonth = inputMonth.month + 1;

      if (
        (currentMonth === adjustedInputMonth &&
          currentYear === inputMonth.year) ||
        inputMonth.year < currentYear
      ) {
        searchDateForProductionDates.value = DateTime.local().toFormat(
          dateFormat
        );
      } else {
        searchDateForProductionDates.value = DateTime.fromObject({
          month: adjustedInputMonth,
          year: inputMonth.year,
        }).toFormat(dateFormat);
      }
    };

    const studySiteProducts = ref([]);
    const fetchStudySiteProducts = async () => {
      studySiteProducts.value = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${props.originalOrder.study}/studysite/${props.originalOrder.studysite}/studysiteproduct`,
        })
      );
    };
    const studySiteProductsApiResource = new ApiResource(
      () => studySiteProducts.value,
      fetchStudySiteProducts,
      undefined,
      "There was an error fetching your products."
    );

    const thisProduct = computed(() => {
      if (props.originalOrder.studysiteproduct) {
        return studySiteProductsApiResource
          .getData()
          .find(
            (product) =>
              product.minervaId === props.originalOrder.studysiteproduct
          );
      }
      return false;
    });

    const availableNetworks = ref([]);

    const fetchAvailableNetworks = async () => {
      availableNetworks.value = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${props.originalOrder.study}/studysite/${props.originalOrder.studysite}/studysiteproduct/${props.originalOrder.studysiteproduct}/studysiteproductnetwork`,
        })
      );
    };

    const availableNetworksApiResource = new ApiResource(
      () => availableNetworks.value,
      fetchAvailableNetworks,
      undefined,
      "There was an error fetching the available networks."
    );

    const thisProductNetwork = computed(() => {
      if (availableNetworksApiResource.getData()) {
        return availableNetworksApiResource
          .getData()
          .find(
            (network) =>
              network.studysiteproduct === props.originalOrder.studysiteproduct
          );
      }

      return {};
    });

    const productDoseNetwork = ref({});

    const fetchProductDoseNetwork = async (id) => {
      productDoseNetwork.value = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: `dosenetwork/${id}`,
        })
      );
    };

    const productDoseNetworkApiResource = new ApiResource(
      () => productDoseNetwork.value,
      fetchProductDoseNetwork,
      undefined,
      "There was an error fetching the product dose network."
    );

    const productionDates = ref([]);

    const fetchProductionDates = async () => {
      productionDates.value = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: "production_dates",
        }),
        {
          network: props.originalOrder.studysiteproductnetwork,
          amount: props.originalOrder.amount,
          order_id: props.originalOrder.order_id,
          additional_data: { studysite: props.originalOrder.studysite },
          product: thisProduct.value.product,
          date: searchDateForProductionDates.value,
          network_implementation_uri: productDoseNetworkApiResource.getData()
            .internal_implementation_uri,
          network_site_id: thisProductNetwork.value.networkstudysiteid,
        }
      );
    };

    const productionDatesApiResource = new ApiResource(
      () => (productionDates.value ? productionDates.value.apiResponse : []),
      fetchProductionDates,
      undefined,
      "There was an error fetching the production dates."
    );

    const disabledDates = computed(() => {
      let disabledDates = [];
      let firstOfMonthCopy = DateTime.fromISO(
        searchDateForProductionDates.value
      );
      const firstOfMonthDate = DateTime.fromISO(
        searchDateForProductionDates.value
      );

      if (productionDatesApiResource.getLoading()) {
        while (firstOfMonthCopy.hasSame(firstOfMonthDate, "month")) {
          disabledDates.push(new Date(firstOfMonthCopy.toString()));
          firstOfMonthCopy = firstOfMonthCopy.plus({ day: 1 });
        }
      } else if (
        !productionDatesApiResource.getLoading() &&
        productionDatesApiResource.getData()
      ) {
        const dates = productionDatesApiResource
          .getData()
          .map((date) => DateTime.fromISO(date.Earliest).toFormat(dateFormat));

        while (firstOfMonthCopy.hasSame(firstOfMonthDate, "month")) {
          if (!dates.includes(firstOfMonthCopy.toFormat(dateFormat))) {
            disabledDates.push(new Date(firstOfMonthCopy.toString()));
          }
          firstOfMonthCopy = firstOfMonthCopy.plus({ day: 1 });
        }
      }
      return disabledDates;
    });

    const timesForSelectedDate = computed(() => {
      if (!props.isSiteOrder) return defaultTimeSlots;
      if (
        productionDatesApiResource.getLoading() ||
        !productionDatesApiResource.getData() ||
        newDateTime.suggested_date.length === 0
      )
        return defaultTimeSlots;

      if (productionDatesApiResource.getData()) {
        const thisDate = productionDatesApiResource
          .getData()
          .find(
            (date) =>
              DateTime.fromISO(date.Earliest).toFormat(dateFormat) ===
              newDateTime.suggested_date
          );
        if (!thisDate) return defaultTimeSlots;
        return {
          minTime: getTime(setTZ(thisDate.Earliest, props.studySite.time_zone)),
          maxTime: getTime(setTZ(thisDate.Latest, props.studySite.time_zone)),
        };
      }
    });

    const suggestedTimePlaceholder = computed(() => {
      if (props.isSiteOrder) {
        return newDateTime.suggested_date.length === 0
          ? "Select a date"
          : "Select a time";
      }
      return "Select a time";
    });

    const suggestedDatePlaceholder = computed(() => {
      if (props.isSiteOrder) {
        return studySiteProductsApiResource.getLoading() ||
          availableNetworksApiResource.getLoading() ||
          productDoseNetworkApiResource.getLoading() ||
          productionDatesApiResource.getLoading()
          ? "Available dates loading"
          : "Select a date";
      }
      return "Select a date";
    });

    watch(
      () => searchDateForProductionDates.value,
      () => {
        if (
          !thisProduct.value ||
          !productDoseNetworkApiResource.getData()
            .internal_implementation_uri ||
          !thisProductNetwork.value
        )
          return;
        productionDatesApiResource.fetchResource();
      }
    );

    onMounted(async () => {
      if (props.isPharmacyOrder) {
        model.value.suggested_date.$model = getDate(
          props.originalOrder.order_data.local_calibration
        );
        model.value.suggested_time.$model = setTimeFromLocalCalibration(
          props.originalOrder.order_data.local_calibration
        );
      }
      if (props.isSiteOrder) {
        model.value.suggested_date.$model = getDate(
          props.originalOrder.local_calibration
        );
        model.value.suggested_time.$model = setTimeFromLocalCalibration(
          props.originalOrder.local_calibration
        );
        searchDateForProductionDates.value = DateTime.fromISO(
          props.sparrowOrder.suggested_calibration
        )
          .set({ day: 1 })
          .toFormat(dateFormat);

        await Promise.all([
          studySiteProductsApiResource.fetchResource(),
          availableNetworksApiResource.fetchResource(),
        ]);
        await productDoseNetworkApiResource.fetchResource(
          thisProductNetwork.value.dosenetwork
        );
        await productionDatesApiResource.fetchResource();
      }
    });

    const formLoading = computed(
      () =>
        productDoseNetworkApiResource.getLoading() ||
        availableNetworksApiResource.getLoading() ||
        productionDatesApiResource.getLoading() ||
        studySiteProductsApiResource.getLoading() ||
        suggestNewDateTimeApiResource.getLoading()
    );

    return {
      model,
      calendarDate,
      suggestedTime,
      handleDateInput,
      handleTimeInput,
      sendData,
      closeForm,
      formatAvailableTime,
      formLoading,
      setSearchDateForProductionDates,
      timesForSelectedDate,
      disabledDates,
      suggestedTimePlaceholder,
      suggestedDatePlaceholder,
      newDateTime,
      enableDateInput,
      setPickupTime,
      pharmacyModel,
      acceptOrderPayload,
      pickupTime,
    };
  },
};
</script>
