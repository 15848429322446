<template>
  <DataTable
    v-model:contextMenuSelection="selectedRow"
    :value="backFilledData"
    paginator
    :rows="rows"
    row-hover
    removable-sort
    context-menu
    auto-layout
    :sortable="sortable"
    :class="
      orderTable
        ? 'order-table-row-height'
        : pharmacyOrderTable
        ? 'pharmacy-order-table-row-height'
        : 'table-row-height'
    "
    @row-contextmenu="onRowContextMenu"
    @page="onPage($event)"
  >
    <template #header>
      <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
        <h4>{{ title }}</h4>
        <div class="p-d-flex p-flex-row p-ai-center">
          <span class="p-mr-4">
            <slot name="headerContent"></slot>
          </span>
          <span
            v-if="
              searchable &&
              searchValue.length > 0 &&
              filteredData.length == 0 &&
              tableData.length > 0
            "
            class="p-mr-4"
            >No results for search</span
          >
          <span v-if="searchable" class="p-input-icon-left ml-4">
            <i class="pi pi-search" />
            <InputText v-model="searchValue" placeholder="Keyword Search" />
          </span>
          <span
            class="p-d-flex p-ai-center p-mr-4 p-ml-4"
            style="width: 25px; height: 25px"
          >
            <ProgressLoader
              style="width: 25px; height: 25px"
              :loading="loading"
            />
          </span>
          <Button
            v-if="showAddButton"
            :disabled="!enableAddButton"
            @click="openAddModal = true"
            >Add {{ addButton }}</Button
          >
        </div>
      </div>
    </template>
    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :sortable="false"
    >
      <template v-if="col.useSlot" #body="{ data }">
        <slot :name="`${col.field}`" :data="data[col.field]"></slot>
      </template>
    </Column>
    <slot></slot>
  </DataTable>
  <Dialog
    v-model:visible="openAddModal"
    :modal="true"
    :header="headerText || `Create new ${addButton.toLowerCase()}`"
  >
    <slot name="addModalContent" :closeAddModal="closeAddModel"></slot>
  </Dialog>
  <ContextMenu ref="contextMenu" :model="contextMenuOptions" />
</template>

<script>
import ProgressLoader from "@/components/ProgressLoader/ProgressLoader";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import Dialog from "primevue/dialog";
import ContextMenu from "primevue/contextmenu";
import { ref, computed, onMounted, watch } from "vue";

export default {
  name: "AppDataTable",
  components: {
    DataTable,
    Column,
    ProgressLoader,
    Dialog,
    ContextMenu,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    addButton: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enableAddButton: {
      type: Boolean,
      default: false,
    },
    contextMenuOptions: {
      type: Array,
      default: () => [],
    },
    selectedRowData: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Number,
      default: 10,
    },
    orderTable: {
      type: Boolean,
      default: false,
    },
    pharmacyOrderTable: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    headerText: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    searchProperties: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:selectedRowData", "last-page", "on-search"],
  setup(props, { emit }) {
    const openAddModal = ref(false);

    const contextMenu = ref(null);
    const selectedRow = ref({});
    const onRowContextMenu = (event) => {
      if (!event.data.minervaId && (!event.data?.order_data?.order_id ?? false))
        return;
      emit("update:selectedRowData", event.data);
      contextMenu.value.show(event.originalEvent);
    };

    const closeAddModel = () => (openAddModal.value = false);
    const onPage = (event) =>
      event.page + 1 === event.pageCount && emit("last-page");

    const searchValue = ref("");

    watch(
      () => searchValue.value,
      () => emit("on-search", searchValue.value)
    );

    const filteredData = computed(() => {
      if (props.tableData.length === 0) return [];
      if (searchValue.value.length === 0) return props.tableData;
      const finalData = [];
      for (const data of props.tableData) {
        let wasAdded = false;
        for (const key of props.searchProperties) {
          const value = data[key];
          if (
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue.value.toLowerCase())
          ) {
            finalData.push(data);
            wasAdded = true;
          } else if (
            typeof value === "number" &&
            value.toString().includes(searchValue.value)
          ) {
            finalData.push(data);
            wasAdded = true;
          }
          if (wasAdded) break;
        }
      }
      return finalData;
    });

    const backFilledData = computed(() => {
      if (filteredData.value.length === 0)
        return new Array(props.rows).fill({});
      if (filteredData.value.length % props.rows === 0)
        return filteredData.value;

      const backFillLength =
        props.rows - (filteredData.value.length % props.rows);
      const backFillArray = new Array(backFillLength).fill({});
      const filledData = [...filteredData.value, ...backFillArray];

      return filledData;
    });

    onMounted(() => {
      if (props.searchable && props.searchProperties.length == 0) {
        throw "searchProperties are required for searching";
      }
    });

    return {
      openAddModal,
      selectedRow,
      contextMenu,
      onRowContextMenu,
      closeAddModel,
      onPage,
      backFilledData,
      searchValue,
      filteredData,
    };
  },
};
</script>
