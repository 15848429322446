<template>
  <div class="p-grid">
    <div class="p-col-4">
      <AppDataTable
        :selected-row-data="selectedRole"
        title="Roles"
        add-button="Role"
        enable-add-button
        :table-data="rolesApiResource.getData()"
        :loading="rolesTableLoading"
        :context-menu-options="contextMenuOptionsRoles"
        :order-table="true"
        @update:selected-row-data="setSelectedRoleId"
      >
        <Column header="Name">
          <template #body="{ data }">
            {{ data.display_name }}
          </template>
        </Column>
        <Column header="View Permissions">
          <template #body="{ data }">
            <Button
              v-if="data.minervaId"
              label="View"
              class="p-button-xs"
              @click="viewPermissions(data)"
            />
          </template>
        </Column>
        <template #addModalContent="{ closeAddModal }">
          <AddEditRole @close-form="closeAddModal" />
        </template>
      </AppDataTable>
    </div>
    <div class="p-col-8">
      <AppDataTable
        :selected-row-data="selectedPermission"
        :title="`Permissions - ${roleToView.name || 'Select a role'}`"
        add-button="New Permission"
        header-text="Create new permission"
        enable-add-button
        :show-add-button="!!roleToView.name"
        :table-data="permissionsToDisplay"
        :loading="permissionsTableLoading"
        :context-menu-options="contextMenuOptionsPermissions"
        :order-table="true"
        @update:selected-row-data="setSelectedPermission"
      >
        <Column header="Name">
          <template #body="{ data }">
            {{ data.permission_name }}
          </template>
        </Column>
        <template #addModalContent="{ closeAddModal }">
          <AddPermission
            :selected-role-name="roleToView.name"
            @close-form="closeAddModal"
          />
        </template>
        <template #headerContent>
          <Button
            v-if="roleToView.name"
            @click="showAddExistingPermissionModal = true"
            >Add Existing Permission</Button
          >
        </template>
      </AppDataTable>
    </div>
  </div>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Role - ${selectedRole && selectedRole.display_name}`"
  >
    <AddEditRole
      :selected-role="selectedRole"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
  <Dialog
    v-model:visible="showConfirmDeletePermissionModal"
    :modal="true"
    :header="`Delete Permission - ${
      selectedPermission && selectedPermission.permission_name
    }`"
  >
    <DeletePermission
      :parent-role="roleToView"
      :selected-permission="selectedPermission"
      @close-form="showConfirmDeletePermissionModal = false"
      @clear-viewed-role="roleToView = {}"
    />
  </Dialog>
  <Dialog
    v-model:visible="showAddExistingPermissionModal"
    :modal="true"
    :header="`Add Existing Permissions - ${
      roleToView && roleToView.display_name
    }`"
  >
    <AddExistingPermission
      :selected-role-name="roleToView.name"
      @close-form="showAddExistingPermissionModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditRole from "@/components/RolesPermissions/AddEditRole.vue";
import DeletePermission from "@/components/RolesPermissions/DeletePermission.vue";
import AddPermission from "@/components/RolesPermissions/AddPermission.vue";
import AddExistingPermission from "@/components/RolesPermissions/AddExistingPermission.vue";

import Column from "primevue/column";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "RolesPermissions",
  components: {
    AppDataTable,
    AddEditRole,
    Dialog,
    Column,
    DeletePermission,
    AddPermission,
    AddExistingPermission,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptionsRoles = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteRoleHandler(event);
        },
      },
    ];

    const roles = () => store.state.rolesPermissions.roles;
    const fetchRoles = () => store.dispatch("rolesPermissions/fetchRoles");
    const rolesApiResource = new ApiResource(
      roles,
      fetchRoles,
      undefined,
      "There was an error fetching your roles."
    );

    onMounted(() => {
      if (
        !rolesApiResource.getData() ||
        rolesApiResource.getData().length === 0
      ) {
        rolesApiResource.fetchResource();
      }
    });

    const deleteRole = () =>
      store.dispatch("rolesPermissions/deleteRole", selectedRole.value);
    const deleteRoleApiResource = new ApiResource(
      undefined,
      deleteRole,
      "Successfully deleted role.",
      "There was an error deleting the role."
    );

    const selectedRoleId = ref("");
    const selectedRole = computed(() => {
      if (selectedRoleId.value.length > 0) {
        return store.state.rolesPermissions.roles.find(
          (role) => role.minervaId === selectedRoleId.value
        );
      }
      return {};
    });
    const setSelectedRoleId = (role) => {
      selectedRoleId.value = role.minervaId;
    };

    const openEditModal = ref(false);

    const rolesTableLoading = computed(() => rolesApiResource.getLoading());

    const permissionsTableLoading = computed(
      () =>
        permissionsApiResource.getLoading() ||
        deleteRoleApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteRoleHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete role ${selectedRole.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteRoleApiResource.fetchResource();
        },
        reject: () => {
          selectedRoleId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const roleToView = ref({});

    const viewPermissions = (role) => {
      roleToView.value = role;
    };

    const showAddExistingPermissionModal = ref(false);

    const showConfirmDeletePermissionModal = ref(false);

    const contextMenuOptionsPermissions = [
      {
        label: "Delete",
        command: () => {
          showConfirmDeletePermissionModal.value = true;
        },
      },
    ];

    const permissions = () => store.state.rolesPermissions.permissionsByRole;
    const fetchPermissions = () =>
      store.dispatch("rolesPermissions/fetchPermissionsByRole", {
        roleName: roleToView.value.name,
      });

    const permissionsApiResource = new ApiResource(
      permissions,
      fetchPermissions,
      undefined,
      "There was an error fetching your permissions."
    );

    watch(roleToView, () => {
      if (roleToView.value.name) {
        permissionsApiResource.fetchResource();
      }
    });

    const permissionsToDisplay = computed(() => {
      if (
        permissionsApiResource.getData() &&
        Object.keys(permissionsApiResource.getData()).length > 0
      ) {
        return permissionsApiResource.getData()[roleToView.value.name];
      }
      return [];
    });

    const selectedPermission = ref({});
    const setSelectedPermission = (permission) => {
      selectedPermission.value = permission;
    };

    return {
      contextMenuOptionsRoles,
      rolesApiResource,
      setSelectedRoleId,
      selectedRole,
      openEditModal,
      rolesTableLoading,
      permissionsTableLoading,
      viewPermissions,
      permissionsToDisplay,
      setSelectedPermission,
      contextMenuOptionsPermissions,
      selectedPermission,
      showConfirmDeletePermissionModal,
      showAddExistingPermissionModal,
      roleToView,
    };
  },
};
</script>
