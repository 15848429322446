<template>
  <transition :name="transitionDirection" mode="out-in">
    <div v-if="!viewChildPage" key="parent">
      <AppDataTable
        :selected-row-data="selectedStudy"
        title="Studies"
        add-button="Study"
        enable-add-button
        :table-data="studiesApiResource.getData()"
        :loading="tableLoading"
        :context-menu-options="contextMenuOptions"
        :rows="15"
        searchable
        :search-properties="['trial_code', 'display_name', 'description']"
        @update:selected-row-data="setSelectedStudyId"
        @last-page="fetchNextSetOfStudies"
      >
        <Column header="Name">
          <template #body="{ data }">
            {{ getStudyNameString(data) }}
          </template>
        </Column>
        <Column header="Current Status">
          <template #body="{ data }">
            {{ data.current_status }}
          </template>
        </Column>
        <Column header="Collaborator">
          <template #body="{ data }">
            {{ data.collaborator }}
          </template>
        </Column>
        <Column header="Description">
          <template #body="{ data }">
            {{ data?.description ?? "" }}
          </template>
        </Column>
        <template #addModalContent="{ closeAddModal }">
          <AddEditStudy @close-form="closeAddModal" />
        </template>
      </AppDataTable>
    </div>
    <div v-else key="child">
      <div class="p-d-flex p-ai-start p-ml-2">
        <Button label="Back" class="p-mb-4" @click="viewChildPage = false" />
        <h4
          v-if="selectedStudy && selectedStudy.display_name"
          class="p-ml-4"
          style="margin: 0px"
        >
          {{ `Study - ${selectedStudy && getStudyNameString(selectedStudy)}` }}
        </h4>
      </div>
      <StudyDetailsWrapper :selected-study="selectedStudy" />
    </div>
  </transition>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Study - ${selectedStudy && selectedStudy.display_name}`"
  >
    <AddEditStudy
      :selected-study="selectedStudy"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditStudy from "@/components/Studies/AddEditStudy.vue";
import StudyDetailsWrapper from "@/components/Studies/StudyDetailsWrapper";
import { getStudyNameString } from "@/helpers/studyName.js";

import Column from "primevue/column";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Studies",
  components: {
    AppDataTable,
    AddEditStudy,
    StudyDetailsWrapper,
    Dialog,
    Column,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const contextMenuOptions = [
      {
        label: "View",
        command: () => {
          viewChildPage.value = true;
        },
      },
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeleteStudyHandler(event);
        },
      },
    ];

    const viewChildPage = ref(false);
    const transitionDirection = computed(() =>
      viewChildPage.value ? "page-right" : "page-left"
    );

    const studiesOffset = ref(0);

    const studies = () => store.state.studyManagement.studies;
    const fetchStudies = () =>
      store.dispatch("studyManagement/fetchStudies", studiesOffset.value);
    const studiesApiResource = new ApiResource(
      studies,
      fetchStudies,
      undefined,
      "There was an error fetching your studies."
    );

    const refreshStudies = (length) =>
      store.dispatch("studyManagement/refreshStudies", length);

    const studiesRefreshApiResource = new ApiResource(
      studies,
      refreshStudies,
      undefined,
      "There was an error fetching your studies."
    );

    const fetchNextSetOfStudies = async () => {
      studiesOffset.value += 30;
      await studiesApiResource.fetchResource();
    };

    onMounted(() => {
      if (
        !studiesApiResource.getData() ||
        studiesApiResource.getData().length === 0
      ) {
        studiesApiResource.fetchResource();
      } else {
        studiesRefreshApiResource.fetchResource(studies().length);
      }
    });

    const deleteStudy = () =>
      store.dispatch("studyManagement/deleteStudy", selectedStudy.value);
    const deleteStudyApiResource = new ApiResource(
      undefined,
      deleteStudy,
      "Successfully deleted study.",
      "There was an error deleting the study."
    );

    const selectedStudyId = ref("");
    const selectedStudy = computed(() => {
      if (selectedStudyId.value.length > 0) {
        return store.state.studyManagement.studies.find(
          (study) => study.minervaId === selectedStudyId.value
        );
      }
      return {};
    });
    const setSelectedStudyId = (study) => {
      selectedStudyId.value = study.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        studiesApiResource.getLoading() ||
        deleteStudyApiResource.getLoading() ||
        studiesRefreshApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeleteStudyHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete study ${selectedStudy.value.display_name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteStudyApiResource.fetchResource();
        },
        reject: () => {
          selectedStudyId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      contextMenuOptions,
      studiesApiResource,
      setSelectedStudyId,
      selectedStudy,
      openEditModal,
      tableLoading,
      viewChildPage,
      transitionDirection,
      fetchNextSetOfStudies,
      getStudyNameString,
    };
  },
};
</script>
