<template>
  <div v-if="modelProperty.$invalid">
    <p
      v-for="(error, index) in modelProperty.$errors"
      :key="index"
      class="p-ml-2 p-mt-2 p-error"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorMessages",
  props: {
    modelProperty: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
