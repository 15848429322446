<template>
  <AppDataTable
    :selected-row-data="selectedPharmacyMap"
    title="Pharmacy Maps"
    add-button="Pharmacy Map"
    enable-add-button
    :table-data="filteredPharmacyMaps"
    :columns="columns"
    :loading="tableLoading"
    :context-menu-options="contextMenuOptions"
    @update:selected-row-data="setSelectedPharmacyMapId"
  >
    <template #study="{ data }">
      {{ getStudyName(data) }}
    </template>
    <template #studysite_name="{ data }">
      {{ data?.studysite_name ?? "" }}
    </template>
    <template #product="{ data }">
      {{ getProductName(data) }}
    </template>
    <template #pharmacy="{ data }">
      {{ getPharmacyName(data) }}
    </template>
    <template #enabled="{ data }">
      <span v-if="data !== undefined">
        <span v-if="data" class="p-tag p-tag-info" style="font-size: 1em"
          >Enabled</span
        >
        <span v-else class="p-tag p-tag-danger" style="font-size: 1em"
          >Disabled</span
        >
      </span>
    </template>
    <template #headerContent>
      <Dropdown
        v-model="selectedStudy"
        :options="studiesApiResource.getData()"
        option-label="display_name"
        option-value="minervaId"
        show-clear
        :placeholder="studiesPlaceholder"
        class="p-mr-4"
      >
        <template #option="{ option }">
          {{ getStudyNameString(option) }}
        </template>
      </Dropdown>
      <Dropdown
        v-model="selectedStudySite"
        :options="studySitesApiResource.getData(selectedStudy)"
        option-label="display_name"
        option-value="minervaId"
        show-clear
        :placeholder="studySitesPlaceholder"
      />
    </template>
    <template #addModalContent="{ closeAddModal }">
      <AddEditPharmacyMap
        :studies-api-resource="studiesApiResource"
        :study-sites-api-resource="studySitesApiResource"
        :products-api-resource="productsApiResource"
        :pharmacies-api-resource="pharmaciesApiResource"
        :pharmacy-maps-api-resource="pharmacyMapsApiResource"
        @close-form="closeAddModal"
      />
    </template>
  </AppDataTable>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Pharmacy Map - ${
      selectedPharmacyMap && selectedPharmacyMap.order_id
    }`"
  >
    <AddEditPharmacyMap
      :studies-api-resource="studiesApiResource"
      :study-sites-api-resource="studySitesApiResource"
      :products-api-resource="productsApiResource"
      :pharmacies-api-resource="pharmaciesApiResource"
      :pharmacy-maps-api-resource="pharmacyMapsApiResource"
      :selected-pharmacy-map="selectedPharmacyMap"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditPharmacyMap from "@/components/Pharmacies/AddEditPharmacyMap.vue";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import { computed, ref, watch, inject, onMounted } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useConfirm";
import { getStudyNameString } from "@/helpers/studyName.js";

export default {
  name: "PharmacyMaps",
  components: {
    AppDataTable,
    Dropdown,
    Dialog,
    AddEditPharmacyMap,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([
      { field: "study", header: "Study", useSlot: true },
      { field: "studysite_name", header: "Site" },
      { field: "product", header: "Product", useSlot: true },
      { field: "pharmacy", header: "Pharmacy", useSlot: true },
      { field: "priority", header: "Priority" },
      { field: "enabled", header: "Status", useSlot: true },
    ]);

    const contextMenuOptions = [
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeletePharmacyHandler(event);
        },
      },
    ];

    const studies = () => store.state.studyManagement.studies;
    const fetchStudies = () => store.dispatch("studyManagement/fetchStudies");
    const studiesApiResource = new ApiResource(
      studies,
      fetchStudies,
      undefined,
      "There was an error fetching your studies."
    );

    const selectedStudy = ref({});
    const studiesPlaceholder = computed(() =>
      studiesApiResource.getLoading() ? "Loading studies" : "Filter by study"
    );

    const studySites = (studyId) => store.state.studySites.studySites[studyId];
    const fetchStudySites = (studyId) =>
      store.dispatch("studySites/fetchStudySites", {
        studyId,
      });
    const studySitesApiResource = new ApiResource(
      studySites,
      fetchStudySites,
      undefined,
      "There was an error fetching your study sites."
    );

    const selectedStudySite = ref({});
    const studySitesPlaceholder = computed(() =>
      selectedStudy.value
        ? studySitesApiResource.getLoading()
          ? "Loading study sites"
          : "Filter by study site"
        : "Select a study"
    );

    watch(selectedStudy, () => (selectedStudySite.value = ""));

    const pharmacies = () => store.state.pharmacies.pharmacies;
    const fetchPharmacies = () => store.dispatch("pharmacies/fetchPharmacies");
    const pharmaciesApiResource = new ApiResource(
      pharmacies,
      fetchPharmacies,
      undefined,
      "There was an error fetching your pharmacies."
    );

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const pharmacyMaps = () => store.state.pharmacies.pharmacyMaps;
    const fetchPharmacyMaps = () =>
      store.dispatch("pharmacies/fetchPharmacyMaps");
    const pharmacyMapsApiResource = new ApiResource(
      pharmacyMaps,
      fetchPharmacyMaps,
      undefined,
      "There was an error fetching your pharmacy maps."
    );

    const deletePharmacyMap = () =>
      store.dispatch("pharmacies/deletePharmacyMap", selectedPharmacyMap.value);

    const deletePharmacyMapApiResource = new ApiResource(
      undefined,
      deletePharmacyMap,
      "Successfully deleted pharmacy map.",
      "There was an error deleting the pharmacy map."
    );

    const confirm = useConfirm();

    const confirmDeletePharmacyHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete pharmacy map ${selectedPharmacyMap.value.display_name}?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deletePharmacyMapApiResource.fetchResource();
        },
        reject: () => {
          selectedPharmacyMapId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    onMounted(async () => {
      if (studiesApiResource.getData().length === 0)
        await studiesApiResource.fetchResource();

      studiesApiResource.getData().forEach((study) => {
        if (
          !studySitesApiResource.getData(study.minervaId) ||
          studySitesApiResource.getData(study.minervaId).length === 0
        )
          studySitesApiResource.fetchResource(study.minervaId);
      });

      if (productsApiResource.getData().length === 0)
        productsApiResource.fetchResource();

      if (pharmaciesApiResource.getData().length === 0)
        pharmaciesApiResource.fetchResource();

      pharmacyMapsApiResource.fetchResource();
    });

    const filteredPharmacyMaps = computed(() => {
      if (pharmacyMapsApiResource.getData().length > 0) {
        return pharmacyMapsApiResource.getData().filter((map) => {
          if (
            selectedStudy.value &&
            selectedStudy.value.length > 0 &&
            selectedStudySite.value &&
            selectedStudySite.value.length > 0
          ) {
            return (
              map.study === selectedStudy.value &&
              map.studysite === selectedStudySite.value
            );
          }
          if (selectedStudy.value && selectedStudy.value.length > 0) {
            return map.study === selectedStudy.value;
          }
          return true;
        });
      }
    });

    const getStudyName = (pharmacyStudy) => {
      if (!pharmacyStudy) return "";
      if (studiesApiResource.getData().length > 0) {
        const thisStudy = studiesApiResource
          .getData()
          .find((study) => study.minervaId === pharmacyStudy);
        return getStudyNameString(thisStudy);
      }
    };

    const getProductName = (pharmacyProduct) => {
      if (!pharmacyProduct) return "";
      if (productsApiResource.getData().length > 0) {
        const thisProduct = productsApiResource
          .getData()
          .find((product) => product.minervaId === pharmacyProduct);
        return thisProduct.name;
      }
    };

    const getPharmacyName = (mapPharmacy) => {
      if (!mapPharmacy) return "";
      if (pharmaciesApiResource.getData().length > 0) {
        const thisProduct = pharmaciesApiResource
          .getData()
          .find((pharmacy) => pharmacy.minervaId === mapPharmacy);
        return thisProduct.name;
      }
    };

    const selectedPharmacyMapId = ref("");
    const selectedPharmacyMap = computed(() => {
      if (selectedPharmacyMapId.value.length > 0) {
        return store.state.pharmacies.pharmacyMaps.find(
          (map) => map.minervaId === selectedPharmacyMapId.value
        );
      }
      return {};
    });

    const setSelectedPharmacyMapId = (order) => {
      selectedPharmacyMapId.value = order.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        studiesApiResource.getLoading() ||
        studySitesApiResource.getLoading() ||
        pharmacyMapsApiResource.getLoading() ||
        deletePharmacyMapApiResource.getLoading()
    );

    return {
      columns,
      contextMenuOptions,
      tableLoading,
      selectedStudy,
      selectedStudySite,
      selectedPharmacyMap,
      setSelectedPharmacyMapId,
      openEditModal,
      studiesApiResource,
      pharmacyMapsApiResource,
      studySitesApiResource,
      pharmaciesApiResource,
      productsApiResource,
      studiesPlaceholder,
      studySitesPlaceholder,
      getStudyName,
      getProductName,
      getPharmacyName,
      filteredPharmacyMaps,
      getStudyNameString,
    };
  },
};
</script>
