<template>
  <FormWrapper :loading="formLoading">
    <FormCol v-if="isSubject" col-width="p-md-6">
      <label for="new_site" class="title">Site</label>
      <Dropdown
        id="new_site"
        v-model="updatedOrder.new_studysite"
        :options="availableStudySites"
        option-value="minervaId"
        option-label="display_name"
        placeholder="Select a Site"
        :disabled="!modifyStudySite"
        append-to="body"
      />
    </FormCol>
    <FormCol v-if="isSubject" col-width="p-md-6">
      <label for="new_subject" class="title">New Subject</label>
      <Dropdown
        id="new_subject"
        v-model="updatedOrderModel.new_studysitesubject.$model"
        :options="availableSubjects"
        :placeholder="otherSubjectPlaceholder"
        :class="{
          'p-error': updatedOrderModel.new_studysitesubject.$invalid,
        }"
        option-label="subjectID"
        option-value="minervaId"
        append-to="body"
        :filter="true"
      />
      <ErrorMessages :model-property="updatedOrderModel.new_studysitesubject" />
    </FormCol>
    <FormCol v-if="isSubject" col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 69px">
        <Checkbox
          id="change_site"
          v-model="modifyStudySite"
          :binary="true"
          class="p-mb-1"
        />
        <label for="change_site" class="checkbox-text p-ml-2 p-mb-1"
          >Modify Study Site</label
        >
      </div>
    </FormCol>
    <FormButtonCol v-if="isSubject">
      <CancelConfirmButtons
        :disabled="formLoading"
        :confirm-text="
          modifyStudySite ? 'UPDATE SITE & SUBJECT' : 'UPDATE SUBJECT'
        "
        @confirmed="confirmUpdateOrderSiteSubject"
      />
    </FormButtonCol>
    <FormCol v-if="isCancellation" col-width="p-md-6">
      <label for="cancellation_reason" class="title">Cancellation Reason</label>
      <Dropdown
        id="cancellation_reason"
        v-model="updatedOrderCancellation.cancellation_reason"
        :options="cancellationReasons"
        placeholder="Select a reason"
        append-to="body"
      />
    </FormCol>
    <FormButtonCol v-if="isCancellation" col-width="p-md-6">
      <Button
        :label="
          selectedOrder.cancelled
            ? 'UPDATE CANCELLATION REASON'
            : 'CANCEL ORDER'
        "
        class="p-button-danger p-button-text p-mt-1"
        :disabled="!cancellationChanged"
        @click="confirmCancelOrderHandler"
      />
    </FormButtonCol>
    <FormCol v-if="updatedOrderCancellation.cancellation_reason === 'Other'">
      <label for="other_reason" class="title">Other Reason</label>
      <Textarea
        v-model="updatedOrderCancellationModel.other.$model"
        label="other_reason"
        :auto-resize="true"
        rows="3"
        cols="60"
        :class="{
          'p-invalid': updatedOrderCancellationModel.other.$invalid,
        }"
      />
      <ErrorMessages :model-property="updatedOrderCancellationModel.other" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        :hide-confirm="true"
        cancel-text="CLOSE"
        @cancelled="closeForm"
      />
    </FormButtonCol>
  </FormWrapper>
  <Dialog
    v-model:visible="showConfirmOrder"
    :modal="true"
    header="Verify Order Modification"
    :dismissable-mask="true"
  >
    <FormWrapper>
      <FormCol v-if="isSubject" col-width="p-md-6">
        <FormDataView title="Site" :data="selectedSiteName" />
      </FormCol>
      <FormCol v-if="isSubject" col-width="p-md-6">
        <FormDataView title="Subject" :data="selectedSubjectName" />
      </FormCol>
      <FormCol v-if="isCancellation" col-width="p-md-6">
        <FormDataView
          title="Cancellation Reason"
          :data="
            updatedOrderCancellation.other ||
            updatedOrderCancellation.cancellation_reason
          "
        />
      </FormCol>
      <Divider />
      <FormButtonCol>
        <CancelConfirmButtons
          cancel-text="CANCEL"
          confirm-text="CONFIRM"
          @cancelled="showConfirmOrder = false"
          @confirmed="verifyConfirmed"
        />
      </FormButtonCol>
    </FormWrapper>
  </Dialog>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import FormDataView from "@/components/FormComponents/FormDataView";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";

import { reactive, ref, computed, inject, watch, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  helpers,
} from "@vuelidate/validators";
import orderCancellationReasons from "@/helpers/orderCancellationReasons";

export default {
  name: "EditPostOrderDate",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    Checkbox,
    Dialog,
    Textarea,
    FormDataView,
  },
  props: {
    selectedOrder: {
      type: Object,
      default: () => ({}),
    },
    availableStudySites: {
      type: Array,
      default: () => [],
    },
    isSubject: {
      type: Boolean,
      default: false,
    },
    isCancellation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const updatedOrder = reactive({
      study: props.selectedOrder.study,
      studysite: props.selectedOrder.studysite,
      studysiteorder: props.selectedOrder.minervaId,
      new_study: props.selectedOrder.study,
      new_studysite: props.selectedOrder.studysite,
      new_studysitesubject: "",
    });

    const updatedOrderRules = {
      new_studysitesubject: {
        required: helpers.withMessage("A new subject is required", required),
      },
    };

    const updatedOrderModel = useVuelidate(updatedOrderRules, updatedOrder, {
      $lazy: true,
    });

    const modifyStudySite = ref(false);

    const updateOrderSiteSubjectRequest = () =>
      store.dispatch("orderManagement/updateOrderSubjectSite", {
        studyId: props.selectedOrder.study,
        studySiteId: props.selectedOrder.studysite,
        studySiteOrderId: props.selectedOrder.minervaId,
        order: updatedOrder,
      });

    const updateOrderSiteSubject = new ApiResource(
      undefined,
      updateOrderSiteSubjectRequest,
      "Successfully updated the order.",
      "There was an error updating the order."
    );

    const confirmUpdateOrderSiteSubject = async () => {
      if (await updatedOrderModel.value.$validate()) {
        showVerificationModal(async () => {
          await updateOrderSiteSubject.fetchResource();
          closeForm();
        });
      }
    };

    const cancellationReasons = orderCancellationReasons["StudyAdmin"];

    const updatedOrderCancellation = reactive({
      cancellation_reason: "",
      other: "",
    });

    onMounted(() => {
      if (props.selectedOrder.cancelled) {
        const reason = props.selectedOrder.cancellation_reason;
        if (!cancellationReasons.includes(reason)) {
          updatedOrderCancellation.cancellation_reason = "Other";
          updatedOrderCancellation.other = reason;
        } else {
          updatedOrderCancellation.cancellation_reason = reason;
        }
      }
    });

    const updatedOrderCancellationRules = {
      other: {
        requiredIf: helpers.withMessage(
          "A reason is required",
          requiredIf(
            () => updatedOrderCancellation.cancellation_reason === "Other"
          )
        ),
        minLength: minLength(1),
      },
    };

    const updatedOrderCancellationModel = useVuelidate(
      updatedOrderCancellationRules,
      updatedOrderCancellation,
      {
        $lazy: true,
      }
    );

    const updateOrderCancellationRequest = () =>
      store.dispatch("orderManagement/updateOrderCancellation", {
        studyId: props.selectedOrder.study,
        studySiteId: props.selectedOrder.studysite,
        studySiteOrderId: props.selectedOrder.minervaId,
        orderId: props.selectedOrder.minervaId,
        orderCancellation: {
          study: props.selectedOrder.study,
          studysite: props.selectedOrder.studysite,
          studysiteorder: props.selectedOrder.minervaId,
          cancellation_reason:
            updatedOrderCancellation.cancellation_reason === "Other"
              ? updatedOrderCancellation.other
              : updatedOrderCancellation.cancellation_reason,
        },
      });

    const updateOrderCancellation = new ApiResource(
      undefined,
      updateOrderCancellationRequest,
      "Successfully updated the order cancellation reason.",
      "There was an error updating the order cancellation reason."
    );

    const confirmCancelOrderHandler = async () => {
      if (await updatedOrderCancellationModel.value.$validate()) {
        showVerificationModal(async () => {
          await updateOrderCancellation.fetchResource();
          closeForm();
        });
      }
    };

    const showConfirmOrder = ref(false);
    let verficationFunc = undefined;

    const showVerificationModal = (func) => {
      verficationFunc = func;
      showConfirmOrder.value = true;
    };

    const verifyConfirmed = () => {
      showConfirmOrder.value = false;
      verficationFunc();
    };

    const selectedStudySite = computed(() => {
      return props.availableStudySites.find(
        (studySite) => studySite.minervaId === updatedOrder.new_studysite
      );
    });

    const studySiteSubjects = () =>
      store.state.orderManagement.studySiteSubjects[updatedOrder.new_studysite];

    const fetchStudySiteSubjects = () =>
      store.dispatch("orderManagement/fetchStudySiteSubjects", {
        studyId: updatedOrder.new_study,
        studySiteId: updatedOrder.new_studysite,
      });

    const studySiteSubjectApiResource = new ApiResource(
      studySiteSubjects,
      fetchStudySiteSubjects,
      undefined,
      "There was an error fetching the study site subjects."
    );

    const otherSubjectPlaceholder = computed(() =>
      studySiteSubjectApiResource.getLoading()
        ? "Loading subjects"
        : "Select a subject"
    );

    const availableSubjects = computed(() => {
      if (
        studySiteSubjectApiResource.getData() &&
        studySiteSubjectApiResource.getData().length > 0
      ) {
        return studySiteSubjectApiResource
          .getData()
          .filter(
            (subject) =>
              subject.minervaId !== props.selectedOrder.studysitesubject
          );
      }
      return [];
    });

    watch(
      () => updatedOrder.new_studysite,
      () => {
        if (props.isSubject) {
          updatedOrder.new_study = selectedStudySite.value.study;
          studySiteSubjectApiResource.fetchResource();
        }
      },
      { immediate: true }
    );

    const selectedSubject = computed(
      () =>
        studySiteSubjectApiResource.getData() &&
        studySiteSubjectApiResource
          .getData()
          .find(
            (subject) => subject.minervaId === updatedOrder.new_studysitesubject
          )
    );

    const selectedSubjectName = computed(
      () => selectedSubject.value && selectedSubject.value.subjectID
    );

    const selectedSite = computed(() =>
      props.availableStudySites.find(
        (site) => site.minervaId === updatedOrder.new_studysite
      )
    );

    const selectedSiteName = computed(
      () => selectedSite.value && selectedSite.value.display_name
    );

    const subjectPlaceholder = computed(() =>
      studySiteSubjectApiResource.getLoading()
        ? "Loading subjects"
        : "Select a subject"
    );

    const subjectChanged = computed(
      () =>
        updatedOrder.new_studysitesubject.length > 0 &&
        props.selectedOrder.studysitesubject !==
          updatedOrder.new_studysitesubject
    );

    const cancellationChanged = computed(() => {
      if (updatedOrderCancellation.cancellation_reason === "Other") {
        return (
          updatedOrderCancellation.other !==
          props.selectedOrder.cancellation_reason
        );
      }
      return (
        props.selectedOrder.cancellation_reason !==
        updatedOrderCancellation.cancellation_reason
      );
    });

    const closeForm = () => emit("close-form");

    const formLoading = computed(
      () =>
        updateOrderSiteSubject.getLoading() ||
        updateOrderCancellation.getLoading()
    );

    return {
      closeForm,
      updatedOrder,
      updatedOrderModel,
      updatedOrderCancellation,
      formLoading,
      modifyStudySite,
      cancellationReasons,
      verifyConfirmed,
      showVerificationModal,
      confirmUpdateOrderSiteSubject,
      subjectChanged,
      cancellationChanged,
      selectedSubjectName,
      subjectPlaceholder,
      orderCancellationReasons,
      confirmCancelOrderHandler,
      updatedOrderCancellationModel,
      studySiteSubjectApiResource,
      otherSubjectPlaceholder,
      showConfirmOrder,
      availableSubjects,
      selectedSiteName,
    };
  },
};
</script>
