<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="permission_list" class="title">Available Permissions</label>
      <MultiSelect
        v-model="newPermissionListModel.permissions.$model"
        :options="allPermissionsApiResource.getData()"
        option-label="display_name"
        placeholder="Select Permissions"
        :class="{
          'p-invalid': newPermissionListModel.permissions.$invalid,
        }"
        append-to="body"
        :filter="true"
      />
      <ErrorMessages :model-property="newPermissionListModel.permissions" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newPermissionListModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import MultiSelect from "primevue/multiselect";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { minLength, required, helpers } from "@vuelidate/validators";

export default {
  name: "AddPermission",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    MultiSelect,
  },
  props: {
    selectedRoleName: {
      type: String,
      default: "",
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPermissionList = reactive({
      permissions: [],
    });

    const rules = {
      permissions: {
        required: helpers.withMessage(
          "Select at least one permission",
          required
        ),
        minLength: minLength(1),
      },
    };

    const newPermissionListModel = useVuelidate(rules, newPermissionList, {
      $lazy: true,
    });

    const addPermissionToRole = (newPermission) =>
      store.dispatch("rolesPermissions/addPermissionsByRole", {
        roleName: props.selectedRoleName,
        payload: {
          role_name: props.selectedRoleName,
          permission_name: newPermission.permission_name,
        },
      });

    const addPermissionToRoleApiRequest = new ApiResource(
      undefined,
      addPermissionToRole,
      "Successfully added the permission to the selected role",
      "There was an error adding the permission to the selected role."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newPermissionListModel.value.$validate()) {
        for (const permission of newPermissionList.permissions) {
          await addPermissionToRoleApiRequest.fetchResource(permission);
        }
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        allPermissionsApiResource.getLoading() ||
        addPermissionToRoleApiRequest.getLoading()
    );

    const allPermissions = () => store.state.rolesPermissions.allPermissions;
    const fetchAllPermissions = () =>
      store.dispatch("rolesPermissions/fetchAllPermissions");

    const allPermissionsApiResource = new ApiResource(
      allPermissions,
      fetchAllPermissions,
      undefined,
      "There was an error fetching your permissions."
    );

    onMounted(() => {
      allPermissionsApiResource.fetchResource();
    });

    return {
      closeForm,
      newPermissionListModel,
      allPermissionsApiResource,
      formLoading,
      handleSave,
    };
  },
};
</script>
