<template>
  <FormWrapper>
    <FormCol col-width="p-md-4">
      <FormDataView title="Subject" :data="selectedSubjectName" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Product" :data="selectedProductName" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Amount" :data="getAmountString(order.amount)" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView title="Network" :data="selectedNetworkName" />
    </FormCol>
    <FormCol v-if="selectedPharmacy" col-width="p-md-4">
      <FormDataView title="Pharmacy" :data="selectedPharmacy" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView title="Date" :data="order.date" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Time" :data="order.time" />
    </FormCol>
    <Divider />
    <FormCol v-if="isEditing">
      <FormDataView title="Order Notes" :data="order.notes" />
    </FormCol>
    <Divider v-if="isEditing" />
    <FormCol v-if="isEditing">
      <FormDataView title="Additional Notes" :data="order.courier_notes" />
    </FormCol>
    <Divider v-if="isEditing" />
    <slot name="message"></slot>
    <FormButtonCol>
      <CancelConfirmButtons
        cancel-text="CANCEL"
        confirm-text="CONFIRM"
        @cancelled="cancel"
        @confirmed="confirm"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import Divider from "primevue/divider";
import FormCol from "@/components/FormComponents/FormCol";
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormDataView from "@/components/FormComponents/FormDataView";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import { getAmountString } from "@/helpers/units";

export default {
  name: "VerifyOrder",
  components: {
    Divider,
    FormCol,
    FormWrapper,
    FormDataView,
    FormButtonCol,
    CancelConfirmButtons,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    selectedSubjectName: {
      type: String,
      default: "",
    },
    selectedProductName: {
      type: String,
      default: "",
    },
    selectedNetworkName: {
      type: String,
      default: "",
    },
    selectedPharmacy: {
      type: String,
      default: "",
    },
  },
  emits: ["confirmed", "canceled"],
  setup(props, { emit }) {
    const cancel = () => emit("canceled");
    const confirm = () => emit("confirmed");
    return {
      cancel,
      confirm,
      getAmountString,
    };
  },
};
</script>
