<template>
  <FormWrapper :loading="formLoading">
    <FormCol>
      <div class="p-d-flex p-flex-column p-ai-start">
        <p class="checkbox-text">Are you sure you want to delete?</p>
        <p class="checkbox-text">Role: {{ selectedUserRole.role_name }}</p>
        <p class="checkbox-text">
          Resource: {{ selectedUserRole.resource_uri }}
        </p>
      </div>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";

import { computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "DeleteUserRole",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
  },
  props: {
    selectedUserRole: {
      type: Object,
      default: () => ({}),
    },
    selectedUser: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const deleteUserRole = () =>
      store.dispatch("rolesPermissions/deleteRoleByUser", {
        user: props.selectedUser,
        payload: props.selectedUserRole,
      });

    const deleteUserRoleApiResource = new ApiResource(
      undefined,
      deleteUserRole,
      "Successfully deleted permission.",
      "There was an error deleting the permission."
    );

    const handleSave = async () => {
      await deleteUserRoleApiResource.fetchResource();

      closeForm();
    };

    const formLoading = computed(() => deleteUserRoleApiResource.getLoading());

    const closeForm = () => emit("close-form");

    return {
      closeForm,
      formLoading,
      handleSave,
    };
  },
};
</script>
