<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="dose_price" class="title">Dose Price</label>
      <InputNumber
        id="dose_price"
        v-model="newDosePrice.dose_default_price"
        mode="decimal"
        :show-buttons="true"
        :min="0"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="dose_freight" class="title">Dose Freight</label>
      <InputNumber
        id="dose_freight"
        v-model="newDoseFreight.dose_default_freight"
        mode="decimal"
        :show-buttons="true"
        :min="0"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import InputNumber from "primevue/inputnumber";

import { reactive, computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "AddEditDosePricing",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
    InputNumber,
  },
  props: {
    selectedProductNetwork: {
      type: Object,
      default: () => ({}),
    },
    existingDosePrice: {
      type: [Number, String],
      default: 0,
    },
    existingFreightPrice: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newDosePrice = reactive({
      study: props.selectedProductNetwork.study,
      studysite: props.selectedProductNetwork.studysite,
      studysiteproduct: props.selectedProductNetwork.studysiteproduct,
      studysiteproductnetwork: props.selectedProductNetwork.minervaId,
      dose_default_price:
        typeof props.existingDosePrice === "string"
          ? 0
          : props.existingDosePrice,
    });

    const newDoseFreight = reactive({
      study: props.selectedProductNetwork.study,
      studysite: props.selectedProductNetwork.studysite,
      studysiteproduct: props.selectedProductNetwork.studysiteproduct,
      studysiteproductnetwork: props.selectedProductNetwork.minervaId,
      dose_default_freight:
        typeof props.existingFreightPrice === "string"
          ? 0
          : props.existingFreightPrice,
    });

    const dosePriceChanged = computed(
      () => props.existingDosePrice !== newDosePrice.dose_default_price
    );

    const doseFreightChanged = computed(
      () => props.existingFreightPrice !== newDoseFreight.dose_default_freight
    );

    const createDosePrice = () =>
      store.dispatch("studySiteProducts/updateStudySiteProductNetworkPrice", {
        studyId: props.selectedProductNetwork.study,
        studySiteId: props.selectedProductNetwork.studysite,
        productId: props.selectedProductNetwork.studysiteproduct,
        networkId: props.selectedProductNetwork.minervaId,
        payload: newDosePrice,
      });

    const setNewDosePriceApiResource = new ApiResource(
      undefined,
      createDosePrice,
      "Successfully saved dose price",
      "There was an error saving the new dose price."
    );

    const createDoseFreight = () =>
      store.dispatch("studySiteProducts/updateStudySiteProductNetworkFreight", {
        studyId: props.selectedProductNetwork.study,
        studySiteId: props.selectedProductNetwork.studysite,
        productId: props.selectedProductNetwork.studysiteproduct,
        networkId: props.selectedProductNetwork.minervaId,
        payload: newDoseFreight,
      });

    const setNewDoseFreightApiResource = new ApiResource(
      undefined,
      createDoseFreight,
      "Successfully saved dose freight",
      "There was an error saving the new dose freight."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      const promises = [];
      if (dosePriceChanged.value) {
        promises.push(setNewDosePriceApiResource.fetchResource());
      }
      if (doseFreightChanged.value) {
        promises.push(setNewDoseFreightApiResource.fetchResource());
      }

      await Promise.all(promises);
      closeForm();
    };

    const formLoading = computed(
      () =>
        setNewDosePriceApiResource.getLoading() ||
        setNewDoseFreightApiResource.getLoading()
    );

    return {
      closeForm,
      newDosePrice,
      newDoseFreight,
      formLoading,
      handleSave,
      dosePriceChanged,
      doseFreightChanged,
    };
  },
};
</script>
