<template>
  <transition :name="transitionDirection" mode="out-in">
    <div v-if="!viewChildPage" key="parent">
      <AppDataTable
        :selected-row-data="selectedPharmacy"
        title="Pharmacies"
        add-button="Pharmacy"
        enable-add-button
        :table-data="pharmaciesApiResource.getData()"
        :columns="columns"
        :loading="tableLoading"
        :context-menu-options="contextMenuOptions"
        @update:selected-row-data="setSelectedPharmacyId"
      >
        <template #addModalContent="{ closeAddModal }">
          <AddEditPharmacy @close-form="closeAddModal" />
        </template>
      </AppDataTable>
    </div>
    <div v-else key="child">
      <div class="p-d-flex p-ai-start p-ml-2">
        <Button label="Back" class="p-mb-4" @click="viewChildPage = false" />
        <h4
          v-if="selectedPharmacy && selectedPharmacy.display_name"
          class="p-ml-4"
          style="margin: 0px"
        >
          {{
            `Pharmacy - ${selectedPharmacy && selectedPharmacy.display_name}`
          }}
        </h4>
      </div>
      <PharmacyDetailsWrapper :selected-pharmacy="selectedPharmacy" />
    </div>
  </transition>
  <Dialog
    v-model:visible="openEditModal"
    :modal="true"
    :header="`Edit Pharmacy - ${
      selectedPharmacy && selectedPharmacy.display_name
    }`"
  >
    <AddEditPharmacy
      :selected-pharmacy="selectedPharmacy"
      :is-editing="true"
      @close-form="openEditModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import AddEditPharmacy from "@/components/Pharmacies/AddEditPharmacy.vue";
import PharmacyDetailsWrapper from "@/components/Pharmacies/PharmacyDetailsWrapper.vue";

import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Pharmacies",
  components: {
    AppDataTable,
    AddEditPharmacy,
    PharmacyDetailsWrapper,
    Dialog,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const columns = ref([
      { field: "name", header: "Name" },
      { field: "city", header: "City" },
      { field: "state", header: "State" },
      { field: "country", header: "Country" },
    ]);

    const contextMenuOptions = [
      {
        label: "View",
        command: () => {
          viewChildPage.value = true;
        },
      },
      {
        label: "Modify",
        command: () => {
          openEditModal.value = true;
        },
      },
      {
        label: "Delete",
        command: (event) => {
          confirmDeletePharmacyHandler(event);
        },
      },
    ];

    const viewChildPage = ref(false);
    const transitionDirection = computed(() =>
      viewChildPage.value ? "page-right" : "page-left"
    );

    const pharmacies = () => store.state.pharmacies.pharmacies;
    const fetchPharmacies = () => store.dispatch("pharmacies/fetchPharmacies");
    const pharmaciesApiResource = new ApiResource(
      pharmacies,
      fetchPharmacies,
      undefined,
      "There was an error fetching your pharmacies."
    );

    onMounted(() => {
      pharmaciesApiResource.fetchResource();
    });

    const deletePharmacy = () =>
      store.dispatch("pharmacies/deletePharmacy", selectedPharmacy.value);
    const deletePharmacyApiResource = new ApiResource(
      undefined,
      deletePharmacy,
      "Successfully deleted pharmacy.",
      "There was an error deleting the pharmacy."
    );

    const selectedPharmacyId = ref("");
    const selectedPharmacy = computed(() => {
      if (selectedPharmacyId.value.length > 0) {
        return store.state.pharmacies.pharmacies.find(
          (pharmacy) => pharmacy.minervaId === selectedPharmacyId.value
        );
      }
      return {};
    });
    const setSelectedPharmacyId = (pharmacy) => {
      selectedPharmacyId.value = pharmacy.minervaId;
    };

    const openEditModal = ref(false);

    const tableLoading = computed(
      () =>
        pharmaciesApiResource.getLoading() ||
        deletePharmacyApiResource.getLoading()
    );

    const confirm = useConfirm();

    const confirmDeletePharmacyHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete pharmacy ${selectedPharmacy.value.display_name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deletePharmacyApiResource.fetchResource();
        },
        reject: () => {
          selectedPharmacyId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    return {
      columns,
      contextMenuOptions,
      pharmaciesApiResource,
      setSelectedPharmacyId,
      selectedPharmacy,
      openEditModal,
      tableLoading,
      viewChildPage,
      transitionDirection,
    };
  },
};
</script>
