<template>
  <div class="p-grid">
    <div class="p-col-4">
      <AppDataTable
        title="Groups"
        :table-data="groupsApiResource.getData()"
        :loading="groupsTableLoading"
        :show-add-button="false"
        :order-table="true"
      >
        <Column header="Name">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
        <Column header="View Roles">
          <template #body="{ data }">
            <Button
              v-if="data.minervaId"
              label="View"
              class="p-button-xs"
              @click="viewRoles(data)"
            />
          </template>
        </Column>
      </AppDataTable>
    </div>
    <div class="p-col-8">
      <AppDataTable
        :selected-row-data="selectedRole"
        :title="`Roles - ${groupToView.name || 'Select a group'}`"
        :table-data="rolesToDisplay"
        :loading="rolesTableLoading"
        add-button="Group Role"
        enable-add-button
        :context-menu-options="contextMenuOptionsRoles"
        :show-add-button="!!groupToView.name"
        :order-table="true"
        @update:selected-row-data="setSelectedRoleId"
      >
        <Column header="Role">
          <template #body="{ data }">
            {{ data.role_name }}
          </template>
        </Column>
        <Column header="Resource">
          <template #body="{ data }">
            {{ data.resource_uri }}
          </template>
        </Column>
        <template #addModalContent="{ closeAddModal }">
          <AddGroupRole
            :selected-group="groupToView"
            @close-form="closeAddModal"
          />
        </template>
      </AppDataTable>
    </div>
  </div>
  <Dialog
    v-model:visible="showConfirmDeleteGroupRoleModal"
    :modal="true"
    header="Delete Role"
  >
    <DeleteGroupRole
      :selected-group="groupToView"
      :selected-group-role="selectedRole"
      @close-form="showConfirmDeleteGroupRoleModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import DeleteGroupRole from "@/components/RolesPermissions/DeleteGroupRole.vue";
import AddGroupRole from "@/components/RolesPermissions/AddGroupRole.vue";

import Column from "primevue/column";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "GroupRoles",
  components: {
    AppDataTable,
    Dialog,
    Column,
    DeleteGroupRole,
    AddGroupRole,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const groups = () => store.state.groups.groups;
    const fetchGroups = () => store.dispatch("groups/fetchGroups");
    const groupsApiResource = new ApiResource(
      groups,
      fetchGroups,
      undefined,
      "There was an error fetching your groups."
    );

    const groupRoles = () => store.state.rolesPermissions.rolesByGroupId;
    const fetchGroupRoles = () =>
      store.dispatch("rolesPermissions/fetchRolesByGroup", groupToView.value);
    const groupRolesApiResource = new ApiResource(
      groupRoles,
      fetchGroupRoles,
      undefined,
      "There was an error fetching the group roles."
    );

    const groupToView = ref({});

    const viewRoles = (role) => {
      groupToView.value = role;
    };

    watch(groupToView, () => {
      if (groupToView.value.minervaId) {
        groupRolesApiResource.fetchResource();
      }
    });

    const rolesToDisplay = computed(() => {
      if (
        groupRolesApiResource.getData() &&
        Object.keys(groupRolesApiResource.getData()).length > 0
      ) {
        return groupRolesApiResource.getData()[groupToView.value.minervaId];
      }
      return [];
    });

    const deleteGroupRole = () =>
      store.dispatch("rolesPermissions/deleteRoleByGroup", {
        group: groupToView.value,
        payload: selectedRole.value,
      });
    const deleteGroupRoleApiResource = new ApiResource(
      undefined,
      deleteGroupRole,
      "Successfully deleted the group role.",
      "There was an error deleting the group role."
    );

    const selectedRoleId = ref("");
    const selectedRole = computed(() => {
      if (
        selectedRoleId.value.length > 0 &&
        store.state.rolesPermissions.rolesByGroupId[groupToView.value.minervaId]
      ) {
        return store.state.rolesPermissions.rolesByGroupId[
          groupToView.value.minervaId
        ].find((role) => role.minervaId === selectedRoleId.value);
      }
      return {};
    });

    const setSelectedRoleId = (role) => {
      selectedRoleId.value = role.minervaId;
    };

    const confirm = useConfirm();

    const confirmDeleteRoleHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete group role ${selectedRole.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteGroupRoleApiResource.fetchResource();
        },
        reject: () => {
          selectedRoleId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const showConfirmDeleteGroupRoleModal = ref(false);

    const contextMenuOptionsRoles = [
      {
        label: "Delete",
        command: () => {
          showConfirmDeleteGroupRoleModal.value = true;
        },
      },
    ];

    onMounted(() => {
      if (
        !groupsApiResource.getData() ||
        groupsApiResource.getData().length === 0
      ) {
        groupsApiResource.fetchResource();
      }
    });

    const groupsTableLoading = computed(() => groupsApiResource.getLoading());

    const rolesTableLoading = computed(
      () =>
        groupRolesApiResource.getLoading() ||
        deleteGroupRoleApiResource.getLoading()
    );

    return {
      groupsApiResource,
      groupsTableLoading,
      rolesTableLoading,
      groupToView,
      confirmDeleteRoleHandler,
      setSelectedRoleId,
      selectedRole,
      viewRoles,
      rolesToDisplay,
      contextMenuOptionsRoles,
      showConfirmDeleteGroupRoleModal,
    };
  },
};
</script>
