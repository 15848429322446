<template>
  <FormWrapper :loading="rejectPharmacyOrderApiResource.getLoading()">
    <FormCol col-width="p-md-6">
      <label for="order_cancellation_reason" class="title"
        >Cancellation Reason</label
      >
      <Dropdown
        id="order_cancellation_reason"
        v-model="model.cancellation_reason.$model"
        :options="cancellationReasons"
        placeholder="Select a reason"
        append-to="body"
        :class="{
          'p-invalid': model.cancellation_reason.$invalid,
        }"
      />
      <ErrorMessages :model-property="model.cancellation_reason" />
    </FormCol>
    <Divider />
    <FormCol v-if="cancellationReason.cancellation_reason === 'Other'">
      <label for="other_reason" class="title">Other Reason</label>
      <Textarea
        v-model="model.other.$model"
        label="other_reason"
        :auto-resize="true"
        rows="3"
        cols="60"
        :class="{
          'p-invalid': model.other.$invalid,
        }"
      />
      <ErrorMessages :model-property="model.other" />
    </FormCol>
    <Divider v-if="cancellationReason.cancellation_reason === 'Other'" />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="
          rejectPharmacyOrderApiResource.getLoading() || model.$invalid
        "
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="rejectOrder"
      />
    </FormButtonCol>
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Textarea from "primevue/textarea";
import { reactive, inject, ref, computed, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  helpers,
} from "@vuelidate/validators";
import { useStore } from "vuex";
import orderCancellationReasons from "@/helpers/orderCancellationReasons";

export default {
  name: "RejectPharmacyOrder",
  components: {
    FormWrapper,
    FormButtonCol,
    FormCol,
    CancelConfirmButtons,
    ErrorMessages,
    Divider,
    Dropdown,
    Textarea,
  },
  props: {
    pharmacyOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const auth = inject("Auth");
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const user = auth?.user?.value ?? undefined;

    const cancellationReason = reactive({
      cancellation_reason: "",
      other: "",
    });

    const rules = {
      cancellation_reason: {
        required: helpers.withMessage(
          "A cancellation reason is required",
          required
        ),
      },
      other: {
        requiredIf: helpers.withMessage(
          "A reason is required",
          requiredIf(() => cancellationReason.cancellation_reason === "Other")
        ),
        minLength: minLength(1),
      },
    };

    const model = useVuelidate(rules, cancellationReason, { $lazy: true });

    const closeForm = () => emit("close-form");

    const rejectPharmacyOrder = () =>
      store.dispatch("pharmacyOrders/rejectPharmacyOrder", {
        pharmacyOrder: props.pharmacyOrder,
        payload:
          cancellationReason.cancellation_reason === "Other"
            ? { cancellation_reason: cancellationReason.other }
            : { cancellation_reason: cancellationReason.cancellation_reason },
      });
    const rejectPharmacyOrderApiResource = new ApiResource(
      undefined,
      rejectPharmacyOrder,
      "Successfully cancelled the pharmacy order",
      "There was an error cancelling the pharmacy order."
    );

    const rejectOrder = async () => {
      if (await model.value.$validate()) {
        await rejectPharmacyOrderApiResource.fetchResource();
        closeForm();
      }
    };

    const reasonPermissionsPharmacySystemAdmin = ref(false);
    const reasonPermissionsPharmacyOrderAdmin = ref(false);
    const reasonPermissionsPharmacyTechnician = ref(false);

    const getReasonPermissionsRequestPharmacySystemAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "PharmacySystemAdmin",
        email: user.email,
        resource: `/pharmacy/${props.pharmacyOrder.active_pharmacy.minervaId}`,
      });

    const reasonPermissionsApiResourcePharmacySystemAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestPharmacySystemAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestPharmacyOrderAdmin = () =>
      store.dispatch("users/hasRole", {
        role: "PharmacyOrderAdmin",
        email: user.email,
        resource: `/pharmacy/${props.pharmacyOrder.active_pharmacy.minervaId}`,
      });

    const reasonPermissionsApiResourcePharmacyOrderAdmin = new ApiResource(
      undefined,
      getReasonPermissionsRequestPharmacyOrderAdmin,
      undefined,
      undefined
    );

    const getReasonPermissionsRequestPharmacyTechnician = () =>
      store.dispatch("users/hasRole", {
        role: "PharmacyTechnician",
        email: user.email,
        resource: `/pharmacy/${props.pharmacyOrder.active_pharmacy.minervaId}`,
      });

    const reasonPermissionsApiResourcePharmacyTechnician = new ApiResource(
      undefined,
      getReasonPermissionsRequestPharmacyTechnician,
      undefined,
      undefined
    );

    const getReasonPermissions = async () => {
      const responses = await Promise.all([
        reasonPermissionsApiResourcePharmacySystemAdmin.fetchResource(),
        reasonPermissionsApiResourcePharmacyOrderAdmin.fetchResource(),
        reasonPermissionsApiResourcePharmacyTechnician.fetchResource(),
      ]);
      reasonPermissionsPharmacySystemAdmin.value = responses[0];
      reasonPermissionsPharmacyOrderAdmin.value = responses[1];
      reasonPermissionsPharmacyTechnician.value = responses[2];
    };

    const cancellationReasons = computed(() => {
      if (
        reasonPermissionsPharmacySystemAdmin.value ||
        reasonPermissionsPharmacyOrderAdmin.value
      ) {
        return orderCancellationReasons["PharmacySystemAdmin"];
      }
      if (reasonPermissionsPharmacyTechnician.value) {
        return orderCancellationReasons["PharmacyTechnician"];
      }
      return [];
    });

    onMounted(() => {
      getReasonPermissions();
    });

    return {
      model,
      rejectPharmacyOrderApiResource,
      rejectOrder,
      closeForm,
      cancellationReason,
      cancellationReasons,
    };
  },
};
</script>
