<template>
  <div class="p-grid">
    <div class="p-col-4">
      <AppDataTable
        title="Users"
        :table-data="usersToDisplay"
        :loading="usersTableLoading"
        :show-add-button="false"
        :order-table="true"
      >
        <Column header="Name">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
        <Column header="View Roles">
          <template #body="{ data }">
            <Button
              v-if="data.minervaId"
              label="View"
              class="p-button-xs"
              @click="viewRoles(data)"
            />
          </template>
        </Column>
        <template #headerContent>
          <div class="p-d-flex p-ai-center">
            <InputSwitch v-model="gsaOnly" />
            <label class="checkbox-text p-ml-3 p-mt-1">GSA</label>
          </div>
        </template>
      </AppDataTable>
    </div>
    <div class="p-col-8">
      <AppDataTable
        :selected-row-data="selectedRole"
        :title="`Roles - ${userToView.name || 'Select a user'}`"
        :table-data="rolesToDisplay"
        :loading="rolesTableLoading"
        add-button="User Role"
        enable-add-button
        :context-menu-options="contextMenuOptionsRoles"
        :show-add-button="!!userToView.name"
        :order-table="true"
        @update:selected-row-data="setSelectedRoleId"
      >
        <Column header="Role">
          <template #body="{ data }">
            {{ data.role_name }}
          </template>
        </Column>
        <Column header="Resource">
          <template #body="{ data }">
            {{ data.resource_uri }}
          </template>
        </Column>
        <template #addModalContent="{ closeAddModal }">
          <AddUserRole
            :selected-user="userToView"
            @close-form="closeAddModal"
          />
        </template>
      </AppDataTable>
    </div>
  </div>
  <Dialog
    v-model:visible="showConfirmDeleteUserRoleModal"
    :modal="true"
    header="Delete Role"
  >
    <DeleteUserRole
      :selected-user="userToView"
      :selected-user-role="selectedRole"
      @close-form="showConfirmDeleteUserRoleModal = false"
    />
  </Dialog>
</template>

<script>
import AppDataTable from "@/components/AppDataTable/AppDataTable.vue";
import DeleteUserRole from "@/components/RolesPermissions/DeleteUserRole.vue";
import AddUserRole from "@/components/RolesPermissions/AddUserRole.vue";

import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import { useConfirm } from "primevue/useConfirm";

import { ref, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "UserRoles",
  components: {
    AppDataTable,
    Dialog,
    Column,
    DeleteUserRole,
    AddUserRole,
    InputSwitch,
  },
  setup() {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const users = () => store.state.users.users;
    const fetchUsers = () => store.dispatch("users/fetchUsers");
    const usersApiResource = new ApiResource(
      users,
      fetchUsers,
      undefined,
      "There was an error fetching your users."
    );

    const gsaOnly = ref(false);

    const usersToDisplay = computed(() => {
      if (usersApiResource.getData() && usersApiResource.getData().length > 0) {
        return gsaOnly.value
          ? usersApiResource.getData().filter((user) => user.gsa_account)
          : usersApiResource.getData().filter((user) => !user.gsa_account);
      }
      return [];
    });

    const userRoles = () => store.state.rolesPermissions.rolesByUserId;
    const fetchUserRoles = () =>
      store.dispatch("rolesPermissions/fetchRolesByUser", userToView.value);
    const userRolesApiResource = new ApiResource(
      userRoles,
      fetchUserRoles,
      undefined,
      "There was an error fetching the user roles."
    );

    const userToView = ref({});

    const viewRoles = (role) => {
      userToView.value = role;
    };

    watch(userToView, () => {
      if (userToView.value.minervaId) {
        userRolesApiResource.fetchResource();
      }
    });

    const rolesToDisplay = computed(() => {
      if (
        userRolesApiResource.getData() &&
        Object.keys(userRolesApiResource.getData()).length > 0
      ) {
        return userRolesApiResource.getData()[userToView.value.minervaId];
      }
      return [];
    });

    const deleteUserRole = () =>
      store.dispatch("rolesPermissions/deleteRoleByUser", {
        user: userToView.value,
        payload: selectedRole.value,
      });
    const deleteUserRoleApiResource = new ApiResource(
      undefined,
      deleteUserRole,
      "Successfully deleted the user role.",
      "There was an error deleting the user role."
    );

    const selectedRoleId = ref("");
    const selectedRole = computed(() => {
      if (
        selectedRoleId.value.length > 0 &&
        store.state.rolesPermissions.rolesByUserId[userToView.value.minervaId]
      ) {
        return store.state.rolesPermissions.rolesByUserId[
          userToView.value.minervaId
        ].find((role) => role.minervaId === selectedRoleId.value);
      }
      return {};
    });

    const setSelectedRoleId = (role) => {
      selectedRoleId.value = role.minervaId;
    };

    const confirm = useConfirm();

    const confirmDeleteRoleHandler = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: `Are you sure you wish to delete user role ${selectedRole.value.name}? This action is irreversible!`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteUserRoleApiResource.fetchResource();
        },
        reject: () => {
          selectedRoleId.value = "";
        },
        rejectClass: "p-button-danger p-button-text",
      });
    };

    const showConfirmDeleteUserRoleModal = ref(false);

    const contextMenuOptionsRoles = [
      {
        label: "Delete",
        command: () => {
          showConfirmDeleteUserRoleModal.value = true;
        },
      },
    ];

    onMounted(() => {
      if (
        !usersApiResource.getData() ||
        usersApiResource.getData().length === 0
      ) {
        usersApiResource.fetchResource();
      }
    });

    const usersTableLoading = computed(() => usersApiResource.getLoading());

    const rolesTableLoading = computed(
      () =>
        userRolesApiResource.getLoading() ||
        deleteUserRoleApiResource.getLoading()
    );

    return {
      usersToDisplay,
      usersTableLoading,
      rolesTableLoading,
      userToView,
      confirmDeleteRoleHandler,
      setSelectedRoleId,
      selectedRole,
      viewRoles,
      rolesToDisplay,
      contextMenuOptionsRoles,
      showConfirmDeleteUserRoleModal,
      gsaOnly,
    };
  },
};
</script>
