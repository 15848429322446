<template>
  <FormWrapper :loading="formLoading">
    <FormCol>
      <label for="name" class="title">Name</label>
      <InputText
        id="name"
        v-model="newUserModel.name.$model"
        :class="{ 'p-error': newUserModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newUserModel.name" />
    </FormCol>
    <FormCol>
      <label for="email" class="title">Email</label>
      <InputText
        id="email"
        v-model="newUserModel.email.$model"
        :class="{ 'p-error': newUserModel.email.$invalid }"
      />
      <ErrorMessages :model-property="newUserModel.email" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newUserModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";

import { reactive, computed, inject } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";

export default {
  name: "AddOrderEmailReceiver",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
  },
  emits: ["close-form"],
  setup(_, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newUser = reactive({
      name: "",
      email: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage("A name is required", required),
      },
      email: {
        required: helpers.withMessage("An email address is required", required),
        email: helpers.withMessage("Please enter a valid email address", email),
      },
    };

    const newUserModel = useVuelidate(rules, newUser, { $lazy: true });

    const addOrderEmailReceiver = () =>
      store.dispatch("studyManagement/addOrderEmailReceiver", newUser);

    const addOrderEmailReceiverApiResource = new ApiResource(
      undefined,
      addOrderEmailReceiver,
      "Successfully added new order email receiver",
      "There was an error adding the order email receiver."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newUserModel.value.$validate()) {
        await addOrderEmailReceiverApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(() =>
      addOrderEmailReceiverApiResource.getLoading()
    );

    return {
      closeForm,
      newUserModel,
      formLoading,
      handleSave,
      newUser,
    };
  },
};
</script>
