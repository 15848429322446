<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="name" class="title">Product Name</label>
      <InputText
        id="name"
        v-model="model.name.$model"
        :class="{ 'p-error': model.name.$invalid }"
      />
      <ErrorMessages :model-property="model.name" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="type" class="title">Type</label>
      <Dropdown
        id="type"
        v-model="model.type.$model"
        :options="typeOptions"
        option-label="display"
        option-value="type"
        :class="{ 'p-error': model.type.$invalid }"
      />
      <ErrorMessages :model-property="model.type" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="usage" class="title">Usage</label>
      <Dropdown
        id="usage"
        v-model="model.usage.$model"
        :options="usageOptions"
        option-label="display"
        option-value="usage"
        :class="{ 'p-error': model.usage.$invalid }"
      />
      <ErrorMessages :model-property="model.usage" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditProduct",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
  },
  props: {
    selectedProduct: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const typeOptions = [
      {
        display: "RADIOLIGAND",
        type: "RADIOLIGAND",
      },
      {
        display: "IMAGE",
        type: "IMAGE",
      },
      {
        display: "REPORT",
        type: "REPORT",
      },
      {
        display: "BIOMARKER",
        type: "BIOMARKER",
      },
    ];

    const usageOptions = [
      {
        display: "Research",
        usage: "Research",
      },
      {
        display: "Commercial",
        usage: "Commercial",
      },
    ];

    const newProduct = reactive({
      name: "",
      type: "",
      usage: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage("A product name is required", required),
      },
      type: {
        required: helpers.withMessage("A product type is required", required),
      },
      usage: {
        required: helpers.withMessage(
          "The product usage is required",
          required
        ),
      },
    };

    const model = useVuelidate(rules, newProduct, { $lazy: true });

    const createProduct = () =>
      store.dispatch("products/createProduct", newProduct);

    const createProductApiResource = new ApiResource(
      undefined,
      createProduct,
      "Successfully created new product",
      "There was an error creating the new product."
    );

    const updateProduct = () =>
      store.dispatch("products/updateProduct", newProduct);

    const updateProductApiResource = new ApiResource(
      undefined,
      updateProduct,
      "Successfully updated the product.",
      "There was an error updating the product."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await model.value.$validate()) {
        props.isEditing
          ? await updateProductApiResource.fetchResource()
          : await createProductApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createProductApiResource.getLoading() ||
        updateProductApiResource.getLoading()
    );

    onMounted(() => {
      if (props.isEditing) {
        newProduct.name = props.selectedProduct.name;
        newProduct.type = props.selectedProduct.type;
        newProduct.usage = props.selectedProduct.usage;
        newProduct.minervaId = props.selectedProduct.minervaId;
      }
    });

    return {
      closeForm,
      model,
      formLoading,
      handleSave,
      typeOptions,
      usageOptions,
    };
  },
};
</script>
