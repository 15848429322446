<template>
  <FormWrapper :loading="formLoading" width="600px">
    <FormCol>
      <label for="product_site" class="title">Site</label>
      <Dropdown
        id="product_site"
        v-model="newStudySiteProductNetworkModel.studysite.$model"
        :options="studySites"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        placeholder="Select a site"
        :disabled="isEditing"
        :class="{
          'p-error': newStudySiteProductNetworkModel.studysite.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.studysite"
      />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="product" class="title">Product</label>
      <Dropdown
        id="product"
        v-model="newStudySiteProductNetworkModel.product.$model"
        :options="availableStudySiteProducts"
        option-label="display_name"
        option-value="product"
        append-to="body"
        :placeholder="productPlaceholder"
        :disabled="isEditing"
        :class="{ 'p-error': newStudySiteProductNetworkModel.product.$invalid }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.product"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="network" class="title">Network</label>
      <Dropdown
        id="network"
        v-model="newStudySiteProductNetworkModel.dosenetwork.$model"
        :options="availableStudySiteProductNetworks"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        :placeholder="networkPlaceholder"
        :disabled="isEditing"
        :class="{
          'p-error': newStudySiteProductNetworkModel.dosenetwork.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.dosenetwork"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="priority" class="title">Priority</label>
      <Dropdown
        id="priority"
        v-model="newStudySiteProductNetworkModel.priority.$model"
        :options="availablePriorities"
        append-to="body"
        :placeholder="priorityPlaceholder"
        :class="{
          'p-error': newStudySiteProductNetworkModel.priority.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.priority"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="cost_currency" class="title">Cost Currency</label>
      <Dropdown
        id="cost_currency"
        v-model="newStudySiteProductNetworkModel.dose_default_currency.$model"
        :options="currencyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a cost currency"
        :class="{
          'p-error':
            newStudySiteProductNetworkModel.dose_default_currency.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.dose_default_currency"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="dose_invoice_policy" class="title">Dose Invoice Policy</label>
      <Dropdown
        id="dose_invoice_policy"
        v-model="newStudySiteProductNetworkModel.dose_invoice_policy.$model"
        :options="doseInvoicePolicyOptions"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a dose invoice policy"
        :class="{
          'p-error':
            newStudySiteProductNetworkModel.dose_invoice_policy.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.dose_invoice_policy"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="dose_billing_policy" class="title">Dose Billing Policy</label>
      <Dropdown
        id="dose_billing_policy"
        v-model="newStudySiteProductNetworkModel.dose_billing_policy.$model"
        :options="doseBillingPolicyOptions"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a dose billing policy"
        :class="{
          'p-error':
            newStudySiteProductNetworkModel.dose_billing_policy.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.dose_billing_policy"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="freight_billing_policy" class="title"
        >Freight Billing Policy</label
      >
      <Dropdown
        id="freight_billing_policy"
        v-model="newStudySiteProductNetworkModel.freight_billing_policy.$model"
        :options="freightBillingPolicyOptions"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a freight billing policy"
        :class="{
          'p-error':
            newStudySiteProductNetworkModel.freight_billing_policy.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.freight_billing_policy"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="billing_currency" class="title">Billing Currency</label>
      <Dropdown
        id="billing_currency"
        v-model="newStudySiteProductNetworkModel.billing_currency.$model"
        :options="currencyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a billing currency"
        :class="{
          'p-error': newStudySiteProductNetworkModel.billing_currency.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.billing_currency"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="fx_policy" class="title">FX Policy</label>
      <Dropdown
        id="fx_policy"
        v-model="newStudySiteProductNetworkModel.fx_policy.$model"
        :options="fXPolicyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select an FX policy"
        :class="{
          'p-error': newStudySiteProductNetworkModel.fx_policy.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.fx_policy"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="markup_percent" class="title">Markup</label>
      <InputNumber
        id="markup_percent"
        v-model="newStudySiteProductNetworkModel.markup_percent.$model"
        mode="decimal"
        :min-fraction-digits="2"
        :class="{
          'p-error': newStudySiteProductNetworkModel.markup_percent.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.markup_percent"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="markup_policy" class="title">Markup Policy</label>
      <Dropdown
        id="markup_policy"
        v-model="newStudySiteProductNetworkModel.markup_policy.$model"
        :options="markupPolicyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a markup policy"
        :class="{
          'p-error': newStudySiteProductNetworkModel.markup_policy.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.fx_policy"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="minimum_cost" class="title">Minimum Price</label>
      <InputNumber
        id="minimum_cost"
        v-model="newStudySiteProductNetworkModel.minimum_cost.$model"
        mode="decimal"
        :min-fraction-digits="2"
        :min="0"
        :class="{
          'p-error': newStudySiteProductNetworkModel.minimum_cost.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.minimum_cost"
      />
    </FormCol>

    <FormCol col-width="p-md-6">
      <label for="minimum_cost_currency" class="title"
        >Minimum Price Currency</label
      >
      <Dropdown
        id="minimum_cost_currency"
        v-model="newStudySiteProductNetworkModel.minimum_cost_currency.$model"
        :options="currencyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a minimum price currency"
        :class="{
          'p-error':
            newStudySiteProductNetworkModel.minimum_cost_currency.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.minimum_cost_currency"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="sales_order" class="title">Sales Order</label>
      <InputText
        id="sales_order"
        v-model="newStudySiteProductNetwork.sales_order"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="pricing_structure" class="title">Pricing Structure</label>
      <InputText
        id="pricing_structure"
        v-model="newStudySiteProductNetwork.pricing_structure"
      />
    </FormCol>
    <Divider />
    <FormCol
      v-if="
        selectedNetwork && Object.keys(selectedNetwork).length > 0
          ? !selectedNetwork.auto_gen_image_site_id
          : false
      "
      col-width="p-md-6"
    >
      <label for="network_study_site_id" class="title"
        >Network Study Site ID</label
      >
      <InputText
        id="network_study_site_id"
        v-model="newStudySiteProductNetworkModel.networkstudysiteid.$model"
        :class="{
          'p-error':
            newStudySiteProductNetworkModel.networkstudysiteid.$invalid,
        }"
      />
      <ErrorMessages
        :model-property="newStudySiteProductNetworkModel.networkstudysiteid"
      />
    </FormCol>
    <Divider
      v-if="
        selectedNetwork && Object.keys(selectedNetwork).length > 0
          ? !selectedNetwork.auto_gen_image_site_id
          : false
      "
    />
    <FormCol v-if="isEditing" col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 49px">
        <Checkbox
          id="ordering_enabled"
          v-model="newStudySiteProductNetwork.ordering_enabled"
          :binary="true"
          class="p-mb-1"
        />
        <label for="ordering_enabled" class="checkbox-text p-ml-2 p-mb-1"
          >Ordering Enabled</label
        >
      </div>
    </FormCol>
    <Divider v-if="isEditing" />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newStudySiteProductNetworkModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";

import { ref, reactive, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import { currencyList } from "@/helpers/currencyList.js";
import {
  doseInvoicePolicyOptions,
  doseBillingPolicyOptions,
  freightBillingPolicyOptions,
  fXPolicyList,
  markupPolicyList,
} from "@/helpers/billingOptions.js";
export default {
  name: "AddEditStudySiteProductNetwork",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    InputNumber,
    Checkbox,
  },
  props: {
    selectedStudy: {
      type: Object,
      default: () => ({}),
    },
    selectedStudySiteProductNetwork: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    studySites: {
      type: Array,
      default: () => [],
    },
    allStudySiteProducts: {
      type: Array,
      default: () => [],
    },
    allStudySiteProductNetworks: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newStudySiteProductNetwork = reactive({
      study: props.selectedStudy.minervaId,
      site: "",
      studysite: "",
      product: "",
      studysiteproduct: "",
      dosenetwork: "",
      priority: undefined,
      networkstudysiteid: "",
      dose_default_currency: "USD",
      ordering_enabled: false,
      dose_invoice_policy: "full_dose_freight",
      dose_billing_policy: "zero_cancel_5days",
      freight_billing_policy: "zero_cancel_5days",
      billing_currency: "USD",
      fx_policy: "OrderCreation",
      markup_percent: 15.0,
      markup_policy: "percent",
      minimum_cost: 0.0,
      minimum_cost_currency: "USD",
      sales_order: "",
      pricing_structure: "",
    });

    const rules = {
      studysite: {
        required: helpers.withMessage("A site is required", required),
      },
      product: {
        required: helpers.withMessage("A product is required", required),
      },
      dosenetwork: {
        required: helpers.withMessage("A network is required", required),
      },
      priority: {
        required: helpers.withMessage("A priority is required", required),
      },
      dose_default_currency: {
        required: helpers.withMessage("A cost currency is required", required),
      },
      networkstudysiteid: {
        requiredIf: helpers.withMessage(
          "A network site id is required",
          requiredIf(() =>
            selectedNetwork.value
              ? !selectedNetwork.value.auto_gen_image_site_id
              : false
          )
        ),
      },
      dose_invoice_policy: {
        required: helpers.withMessage(
          "A dose invoice policy is required",
          required
        ),
      },
      dose_billing_policy: {
        required: helpers.withMessage(
          "A dose billing policy is required",
          required
        ),
      },
      freight_billing_policy: {
        required: helpers.withMessage(
          "A freight billing is required",
          required
        ),
      },
      markup_percent: {
        required: helpers.withMessage("A markup is required", required),
      },
      minimum_cost: {
        required: helpers.withMessage("A minimum price is required", required),
      },
      billing_currency: {
        required: helpers.withMessage(
          "A billing currency is required",
          required
        ),
      },
      minimum_cost_currency: {
        required: helpers.withMessage(
          "A minimum price currency is required",
          required
        ),
      },
      fx_policy: {
        required: helpers.withMessage("An FX policy is required", required),
      },
      markup_policy: {
        required: helpers.withMessage("A markup policy is required", required),
      },
    };

    const newStudySiteProductNetworkModel = useVuelidate(
      rules,
      newStudySiteProductNetwork,
      { $lazy: true }
    );

    const settingUp = ref(false);

    watch(
      [
        () => newStudySiteProductNetwork.studysite,
        () => newStudySiteProductNetwork.product,
        () => newStudySiteProductNetwork.dosenetwork,
      ],
      (newValues, oldValues) => {
        if (settingUp.value) return;
        if (newValues[2] !== oldValues[2]) {
          newStudySiteProductNetwork.priority = undefined;
          newStudySiteProductNetwork.networkstudysiteid = "";
        }
        if (newValues[1] !== oldValues[1]) {
          newStudySiteProductNetwork.dosenetwork = "";
        }
        if (newValues[0] !== oldValues[0]) {
          newStudySiteProductNetwork.product = "";
        }
        newStudySiteProductNetworkModel.value.$reset();
      }
    );

    const selectedStudySite = computed(() => {
      if (newStudySiteProductNetwork.studysite) {
        return props.studySites.find(
          (studySite) =>
            studySite.minervaId === newStudySiteProductNetwork.studysite
        );
      }
      return {};
    });

    watch(
      () => selectedStudySite.value,
      () => (newStudySiteProductNetwork.site = selectedStudySite.value.site)
    );

    const selectedStudySiteProduct = computed(() => {
      if (
        newStudySiteProductNetwork.site &&
        newStudySiteProductNetwork.studysite &&
        newStudySiteProductNetwork.product
      ) {
        return props.allStudySiteProducts.find(
          (product) =>
            product.product === newStudySiteProductNetwork.product &&
            newStudySiteProductNetwork.site === product.site &&
            newStudySiteProductNetwork.studysite === product.studysite
        );
      }
      return {};
    });

    const selectedNetwork = computed(() => {
      if (newStudySiteProductNetwork.dosenetwork) {
        return availableStudySiteProductNetworks.value.find(
          (network) =>
            network.minervaId === newStudySiteProductNetwork.dosenetwork
        );
      }
      return {};
    });

    watch(
      () => selectedNetwork.value,
      () => {
        if (
          selectedNetwork.value &&
          selectedNetwork.value.auto_gen_image_site_id
        ) {
          if (!props.isEditing)
            newStudySiteProductNetwork.networkstudysiteid = "";
        }
      }
    );

    const availableStudySiteProducts = computed(() => {
      if (newStudySiteProductNetwork.studysite) {
        return props.allStudySiteProducts.filter(
          (product) =>
            product.studysite === newStudySiteProductNetwork.studysite
        );
      }
      return [];
    });

    watch(
      () => selectedStudySiteProduct.value,
      () =>
        (newStudySiteProductNetwork.studysiteproduct =
          selectedStudySiteProduct.value.minervaId)
    );

    const availableStudySiteProductNetworks = computed(() => {
      if (
        newStudySiteProductNetwork.product &&
        newStudySiteProductNetwork.studysite &&
        networksApiResource.getData()
      ) {
        const existingNetworkIds = props.allStudySiteProductNetworks
          .filter(
            (network) =>
              network.product === newStudySiteProductNetwork.product &&
              network.site === newStudySiteProductNetwork.site &&
              network.studysite === newStudySiteProductNetwork.studysite
          )
          .map((network) => network.dosenetwork);

        return props.isEditing
          ? networksApiResource.getData()
          : networksApiResource
              .getData()
              .filter(
                (network) => !existingNetworkIds.includes(network.minervaId)
              );
      }

      return [];
    });

    const basePriorities = [...Array(10).keys()].map((i) => i + 1);

    const availablePriorities = computed(() => {
      if (
        newStudySiteProductNetwork.studysite &&
        newStudySiteProductNetwork.product &&
        newStudySiteProductNetwork.dosenetwork
      ) {
        const existingPriorities = props.allStudySiteProductNetworks
          .filter(
            (network) =>
              network.product === newStudySiteProductNetwork.product &&
              network.studysite === newStudySiteProductNetwork.studysite
          )
          .map((network) => network.priority);
        return basePriorities.filter((priority) => {
          const isSelectedPriority =
            props.isEditing &&
            props.selectedStudySiteProductNetwork.priority === priority;
          return !isSelectedPriority
            ? !existingPriorities.includes(priority)
            : true;
        });
      }
      return [];
    });

    const createStudySiteProductNetwork = () =>
      store.dispatch("studySiteProducts/addStudySiteProductNetwork", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: selectedStudySite.value.minervaId,
        productId: selectedStudySiteProduct.value.minervaId,
        payload: newStudySiteProductNetwork,
      });

    const createStudySiteProductNetworkApiResource = new ApiResource(
      undefined,
      createStudySiteProductNetwork,
      "Successfully created new study site product network",
      "There was an error creating the new study site product network."
    );

    const getUpdatePayload = () => {
      const properties = [
        "priority",
        "networkstudysiteid",
        "dose_default_currency",
        "ordering_enabled",
        "dose_invoice_policy",
        "dose_billing_policy",
        "freight_billing_policy",
        "billing_currency",
        "fx_policy",
        "markup_percent",
        "markup_policy",
        "minimum_cost",
        "minimum_cost_currency",
        "sales_order",
        "pricing_structure",
      ];

      const payload = {};

      for (const property of properties) {
        if (
          newStudySiteProductNetwork[property] !==
          props.selectedStudySiteProductNetwork[property]
        )
          payload[property] = newStudySiteProductNetwork[property];
      }
      return payload;
    };

    const updateStudySiteProductNetwork = () =>
      store.dispatch("studySiteProducts/updateStudySiteProductNetwork", {
        studyId: props.selectedStudy.minervaId,
        studySiteId: selectedStudySite.value.minervaId,
        productId: selectedStudySiteProduct.value.minervaId,
        productNetworkId: props.selectedStudySiteProductNetwork.minervaId,
        payload: getUpdatePayload(),
      });

    const updateStudySiteProductNetworkApiResource = new ApiResource(
      undefined,
      updateStudySiteProductNetwork,
      "Successfully updated the study site product network",
      "There was an error updating the study site product network."
    );

    const networks = () => store.state.studyManagement.networks;
    const fetchNetworks = () => store.dispatch("studyManagement/fetchNetworks");

    const networksApiResource = new ApiResource(
      networks,
      fetchNetworks,
      undefined,
      "There was an error fetching the networks."
    );

    const productPlaceholder = computed(() =>
      newStudySiteProductNetwork.studysite === ""
        ? "Select a site"
        : "Select a product"
    );

    const networkPlaceholder = computed(() =>
      newStudySiteProductNetwork.product === ""
        ? "Select a product"
        : "Select a network"
    );

    const priorityPlaceholder = computed(() =>
      newStudySiteProductNetwork.dosenetwork === ""
        ? "Select a network"
        : "Select a priority"
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newStudySiteProductNetworkModel.value.$validate()) {
        props.isEditing
          ? await updateStudySiteProductNetworkApiResource.fetchResource()
          : await createStudySiteProductNetworkApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        networksApiResource.getLoading() ||
        createStudySiteProductNetworkApiResource.getLoading() ||
        updateStudySiteProductNetworkApiResource.getLoading()
    );

    onMounted(async () => {
      await networksApiResource.fetchResource();
      settingUp.value = true;
      if (props.isEditing) {
        for (const [key, value] of Object.entries(
          props.selectedStudySiteProductNetwork
        )) {
          const isString = typeof value === "string";

          if (
            value != null &&
            (isString ? value.length > 0 : !isNaN(value) && value >= 0)
          ) {
            newStudySiteProductNetwork[key] = value;
          }
        }
      }
      setTimeout(() => {
        settingUp.value = false;
      }, 100);
    });

    return {
      closeForm,
      newStudySiteProductNetwork,
      newStudySiteProductNetworkModel,
      productPlaceholder,
      networkPlaceholder,
      priorityPlaceholder,
      formLoading,
      handleSave,
      availableStudySiteProductNetworks,
      availablePriorities,
      currencyList,
      availableStudySiteProducts,
      selectedNetwork,
      networksApiResource,
      doseInvoicePolicyOptions,
      doseBillingPolicyOptions,
      freightBillingPolicyOptions,
      fXPolicyList,
      markupPolicyList,
    };
  },
};
</script>
