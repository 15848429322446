<template>
  <Panel>
    <template #header>
      <div
        class="p-d-flex p-flex-row p-jc-between p-ai-center"
        style="width: 100%; height: 40px"
      >
        <h5 class="app-data-list-title">Pharmacy Order Calendar</h5>
        <div class="p-d-flex p-flex-row p-py-1">
          <span class="p-mr-4">
            <ProgressLoader
              style="width: 23px; height: 23px"
              :loading="loading"
            />
          </span>
        </div>
      </div>
    </template>
    <div class="calendar-container">
      <FullCalendar
        ref="calendarRef"
        :events="mappedOrders"
        :options="calendarOptions"
      />
    </div>
  </Panel>
</template>

<script>
import ProgressLoader from "@/components/ProgressLoader/ProgressLoader";
import Panel from "primevue/panel";
import FullCalendar from "primevue/fullcalendar";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { getDate, getTime } from "@/helpers/timeHelpers";
import { DateTime } from "luxon";
import { ref, computed, inject, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "PharmacyOrderCalendar",
  components: {
    Panel,
    ProgressLoader,
    FullCalendar,
  },
  props: {
    selectedPharmacy: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["order-options"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();
    const openAddModal = ref(false);
    const calendarRef = ref(null);
    const closeAddModel = () => (openAddModal.value = false);

    const calendarOptions = {
      initialView: "listWeek",
      timeZone: props.selectedPharmacy.timezone,
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      weekends: true,
      initialDate: new Date(),
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "",
      },
      editable: false,
      slotMinTime: "06:00",
      slotMaxTime: "22:00",
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false,
      },
      datesSet: () => {
        startDate.value = DateTime.fromISO(
          new Date(calendarRef.value.calendar.getDate()).toISOString()
        ).plus({ day: 2 });
        getOrdersWeekly();
      },
      eventDidMount: function (info) {
        const color = getStatusColorDot(info.event.extendedProps.order);
        var timeText = info.el.getElementsByClassName("fc-list-event-time")[0];
        timeText.style.fontSize = "14px";
        timeText.style.fontWeight = "500";

        var dotEl = info.el.getElementsByClassName("fc-list-event-dot")[0];
        if (dotEl) {
          dotEl.classList.add(color);
        }
        var contentParent = info.el.getElementsByClassName(
          "fc-list-event-title"
        )[0];
        const orderDetailsRow = orderSubjectStatusRow(
          info.event.extendedProps.order
        );
        contentParent.firstChild.appendChild(orderDetailsRow);

        info.el.addEventListener("contextmenu", (event) => {
          event.preventDefault();
          emit("order-options", event, info.event.extendedProps.order);
        });
      },
    };

    const orderSubjectStatusRow = (order) => {
      const orderId = generateTitleValueText(
        "Order ID",
        order.order_data?.order_id ?? ""
      );
      const status = generateStatus(order);
      const product = generateTitleValueText(
        "Product",
        order.product_info?.name ?? ""
      );
      const subjectId = generateTitleValueText(
        "Subject ID",
        order.order_data?.subject_id ?? ""
      );
      const contentDiv = document.createElement("div");
      contentDiv.style.display = "flex";
      contentDiv.style.width = "100%";
      contentDiv.appendChild(orderId);
      contentDiv.appendChild(status);
      contentDiv.appendChild(product);
      contentDiv.appendChild(subjectId);
      if (order.active_pharmacy.courier_needed) {
        const pickupTime = generateTitleValueText(
          "Pickup Time",
          getTime(order.active_pharmacy.pickup_time)
        );
        contentDiv.appendChild(pickupTime);
        if (getWaybillText(order)) {
          const waybillText = generateTitleValueText(
            "Waybill",
            getWaybillText(order)
          );

          contentDiv.appendChild(waybillText);
        }
      }
      return contentDiv;
    };

    const generateStatus = (order) => {
      const contentDiv = document.createElement("div");

      const valueSpan = document.createElement("div");
      valueSpan.classList.add(getStatusColorText(order));
      valueSpan.classList.add("calendar-item");
      const valueContent = document.createTextNode(getStatusText(order));
      valueSpan.appendChild(valueContent);

      contentDiv.style.margin = "0px 18px 0px 2px";
      contentDiv.appendChild(titleSpan("Status"));
      contentDiv.appendChild(valueSpan);
      return contentDiv;
    };

    const generateTitleValueText = (title, value) => {
      const contentDiv = document.createElement("div");

      const valueSpan = document.createElement("div");
      valueSpan.classList.add("calendar-item");
      const valueContent = document.createTextNode(value);
      valueSpan.appendChild(valueContent);

      contentDiv.style.margin = "0px 18px 0px 2px";
      contentDiv.appendChild(titleSpan(title));
      contentDiv.appendChild(valueSpan);
      return contentDiv;
    };

    const titleSpan = (title) => {
      const titleSpan = document.createElement("span");
      titleSpan.classList.add("calendar-title");
      const titleContent = document.createTextNode(`${title}: `);
      titleSpan.appendChild(titleContent);
      return titleSpan;
    };

    const startDate = ref(DateTime.local().toUTC());
    const endDate = computed(() => startDate.value.plus({ days: 6 }));

    const pharmacyOrdersWeekly = () =>
      store.state.pharmacyOrders.pharmacyOrdersWeekly[
        props.selectedPharmacy.minervaId
      ];
    const fetchPharmacyOrdersWeekly = () =>
      store.dispatch("pharmacyOrders/fetchPharmacyOrdersWeekly", {
        pharmacyId: props.selectedPharmacy.minervaId,
        startDate: getDate(startDate.value),
        endDate: getDate(endDate.value),
      });

    const pharmacyOrdersWeeklyApiResource = new ApiResource(
      pharmacyOrdersWeekly,
      fetchPharmacyOrdersWeekly,
      undefined,
      "There was an error fetching your pharmacy orders."
    );

    const getOrdersWeekly = () => {
      const startDayOfWeek = startDate.value.toFormat("c");
      if (startDayOfWeek !== 1) {
        startDate.value = startDate.value.minus({ days: startDayOfWeek - 1 });
      }
      if (!pharmacyOrdersWeeklyApiResource.getLoading()) {
        pharmacyOrdersWeeklyApiResource.fetchResource();
      }
    };

    const getStatusText = (order) => {
      if (order.completed) return "Completed";
      if (order.cancelled) return "Cancelled";
      if (order.confirmation_id !== undefined)
        return order.confirmation_id === "" ? "Unconfirmed" : "Confirmed";

      return "";
    };

    // const getStatusColor = (order) => {
    //   if (order.completed) return "p-tag-success";
    //   if (order.cancelled) return "p-tag-danger";
    //   if (order.confirmation_id !== undefined)
    //     return order.confirmation_id === "" ? "p-tag-warning" : "p-tag-success";

    //   return "";
    // };

    const getStatusColorText = (order) => {
      if (order.completed) return "p-tag-success-text";
      if (order.cancelled) return "p-tag-danger-text";
      if (order.confirmation_id !== undefined)
        return order.confirmation_id === ""
          ? "p-tag-warning-text"
          : "p-tag-success-text";

      return "";
    };

    const getStatusColorDot = (order) => {
      if (order.completed) return "p-tag-success-dot";
      if (order.cancelled) return "p-tag-danger-dot";
      if (order.confirmation_id !== undefined)
        return order.confirmation_id === ""
          ? "p-tag-warning-dot"
          : "p-tag-success-dot";

      return "";
    };

    const getWaybillText = (pharmacyOrder) => {
      const activePharmacy = pharmacyOrder.active_pharmacy;
      if (activePharmacy.waybill == "") return "";
      return `${activePharmacy.courier} | ${activePharmacy.courier_type} | ${activePharmacy.waybill}`;
    };

    const products = () => store.state.products.products;
    const fetchProducts = () => store.dispatch("products/fetchProducts");
    const productsApiResource = new ApiResource(
      products,
      fetchProducts,
      undefined,
      "There was an error fetching your products."
    );

    const mappedOrders = computed(() => {
      if (
        pharmacyOrdersWeeklyApiResource.getData() &&
        pharmacyOrdersWeeklyApiResource.getData().length > 0
      ) {
        return pharmacyOrdersWeeklyApiResource.getData().map(orderMapper);
      }
      return [];
    });

    const orderMapper = (order) => {
      return {
        start: DateTime.fromISO(order.order_data.local_calibration, {
          setZone: true,
        }).toString(),
        extendedProps: {
          order,
        },
      };
    };

    watch(
      () => props.selectedPharmacy.value,
      () => getOrdersWeekly()
    );

    const loading = computed(
      () =>
        pharmacyOrdersWeeklyApiResource.getLoading() ||
        productsApiResource.getLoading()
    );

    onMounted(async () => {
      if (productsApiResource.getData().length === 0)
        await productsApiResource.fetchResource();
    });

    return {
      openAddModal,
      closeAddModel,
      calendarOptions,
      calendarRef,
      loading,
      mappedOrders,
    };
  },
};
</script>

<style>
.p-panel-header {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.app-data-list-title {
  margin: 0px !important;
}
.fc-event-title {
  font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  cursor: pointer;
  white-space: pre-wrap;
}
.fc-event-title-container {
  font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  cursor: pointer;
}
.fc-event-time {
  display: none;
}
.calendar-container {
  margin: 0 auto;
}
.p-tag-success-dot {
  border-bottom-color: #689f38 !important;
  border-left-color: #689f38 !important;
  border-right-color: #689f38 !important;
  border-top-color: #689f38 !important;
}
.p-tag-warning-dot {
  border-bottom-color: #fbc02d !important;
  border-left-color: #fbc02d !important;
  border-right-color: #fbc02d !important;
  border-top-color: #fbc02d !important;
}
.p-tag-danger-dot {
  border-bottom-color: #d32f2f !important;
  border-left-color: #d32f2f !important;
  border-right-color: #d32f2f !important;
  border-top-color: #d32f2f !important;
}
.p-tag-success-text {
  color: #689f38 !important;
}
.p-tag-warning-text {
  color: #fbc02d !important;
}
.p-tag-danger-text {
  color: #d32f2f !important;
}
.calendar-title {
  font-size: 14px;
  font-weight: 500;
}
.calendar-item {
  font-size: 14px;
}
</style>
