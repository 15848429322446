export const doseInvoicePolicyOptions = [
  {
    value: "free_all",
    display_name: "Free All",
  },
  {
    value: "free_dose",
    display_name: "Free Dose",
  },
  {
    value: "free_freight",
    display_name: "Free Freight",
  },
  {
    value: "full_dose_freight",
    display_name: "Full Dose Freight",
  },
  {
    value: "50_dose_full_freight",
    display_name: "50% Dose Full Freight",
  },
  {
    value: "50_dose_50_freight",
    display_name: "50% Dose 50% Freight",
  },
];

export const doseBillingPolicyOptions = [
  {
    value: "zero_cancel_anytime",
    display_name: "Cancel Anytime",
  },
  {
    value: "zero_cancel_day_of",
    display_name: "Cancel Day Of",
  },
  {
    value: "zero_cancel_day_before",
    display_name: "Cancel Day Before",
  },
  {
    value: "zero_cancel_3pm_day_before",
    display_name: "Cancel 3pm Day Before",
  },
  {
    value: "zero_cancel_2days",
    display_name: "Cancel 2 days",
  },
  {
    value: "zero_cancel_5days",
    display_name: "Cancel 5 days",
  },
];

export const freightBillingPolicyOptions = [
  {
    value: "zero_cancel_anytime",
    display_name: "Cancel Anytime",
  },
  {
    value: "zero_cancel_day_of",
    display_name: "Cancel Day Of",
  },
  {
    value: "zero_cancel_day_before",
    display_name: "Cancel Day Before",
  },
  {
    value: "zero_cancel_3pm_day_before",
    display_name: "Cancel 3pm Day Before",
  },
  {
    value: "zero_cancel_2days",
    display_name: "Cancel 2 days",
  },
  {
    value: "zero_cancel_5days",
    display_name: "Cancel 5 days",
  },
];

export const fXPolicyList = [
  { value: "OrderCreation", display_name: "Order Creation" },
  { value: "Fixed", display_name: "Fixed" },
];

export const markupPolicyList = [
  { value: "percent", display_name: "Percent" },
  { value: "fixed", display_name: "Fixed" },
];
