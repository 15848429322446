<template>
  <ProgressSpinner v-if="loading" class="app-loader" stroke-width="5" />
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "ProgressLoader",
  components: {
    ProgressSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-loader {
  width: 33px;
  height: 33px;
}
</style>
