<template>
  <FormWrapper :loading="formLoading" width="850px">
    <FormCol col-width="p-md-4">
      <label for="doc_type" class="title">Document Type</label>
      <Dropdown
        id="doc_type"
        v-model="model.doc_type.$model"
        append-to="body"
        :options="documentTypes"
        :class="{ 'p-error': model.doc_type.$invalid }"
        placeholder="Select a type"
      />
      <ErrorMessages :model-property="model.doc_type" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <label for="date" class="title">Date</label>
      <Calendar
        id="date"
        :model-value="displayDate"
        :disabled-days="[0]"
        date-format="yy-mm-dd"
        :manual-input="false"
        :max-date="maxDate"
        placeholder="Select a date"
        append-to="body"
        :class="{ 'p-error': model.date_string.$invalid }"
        @update:model-value="setDocumentDate(model, $event)"
      />
      <ErrorMessages :model-property="model.date_string" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <label for="product" class="title">Product</label>
      <Dropdown
        id="product"
        v-model="model.product.$model"
        placeholder="Select a product"
        :options="products"
        append-to="body"
        option-label="display_name"
        option-value="minervaId"
        :class="{ 'p-error': model.product.$invalid }"
      />
      <ErrorMessages :model-property="model.product" />
    </FormCol>
    <div style="height: 20px"></div>
    <FormCol>
      <label for="order_ids" class="title">Order IDs</label>
      <p v-if="orderPlaceholder.length > 0">{{ orderPlaceholder }}</p>
      <div
        class="p-d-flex p-flex-column p-flex-wrap"
        :style="`height: ${Math.max(
          185,
          Math.ceil(pharmacyOrdersForDateAndProduct.length / 2) * 37
        )}px`"
      >
        <div
          v-for="order of pharmacyOrdersForDateAndProduct"
          :key="order.order_id"
          class="p-d-flex p-ai-center p-pt-2"
          style="width: 325px"
        >
          <Checkbox
            v-model="model.order_ids.$model"
            :input-id="order.order_id"
            name="order"
            :value="order.order_id"
          />
          <div class="p-px-3 p-py-2">{{ order.order_id }}</div>
          <span
            v-if="order.cancelled"
            class="p-tag p-tag-danger p-ml-2"
            style="font-size: 1em"
          >
            Cancelled
          </span>
        </div>
      </div>
      <ErrorMessages :model-property="model.order_ids" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <input
        ref="fileInput"
        type="file"
        style="visibility: hidden; width: 0px; height: 0px"
        @change="processFile(fileModel, $event)"
      />
      <Button style="width: 100px" @click="selectFile">Select a file</Button>
      <ErrorMessages :model-property="fileModel.file" />
      <div style="height: 10px"></div>
      <InlineMessage v-if="newFile.file" severity="info" class="p-mt-2">
        Selected File: {{ newFile.file.name }}
      </InlineMessage>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || model.$invalid || fileModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="UPLOAD"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
    <ProgressBar v-if="progress > 0" :value="progress" style="height: 0.5rem" />
  </FormWrapper>
</template>

<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Calendar from "primevue/calendar";
import InlineMessage from "primevue/inlinemessage";
import ProgressBar from "primevue/progressbar";
import Checkbox from "primevue/checkbox";

import { reactive, computed, inject, ref, watch } from "vue";
import { getDate, setTZ } from "@/helpers/timeHelpers";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import { DateTime } from "luxon";
import { ToastService } from "@/toast/toast";
import axios from "axios";

export default {
  name: "AddDocument",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    Calendar,
    InlineMessage,
    ProgressBar,
    Checkbox,
  },
  props: {
    pharmacy: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    documentTypes: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");

    const newDocument = reactive({
      doc_type: "",
      date_string: "",
      product: "",
      order_ids: [],
    });

    const rules = {
      doc_type: {
        required: helpers.withMessage("A type is required", required),
      },
      date_string: {
        required: helpers.withMessage("A date is required", required),
      },
      product: {
        required: helpers.withMessage("A product is required", required),
      },
      order_ids: {
        required: helpers.withMessage("An order ID is required", required),
      },
    };

    const model = useVuelidate(rules, newDocument, { $lazy: true });

    const maxDate = new Date(setTZ(DateTime.local(), props.pharmacy.timezone));

    const signedURL = ref("");

    const fetchSignedURL = async () => {
      try {
        const url = await axiosInstance.post(
          buildBaseUrl({
            apiModule: "pharmacyManagerApi",
            url: `pharmacy/${props.pharmacy.minervaId}/pharmacy_document`,
          }),
          newDocument
        );
        if (url) signedURL.value = url.url;
      } catch (error) {
        return Promise.reject(error);
      }
    };
    const signedUrlApiResource = new ApiResource(
      undefined,
      fetchSignedURL,
      undefined,
      "There was an error fetching the upload location."
    );

    const pharmacyOrdersForDate = ref([]);

    const fetchPharmacyOrders = async () => {
      try {
        const pharmacyOrders = await axiosInstance.get(
          buildBaseUrl({
            apiModule: "pharmacyManagerApi",
            url: `pharmacy/${props.pharmacy.minervaId}/pharmacy_order?date_filter=true&start_date=${newDocument.date_string}&end_date=${newDocument.date_string}`,
          })
        );
        pharmacyOrdersForDate.value = pharmacyOrders ?? [];
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const pharmacyOrdersApiResource = new ApiResource(
      undefined,
      fetchPharmacyOrders,
      undefined,
      "There was an error fetching your pharmacy orders."
    );

    const pharmacyOrdersForDateAndProduct = computed(() => {
      if (pharmacyOrdersForDate.value.length > 0) {
        return pharmacyOrdersForDate.value
          .filter((order) => order.order_data.product === newDocument.product)
          .map((order) => {
            return {
              order_id: order.order_data.order_id,
              cancelled: order.cancelled,
            };
          });
      }
      return [];
    });

    watch(
      () => pharmacyOrdersForDateAndProduct.value,
      () => {
        if (pharmacyOrdersForDateAndProduct.value.length > 0) {
          newDocument.order_ids = [];
          for (const order of pharmacyOrdersForDateAndProduct.value) {
            if (!order.cancelled) newDocument.order_ids.push(order.order_id);
          }
        } else {
          newDocument.order_ids = [];
        }
      }
    );

    watch(
      () => newDocument.date_string,
      () => {
        if (newDocument.date_string.length > 0)
          pharmacyOrdersApiResource.fetchResource();
      }
    );

    const progress = ref(0);

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      const formValid = await model.value.$validate();
      const fileValid = await fileModel.value.$validate();
      if (formValid && fileValid) {
        await signedUrlApiResource.fetchResource();

        try {
          await axios.put(signedURL.value, newFile.file, {
            onUploadProgress(progressEvent) {
              progress.value = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            },
            headers: {
              "Content-Type": "application/pdf",
            },
          });
          ToastService.addWithNotification({
            type: "success",
            message: "Successfully uploaded document",
          });
          closeForm();
        } catch {
          ToastService.addWithNotification({
            type: "error",
            message: "There was an error uploading your document",
          });
          // signedURL.value = "";
          // model.doc_type.$model = "";
          // model.date_string.$model = "";
          // model.product.$model = "";
          // model.order_ids.$model = [];
          // fileModel.file.$model = undefined;
        } finally {
          progress.value = 0;
        }
      }
    };

    const displayDate = computed(() => {
      return newDocument.date_string === ""
        ? ""
        : getDate(newDocument.date_string, props.pharmacy.timezone);
    });

    const setDocumentDate = (model, date) => {
      model.date_string.$model = getDate(
        setTZ(DateTime.fromISO(new Date(date).toISOString()))
      );
    };

    const formLoading = computed(() => signedUrlApiResource.getLoading());

    const orderPlaceholder = computed(() => {
      if (newDocument.date_string.length == 0) return "Select a date";
      if (newDocument.product.length == 0) return "Select a product";
      if (pharmacyOrdersApiResource.getLoading()) return "Loading orders";
      if (pharmacyOrdersForDateAndProduct.value.length == 0)
        return "No orders for selected date and product";
      return "";
    });

    const newFile = reactive({ file: undefined });

    const newFileRules = {
      file: {
        required: helpers.withMessage("A file is required", required),
      },
    };

    const fileModel = useVuelidate(newFileRules, newFile, { $lazy: true });

    const fileInput = ref("fileInput");

    const selectFile = () => {
      fileInput.value.click();
    };

    const maxSize = 10000000;

    const processFile = async (fileModel) => {
      const file = event.target.files[0];
      if (file.type !== "application/pdf") {
        ToastService.addWithNotification({
          type: "error",
          message: "Documents must be PDF files",
        });
        return;
      }
      if (file.size > maxSize) {
        ToastService.addWithNotification({
          type: "error",
          message: "Document is too large",
        });
        return;
      }
      fileModel.file.$model = file;
    };

    return {
      closeForm,
      model,
      formLoading,
      handleSave,
      newDocument,
      displayDate,
      setDocumentDate,
      maxDate,
      pharmacyOrdersApiResource,
      orderPlaceholder,
      pharmacyOrdersForDate,
      signedURL,
      fileInput,
      selectFile,
      processFile,
      newFileRules,
      fileModel,
      newFile,
      progress,
      pharmacyOrdersForDateAndProduct,
    };
  },
};
</script>
