<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="organization_name" class="title">Organization Name</label>
      <InputText
        id="organization_name"
        v-model="newOrganizationModel.name.$model"
        :class="{ 'p-error': newOrganizationModel.name.$invalid }"
      />
      <ErrorMessages :model-property="newOrganizationModel.name" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="organization_type" class="title">Organization Type</label>
      <Dropdown
        id="organization_type"
        v-model="newOrganizationModel.type.$model"
        :options="organizationTypes"
        option-label="display_name"
        option-value="value"
        placeholder="Select a Type"
        :class="{ 'p-error': newOrganizationModel.type.$invalid }"
        append-to="body"
      />
      <ErrorMessages :model-property="newOrganizationModel.type" />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newOrganizationModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";

import { ref, reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditUser",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
  },
  props: {
    selectedOrganization: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newOrganization = reactive({
      name: "",
      type: "",
    });

    const rules = {
      name: {
        required: helpers.withMessage(
          "An organization name is required",
          required
        ),
      },
      type: {
        required: helpers.withMessage(
          "An organization type is required",
          required
        ),
      },
    };

    const newOrganizationModel = useVuelidate(rules, newOrganization, {
      $lazy: true,
    });

    const createOrganization = () =>
      store.dispatch("organizations/createOrganization", newOrganization);

    const createOrganizationApiResource = new ApiResource(
      undefined,
      createOrganization,
      "Successfully created new organization",
      "There was an error creating the new organization."
    );

    const updateOrganization = () =>
      store.dispatch("organizations/updateOrganization", newOrganization);

    const updateOrganizationApiResource = new ApiResource(
      undefined,
      updateOrganization,
      "Successfully updated the organization.",
      "There was an error updating the organization."
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newOrganizationModel.value.$validate()) {
        props.isEditing
          ? await updateOrganizationApiResource.fetchResource()
          : await createOrganizationApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createOrganizationApiResource.getLoading() ||
        updateOrganizationApiResource.getLoading()
    );

    const organizationTypes = ref([
      {
        value: "PHARMA",
        display_name: "PHARMA",
      },
      {
        value: "ACADEMIC",
        display_name: "ACADEMIC",
      },
      {
        value: "VENDOR",
        display_name: "VENDOR",
      },
      {
        value: "INDUSTRY",
        display_name: "INDUSTRY",
      },
      {
        value: "PARTNER",
        display_name: "PARTNER",
      },
    ]);

    onMounted(() => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedOrganization)) {
          newOrganization[key] = value;
        }
      }
    });

    return {
      closeForm,
      newOrganization,
      newOrganizationModel,
      formLoading,
      handleSave,
      organizationTypes,
    };
  },
};
</script>
