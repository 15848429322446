<template>
  <FormWrapper :loading="formLoading">
    <FormCol col-width="p-md-6">
      <label for="status" class="title">Status</label>
      <Dropdown
        id="status"
        v-model="newBillingReport.status"
        append-to="body"
        :options="['Active', 'Fixed']"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="billing_currency" class="title">Billing Currency</label>
      <Dropdown
        id="billing_currency"
        v-model="newBillingReport.billing_currency"
        :options="currencyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a billing currency"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="price" class="title">Cost Price</label>
      <InputNumber
        id="price"
        v-model="newBillingReport.price"
        mode="decimal"
        :min-fraction-digits="2"
        :min="0"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="currency" class="title">Currency</label>
      <Dropdown
        id="currency"
        v-model="newBillingReport.currency"
        :options="currencyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a currency"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="minimum_cost" class="title">Minimum Price</label>
      <InputNumber
        id="minimum_cost"
        v-model="newBillingReport.minimum_cost"
        mode="decimal"
        :min-fraction-digits="2"
        :min="0"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="minimum_cost_currency" class="title">Currency</label>
      <Dropdown
        id="minimum_cost_currency"
        v-model="newBillingReport.minimum_cost_currency"
        :options="currencyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a minumum cost currency"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="markup_percent" class="title">Markup</label>
      <InputNumber
        id="markup_percent"
        v-model="newBillingReport.markup_percent"
        mode="decimal"
        :min-fraction-digits="2"
      />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="markup_policy" class="title">Markup Policy</label>
      <Dropdown
        id="markup_policy"
        v-model="newBillingReport.markup_policy"
        :options="markupPolicyList"
        option-label="display_name"
        option-value="value"
        append-to="body"
        placeholder="Select a markup policy"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="fx_rate_cost_2_bill" class="title"
        >FX Rate Cost Billing</label
      >
      <InputNumber
        id="fx_rate_cost_2_bill"
        v-model="newBillingReport.fx_rate_cost_2_bill"
        mode="decimal"
        :min-fraction-digits="2"
        :min="0"
        :class="{
          'p-error': fxCostInvalid,
        }"
      />
      <p v-if="fxCostInvalid" class="p-ml-2 p-mt-2 p-error">
        A FX Rate Cost Billing is required
      </p>
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="fx_rate_min_2_bill" class="title"
        >FX Rate Minimum Billing</label
      >
      <InputNumber
        id="fx_rate_min_2_bill"
        v-model="newBillingReport.fx_rate_min_2_bill"
        mode="decimal"
        :min-fraction-digits="2"
        :min="0"
        :class="{
          'p-error': fxMinInvalid,
        }"
      />
      <p v-if="fxMinInvalid" class="p-ml-2 p-mt-2 p-error">
        A FX Rate Minimum Billing is required
      </p>
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="FX Rate Date"
        :data="
          DateTime.fromISO(newBillingReport.fx_rate_date).toLocaleString(
            DateTime.DATETIME_FULL
          )
        "
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Converted Price"
        :data="`$${newBillingReport.converted_price.toFixed(2)}`"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Calculated Price"
        :data="`$${newBillingReport.calculated_price.toFixed(2)}`"
      />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Final Price"
        :data="`$${newBillingReport.final_price.toFixed(2)}`"
        classes="p-text-bold"
      />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="freight" class="title">Freight</label>
      <InputNumber
        id="freight"
        v-model="newBillingReport.freight"
        mode="decimal"
        :min-fraction-digits="2"
        :min="0"
      />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="comments" class="title">Comments</label>
      <Textarea
        v-model="newBillingReport.comments"
        label="comments"
        :auto-resize="true"
        rows="3"
        cols="60"
      />
    </FormCol>
    <Divider />
    <FormCol>
      <FormDataView title="System Messages" :data="systemMessages" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <FormDataView title="Dose Invoice Policy" :data="doseInvoicePolicy" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <FormDataView title="Dose Billing Policy" :data="doseBillingPolicy" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <FormDataView title="Freight Billing Policy" :data="doseFrieghtPolicy" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView title="Sales Order" :data="newBillingReport.sales_order" />
    </FormCol>
    <FormCol col-width="p-md-4">
      <FormDataView
        title="Pricing Structure"
        :data="newBillingReport.pricing_structure"
      />
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import FormDataView from "@/components/FormComponents/FormDataView";

import { currencyList } from "@/helpers/currencyList.js";
import { reactive, computed, inject, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import {
  doseInvoicePolicyOptions,
  doseBillingPolicyOptions,
  freightBillingPolicyOptions,
  fXPolicyList,
  markupPolicyList,
} from "@/helpers/billingOptions.js";
import { DateTime } from "luxon";

export default {
  name: "ViewEditBillingReport",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    InputNumber,
    Textarea,
    FormDataView,
  },
  props: {
    selectedOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newBillingReport = reactive({
      status: "",
      billing_currency: "",
      price: 0,
      freight: 0,
      currency: "",
      comments: "",
      minimum_cost: 0,
      minimum_cost_currency: "",
      markup_percent: 0,
      fx_rate_cost_2_bill: 0,
      fx_rate_min_2_bill: 0,
      markup_policy: "",
      fx_rate_date: "",
      final_price: 0,
      converted_price: 0,
      calculated_price: 0,
      sales_order: "",
      pricing_structure: "",
    });

    const fxCostInvalid = computed(
      () =>
        newBillingReport.fx_rate_cost_2_bill == 0 &&
        newBillingReport.fx_rate_min_2_bill > 0
    );

    const fxMinInvalid = computed(
      () =>
        newBillingReport.fx_rate_min_2_bill == 0 &&
        newBillingReport.fx_rate_cost_2_bill > 0
    );

    // const fetchBillingReport = async () => {
    //   await store.dispatch("orderManagement/fetchBillingReport", {
    //     studyId: props.selectedOrder.study,
    //     studySiteId: props.selectedOrder.studysite,
    //     orderId: props.selectedOrder.minervaId,
    //   });
    // };

    // const fetchBillingReportApiResource = new ApiResource(
    //   undefined,
    //   fetchBillingReport,
    //   undefined,
    //   "There was an error fetching the billing report"
    // );

    const existingBillingReport = computed(
      () =>
        store.state.orderManagement.billingReports[
          props.selectedOrder.minervaId
        ]
    );

    const updateBillingReport = () =>
      store.dispatch("orderManagement/updateBillingReport", {
        params: {
          studyId: props.selectedOrder.study,
          studySiteId: props.selectedOrder.studysite,
          billingReportId: existingBillingReport.value.minervaId,
        },
        payload: getUpdatePayload(),
      });

    const updateBillingReportApiResource = new ApiResource(
      undefined,
      updateBillingReport,
      "Successfully updated the billing report.",
      "There was an error updating the billing report."
    );

    const getUpdatePayload = () => {
      const properties = [
        "status",
        "billing_currency",
        "price",
        "freight",
        "currency",
        "comments",
        "minimum_cost",
        "minimum_cost_currency",
        "markup_percent",
        "fx_rate_cost_2_bill",
        "fx_rate_min_2_bill",
        "markup_policy",
      ];

      const payload = {};

      for (const property of properties) {
        if (
          newBillingReport[property] !== existingBillingReport.value[property]
        )
          payload[property] = newBillingReport[property];
      }
      return payload;
    };

    const hasBillingReport = computed(
      () =>
        existingBillingReport.value &&
        Object.keys(existingBillingReport.value).length > 0
    );

    const systemMessages = computed(() =>
      hasBillingReport.value ? existingBillingReport.value.system_messages : ""
    );

    const doseBillingPolicy = computed(() => {
      if (
        hasBillingReport.value &&
        existingBillingReport.value.dose_billing_policy
      ) {
        const thisOption = doseBillingPolicyOptions.find(
          (option) =>
            option.value == existingBillingReport.value.dose_billing_policy
        );
        return thisOption ? thisOption.display_name : "";
      }
      return "";
    });

    const doseInvoicePolicy = computed(() => {
      if (
        hasBillingReport.value &&
        existingBillingReport.value.dose_invoice_policy
      ) {
        const thisOption = doseInvoicePolicyOptions.find(
          (option) =>
            option.value == existingBillingReport.value.dose_invoice_policy
        );
        return thisOption ? thisOption.display_name : "";
      }
      return "";
    });

    const doseFrieghtPolicy = computed(() => {
      if (
        hasBillingReport.value &&
        existingBillingReport.value.freight_billing_policy
      ) {
        const thisOption = freightBillingPolicyOptions.find(
          (option) =>
            option.value == existingBillingReport.value.freight_billing_policy
        );

        return thisOption ? thisOption.display_name : "";
      }
      return "";
    });

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (!fxCostInvalid.value && !fxMinInvalid.value) {
        await updateBillingReportApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(() =>
      updateBillingReportApiResource.getLoading()
    );

    onMounted(async () => {
      // await fetchBillingReportApiResource.fetchResource();
      if (
        existingBillingReport.value &&
        Object.keys(existingBillingReport.value).length > 0
      ) {
        newBillingReport.status = existingBillingReport.value.status;
        newBillingReport.billing_currency =
          existingBillingReport.value.billing_currency;
        newBillingReport.price = existingBillingReport.value.price;
        newBillingReport.minimum_cost =
          existingBillingReport.value.minimum_cost;
        newBillingReport.minimum_cost_currency =
          existingBillingReport.value.minimum_cost_currency;
        newBillingReport.currency = existingBillingReport.value.currency;
        newBillingReport.freight = existingBillingReport.value.freight;
        newBillingReport.currency = existingBillingReport.value.currency;
        newBillingReport.comments = existingBillingReport.value.comments;
        newBillingReport.markup_percent =
          existingBillingReport.value.markup_percent;
        newBillingReport.markup_policy =
          existingBillingReport.value.markup_policy;
        newBillingReport.fx_rate_cost_2_bill =
          existingBillingReport.value.fx_rate_cost_2_bill;
        newBillingReport.fx_rate_min_2_bill =
          existingBillingReport.value.fx_rate_min_2_bill;
        newBillingReport.fx_rate_date =
          existingBillingReport.value.fx_rate_date;
        newBillingReport.final_price = existingBillingReport.value.final_price;
        newBillingReport.converted_price =
          existingBillingReport.value.converted_price;
        newBillingReport.calculated_price =
          existingBillingReport.value.calculated_price;
        newBillingReport.sales_order = existingBillingReport.value.sales_order;
        newBillingReport.pricing_structure =
          existingBillingReport.value.pricing_structure;
      }
    });

    onUnmounted(() => {
      newBillingReport.status = "";
      newBillingReport.billing_currency = "";
      newBillingReport.currency = "";
      newBillingReport.price = 0;
      newBillingReport.minimum_cost = 0;
      newBillingReport.minimum_cost_currency = "";
      newBillingReport.freight = 0;
      newBillingReport.markup_percent = 0;
      newBillingReport.fx_rate_cost_2_bill = 0;
      newBillingReport.fx_rate_min_2_bill = 0;
      newBillingReport.markup_policy = "";
      newBillingReport.currency = "";
      newBillingReport.comments = "";
      newBillingReport.fx_rate_date = "";
      newBillingReport.final_price = 0;
      newBillingReport.converted_price = 0;
      newBillingReport.calculated_price = 0;
      newBillingReport.sales_order = "";
      newBillingReport.pricing_structure = "";
    });

    return {
      newBillingReport,
      closeForm,
      formLoading,
      handleSave,
      currencyList,
      systemMessages,
      doseBillingPolicy,
      doseInvoicePolicy,
      doseFrieghtPolicy,
      fXPolicyList,
      markupPolicyList,
      DateTime,
      fxCostInvalid,
      fxMinInvalid,
    };
  },
};
</script>
