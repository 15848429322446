``
<template>
  <FormWrapper :loading="formLoading" width="600px">
    <FormCol col-width="p-md-6">
      <label for="study" class="title">Study</label>
      <Dropdown
        id="study"
        v-model="newPharmacyMapModel.study.$model"
        :options="studiesApiResource.getData()"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        placeholder="Select a study"
        :disabled="isEditing"
        :class="{
          'p-error': newPharmacyMapModel.study.$invalid,
        }"
      >
        <template #value="{ value, placeholder }">
          {{ value ? getStudyName(value) : placeholder }}
        </template>
        <template #option="{ option }">
          {{ getStudyNameString(option) }}
        </template>
      </Dropdown>
      <ErrorMessages :model-property="newPharmacyMapModel.study" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="study_site" class="title">Study Site</label>
      <Dropdown
        id="study_site"
        v-model="newPharmacyMapModel.studysite.$model"
        :options="availableStudySites"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        :placeholder="studySitePlaceholder"
        :disabled="isEditing"
        :class="{
          'p-error': newPharmacyMapModel.studysite.$invalid,
        }"
      />
      <ErrorMessages :model-property="newPharmacyMapModel.studysite" />
    </FormCol>
    <Divider />
    <FormCol>
      <label for="product" class="title">Product</label>
      <Dropdown
        id="product"
        v-model="newPharmacyMapModel.product.$model"
        :options="productsApiResource.getData()"
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        placeholder="Select a product"
        :disabled="isEditing"
        :class="{ 'p-error': newPharmacyMapModel.product.$invalid }"
      />
      <ErrorMessages :model-property="newPharmacyMapModel.product" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <label for="pharmacy" class="title">Pharmacy</label>
      <Dropdown
        id="pharmacy"
        v-model="newPharmacyMapModel.pharmacy.$model"
        :options="
          isEditing ? pharmaciesApiResource.getData() : availablePharmacies
        "
        option-label="display_name"
        option-value="minervaId"
        append-to="body"
        :placeholder="pharmacyPlaceholder"
        :disabled="isEditing"
        :class="{
          'p-error': newPharmacyMapModel.pharmacy.$invalid,
        }"
      />
      <ErrorMessages :model-property="newPharmacyMapModel.pharmacy" />
    </FormCol>
    <FormCol col-width="p-md-6">
      <label for="priority" class="title">Priority</label>
      <Dropdown
        id="priority"
        v-model="newPharmacyMapModel.priority.$model"
        :options="availablePriorities"
        append-to="body"
        :placeholder="priorityPlaceholder"
        :class="{
          'p-error': newPharmacyMapModel.priority.$invalid,
        }"
      />
      <ErrorMessages :model-property="newPharmacyMapModel.priority" />
    </FormCol>
    <Divider />
    <FormCol col-width="p-md-6">
      <div class="p-d-flex p-ai-end p-pb-2" style="height: 49px">
        <Checkbox
          id="enabled"
          v-model="newPharmacyMap.enabled"
          :binary="true"
          class="p-mb-1"
        />
        <label for="enabled" class="checkbox-text p-ml-2 p-mb-1">Enabled</label>
      </div>
    </FormCol>
    <Divider />
    <FormButtonCol>
      <CancelConfirmButtons
        :disabled="formLoading || newPharmacyMapModel.$invalid"
        cancel-text="CLOSE"
        confirm-text="SUBMIT"
        @cancelled="closeForm"
        @confirmed="handleSave"
      />
    </FormButtonCol>
  </FormWrapper>
</template>
<script>
import FormWrapper from "@/components/FormComponents/FormWrapper";
import FormCol from "@/components/FormComponents/FormCol";
import FormButtonCol from "@/components/FormComponents/FormButtonCol";
import ErrorMessages from "@/components/ErrorMessages/ErrorMessages";
import CancelConfirmButtons from "@/components/FormComponents/CancelConfirmButtons";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import { getStudyNameString } from "@/helpers/studyName.js";

import { reactive, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  name: "AddEditPharmacyMap",
  components: {
    FormWrapper,
    FormCol,
    FormButtonCol,
    ErrorMessages,
    CancelConfirmButtons,
    Divider,
    Dropdown,
    Checkbox,
  },
  props: {
    studiesApiResource: {
      type: Object,
      default: () => ({}),
    },
    studySitesApiResource: {
      type: Object,
      default: () => ({}),
    },
    productsApiResource: {
      type: Object,
      default: () => ({}),
    },
    pharmaciesApiResource: {
      type: Object,
      default: () => ({}),
    },
    pharmacyMapsApiResource: {
      type: Object,
      default: () => ({}),
    },
    selectedPharmacyMap: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-form"],
  setup(props, { emit }) {
    const ApiResource = inject("ApiResource");
    const store = useStore();

    const newPharmacyMap = reactive({
      study: "",
      studysite: "",
      product: "",
      pharmacy: "",
      priority: 0,
      enabled: true,
    });

    const rules = {
      study: { required: helpers.withMessage("A study is required", required) },
      studysite: {
        required: helpers.withMessage("A site is required", required),
      },
      product: {
        required: helpers.withMessage("A product is required", required),
      },
      pharmacy: {
        required: helpers.withMessage("A pharmacy is required", required),
      },
      priority: {
        required: helpers.withMessage("A priority is required", required),
      },
    };

    const newPharmacyMapModel = useVuelidate(rules, newPharmacyMap, {
      $lazy: true,
    });

    // const selectedStudy = computed(() => {
    //   if (newPharmacyMap.study) {
    //     return props.studiesApiResource
    //       .getData()
    //       .find((study) => study.minervaId === newPharmacyMap.study);
    //   }
    //   return {};
    // });

    const availableStudySites = computed(() => {
      if (newPharmacyMap.study) {
        return props.studySitesApiResource.getData(newPharmacyMap.study);
      }
      return [];
    });

    // const selectedStudySite = computed(() => {
    //   if (newPharmacyMap.study && newPharmacyMap.studysite) {
    //     return props.studySitesApiResource
    //       .getData(newPharmacyMap.study)
    //       .find(
    //         (studySite) => studySite.minervaId === newPharmacyMap.studysite
    //       );
    //   }
    //   return {};
    // });

    const availablePharmacies = computed(() => {
      if (newPharmacyMap.studysite && newPharmacyMap.product) {
        const existingPharmacies = props.pharmacyMapsApiResource
          .getData()
          .filter(
            (map) =>
              map.studysite === newPharmacyMap.studysite &&
              map.product === newPharmacyMap.product
          )
          .map((map) => map.pharmacy);
        return props.pharmaciesApiResource
          .getData()
          .filter(
            (pharmacy) => !existingPharmacies.includes(pharmacy.minervaId)
          );
      }

      return [];
    });

    // const availableStudySiteProducts = computed(() => {
    //   if (newPharmacyMap.studysite) {
    //     return props.allStudySiteProducts.filter(
    //       (product) => product.studysite === newPharmacyMap.studysite
    //     );
    //   }
    //   return [];
    // });

    const getStudyName = (studyId) => {
      const thisStudy = props.studiesApiResource
        .getData()
        .find((study) => study.minervaId === studyId);
      return thisStudy ? getStudyNameString(thisStudy) : "";
    };

    const basePriorities = [...Array(10).keys()].map((i) => i + 1);

    const availablePriorities = computed(() => {
      if (newPharmacyMap.study && newPharmacyMap.studysite) {
        const existingPriorities = props.pharmacyMapsApiResource
          .getData()
          .filter(
            (network) =>
              network.study === newPharmacyMap.study &&
              network.studysite === newPharmacyMap.studysite
          )
          .map((network) => network.priority);
        return basePriorities.filter((priority) => {
          const isSelectedPriority =
            props.isEditing && props.selectedPharmacyMap.priority === priority;
          return !isSelectedPriority
            ? !existingPriorities.includes(priority)
            : true;
        });
      }
      return [];
    });

    const createPharmacyMap = () =>
      store.dispatch("pharmacies/createPharmacyMap", newPharmacyMap);

    const createPharmacyMapApiResource = new ApiResource(
      undefined,
      createPharmacyMap,
      "Successfully created new pharmacy map",
      "There was an error creating the new pharmacy map."
    );

    const updatePharmacyMap = () =>
      store.dispatch("pharmacies/updatePharmacyMap", newPharmacyMap);

    const updatePharmacyMapApiResource = new ApiResource(
      undefined,
      updatePharmacyMap,
      "Successfully updated the pharmacy map",
      "There was an error updating the pharmacy map."
    );

    const studySitePlaceholder = computed(() =>
      newPharmacyMap.study === "" ? "Select a study" : "Select a study site"
    );

    const productPlaceholder = computed(() =>
      newPharmacyMap.studysite === ""
        ? "Select a study site"
        : "Select a product"
    );

    const pharmacyPlaceholder = computed(() =>
      newPharmacyMap.product === ""
        ? "Select a product"
        : availablePharmacies.value.length > 0
        ? "Select a pharmacy"
        : "None available"
    );

    const priorityPlaceholder = computed(() =>
      newPharmacyMap.pharmacy === "" ? "Select a pharmacy" : "Select a priority"
    );

    const closeForm = () => emit("close-form");

    const handleSave = async () => {
      if (await newPharmacyMapModel.value.$validate()) {
        props.isEditing
          ? await updatePharmacyMapApiResource.fetchResource()
          : await createPharmacyMapApiResource.fetchResource();
        closeForm();
      }
    };

    const formLoading = computed(
      () =>
        createPharmacyMapApiResource.getLoading() ||
        updatePharmacyMapApiResource.getLoading()
    );

    onMounted(async () => {
      if (props.isEditing) {
        for (const [key, value] of Object.entries(props.selectedPharmacyMap)) {
          if ((value && value.length > 0) || value > 0) {
            newPharmacyMap[key] = value;
          }
        }
      }
    });

    return {
      closeForm,
      newPharmacyMap,
      newPharmacyMapModel,
      studySitePlaceholder,
      productPlaceholder,
      pharmacyPlaceholder,
      priorityPlaceholder,
      formLoading,
      handleSave,
      availableStudySites,
      availablePriorities,
      availablePharmacies,
      getStudyName,
      getStudyNameString,
    };
  },
};
</script>
